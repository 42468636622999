import React from "react";
import { makeStyles } from "@material-ui/styles";
import { navigate, useLocation } from "@reach/router";

const ModalButton = (props) => {
  const useStyles = makeStyles((theme) => ({
    modalButtonWrapper: {
      width: "100%",
    },
    occasionBtn: {
      minWidth: "11rem",
      width: "100%",
      padding: " 0 2rem",
      height: "3.5rem",
      borderRadius: "10px",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      border: "2px solid rgb(75, 75, 75);",
      fontSize: "1rem",
      fontWeight: "600",
      cursor: "pointer",
      transition: "all 0.1s ease",
      "&:disabled": {
        pointerEvents: "none",
        opacity: "0.3",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "1rem",
      },
    },

    btnBackground: {
      background: ({ primaryBtn }) => (primaryBtn ? "#000" : "#fff"),
      color: ({ primaryBtn }) => (primaryBtn ? "#fff" : "#000"),
      border: ({ primaryBtn }) =>
        primaryBtn ? "solid 1px #313A2F" : "solid 2px #000;",
      "&:hover": {
        opacity: ({ primaryBtn }) => (primaryBtn ? "0.7" : ""),
        background: ({ primaryBtn }) => (primaryBtn ? "" : "#e3e3e3"),
        transform: "translateY(-1px)",
      },
    },
  }));
  const classes = useStyles(props);
  const { setAddedOrder, selfUrl } = props;
  const location = useLocation();
  const redirect = () => {
    window.scrollTo(0, 0);
    navigate(`${location.origin}/ticketshop/cart`);
  };

  return (
    <>
      {props.redirect ? (
        <button
          className={`articleModalButton articleToCartButton ${classes.occasionBtn} ${classes.btnBackground}`}
          onClick={() => redirect()}
          aria-label={props.text}
        >
          {props.text}
        </button>
      ) : (
        <button
          className={`articleModalButton articleContinueShoppingButton ${classes.occasionBtn} ${classes.btnBackground}`}
          onClick={() => setAddedOrder(false)}
          aria-label={props.text}
        >
          {props.text}
        </button>
      )}
    </>
  );
};

export default ModalButton;
