import { useEffect } from "react";
import { useLocation, useNavigate } from "@reach/router";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const goBackUrl = localStorage.getItem("goBackUrl");
    if (goBackUrl && goBackUrl.startsWith("publicBooking")) {
      navigate(goBackUrl.split("?")?.[1]);
    }
    window.scrollTo(0, 0);
    localStorage.setItem("goBackUrl", location.href);
  }, [pathname]);

  return null;
}
