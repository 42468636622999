import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Markdown from "react-markdown";
import TicketImage from "./TicketImage";

const useStyles = makeStyles((theme) => ({
  ticketWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "start",
    transition: "all 0.3s ease",
    [theme.breakpoints.up("sm")]: {
      // padding: "0.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "1rem",
      gridTemplateColumns: "1fr",
      alignItems: "center",
      padding: "0.5rem",
    },
  },

  ticketTextWrapper: {
    marginTop: "0",
  },

  ticketTextPadding: {
    padding: "0 0 0 1.5rem",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem",
    },
  },

  ticketTextPaddingNoImg: {
    [theme.breakpoints.up("sm")]: {
      padding: "0",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "0rem 0rem",
    },
  },

  ticketText: {
    [theme.breakpoints.down("sm")]: {
      maskImage: "none",
    },
  },

  readMoreButton: {
    display: "none",
    border: "none",
    background: "transparent",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem",
      color: "#2a5fb5",
      fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "500",
      display: "block",
    },
  },
}));

const TicketIntroduction = (props) => {
  const { urlParams, ticketData, altText, text, language } = props;
  const [hideLongText, setHideLongText] = useState(true);

  const filter = ticketData.filter((el) => el.slug.toLowerCase() === urlParams);
  const classes = useStyles();
  useEffect(() => {
    setHideLongText(false);
  }, [text]);

  return (
    <div className={classes.ticketWrapper}>
      {filter && filter[0]?.image ? (
        <TicketImage
          imageUrl={`${
            filter[0].image
              ? `/ticketshop${props.config.imageFolderUrl}${filter[0].image}`
              : ""
          }`}
          altText={altText}
        />
      ) : (
        ""
      )}
      {filter && filter.length >= 1 ? (
        <div
          className={`${classes.ticketTextWrapper} ${
            filter[0].image
              ? classes.ticketTextPadding
              : classes.ticketTextPaddingNoImg
          }  `}
        >
          <Typography component="div">
            <div className={classes.showMore}>
              {text.length > 300 && window.screen.availWidth <= 768 ? (
                <div
                  className="ticketTextWrapper"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Markdown
                    className={
                      hideLongText === true
                        ? classes.ticketText
                        : classes.ticketTextGradient
                    }
                    source={
                      hideLongText === false
                        ? text.substr(0, 300) + "... "
                        : text
                    }
                    escapeHtml={false}
                    skipHtml={false}
                  />
                </div>
              ) : (
                <Markdown
                  className="ticketTextWrapper"
                  source={text}
                  escapeHtml={false}
                  skipHtml={false}
                />
              )}
              {text.length > 300 && window.screen.availWidth <= 768 ? (
                <button
                  className={classes.readMoreButton}
                  style={{ fontSize: "1rem", paddingLeft: 0 }}
                  onClick={() => setHideLongText(!hideLongText)}
                >
                  {hideLongText === false
                    ? language.ReadMore
                    : language.ShowLessText}
                </button>
              ) : (
                ""
              )}
            </div>
          </Typography>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TicketIntroduction;
