import moment from "moment";

export const getInitialMonth = (config, product) => {
  if (!product.overrideStartDate) return moment();
  if (config.overrideStartdate) {
    if (
      config.overrideStartdate.allowedRange &&
      moment() < moment(config.overrideStartdate.allowedRange.start)
    ) {
      return moment(config.overrideStartdate.allowedRange.start);
    }
  }
  return moment();
};

export const getFormConfig = (config, product) => {
  const plu = product.plu ? product.plu : product.articles[0].plu;
  if (!config) return undefined;
  if (config.filter((c) => c.plu === plu).length > 0)
    return config.find((c) => c.plu === plu);
  return config.find((c) => c.plu === "default");
};

export const getSaleData = (
  product,
  article,
  values,
  photo,
  quantity,
  overrideStartDate
) => {
  const saleArticle = product.articles
    ? product.articles.find((a) => a.id === article)
    : product;
  if (!saleArticle) throw new Error("No article");
  if (!values) {
    return {
      plu: saleArticle.plu,
      quantity: quantity || 1,
      overrideStartDate,
    };
  }
  const { plu } = saleArticle;
  const ticketHolderCategoryId = saleArticle.ticketHolders[0].categoryId;
  const ticketOwners = [
    {
      ticketHolderCategoryId,
      customerData: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        civicRegistrationNumber: values.civicRegistrationNumber,
        phoneNumbers: [
          {
            type: "Cell",
            number: values.cellPhone,
          },
        ],
        addresses: [
          {
            type: "Generic",
            street: values.street,
            streetNumber: values.streetNumber,
            city: values.city,
            zipCode: values.zipCode,
            countryCode: values.countryCode,
          },
        ],
        photoBase64: photo && photo,
        photoExtension: photo && "jpg",
      },
    },
  ];
  return { plu, quantity: 1, ticketOwners };
};

export const isPersonal = (config, product) => {
  let productGroupConfig = config.productGroupConfig.find(
    (c) => c.name === product.name
  );
  if (productGroupConfig?.useGroupsAsItems) {
    return product.articles[0].isPersonalTicket;
  }
  return product.isPersonalTicket;
};

export const formatDisplayPrice = (price, currency) => {
  if (!price) return "-";
  switch (currency) {
    case "SEK":
    case "NOK":
    case "DKK":
      return `${Number(price).toFixed(2)} kr`;
    case "EUR":
      return `€${Number(price).toFixed(2)}`;
    case "USD":
      return `$${Number(price).toFixed(2)}`;
    default:
      return price;
  }
};

export const getArticleConfig = (config, plu) => {
  return config.find((p) => p.plu === plu);
};
