import React, { useMemo, useState } from "react";
import { createStyles, Textarea, TextInput } from "@mantine/core";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: "1rem !important",
  },
  description: {
    fontSize: "1rem",
    marginTop: "-0.35rem",
    paddingBottom: ".5rem",
    color: "black",
  },
}));

const TextField = ({
  id,
  index,
  placeholder,
  description,
  required,
  label,
  min,
  max,
  form,
  extraFormIsValid,
  extraFormIsValidSet,
}) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const [isError, isErrorSet] = useState(null);
  const { classes } = useStyles();

  const addInput = (e) => {
    const split = e.target.value.split("");
    form.setFieldValue(`formFields.${index}.stringVal`, e.target.value);
    if (split.length !== 0 && split.length < min) {
      isErrorSet(
        `${language.FormErrorMinChar} ${min - 1} ${language.Characters}`
      );
      extraFormIsValidSet([...extraFormIsValid, { id: index, isValid: false }]);
    } else if (!required && split.length === 0) {
      const filter =
        extraFormIsValid && extraFormIsValid.filter((el) => el.id !== index);
      extraFormIsValidSet(filter);
    } else if (split.length > max) {
      isErrorSet(`${language.FormErrorMaxChar} ${max} ${language.Characters}`);
      extraFormIsValidSet([...extraFormIsValid, { id: index, isValid: false }]);
    } else if (!required || split.length >= min || split.length <= max) {
      isErrorSet(null);

      if (extraFormIsValid) {
        const filter =
          extraFormIsValid && extraFormIsValid.filter((el) => el.id !== index);
        extraFormIsValidSet(filter);
      }
    }
    if (!required && split.length === 0) {
      isErrorSet(null);
    }
  };

  const getValue = useMemo(() => {
    const filter = form.values?.formFields.filter((el) => el.id === id)?.[0];
    if (!filter?.stringVal) {
      return "";
    } else {
      return filter?.stringVal;
    }
  }, [id, form]);

  return (
    <div style={{ width: "100%" }}>
      {max > 50 ? (
        <Textarea
          label={label}
          description={description}
          classNames={{
            label: classes.label,
            description: classes.description,
          }}
          placeholder={placeholder}
          withAsterisk={required}
          style={{ width: "100%" }}
          error={isError}
          value={getValue}
          onChange={(e) => addInput(e)}
        />
      ) : (
        <TextInput
          label={label}
          description={description}
          classNames={{
            label: classes.label,
            description: classes.description,
          }}
          placeholder={placeholder}
          withAsterisk={required}
          style={{ width: "100%" }}
          error={isError}
          value={getValue}
          onChange={(e) => addInput(e)}
        />
      )}
    </div>
  );
};

export default TextField;
