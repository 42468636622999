import React from "react";
import { Checkbox, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: "1rem !important",
  },
  description: {
    fontSize: "1rem",
    marginTop: "0.25rem",
    paddingBottom: ".5rem",
    color: "black",
  },
}));

const CheckboxGroupField = ({
  form,
  label,
  description,
  values,
  required,
  index,
}) => {
  const { classes } = useStyles();

  return (
    <div className={"pbCheckbox"}>
      <Checkbox.Group
        classNames={{ label: classes.label, description: classes.description }}
        label={label}
        description={description}
        withAsterisk={required}
        {...form.getInputProps(`formFields.${index}.multiVal`)}
      >
        {values.map((el) => (
          <Checkbox key={el} label={el} value={el} color="indigo" />
        ))}
      </Checkbox.Group>
    </div>
  );
};

export default CheckboxGroupField;
