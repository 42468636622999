import React, { useContext } from "react";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/styles";
import { CLOSE_TOAST, Context } from "../../context";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.app.successColor,
    color: "white",
  },
  error: {
    backgroundColor: theme.app.dangerColor,
    color: "white",
  },
  info: {
    backgroundColor: theme.app.infoColor,
    color: "white",
  },
  warning: {
    backgroundColor: theme.app.warningColor,
    color: "white",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: "8px",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const Content = (props) => {
  const classes = useStyles();
  const { className, message, onClose, variant } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
};

const Toast = (props) => {
  const {
    state: { toastOpen, toastText, toastVariant },
    dispatch,
  } = useContext(Context);
  const handleClose = () => dispatch({ type: CLOSE_TOAST });
  return (
    <div role="alert" aria-live="assertive">
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        ClickAwayListenerProps={{
          mouseEvent: false,
        }}
      >
        {!toastVariant ? (
          <div />
        ) : (
          <Content
            onClose={handleClose}
            variant={toastVariant}
            message={toastText}
          />
        )}
      </Snackbar>
    </div>
  );
};

export default Toast;
