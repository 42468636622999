import React from "react";
import { Card, createStyles, Image, Text, UnstyledButton } from "@mantine/core";
import { useNavigate } from "@reach/router";
import { useSelector } from "react-redux";
import slugify from "../common/slugify";
import { v4 as uuid } from "uuid";
import { useSessionStorage } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  cardWrapper: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    cursor: "pointer",
    transition: "0.2s",
    border: "none",
    borderRadius: "8px",
    padding: "1rem 1rem",
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.blue[3]
          : theme.colors.gray[2],
      transform: "translateY(-3px)",
      p: {
        color: "black",
      },
      div: {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.blue[3]
            : theme.colors.gray[2],
      },
    },
  },

  card: {
    transition: "0.2s",
  },

  title: {
    display: "block",
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.xs / 2,
    fontSize: "1.25rem",
  },

  footer: {
    marginTop: theme.spacing.md,
  },
}));

const CardV2 = ({ item, slug }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [selected, setSelected] = useSessionStorage({
    key: "activeLink",
    defaultValue: "",
  });
  const { classes, cx, theme } = useStyles();
  const navigate = useNavigate();

  const link = () => {
    setSelected(item?.text?.primary.toLowerCase());
    const filter = config.landingPage.links.filter(
      (el) => el.name === item.name
    )?.[0];
    if (slug) {
      return slug;
    }
    if (item?.eventCalendar) {
      return `/ticketshop/tickets/${slugify(item.name)}`;
    }
    if (item?.articleBooking) {
      return `/ticketshop/booking/${slugify(item.name)}`;
    }
    if (item?.externalLink) {
      return item?.externalLink;
    }
    if (item?.internalLink) {
      return item?.internalLink;
    }
    if (item?.publicBooking) {
      return `/ticketshop/publicbooking/${slugify(item.name)}`;
    } else {
      if (filter) {
        return `/ticketshop/${slugify(filter.name)}`;
      }
    }
  };
  return (
    <UnstyledButton
      component={"a"}
      className={`${classes.cardWrapper} landing-listButton`}
      onClick={() => !item.externalLink && navigate(link())}
      href={item?.externalLink && item?.externalLink}
      target={item?.externalLink && "_blank"}
      key={uuid()}
      aria-label={`${item?.text?.primary}, ${item?.text?.secondary}`}
      tabIndex={0}
    >
      <Card
        className={classes.card}
        radius="md"
        key={item?.text?.primary}
        p={0}
      >
        <Card.Section>
          <Image
            src={item.image}
            height={"20rem"}
            withPlaceholder
            radius="md"
            alt={`${language.PhotoOf} ${item?.text?.primary}`}
          />
        </Card.Section>

        <Text
          className={`${classes.title} landing-cardPrimaryText`}
          weight={500}
          component="p"
          mt={"xs"}
        >
          {item?.text?.primary}
        </Text>

        <Text
          className="landing-cardSecondaryText"
          size="sm"
          lineClamp={4}
          component="p"
          mt={"0"}
          weight={"600"}
          mb={0}
          color={"gray.7"}
        >
          {item?.text?.secondary} &nbsp;
        </Text>
      </Card>
    </UnstyledButton>
  );
};
export default CardV2;
