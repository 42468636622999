import React from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import RightIcon from "@material-ui/icons/ChevronRight";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import { getMonths, hasMonthEvent } from "./util";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  outer: {
    borderBottom: `1px solid ${theme.app.borderColor}`,
    borderTop: `1px solid ${theme.app.borderColor}`,
    margin: "16px 0",
    backgroundColor: theme.app.background[1],
  },
  outerWithNav: {
    borderBottom: `1px solid ${theme.app.borderColor}`,
    marginBottom: "16px",
  },
  outerScroll: {
    position: "relative",
    padding: "0 50px",
  },
  container: {
    overflowX: "auto",
    overflowY: "hidden",
    "-ms-overflow-style": "-ms-autohiding-scrollbar",
    "-webkit-overflow-scrolling": "touch",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  monthsContainer: {
    display: "flex",
  },
  year: {
    fontSize: "0.75rem",
    color: theme.app.labelColor,
    padding: "0 16px",
  },
  invisible: {
    fontSize: "0.75rem",
    color: theme.app.labelColor,
    visibility: "hidden",
  },
  monthContainer: {
    padding: "8px 0 0",
  },
  month: {
    borderBottom: "2px solid transparent",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    cursor: "pointer",
    padding: "8px 16px",
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  monthDisabled: {
    color: theme.app.labelColor,
    opacity: 0.3,
    cursor: "auto",
    pointerEvents: "none",
  },
  monthActive: {
    borderColor: theme.palette.primary.light,
    cursor: "auto",
    pointerEvents: "none",
  },
  rightButtonContainer: {
    position: "absolute",
    zIndex: 1,
    top: "2px",
    right: 0,
    bottom: "2px",
    display: "flex",
    alignItems: "center",
  },
  leftButtonContainer: {
    position: "absolute",
    zIndex: 1,
    top: "2px",
    left: 0,
    bottom: "2px",
    display: "flex",
    alignItems: "center",
  },
  navButton: {
    padding: "8px",
    color: theme.app.labelColor,
    height: "100%",
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
    "&:hover": {
      color: theme.app.secondaryColor,
    },
  },
  removeBorderBg: {
    border: "none",
    background: "none",
  },
}));

const EventCalendar = (props) => {
  const {
    startMonth = moment().format("YYYYMM"),
    duration = 18,
    culture,
    active,
    setActive,
    events,
    withNav,
  } = props;
  const classes = useStyles();
  const containerRef = React.createRef();

  const monthsRef = React.createRef();
  const months = getMonths(startMonth, duration);
  const locale = culture.substring(0, 2).toLowerCase();
  const showScroll = () => {
    if (!containerRef.current) return true;
    if (
      containerRef.current &&
      containerRef.current.scrollWidth > containerRef.current.clientWidth
    ) {
      return true;
    }
    return false;
  };
  return (
    <div
      className={classNames(
        withNav ? classes.outerWithNav : classes.outer,
        showScroll() && classes.outerScroll
      )}
    >
      <div className={classes.container} ref={containerRef}>
        <div className={classes.monthsContainer} ref={monthsRef}>
          {months.map((m, i) => {
            const date = `${m}01`;
            const firstOfYear = moment(date).month() < 1;
            const isEnabled = hasMonthEvent(m, events);
            const isActive = active === Number(m);
            return (
              <Typography
                key={m}
                component="div"
                className={classes.monthContainer}
              >
                <Typography
                  component="div"
                  className={
                    i < 1 || firstOfYear ? classes.year : classes.invisible
                  }
                >
                  {moment(date).format("YYYY")}
                </Typography>
                <button
                  className={classNames(
                    classes.removeBorderBg,
                    classes.month,
                    !isEnabled && classes.monthDisabled,
                    isActive && classes.monthActive
                  )}
                  onClick={() => setActive(Number(m))}
                  disabled={!isEnabled && classes.monthDisabled}
                >
                  {moment(date).locale(locale).format("MMMM")}
                </button>
              </Typography>
            );
          })}
        </div>
      </div>
      {showScroll() && (
        <>
          <div className={classes.leftButtonContainer}>
            <ButtonBase
              onClick={() => (containerRef.current.scrollLeft -= 150)}
              tabIndex={0}
              className={classes.navButton}
            >
              <LeftIcon />
            </ButtonBase>
          </div>
          <div className={classes.rightButtonContainer}>
            <ButtonBase
              onClick={() => (containerRef.current.scrollLeft += 150)}
              tabIndex={0}
              className={classes.navButton}
            >
              <RightIcon />
            </ButtonBase>
          </div>
        </>
      )}
    </div>
  );
};

export default EventCalendar;
