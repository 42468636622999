import React, { useMemo } from "react";
import { createStyles, Divider, Image, UnstyledButton } from "@mantine/core";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import { navigate } from "@reach/router";
import slugify from "../../common/slugify";
import Markdown from "react-markdown";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridGap: "1rem",
    padding: "0rem 1rem",
    transition: "0.2s",
    borderRadius: "8px",
    ":hover": {
      backgroundColor: theme.colors.gray[1],
    },
    "@media screen and (max-width: 768px)": {
      gridGap: "0.5rem",
      padding: "0rem 0.5rem",
      gridTemplateColumns: "auto auto",
    },
  },
  isCard: {
    ":hover": {
      transform: "translateY(-3px)",
    },
  },
  priceWrapper: {
    p: {
      textAlign: "right",
    },
    "&:last-child": {
      justifySelf: "end",
      alignSelf: "center",
    },
  },
  imageWrapper: {
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    marginBottom: 0,
    marginTop: ".6rem",
    "@media screen and (max-width: 768px)": {},
  },
  price: {
    fontWeight: "600",
    color: theme.colors.gray[8],
    paddingRight: "1rem",
    marginTop: ".5rem",
  },
  grid: {
    display: "flex",
    gap: "1rem",
    width: "100%",
    padding: "1rem 0rem",
    "@media screen and (max-width: 768px)": { gap: "0.7rem" },
  },
  markdown: {
    p: {
      display: "inline",
      color: "black",
    },
  },
}));

const Event = ({ date, event }) => {
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));
  const listType =
    window.innerWidth < 768
      ? config?.allEventsListType?.sm
      : config?.allEventsListType?.lg;
  const isCard = listType
    ? listType === "CARD"
    : window.innerWidth < 768
    ? config.articleListType?.sm === "CARD"
    : config.articleListType?.lg === "CARD";
  const { classes, theme } = useStyles();

  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const image = useMemo(() => {
    return `${uri}/allotmentimage/${event.imageFileName}`;
  }, [event]);

  const filter = useMemo(
    () => event.translation.filter((el) => el.culture === culture)[0],
    [event]
  );

  const links = () => {
    if (config?.ticketConfigurator) {
      const isEventCalendar = config?.ticketConfigurator.filter(
        (el) => slugify(el.name) === slugify(event.description)
      )?.[0];
      if (isEventCalendar) return `tickets/${slugify(event.description)}`;
      return `events/${slugify(event.description)}`;
    } else {
      return `events/${slugify(event.description)}`;
    }
  };

  return (
    <div style={{ height: isCard && "100%" }}>
      <UnstyledButton
        className={`${classes.wrapper} ${
          isCard && classes.isCard
        } allEventsEventButton`}
        onClick={() => navigate(links(), { state: date })}
        style={{
          gridTemplateColumns: isCard ? "1fr" : "1fr",
          height: isCard && "100%",
          alignContent: isCard && "space-between",
          width: "100%",
        }}
        aria-label={filter?.name}
      >
        <div
          className={classes.grid}
          style={{
            flexDirection: isCard ? "column" : "row",
          }}
        >
          <div
            className={classes.imageWrapper}
            style={{ width: isCard ? "100%" : "auto" }}
          >
            <Image
              radius="md"
              src={image}
              fit={"cover"}
              height={isCard ? "20rem" : "6rem"}
              width={isCard ? "100%" : "6rem"}
              withPlaceholder
              alt={`${language.EventAltLabel} ${filter?.name}`}
            />
          </div>
          <div>
            <p
              className={`${classes.title} boldText`}
              style={{ margin: isCard && "0 0 0.5rem 0", color: "black" }}
            >
              {filter?.name}
            </p>
            <div className={classes.markdown} style={{ marginTop: "0.25rem" }}>
              <Markdown
                source={
                  isCard && filter?.description.length > 230
                    ? filter.description.substring(0, 230) + "..."
                    : !isCard
                    ? filter?.description.substring(0, 70)
                    : filter?.description
                }
                escapeHtml={false}
                skipHtml={false}
              />
            </div>
          </div>
        </div>
      </UnstyledButton>
      {!isCard && <Divider color={theme.colors.gray[2]} />}
    </div>
  );
};

export default Event;
