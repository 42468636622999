import React from "react";
import DayPickerSingleDateControllerWrapper from "./wrapper";
import "react-dates/initialize";

const Calendar = React.memo(function Calendar(props) {
  const {
    initialDate,
    onDateChange,
    startdateConfig,
    highlightedDates,
    initialVisibleMonth,
    isDayBlocked,
    isOutsideRange,
    isOverrideStartdate,
    occasionDates,
  } = props;
  return (
    <DayPickerSingleDateControllerWrapper
      blockedDates={(startdateConfig && startdateConfig.blockedDates) || []}
      allowedRange={(startdateConfig && startdateConfig.allowedRange) || {}}
      occasionDates={occasionDates || []}
      highlightedDates={highlightedDates || []}
      initialDate={initialDate}
      initialVisibleMonth={initialVisibleMonth}
      isDayBlocked={isDayBlocked}
      isOutsideRange={isOutsideRange}
      isOverrideStartdate={isOverrideStartdate}
      onDateChange={(date) => onDateChange(date)}
    />
  );
});

export default Calendar;