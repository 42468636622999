import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  headerContent: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
  },
}));

const HeaderContent = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.headerContent}>{children}</div>;
};

export default HeaderContent;
