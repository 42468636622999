// open price
export const formatDisplayPrice = (price, currency) => {
  if (!price) return "-";
  switch (currency) {
    case "SEK":
    case "NOK":
    case "DKK":
      return `${Number(price).toFixed(2)} kr`;
    case "EUR":
      return `€${Number(price).toFixed(2)}`;
    case "USD":
      return `$${Number(price).toFixed(2)}`;
    default:
      return price;
  }
};

export const getDisplayPrice = (articles) => {
  var filteredArticles = articles.filter((a) => a.price !== undefined);

  if (filteredArticles.length > 0) {
    filteredArticles.sort(
      (a, b) => a.price?.amountInclVat - b.price?.amountInclVat
    );
    const first = filteredArticles[0].price?.amountInclVat;
    const last =
      filteredArticles[filteredArticles.length - 1].price?.amountInclVat;
    if (first !== last) {
      return `${filteredArticles[0].priceInclVat} - ${
        filteredArticles[filteredArticles.length - 1].priceInclVat
      }`;
    }
    return filteredArticles[0].priceInclVat;
  }
  return undefined;
};
