import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import EventCalendar from "../common/EventCalendar";
import MonthPicker from "../common/EventCalendar/monthPicker";
import { filterEvents, getEventConfig, getOccasionMonths } from "./util";
import ListItem from "./listItem";
import { STATE_CULTURE } from "../../common/state";

const useStyles = makeStyles({
  container: {
    paddingBottom: 0,
  },
  listContainer: {
    marginTop: "16px",
  },
  largeScreen: {
    display: "block",
    "@media(max-width: 667px)": {
      display: "none",
    },
  },
  smallScreen: {
    display: "none",
    "@media(max-width: 667px)": {
      display: "block",
    },
  },
});

const Index = (props) => {
  const { events, filter, setFilter, withNav } = props;
  const classes = useStyles();
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));

  useEffect(() => {
    const hasOccasionsInFilterMonth = filterEvents(events, filter).length > 0;
    if (!hasOccasionsInFilterMonth) {
      setFilter(Number(getOccasionMonths(events).sort()[0]));
    }
  }, [events, filter, setFilter]);
  const filteredEvents = filterEvents(events, filter);

  return (
    <div className={classes.container}>
      {events.length > 0 && (
        <div>
          <div className={classes.largeScreen}>
            <EventCalendar
              active={filter}
              setActive={setFilter}
              events={events}
              culture={culture}
              withNav={withNav}
            />
          </div>
          <div className={classes.smallScreen}>
            <MonthPicker
              active={filter}
              setActive={setFilter}
              events={events}
              culture={culture}
              withNav={withNav}
            />
          </div>
        </div>
      )}
      <div className={classes.listContainer}>
        {filteredEvents.map((e, i) => {
          const eventConfig = getEventConfig(config, e.description);
          const occasionsInMonth = e.occasions.filter(
            (o) => Number(moment(o.time).format("YYYYMM")) === filter
          );
          if (!eventConfig) return null;
          return (
            <ListItem
              key={i}
              event={e}
              occasionsInMonth={occasionsInMonth}
              eventConfig={eventConfig}
              config={config}
              culture={culture}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Index;
