import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const HamburgerMenu = ({ open, setOpen, changeBackground }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  useEffect(() => {
    setOpen(false);
  }, [window.location.pathname]);

  useEffect(() => {
    const body = document.getElementById("AppBody");
    if (open) {
      body.setAttribute("aria-hidden", true);
    } else {
      body.removeAttribute("aria-hidden");
    }
  }, [open]);

  return (
    <motion.button
      onClick={() => setOpen(!open)}
      className="mobileMenuV2"
      data-isopen={open}
      initial={false}
      animate={open ? "open" : "closed"}
      aria-label={open ? language.Close : language.OpenMenu}
      aria-expanded={open}
    >
      <motion.svg width="25" height="25" viewBox="0 0 23 23">
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
          className={`menuLinePath ${
            !open
              ? changeBackground
                ? "darkHamburgerMenu"
                : "lightHamburgerMenu"
              : ""
          }`}
          data-isopen={open}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
          className={`menuLinePath ${
            !open
              ? changeBackground
                ? "darkHamburgerMenu"
                : "lightHamburgerMenu"
              : ""
          }`}
          data-isopen={open}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
          className={`menuLinePath ${
            !open
              ? changeBackground
                ? "darkHamburgerMenu"
                : "lightHamburgerMenu"
              : ""
          }`}
          data-isopen={open}
        />
      </motion.svg>
    </motion.button>
  );
};

export default HamburgerMenu;
