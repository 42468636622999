import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  createStyles,
  Divider,
  Image,
  Skeleton,
  UnstyledButton,
} from "@mantine/core";
import { useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import slugify from "../common/slugify";
import { getSaleData } from "../Product/util";
import { addArticle, addToCartTracking } from "../../common/sale";
import { DataContext } from "../common/dataProvider";
import { SaleContext } from "../common/saleProvider";
import "./styles.css";
import { getProduct } from "../../Pages/ProductPage/util";
import { STATE_CURRENCY } from "../../common/state";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "90% 1fr",
    gridGap: "1rem",
    padding: "0rem 1rem",
    ":hover": {
      backgroundColor: theme.colors.gray[1],
      p: {
        color: "black",
      },
    },
    "@media screen and (max-width: 768px)": {
      gridGap: "0.5rem",
      padding: "0rem 0.5rem",
      gridTemplateColumns: "auto auto",
    },
  },
  priceWrapper: {
    p: {
      textAlign: "right",
    },
    "&:last-child": {
      justifySelf: "end",
      alignSelf: "center",
    },
  },
  imageWrapper: {},
  title: {
    fontWeight: "bold",
    marginBottom: 0,
    marginTop: "1rem",
    "@media screen and (max-width: 768px)": {},
  },
  price: {
    paddingRight: "1rem",
    marginTop: ".5rem",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    width: "100%",
    padding: "1rem 0rem",
    borderRadius: "8px",
    "@media screen and (max-width: 768px)": { gap: "0.7rem" },
  },
  skeleton: {
    display: "grid",
    gridTemplateColumns: ".85fr 1.5fr .6fr",
    padding: "1rem .5rem",
  },
  skeletonTextWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
}));
const ListItemV2 = ({ product, culture }) => {
  const [addedToCart, addedToCartSet] = useState(null);
  const [price, priceSet] = useState(null);
  const [clicked, clickedSet] = useState(false);
  const { config, language, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { classes, theme } = useStyles();
  const rdxDispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useContext(DataContext);
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;

  const regex = /\D/g;
  const reduce =
    product.products &&
    product.products.reduce((acc, curr) => {
      acc.push(curr.priceInclVat);
      return acc;
    }, []);

  const reduceChooseValue =
    product.openPriceValues &&
    product.openPriceValues.reduce((acc, curr) => {
      acc.push(curr);
      return acc;
    }, []);

  const sortPrice = (prices) => {
    if (prices && typeof prices?.[0] === typeof 0) {
      const sortOpenPrice = prices.sort((a, b) => a - b);
      return sortOpenPrice;
    } else if (prices && typeof prices?.[0] === typeof "") {
      const sortStringValue = prices.sort(
        (a, b) => a.replace(regex, "") - b.replace(regex, "")
      );
      return sortStringValue;
    }
  };

  const links = (item) => {
    const eventCalendarLinks = config.eventConfig.filter(
      (el) => el.eventCalendar && el?.name === item?.name
    );
    const articleBookingLinks = config.eventConfig.filter(
      (el) => el.articleBooking && el?.name === item?.name
    );
    if (eventCalendarLinks && eventCalendarLinks[0]) {
      return navigate(`tickets/${slugify(eventCalendarLinks[0].name)}`);
    }
    if (articleBookingLinks && articleBookingLinks[0]) {
      return navigate(`booking/${slugify(articleBookingLinks[0]?.name)}`);
    }
    if (!eventCalendarLinks[0] && !articleBookingLinks[0]) {
      return navigate(`/ticketshop/${product.slug}`);
    }
  };

  useEffect(() => {
    const giftCard = config?.productConfig?.filter((el) =>
      el.description?.includes("presentkort")
    );
    const filterGiftCard = giftCard?.filter((el) => el.plu === product.plu);

    if (product?.priceInclVat && !reduce && !reduceChooseValue) {
      priceSet(product.priceInclVat);
    }

    if (product?.displayPrice && !reduce && !reduceChooseValue) {
      priceSet(product.displayPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length > 0
    ) {
      priceSet(
        `${language.FromCost} ${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 0
    ) {
      priceSet(language.OpenPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 1
    ) {
      priceSet(
        `${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (reduce) {
      priceSet(
        `${sortPrice(reduce)?.[0]} - ${
          sortPrice(reduce)?.[sortPrice(reduce).length - 1]
        }`
      );
    }

    if (reduceChooseValue) {
      priceSet(
        `${sortPrice(reduceChooseValue)?.[0]} kr - ${
          sortPrice(reduceChooseValue)?.[
            sortPrice(reduceChooseValue).length - 1
          ]
        } kr`
      );
    }
  }, [config, product]);

  const trackProductData = [
    {
      id: product.plu,
      name: product.name,
      price: product?.price?.amountInclVat,
      quantity: 1,
    },
  ];

  const handleAdd = () => {
    addToCartTracking(currency, trackProductData);
    clickedSet(true);
    const callback = (status, msg) => {
      if (status === 200) {
        clickedSet(false);

        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        clickedSet(false);

        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    let article;
    let values;
    let photo;
    let overrideDate;
    let quantity = 1;
    const saleData = getSaleData(
      product,
      article,
      values,
      photo,
      quantity,
      overrideDate
    );
    rdxDispatch(addArticle(saleData, callback));
  };

  const image = useMemo(() => {
    if (product?.images?.[0].startsWith("http")) {
      return product?.images?.[0];
    }
    if (product?.images?.[0].startsWith("/ticketshop/")) {
      return `${process.env.PUBLIC_URL}${product?.images?.[0].replace(
        "/ticketshop/",
        "/"
      )}`;
    }
    if (product?.images?.[0]) {
      return `${process.env.PUBLIC_URL}${product?.images?.[0]}`;
    } else {
      return "";
    }
  }, [product.images]);

  const productData = useMemo(() => {
    if (sale) {
      return getProduct(data, config, product.plu, culture);
    }
  }, [data, product, config, culture, sale]);

  if (!product || !productData)
    return (
      <>
        <div className={classes.skeleton}>
          <Skeleton height={96} width={96} radius="md" />
          <div className={classes.skeletonTextWrapper}>
            <Skeleton height={19} width="75%" radius="sm" />
            <Skeleton height={17} mb={16} width="25%" radius="sm" />
          </div>
          <Skeleton height={42} width={"100%"} radius="sm" mt={16} />
        </div>
        <Divider color={theme.colors.gray[2]} />
      </>
    );

  return (
    <>
      <div className={classes.wrapper}>
        <UnstyledButton
          className={`${classes.grid} listItemButton`}
          onClick={() => links(product)}
          component="a"
        >
          <div className={classes.imageWrapper}>
            <Image
              radius="md"
              src={image}
              fit={"cover"}
              height={"6rem"}
              width={"6rem"}
              withPlaceholder={image === ""}
              alt={`${language.ProductAltLabel} ${product?.name}`}
            />
          </div>
          <div>
            <p className={classes.title}>{product.name}</p>
            <p className={classes.price} color={"gray.7"} weight={"500"}>
              {price}
            </p>
          </div>
        </UnstyledButton>
        <div
          className={classes.priceWrapper}
          role="region"
          aria-live="assertive"
        >
          {productData?.isProduct ? (
            <Button
              className={`add_to_cart_product ${
                !addedToCart && "regularButton"
              }`}
              classNames={{
                label: "add_to_cart_product",
                inner: "add_to_cart_product",
              }}
              size="md"
              onClick={() => handleAdd()}
              color={addedToCart && "green"}
              loading={clicked}
              disabled={product.inStock <= 0}
            >
              {!addedToCart && (!product.inStock || product.inStock >= 1)
                ? language.Buy
                : !addedToCart && product.inStock <= 0
                ? language.SoldOut
                : addedToCart && language.Added_To_Cart}
            </Button>
          ) : (
            <Button
              size="md"
              onClick={() => links(product)}
              variant="outline"
              className="productChooseBtn"
              aria-label={`${language.Choose} ${product.name}`}
            >
              {language.Choose}
            </Button>
          )}
        </div>
      </div>
      <Divider color={theme.colors.gray[2]} />
    </>
  );
};

export default ListItemV2;
