import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  createStyles,
  RangeSlider,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { DateRangePicker } from "@mantine/dates";
import "dayjs/locale/sv";
import "./styles.css";
import { useMediaQuery } from "@mantine/hooks";
import autoAnimate from "@formkit/auto-animate";
import _ from "lodash";

const useStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: "80rem",
    backgroundColor: "#F0F4F8",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
    height: "100%",
    borderRadius: "6px",
    padding: "2rem 2rem 2.5rem 2rem",
    color: "white",
    marginBottom: "1rem",
    marginTop: "1rem",
    "@media screen and (max-width: 768px)": {
      padding: "1.35rem 1.35rem 1.5rem 1.35rem",
    },
  },
  filterWrapper: {
    display: "grid",
    gridGap: "2rem",
    gridTemplateColumns: "1fr 30% 1fr",
    alignItems: "start",
    justifyContent: "space-between",
    color: "white",
    marginTop: "1rem",
    "@media screen and (max-width: 768px)": {
      display: "flex",
      flexDirection: "column",
      // gridTemplateColumns: "1fr",
      gap: "1.25rem",
    },
  },
  datePicker: {
    width: "100%",
    label: {
      color: "black",
      fontSize: "1rem",
    },
  },
  sliderWrapper: {
    color: "black",
  },
  label: {
    top: 0,
    height: 28,
    lineHeight: "28px",
    width: 34,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    backgroundColor: "transparent",
  },

  thumb: {
    backgroundColor: "var(--theme-primary-color)",
    height: 28,
    width: 34,
    border: "none",
  },

  dragging: {
    transform: "translate(-50%, -50%)",
  },
  search: {
    borderColor: "#8A8C93",
    label: {
      color: "black",
      fontSize: "1rem",
    },
  },
}));
const EventFilter = ({
  events,
  setFilter,
  priceVal,
  priceValSet,
  priceBeforeChange,
  priceBeforeChangeSet,
  text,
  textSet,
  date,
  dateSet,
}) => {
  const [initialValues, initialValuesSet] = useState(null);
  const [newValues, newValuesSet] = useState([null]);
  const [show, showSet] = useState(false);
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const isMobile = useMediaQuery("(max-width: 755px)");
  const { classes } = useStyles();
  const parent = useRef(null);
  const getPriceRange = events.reduce((acc, curr) => {
    acc.push(curr.articles);
    return acc.flat();
  }, []);

  const articles = getPriceRange.sort(
    (a, b) => parseFloat(a.priceInclVat) - parseFloat(b.priceInclVat)
  );

  const maxDate = events.reduce((acc, curr) => {
    acc.push(curr.occasions);
    return acc.flat().sort((a, b) => new Date(a.time) - new Date(b.time));
  }, []);

  useEffect(() => {
    initialValuesSet([
      date,
      [
        parseFloat(articles?.[0]?.priceInclVat),
        parseFloat(articles?.[articles.length - 1]?.priceInclVat),
      ],
      text,
    ]);
  }, []);

  useEffect(() => {
    newValuesSet([date, priceVal, text]);
  }, [date, priceVal, text]);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    if (!_.isEqual(initialValues, newValues)) {
      showSet(true);
    }
    if (_.isEqual(initialValues, newValues)) {
      showSet(false);
    }
  }, [newValues, initialValues]);

  return (
    <div className={`${classes.wrapper} allEventsFilter`}>
      <Title order={2} size="h3" color="black">
        {language.FilterEvents}
      </Title>
      <Text size="sm" color="gray.7" component={"span"}>
        {language.FilterEventsBody}
      </Text>
      <div className={classes.filterWrapper} ref={parent}>
        <DateRangePicker
          label={language.Choose_From_To_Date}
          placeholder={language.Choose_Date}
          value={date}
          onChange={dateSet}
          className={classes.datePicker}
          locale="sv"
          size="lg"
          radius="md"
          dropdownType={isMobile ? "modal" : "popover"}
          minDate={new Date(maxDate?.[0]?.time)}
          maxDate={new Date(maxDate?.[maxDate.length - 1]?.time)}
          inputFormat="D MMMM YYYY"
          labelFormat="D MMMM YYYY"
          defaultValue={[new Date(), new Date()]}
          clearable={false}
        />
        <div className={classes.sliderWrapper} style={{ width: "100%" }}>
          <Text component="label" htmlFor="allEventsRangeSlider" size={"md"}>
            {language.ChoosePrice}
          </Text>
          <RangeSlider
            id="allEventsRangeSlider"
            labelAlwaysOn
            value={priceBeforeChange}
            onChange={priceBeforeChangeSet}
            onChangeEnd={priceValSet}
            min={0}
            max={
              parseFloat(articles?.[articles.length - 1]?.priceInclVat) + 100
            }
            defaultValue={[
              0,
              parseFloat(articles?.[articles.length - 1]?.priceInclVat + 100),
            ]}
            classNames={classes}
            mt={"0.9rem"}
            size={"lg"}
            thumbFromLabel={language.ChooseFromPrice}
            thumbToLabel={language.ChooseToPrice}
          />
        </div>
        <TextInput
          label={language.Search}
          icon={<BiSearch style={{ height: "1.35rem", width: "1.35rem" }} />}
          radius="md"
          size="md"
          placeholder={language.Search}
          rightSectionWidth={42}
          className={classes.search}
          value={text}
          onChange={(e) => textSet(e.target.value)}
          style={{ width: "100%" }}
        />
        {show && (
          <div style={{ width: "fit-content" }}>
            <Button
              className={"regularButton"}
              style={{
                gridColumn: 3,
                width: "fit-content",
                marginLeft: "auto",
              }}
              sx={{
                marginBottom: "-1rem",
                "@media (max-width: 768px)": {
                  marginBottom: "0rem",
                },
              }}
              onClick={() => {
                dateSet(initialValues[0]);
                priceValSet(initialValues[1]);
                priceBeforeChangeSet([
                  0,
                  parseFloat(
                    articles?.[articles.length - 1]?.priceInclVat + 100
                  ),
                ]);
                textSet(initialValues[2]);
              }}
            >
              {language.Clear_Filter}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventFilter;
