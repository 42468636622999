import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { StylesContext } from "@material-ui/styles";
import _ from "lodash";
import { motion } from "framer-motion";
import { useLocation } from "@reach/router";
import slugify from "../common/slugify";

const IntroductionSection = ({
  config,
  language,
  culture,
  section,
  selfUrl,
  settingsData,
  bookingData,
}) => {
  const [content, setContent] = useState();
  const [showLongText, setShowLongText] = useState(false);
  const [text, setText] = useState();
  const [header, setHeader] = useState("");

  useEffect(() => {
    const filter = bookingData.filter((el) => el.name === section.name);

    if (filter.length > 0 && settingsData) {
      axios.all([
        axios
          .get(
            `${process.env.PUBLIC_URL}/ee-static/shop/${
              config.shopName
            }//Texts/article-booking-${slugify(settingsData?.name)}-${
              filter[0].slug
            }.${culture}.md`
          )
          .then((data) => setText(data.data))
          .catch((err) => {
            console.log(err);
          }),
      ]);
      setContent(filter);
    }
  }, [bookingData, settingsData, section]);

  return (
    <div
      className="introductionWrapper"
      key="introduction"
      style={{
        marginTop: bookingData.length === 1 ? "-2rem" : "",
        transition: bookingData.length === 1 ? "none" : "",
      }}
    >
      {sessionStorage.getItem("articleBookingBtn") !== "notactive" ? (
        <>
          <Typography className="introductionHeader" component="div">
            <Markdown
              className="articleHeader"
              source={header}
              escapeHtml={false}
            />
          </Typography>
          {content && content[0] !== undefined ? (
            <div
              className="introductionTextWrapper"
              key="articleBookingIntroductionWrapper"
            >
              <img
                src={`${process.env.PUBLIC_URL}${config.imageFolderUrl}${content[0].introductionImage}`}
                key="articleBookingImg"
                className="introductionImage"
                alt="bild på evenemanget"
              />
              <Typography className="introductionText" component="div">
                {text &&
                window.screen.availWidth <= 768 &&
                text.length > 300 ? (
                  <Markdown
                    source={
                      showLongText
                        ? text
                        : _.truncate(text, { length: 300 }) + "... "
                    }
                    escapeHtml={false}
                  />
                ) : (
                  <Markdown source={text} escapeHtml={false} />
                )}
              </Typography>
              {text && text?.length > 300 ? (
                <button
                  className="readMoreButton"
                  onClick={() => setShowLongText(!showLongText)}
                  aria-label={
                    !showLongText
                      ? language.ArticleBooking.ReadMoreButton
                      : language.ArticleBooking.HideText
                  }
                >
                  {!showLongText
                    ? language.ArticleBooking.ReadMoreButton
                    : language.ArticleBooking.HideText}
                </button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default IntroductionSection;
