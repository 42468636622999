import React, { useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import { getMonthPickerMonths, hasMonthEvent } from "./util";

const useStyles = makeStyles((theme) => ({
  monthPicker: {},
  selector: {
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    alignItems: "center",
  },
  year: { textAlign: "center", fontWeight: 500 },
  right: { textAlign: "right" },
  calendar: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 100px)",
    borderLeft: `1px solid ${theme.app.borderColor}`,
    borderTop: `1px solid ${theme.app.borderColor}`,
    width: "300px",
    marginRight: "16px",
    margin: "0 auto",
  },
  monthItem: {
    borderRight: `1px solid ${theme.app.borderColor}`,
    borderBottom: `1px solid ${theme.app.borderColor}`,
  },
  monthButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 0",
    height: "100%",
    width: "100%",
    "&:disabled": {
      opacity: 0.2,
    },
  },
  monthText: {
    color: "inherit",
  },
  active: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const thisMonth = Number(moment().format("YYYYMM"));
const calData = getMonthPickerMonths();
const calDataYears = calData.map((d) => Number(d.year));

const MonthPicker = (props) => {
  const { culture, active, setActive, events } = props;
  const classes = useStyles();
  const [cal, setCal] = useState(() => calData[0]);
  const locale = culture.substring(0, 2).toLowerCase();
  const handleSetCal = (year) =>
    setCal(calData.find((d) => d.yearValue === year));
  const prevDisabled = cal.yearValue <= calDataYears[0];
  const nextDisabled = cal.yearValue >= calDataYears[calDataYears.length - 1];
  return (
    <div className={classes.monthPicker}>
      <div className={classes.selector}>
        <div className={classes.left}>
          <IconButton
            className={classNames(classes.leftButton)}
            onClick={() => handleSetCal(cal.yearValue - 1)}
            disabled={prevDisabled || false}
          >
            <LeftIcon />
          </IconButton>
        </div>
        <div className={classes.year}>{cal.year}</div>
        <div className={classes.right}>
          <IconButton
            className={classNames(classes.rightButton)}
            onClick={() => handleSetCal(cal.yearValue + 1)}
            disabled={nextDisabled || false}
          >
            <RightIcon />
          </IconButton>
        </div>
      </div>

      <div className={classes.calendar}>
        {cal.months.map((month, i) => {
          const isEnabled = hasMonthEvent(month.value.toString(), events);
          const isActive = active === month.value;
          return (
            <div
              key={i}
              className={classNames(
                classes.monthItem,
                isActive && classes.active
              )}
            >
              <ButtonBase
                onClick={() => setActive(month.value)}
                className={classes.monthButton}
                disabled={month.value < thisMonth || !isEnabled}
              >
                <Typography variant="button" className={classes.monthText}>
                  {moment(month.date).locale(locale).format("MMM")}
                </Typography>
              </ButtonBase>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthPicker;
