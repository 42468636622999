import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import slugify from "../../common/slugify";
import { Link } from "@reach/router";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { CloseButton } from "@mantine/core";

const MenuItemV2 = ({
  data,
  culture,
  config,
  changeBackground,
  selected,
  setSelected,
  onClick,
  open,
}) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const [showLinks, setShowLinks] = useState(false);
  useEffect(() => {
    if (data?.subLinks) {
      setShowLinks(false);
    }
  }, [window.location.pathname]);

  let pathname = window.location.pathname !== "/ticketshop/cart";

  const getLink = (item, dropdown) => {
    let productGroup = item?.productGroups;
    if (item?.name) {
      let theme;
      let underlineTheme;
      if (open) {
        theme = "menuItem-item openMenu";
        underlineTheme = "menuItem-underline-color";
      } else {
        theme = `menuItem-item  ${
          changeBackground ? "darkColor" : "lightColor"
        }`;
        underlineTheme = `${
          changeBackground
            ? "menuItem-darkUnderline"
            : "menuItem-lightUnderline"
        }`;
      }

      if (item?.externalLink) {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <a
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              href={item?.externalLink}
              target="_blank"
              onClick={onClick}
            >
              {item?.name}
            </a>
          </motion.li>
        );
      }

      if (item?.internalLink) {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={item?.internalLink}
              onClick={onClick}
            >
              {item?.name}
            </Link>
            {selected && pathname && !dropdown && (
              <>
                <motion.div
                  className={`${underlineTheme} menuItem-underline`}
                  layoutId="underline"
                />
              </>
            )}
          </motion.li>
        );
      }
      if (
        productGroup?.length === 1 &&
        productGroup?.[0]?.length > 0 &&
        language?.LandingTexts
      ) {
        const filter = Object.keys(language?.LandingTexts)
          .filter((key) => item?.name.includes(key))
          .reduce((obj, key) => {
            obj[key] = language?.LandingTexts[key];
            return obj[key];
          }, []);
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={`/ticketshop/${slugify(productGroup[0].toLowerCase())}`}
              onClick={onClick}
            >
              {filter ? filter?.primary : item?.name}
            </Link>
            {selected && pathname && !dropdown && (
              <>
                <motion.div
                  className={`${underlineTheme} menuItem-underline`}
                  layoutId="underline"
                />
              </>
            )}
          </motion.li>
        );
      }
      if (productGroup?.length > 1) {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={`/ticketshop/${slugify(item?.name)}`}
              onClick={onClick}
            >
              {item?.name}
            </Link>
            {selected && pathname && !dropdown && (
              <motion.div
                className={`${underlineTheme} menuItem-underline`}
                layoutId="underline"
              />
            )}
          </motion.li>
        );
      }

      if (item?.name === "Hem") {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={"/ticketshop"}
              onClick={onClick}
            >
              {language?.Home}
            </Link>
            {selected && pathname && !dropdown && (
              <motion.div
                className={`${underlineTheme} menuItem-underline`}
                layoutId="underline"
              />
            )}
          </motion.li>
        );
      }
      if (item?.name === "Alla produkter" || item?.name === "Alla evenemang") {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={`/ticketshop/${slugify(
                item?.name === "Alla produkter" ? "all products" : "all events"
              )}`}
              onClick={onClick}
            >
              {item?.name === "Alla produkter"
                ? language.All_Products
                : language.All_Events}
            </Link>
            {selected && pathname && !dropdown && (
              <motion.div
                className={`${underlineTheme} menuItem-underline`}
                layoutId="underline"
              />
            )}
          </motion.li>
        );
      }

      if (item?.eventCalendar || item?.articleBooking) {
        if (config?.ticketConfigurator || config?.articleBookingConfigurator) {
          const ticketConfiguratorFilter =
            config?.ticketConfigurator &&
            config?.ticketConfigurator?.filter(
              (el) => el.name.toLowerCase() === item?.name.toLowerCase()
            );
          const getTicketLang =
            ticketConfiguratorFilter?.[0]?.translations.filter(
              (el) => el.culture === culture
            );
          const articleBookingConfiguratorFilter =
            config?.articleBookingConfigurator &&
            config?.articleBookingConfigurator.filter(
              (el) => el.name.toLowerCase() === item?.name.toLowerCase()
            );

          const getBookingLang =
            articleBookingConfiguratorFilter?.[0]?.translations?.filter(
              (el) => el.culture === culture
            );

          return (
            <motion.li
              className="menuItem-link"
              animate={{
                opacity: dropdown || selected ? 1 : 0.7,
              }}
            >
              <Link
                className={`menuItem-link ${!dropdown ? theme : ""}`}
                to={
                  item?.eventCalendar
                    ? "tickets/" + slugify(item?.name)
                    : "booking/" + slugify(item?.name)
                }
                onClick={onClick}
              >
                {item?.eventCalendar
                  ? getTicketLang?.[0]?.name
                  : getBookingLang?.[0]?.name}
              </Link>
              {selected && pathname && !dropdown && (
                <motion.div
                  className={`${underlineTheme} menuItem-underline`}
                  layoutId="underline"
                />
              )}
            </motion.li>
          );
        }
      }
      if (item?.events?.length === 1) {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={"/ticketshop/events/" + slugify(item?.events[0])}
              onClick={onClick}
            >
              {item?.name}
            </Link>
            {selected && pathname && !dropdown && (
              <motion.div
                className={`${underlineTheme} menuItem-underline`}
                layoutId="underline"
              />
            )}
          </motion.li>
        );
      }

      if (item?.events?.length > 1) {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={"/ticketshop/" + slugify(item?.name)}
              onClick={onClick}
            >
              {item?.name}
            </Link>
            {selected && pathname && !dropdown && (
              <motion.div
                className={`${underlineTheme} menuItem-underline`}
                layoutId="underline"
              />
            )}
          </motion.li>
        );
      }

      if (item?.publicBooking) {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={"/ticketshop/publicbooking/" + slugify(item?.name)}
              onClick={onClick}
            >
              {item?.name}
            </Link>
            {selected && pathname && !dropdown && (
              <motion.div
                className={`${underlineTheme} menuItem-underline`}
                layoutId="underline"
              />
            )}
          </motion.li>
        );
      } else {
        return (
          <motion.li
            className="menuItem-link"
            animate={{
              opacity: dropdown || selected ? 1 : 0.7,
            }}
          >
            <Link
              className={`menuItem-link ${!dropdown ? theme : ""}`}
              to={"events/" + slugify(item?.name)}
              onClick={onClick}
            >
              {item?.name}
            </Link>
            {selected && pathname && !dropdown && (
              <motion.div
                className={`${underlineTheme} menuItem-underline`}
                layoutId="underline"
              />
            )}
          </motion.li>
        );
      }
    }
  };
  let underlineTheme;
  if (open) {
    underlineTheme = "menuItem-lightUnderline";
  } else {
    underlineTheme = `${
      changeBackground ? "menuItem-darkUnderline" : "menuItem-lightUnderline"
    }`;
  }

  const nameTranslation = (data) => {
    const filter = data?.translations.filter((el) => el.culture === culture);
    if (filter.length > 0) {
      return filter[0]?.name;
    } else {
      return data?.name;
    }
  };

  return (
    <>
      {data && data?.subLinks && data.subLinks.length > 0 ? (
        <li style={{ position: "relative" }}>
          <ul style={{ padding: 0 }}>
            <motion.li
              className={`menuItem-container ${
                !open ? (changeBackground ? "darkColor" : "lightColor") : ""
              }`}
              onMouseEnter={() => setShowLinks(true)}
              whileFocus={() => {
                if (document.documentElement.clientWidth > 768) {
                  setShowLinks(true);
                }
              }}
              onMouseLeave={() => {
                if (document.documentElement.clientWidth > 768) {
                  setShowLinks(false);
                }
              }}
              onClick={() => {
                setShowLinks(!showLinks);
              }}
              animate={{
                opacity: selected || showLinks ? 1 : 1,
              }}
              aria-live={"polite"}
            >
              <button
                className={`removeBorderBg dropdownLabel ${
                  !open ? (changeBackground ? "darkColor" : "lightColor") : ""
                }`}
                style={{ margin: 0, fontWeight: "600", padding: 0 }}
                onClick={() => setShowLinks(!showLinks)}
                aria-expanded={showLinks}
              >
                {nameTranslation(data)}
                {showLinks ? (
                  <i className="headerIcon">
                    <MdOutlineKeyboardArrowUp />
                  </i>
                ) : (
                  <i className="headerIcon">
                    <MdOutlineKeyboardArrowDown />
                  </i>
                )}
              </button>

              <AnimatePresence exitBeforeEnter>
                {showLinks && (
                  <motion.ul
                    className="menuItem-wrapper"
                    data-isshowing={showLinks}
                    aria-expanded={showLinks ? true : false}
                    style={{
                      gridTemplateColumns:
                        data?.subLinks?.length > 10 ? "repeat(2, 1fr)" : "1fr",
                    }}
                    initial={{ opacity: 0, y: "-5px" }}
                    animate={{ opacity: 1, y: "0px" }}
                    exit={{ opacity: 0, y: "-5px" }}
                    transition={{ duration: 0.2 }}
                  >
                    {data.subLinks.map((el) => (
                      <React.Fragment key={el.name}>
                        {el?.header && !el.disabled ? (
                          <div className="navSubCategoryWrapper">
                            <label htmlFor="" style={{ margin: 0, padding: 0 }}>
                              {el.name}
                            </label>
                            {el.subLinks.map((ele) => (
                              <React.Fragment key={ele.name}>
                                {!ele.disabled && getLink(ele, "dropdown")}
                              </React.Fragment>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ))}
                    <li style={{ width: "100%" }}>
                      <CloseButton
                        className="subMenuCloseButton"
                        aria-label={language.CloseSubmenu}
                        title={language.CloseSubmenu}
                        onClick={() => setShowLinks(false)}
                        iconSize={20}
                      />
                    </li>
                    {data.subLinks.map((el) => (
                      <React.Fragment key={el.name}>
                        {!el.disabled && !el.header ? (
                          <>{getLink(el, "dropdown")}</>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </motion.li>
          </ul>
          {selected && pathname && (
            <motion.span
              className={`${underlineTheme} menuItem-underline`}
              layoutId="underline"
              layout
              tabIndex={-1}
              aria-hidden={"true"}
            />
          )}
        </li>
      ) : (
        getLink(data)
      )}
    </>
  );
};

export default MenuItemV2;
