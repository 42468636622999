import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "@reach/router";
import { makeStyles } from "@material-ui/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import slugify from "../common/slugify";
import { Context } from "../../context";
import { DataContext } from "../common/dataProvider";
import { STATE_CULTURE } from "../../common/state";

const useStyles = makeStyles((theme) => ({
  mobileMenuButton: {
    display: "none",
    padding: "4px",
    "@media(max-width: 667px)": {
      display: "block",
      marginRight: "4px",
    },
    "&:focus": {
      border: "3px solid black",
    },
  },
  active: {},
}));

const MobileMenu = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [bookingSlugs, setBookingSlugs] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleLogin, handleLogout } = useContext(Context);
  const { data } = useContext(DataContext);
  const { auth } = useSelector((state) => ({
    auth: state.oidc.user !== null && !state.oidc.user.expired,
  }));
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));

  const isActive = (link) => link === location.pathname;

  const getArticleBookingLinks = (item) => {
    if (item?.translations) {
      const filtered = item?.translations?.filter(
        (el) => el.culture === culture
      );
      return filtered[0]?.name;
    } else {
      return item?.name;
    }
  };

  const isPageContainsOnlyOneEventType = (page) => {
    if (page.events && page.events.length === 1) {
      return true;
    }
  };

  useEffect(() => {
    if (config.ticketConfigurator !== undefined) {
      let bookingLinks = config?.ticketConfigurator;
      const getBookingSlugs = bookingLinks?.reduce((acc, curr) => {
        if (!curr.disabledNav && !curr.disabled) {
          acc.push({
            name: curr.name,
            slug: curr.slug,
            translations: curr?.translations?.filter(
              (el) => el.culture === culture
            ),
          });
        }
        return acc;
      }, []);
      setBookingSlugs(getBookingSlugs);
    }
  }, []);

  return (
    <>
      <ButtonBase
        className={classes.mobileMenuButton}
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </ButtonBase>
      <Dialog open={open} fullScreen>
        <DialogTitle>{config.shopName}</DialogTitle>
        <DialogContent>
          <List>
            <ListItem
              button
              onClick={() => {
                if (auth) {
                  handleLogout();
                  return setOpen(false);
                }
                handleLogin();
                setOpen(false);
              }}
            >
              {auth ? language.Log_Off : language.Log_On}
            </ListItem>
            {auth && (
              <ListItem
                button
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/user`);
                  setOpen(false);
                }}
                className={
                  isActive(`${process.env.PUBLIC_URL}/user`)
                    ? classes.active
                    : null
                }
                disabled={isActive(`${process.env.PUBLIC_URL}/user`)}
              >
                {language.Account}
              </ListItem>
            )}
            <ListItem
              button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/`);
                setOpen(false);
              }}
            >
              {language.Home}
            </ListItem>

            {bookingSlugs &&
              bookingSlugs.map((bookingPage, i) => (
                <ListItem
                  button
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/tickets/${bookingPage.slug}`
                    );
                    setOpen(false);
                  }}
                  className={
                    isActive(
                      `${process.env.PUBLIC_URL}/tickets/${bookingPage.slug}`
                    )
                      ? classes.active
                      : null
                  }
                  disabled={isActive(
                    `${process.env.PUBLIC_URL}/tickets/${bookingPage.slug}`
                  )}
                  key={bookingPage + i}
                >
                  {bookingPage?.translations?.[0]
                    ? bookingPage?.translations?.[0]?.name
                    : bookingPage?.name}
                </ListItem>
              ))}

            {config.articleBookingConfigurator &&
              config.articleBookingConfigurator.map((el, i) => (
                <ListItem
                  button
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/booking/${el.slug}`);
                    setOpen(false);
                  }}
                  className={
                    isActive(`${process.env.PUBLIC_URL}/booking/${el.slug}`)
                      ? classes.active
                      : null
                  }
                  disabled={isActive(
                    `${process.env.PUBLIC_URL}/booking/${el.slug}`
                  )}
                  key={el + i}
                >
                  {getArticleBookingLinks(el)}
                </ListItem>
              ))}

            {config.pages.map((p, i) => {
              if (p.disabled) return null;
              if (isPageContainsOnlyOneEventType(p))
                return (
                  <ListItem
                    key={i}
                    button
                    onClick={() => {
                      navigate(
                        `${process.env.PUBLIC_URL}/events/${slugify(p.name)}`
                      );
                      setOpen(false);
                    }}
                    className={
                      isActive(
                        `${process.env.PUBLIC_URL}/events/${slugify(p.name)}`
                      )
                        ? classes.active
                        : null
                    }
                    disabled={isActive(
                      `${process.env.PUBLIC_URL}/${slugify(p.name)}`
                    )}
                  >
                    {language.Nav ? language?.Nav[p.name] || p.name : p.name}
                  </ListItem>
                );
              else
                return (
                  <ListItem
                    key={i}
                    button
                    onClick={() => {
                      navigate(`${process.env.PUBLIC_URL}/${slugify(p.name)}`);
                      setOpen(false);
                    }}
                    className={
                      isActive(`${process.env.PUBLIC_URL}/${slugify(p.name)}`)
                        ? classes.active
                        : null
                    }
                    disabled={isActive(
                      `${process.env.PUBLIC_URL}/${slugify(p.name)}`
                    )}
                  >
                    {p.name}
                  </ListItem>
                );
            })}

            {data?.allotments?.length > 0 && config.useAllEvents && (
              <ListItem
                button
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/all-events`);
                  setOpen(false);
                }}
                className={
                  isActive(`${process.env.PUBLIC_URL}/all-events`)
                    ? classes.active
                    : null
                }
                disabled={isActive(`${process.env.PUBLIC_URL}/all-events`)}
              >
                {language.All_Events}
              </ListItem>
            )}
            {config.showAllProducts && (
              <ListItem
                button
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/all-products`);
                  setOpen(false);
                }}
                className={
                  isActive(`${process.env.PUBLIC_URL}/all-products`)
                    ? classes.active
                    : null
                }
                disabled={isActive(`${process.env.PUBLIC_URL}/all-products`)}
              >
                {language.All_Products}
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            {language?.Close || "Stäng"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileMenu;
