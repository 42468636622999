import { Checkbox, createStyles, Text, UnstyledButton } from "@mantine/core";
import React from "react";

const useStyles = createStyles((theme, { checked }) => ({
  button: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    transition: "background-color 150ms ease, border-color 150ms ease",
    border: `1px solid ${
      checked
        ? theme.fn.variant({ variant: "outline", color: theme.primaryColor })
            .border
        : theme.colorScheme === "dark"
        ? theme.colors.dark[8]
        : "#8A8C93"
    }`,
    borderRadius: "6px",
    padding: theme.spacing.sm,
    backgroundColor: checked
      ? theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .background
      : theme.colorScheme === "dark"
      ? theme.colors.dark[8]
      : theme.white,
    marginBottom: theme.spacing.sm,
    ":hover": {
      backgroundColor: theme.colors.gray[1],
    },
  },

  body: {
    flex: 1,
    marginLeft: theme.spacing.md,
  },
  checkbox: {
    // backgroundColor: checked ? theme.colors.dark[6] : theme.colors.gray[2],
    border: checked ? theme.colors.gray[5] : null,
    borderRadius: "4px",
  },
}));

const ExtensionModifications = ({
  modification,
  id,
  title,
  price,
  checked,
  checkedSet,
}) => {
  const { classes, cx } = useStyles({ checked: checked?.id === id });

  return (
    <UnstyledButton
      key={id}
      onClick={() => checkedSet(modification)}
      className={cx(classes.button)}
    >
      <div className={classes.body}>
        <Text color="black" size="xs" sx={{ lineHeight: 1 }} mb={5}>
          {price}
        </Text>
        <Text weight={500} size="sm" sx={{ lineHeight: 1 }}>
          {title}
        </Text>
      </div>
      <Checkbox
        checked={checked?.id === id}
        onChange={() => {
          checkedSet(modification);
        }}
        tabIndex={-1}
        styles={{ input: { cursor: "pointer" } }}
        className={`${classes.checkbox}`}
      />
    </UnstyledButton>
  );
};

export default ExtensionModifications;
