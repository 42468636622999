import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Markdown from "react-markdown";
let source;

const Terms = () => {
  const [text, setText] = useState("");
  const { shopName } = useSelector((state) => state.config);
  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    axios
      .get(
        `${process.env.PUBLIC_URL}/ee-static/shop/${shopName}/Texts/terms.md`
      )
      .then((res) => setText(res.data));

    return () => source.cancel("Component Is Unmounting");
  }, [shopName]);

  return (
    <Markdown
      source={text}
      escapeHtml={false}
      skipHtml={false}
      className="terms__text"
    />
  );
};

export default Terms;
