import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Article from "./article";
import TimePicker from "./timepicker";
import { STATE_CURRENCY, STATE_CULTURE } from "../../common/state";

const useStyles = makeStyles({
  container: {},
  heading: {},
  occasionContainer: {},
  articlesContainer: {
    marginTop: "16px",
  },
});

const query = gql`
  query detailQuery(
    $currency: String
    $culture: String
    $posId: Int
    $from: DateTime
    $until: DateTime
  ) {
    allotments(
      currency: $currency
      culture: $culture
      posId: $posId
      from: $from
      until: $until
    ) {
      description
      imageFileName
      translation {
        culture
        name
        description
      }
      occasions {
        time
        remaining
        allotment {
          id
          maxPerSale
        }
        articles {
          id
          name
          description
          imageFilename
          plu
          priceInclVat
          translation {
            culture
            name
            description
          }
          isPersonalTicket
        }
      }
    }
  }
`;

const Articles = (props) => {
  const { from, until, event } = props;
  const eventName = event.name;
  const eventImage = event.imageFileName || undefined;
  const classes = useStyles();
  const { config, culture, currency, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
    language: state.language,
  }));
  const { posId } = config;
  const { data, loading, error } = useQuery(query, {
    variables: { posId, currency, culture, from, until },
  });

  if (!from || !until)
    return (
      <div className={classes.container}>
        <Typography
          variant="h6"
          className={classNames(
            classes.heading,
            "product-detail__articles__heading"
          )}
        >
          {language.Tickets}
        </Typography>
        <Typography
          className={classNames(
            classes.heading,
            "product-detail__articles__heading"
          )}
        >
          {language.Choose_Date_In_Calendar}
        </Typography>
      </div>
    );
  if (loading)
    return (
      <div className={classes.container}>
        <Typography
          variant="h6"
          className={classNames(
            classes.heading,
            "product-detail__articles__heading"
          )}
        >
          {language.Tickets}
        </Typography>
        <div
          className={classNames(
            classes.heading,
            "product-detail__articles__heading"
          )}
        >
          loading...
        </div>
      </div>
    );
  if (error)
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );

  const occasions =
    data.allotments.length > 0
      ? data.allotments.find((a) => a.description === eventName).occasions
      : [];
  if (occasions.length > 1) {
    return <TimePicker occasions={occasions} eventImage={eventImage} />;
  }
  return (
    <div className={classes.container}>
      <Typography
        variant="h6"
        className={classNames(
          classes.heading,
          "product-detail__articles__heading"
        )}
      >
        {language.Tickets}
      </Typography>
      {occasions.map((o, i) => {
        const articles = o.articles.sort((a, b) => a.plu - b.plu);
        return (
          <div className={classes.occasionContainer} key={i}>
            <Typography
              variant="subtitle1"
              className={classNames(
                classes.heading,
                "product-detail__articles__heading"
              )}
            >
              {moment(o.time).format("LLL")}
            </Typography>
            <div className={classes.articlesContainer}>
              {articles.map((a) => (
                <Article
                  key={a.id}
                  a={a}
                  occasion={o}
                  eventImage={eventImage}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Articles;
