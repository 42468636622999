import React from "react";
import { CallbackComponent } from "redux-oidc";

class MyCallbackPage extends React.Component {
  // define a success callback which receives the signed in user & handles redirection
  // NOTE: this example uses react-router-redux,
  // but any other routing library should work the same
  successCallback = (user) => {
    // console.log(this.props);
    // the user object gets the browser's URL before
    // redirection was triggered passed into its state
    // when triggerAuthFlow is set to `true`
    // const urlBeforeRedirection = user.state.redirectUrl;
    // this.props.dispatch(push(urlBeforeRedirection));
    //alert(JSON.stringify(user));
    this.props.navigate(`${process.env.PUBLIC_URL}/`);
  };

  errorCallback = (err) => {
    // console.log(this.props);
    alert(err);
    this.props.navigate(`${process.env.PUBLIC_URL}/`);
  };

  render() {
    return (
      <CallbackComponent
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
        userManager={this.props.userManager}
      >
        <div>Redirect...</div>
      </CallbackComponent>
    );
  }
}

export default MyCallbackPage;
