import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import QuantitySelect from "../common/quantitySelect";
import { Context, TOAST, CART_ARTICLE_ADD, OPEN_ALERT } from "../../context";
import { addAllotment } from "../../common/sale";
import { getCartArticle } from "../cart/util";
import { STATE_CULTURE } from "../../common/state";

const useStyles = makeStyles((theme) => ({
  article: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: "8px",
    alignItems: "center",
    padding: "8px",
    borderBottom: `1px solid ${theme.app.borderColor}`,
    "&:first-child": {
      borderTop: `1px solid ${theme.app.borderColor}`,
    },
  },
  articleName: {
    fontSize: "1rem",
  },
  articlePrice: {
    color: theme.app.secondaryColor,
  },
  full: {
    color: theme.app.dangerColor,
  },
  actions: {
    display: "flex",
  },
  addButton: {
    alignSelf: "flex-end",
    marginLeft: "16px",
  },
  disabledButton: {
    opacity: "0.3",
    pointerEvents: "none",
  },
  buttonProgress: {
    position: "absolute",
  },
}));

const Article = (props) => {
  const { a, occasion, eventImage } = props;
  const classes = useStyles();
  const rdxDispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(Context);
  const { language, config, culture } = useSelector((state) => ({
    language: state.language,
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));

  useEffect(() => {
    sessionStorage.setItem("addingOccasions", "null");
  }, []);

  useEffect(() => {
    if (loading === false) {
      sessionStorage.setItem("addingOccasions", "null");
    }
  }, [loading]);
  let session = sessionStorage.getItem("addingOccasions");

  const add = () => {
    sessionStorage.setItem("addingOccasions", "adding");
    setLoading(true);
    rdxDispatch(addAllotment(saleData, cb));
  };

  const cartArticle = getCartArticle(a, config, a, eventImage);

  const cb = (status, msg) => {
    sessionStorage.setItem("addingOccasions", "null");
    setQuantity(1);
    setLoading(false);

    if (status === 406) {
      return dispatch({
        type: OPEN_ALERT,
        payload: {
          text: language.Could_Not_Reserve,
          severity: "error",
        },
      });
    } else if (status >= 400) {
      return dispatch({
        type: TOAST,
        payload: {
          text: msg,
          variant: "error",
        },
      });
    }
    dispatch({
      type: CART_ARTICLE_ADD,
      payload: cartArticle,
    });
  };
  const saleData = {
    plu: a.plu,
    quantity: quantity,
    allotmentId: occasion.allotment.id,
    allotmentOccasion: occasion.time,
  };
  const disabled = () => {
    if (occasion.remaining < 1) {
      return true;
    } else if (loading) {
      return true;
    }
    return false;
  };
  const getMax = () =>
    Math.min(occasion.allotment.maxPerSale, occasion.remaining);
  const translatedName =
    a.translation.find((t) => t.culture === culture)?.name ||
    a.translation.find((t) => t.culture === "sv-SE")?.name ||
    a.name;
  return (
    <div className={classes.article}>
      <div className={classes.articleContent}>
        <Typography className={classes.articleName}>
          {translatedName}
        </Typography>
        <Typography className={classes.articlePrice}>
          {a.priceInclVat}
        </Typography>
      </div>
      <div className={classes.actions}>
        {occasion.remaining < 1 ? (
          <Typography className={classes.full}>
            {language.Allotment_Full}
          </Typography>
        ) : (
          <>
            <QuantitySelect
              disabled={disabled()}
              handleOnChange={(v) => setQuantity(v)}
              language={language}
              value={quantity}
              hideLabel={true}
              max={getMax()}
            />
            <Button
              onClick={() => add()}
              className={`${classes.addButton} ${
                session === "adding" ? classes.disabledButton : ""
              }`}
              disabled={disabled()}
              color="primary"
            >
              {language.Article_Add}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Article;
