import React, { useContext, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { GrFormClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import slugify from "../slugify";
import { DataContext } from "../dataProvider";
import Placeholder from "../../../images/placeholder.svg";
import { getAllProducts } from "../../../Pages/AllProductsPage/util";
import { motion } from "framer-motion";
import _ from "lodash";

const SearchResult = ({
  toggleSearch,
  setToggleSearch,
  searchValue,
  setSearchValue,
  articles,
  allotments,
  loading,
  setLoading,
  culture,
}) => {
  const [result, setResult] = useState([]);
  const [newResult, setNewResult] = useState([]);
  const [toggleAllotment, setToggleAllotment] = useState(true);
  const [toggleArticle, setToggleArticle] = useState(true);
  const [extensionArticles, setExtensionArticles] = useState(null);
  const [loadingResults, setLoadingResults] = useState(true);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const { data } = useContext(DataContext);
  const products = getAllProducts(data, config, culture);

  useEffect(() => {
    const filter = config?.productConfig.filter(
      (el) => el?.isModification === true
    );
    if (filter?.length > 0) {
      setExtensionArticles(filter);
    }
  }, [searchValue]);

  useEffect(() => {
    if (
      (allotments.length > 0 || articles.length > 0) &&
      searchValue.length > 0
    ) {
      let allAllotments = allotments;
      let articleBooking = config?.articleBookingConfigurator;
      let eventCalendar = config?.ticketConfigurator;
      const reduceAllotmentsToId =
        eventCalendar &&
        eventCalendar?.reduce((acc, curr) => {
          acc.push({
            id: curr.allotmentId,
            optionAllotmentId: curr.optionAllotmentId,
          });

          return acc;
        }, []);

      let arr = [];
      articleBooking &&
        articleBooking.forEach((el) => {
          el.tickets.forEach((ele) => {
            arr.push({
              id: ele.articleId,
            });
          });
        });
      const flatArr =
        articleBooking &&
        arr &&
        arr.reduce((acc, curr) => {
          acc.push(curr?.id);
          return acc;
        }, []);

      const reduceArticleBookingToId =
        flatArr &&
        flatArr?.reduce((a, b) => {
          return a.concat(b);
        });

      let flattenedArr =
        reduceAllotmentsToId &&
        reduceAllotmentsToId.reduce((a, b) => {
          if (b.id) {
            a.push(b.id);
          }
          if (b.optionAllotmentId) {
            a.push(b.optionAllotmentId);
          }
          return a;
        }, []);

      const removeEmptyArr =
        flattenedArr && flattenedArr.filter((el) => el.length).flat();

      let reducedArr;
      if (reduceArticleBookingToId || removeEmptyArr) {
        reducedArr = [].concat(removeEmptyArr, reduceArticleBookingToId);
      }
      setLoading(true);
      setLoadingResults(true);
      let addCalendar = [];
      if (reducedArr) {
        let newAllotments = allotments.filter(
          (el) => !reducedArr.includes(el.id)
        );

        if (config?.ticketConfigurator || config?.articleBookingConfigurator) {
          let calendarAllotments = config?.ticketConfigurator;
          let articleBookingAllotments = config?.articleBookingConfigurator;
          // addCalendar = newAllotments.concat(calendarAllotments);
          addCalendar = newAllotments.concat(
            calendarAllotments,
            articleBookingAllotments
          );
        } else {
          addCalendar = newAllotments;
        }
      } else {
        addCalendar = allotments;
      }

      const articleFilter = articles.filter((x) => {
        let data = x.translation.some((y) =>
          y?.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        return data;
      });

      const removeDisabledAllotments = addCalendar.filter(
        (el) => !el?.disabledNav
      );

      const allotmentFilter = removeDisabledAllotments.filter((x) => {
        let data = x?.translation
          ? x?.translation.some((y) =>
              y?.name.toLowerCase().includes(searchValue.toLowerCase())
            )
          : x?.translations.some((y) =>
              y?.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        return data;
      });

      if (articleFilter.length > 0 || allotmentFilter.length > 0) {
        if (articleFilter.length > 0 && allotmentFilter.length > 0) {
          setResult(articleFilter.concat(allotmentFilter));
        } else if (allotmentFilter.length > 0 && articleFilter.length === 0) {
          setResult(allotmentFilter);
        } else if (articleFilter.length > 0 && allotmentFilter.length === 0) {
          setResult(articleFilter);
        }
      } else {
        setResult(null);
      }
      setLoading(false);
    }
  }, [searchValue]);

  useEffect(() => {
    setLoadingResults(true);
    const extensionFilter =
      extensionArticles &&
      extensionArticles.filter((x) => {
        let data;
        if (x.translation) {
          data =
            x.translation &&
            x.translation.some((y) =>
              y?.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        } else {
          data = x.name.toLowerCase().includes(searchValue.toLowerCase());
        }
        return data;
      });
    if (extensionFilter !== null && extensionFilter?.length > 0) {
      let arr = [];
      arr = [result, extensionArticles.flat()];
      setNewResult(arr.flat());
    } else {
      setNewResult(result);
    }
  }, [searchValue, result]);

  useEffect(() => {
    setLoadingResults(false);
  }, [newResult, searchValue]);

  const getName = (item) => {
    if (item) {
      const filter = item.filter((el) => el?.culture === culture);
      if (filter?.length > 0) {
        return filter[0]?.name;
      } else {
        return item[0].name;
      }
    }
  };

  const getMarkdownDescription = (item) => {
    let text = language?.LandingTexts;
    if (text) {
      let getDesc = Object.values(_.pick(text, [item?.name]));
      return getDesc?.[0]?.secondary;
    }
  };

  const getDescription = (item) => {
    if (
      config?.showSearchDescription &&
      item &&
      !item.isEventCalendar &&
      !item.isArticleBooking
    ) {
      const filter = item.filter((el) => el.culture === culture);
      if (filter.length === 0) {
        return item?.[0].description
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/#(\S)/g, "");
      }
      if (filter !== null && filter[0]?.description?.length > 60) {
        return (
          filter[0]?.description
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/#(\S)/g, "")
            .substring(0, 60) + "..."
        );
      } else if (filter[0]?.description) {
        return filter[0]?.description
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/#(\S)/g, "");
      }
    }
  };
  const getImage = (id) => {
    const filter = products?.filter((el) => el.id === id);
    return filter[0].images;
  };

  const getNewImage = (item) => {
    if (item?.isEventCalendar || item?.isArticleBooking) {
      if (item?.image) {
        return `${process.env.PUBLIC_URL}/ee-static/shop/${config?.shopName}/Images/${item?.image}`;
      } else {
        return Placeholder;
      }
    }
    if (item?.isModification === true) {
      return `${process.env.PUBLIC_URL}/ee-static/shop/${config?.shopName}/Images/${item?.images?.[0]}`;
    }
    if (!item?.isModification) {
      const uri = config?.apiUriOverride?.startsWith("http")
        ? config.apiUriOverride
        : config.apiUri;

      let link3 = `${uri + "/articleimage/" + item.imageFilename}`;
      let link2 = `${
        item.plu ? uri + "/articleimage/" + item.imageUri : uri
      }/allotmentimage/${item.imageFileName}`;

      let link = `${uri}/articleimage/${item?.imageFileName}`;
      if (
        !item?.isProduct &&
        item?.imageFilename !== null &&
        item?.imageFilename !== undefined
      ) {
        return link3;
      }
      if (
        item?.imageFilename !== null &&
        !item?.imageFileName &&
        item?.imageFileName?.length > 0
      ) {
        return link;
      }
      if (item?.imageFilename !== null && item?.imageFileName) {
        return link2;
      }
      if (item?.imageFileName && !item?.imageFilename) {
        return link2;
      } else {
        return Placeholder;
      }
    }
  };

  const giftCard = (item) => {
    if (parseInt(item?.priceInclVat) === 0) {
      const filter = config?.productConfig.filter((el) => el.plu === item.plu);
      if (
        filter[0]?.openPriceValues &&
        filter[0]?.openPriceValues.length > 0 &&
        config?.showSearchPrice
      ) {
        return (
          <p className="text-price">
            {language?.FromCost} {filter[0].openPriceValues[0]}{" "}
            {language?.CurrencyAbbreviation}
          </p>
        );
      }
      if (
        filter[0]?.openPriceValues &&
        filter[0]?.openPriceValues.length === 0 &&
        config?.showSearchPrice
      ) {
        return <p className="text-price">{language?.OpenPrice}</p>;
      }
    }
  };

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      x: -15,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.25,
      },
    },
  };

  const getCalendarBookingLink = (item) => {
    let articleBooking = config?.articleBookingConfigurator;
    let eventCalendar = config?.ticketConfigurator;

    const eventFilter =
      eventCalendar &&
      eventCalendar.reduce((a, b) => {
        let filter = b?.tickets?.filter((el) =>
          el?.articleId?.includes(item?.id)
        );
        if (filter && filter?.length > 0) {
          a.push(b);
        }
        return a;
      }, []);

    const articleFilter =
      articleBooking &&
      articleBooking.reduce((a, b) => {
        const filter = b?.tickets.filter((el) =>
          el?.articleId.includes(item?.id)
        );
        if (filter && filter.length > 0) {
          a.push(b);
        }
        return a;
      }, []);
    if (articleFilter?.length > 0) {
      return `/ticketshop/booking/${articleFilter[0].slug}`;
    }

    if (eventFilter?.length > 0) {
      return `/ticketshop/tickets/${eventFilter[0].slug}`;
    }
  };

  return (
    <div data-isactive={toggleSearch} className="search-result-wrapper">
      <div data-isactive={toggleSearch} className="items-wrapper">
        {searchValue &&
        searchValue.length > 0 &&
        (allotments.length > 0 || articles.length > 0) ? (
          <div className="search-category">
            {newResult && newResult.length > 0 ? (
              <p>{language?.SearchCategories}</p>
            ) : (
              <p>{language?.NotFound}</p>
            )}

            <div className="category-button-wrapper">
              {newResult &&
              newResult.find(
                (el) => !el?.isProduct || el?.isModification === true
              ) ? (
                <button
                  className={`category-button ${
                    toggleAllotment ? "active-category" : "inactive-category"
                  }`}
                  onClick={() => setToggleAllotment(!toggleAllotment)}
                >
                  <span>{language?.Tickets}</span>

                  <GrFormClose
                    data-isactive={toggleSearch}
                    className="category-close-icon"
                    style={{ display: toggleAllotment ? "block" : "none" }}
                  />
                </button>
              ) : (
                ""
              )}

              {newResult &&
              newResult.find(
                (el) => el?.isProduct || el?.isModification === true
              ) ? (
                <button
                  className={`category-button ${
                    toggleArticle ? "active-category" : "inactive-category"
                  }`}
                  onClick={() => {
                    setToggleArticle(!toggleArticle);
                  }}
                >
                  <span>{language?.Products}</span>
                  <GrFormClose
                    data-isactive={toggleSearch}
                    className="category-close-icon"
                    style={{ display: toggleArticle ? "block" : "none" }}
                  />
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <ul style={{ margin: 0, padding: 0 }}>
          {newResult &&
            newResult.length > 0 &&
            !loadingResults &&
            searchValue.length > 0 && (
              <motion.div variants={variants} initial="hidden" animate="show">
                {!loading ? (
                  newResult.map((el, i) => (
                    <motion.div variants={i < 10 ? item : ""} key={el + i}>
                      {toggleArticle &&
                      el?.isProduct &&
                      el?.isProduct !== null ? (
                        <button
                          role="link"
                          className="search-result-btn"
                          onClick={() => {
                            setToggleSearch(false);
                            setSearchValue("");
                            navigate(`/ticketshop/products/${el.plu}`);
                          }}
                        >
                          <div
                            className="search-result"
                            style={{
                              minHeight: config?.useSmallSearchResult
                                ? "2.5rem"
                                : "5rem",
                            }}
                          >
                            <img
                              src={
                                getImage(el.id) ? getImage(el.id) : Placeholder
                              }
                              alt=""
                              style={{
                                width: config?.useSmallSearchResult
                                  ? "2.5rem"
                                  : "5rem",
                                height: config?.useSmallSearchResult
                                  ? "2.5rem"
                                  : "5rem",
                              }}
                            />

                            <div className="text-wrapper">
                              <div>
                                <h3 className="text-header">
                                  {getName(
                                    el?.translation
                                      ? el?.translation
                                      : el?.translations
                                  )}
                                </h3>
                                <p className="text-description">
                                  {getDescription(
                                    el?.translation
                                      ? el?.translation
                                      : el?.translations
                                  )}
                                </p>
                              </div>

                              {config?.showSearchPrice &&
                              parseInt(el.priceInclVat) > 0 ? (
                                <p className="text-price">
                                  {el.priceInclVat}
                                  {/* {language?.CurrencyAbbreviation} */}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <hr />
                        </button>
                      ) : (
                        ""
                      )}

                      {toggleAllotment &&
                      el !== null &&
                      (!el?.isProduct || el?.isModification === true) ? (
                        <button
                          role="link"
                          className="search-result-btn"
                          onClick={() => {
                            setToggleSearch(false);
                            setSearchValue("");
                            if (el?.plu) {
                              navigate(`/ticketshop/products/${el?.plu}`);
                            }
                            if (el?.isEventCalendar) {
                              localStorage.setItem("selected", el?.name);
                              navigate(`/ticketshop/tickets/${el?.slug}`);
                            }
                            if (el?.isArticleBooking) {
                              localStorage.setItem("selected", el?.name);
                              navigate(`/ticketshop/booking/${el?.slug}`);
                            }

                            if (getCalendarBookingLink(el)) {
                              let translation = el?.translation?.filter(
                                (el) => el.culture === "sv-SE"
                              )[0]?.name;

                              if (translation) {
                                localStorage.setItem("selected", el?.name);
                              }
                              navigate(getCalendarBookingLink(el));
                            } else if (
                              !el.plu &&
                              !el.isEventCalendar &&
                              !el.isArticleBooking &&
                              !getCalendarBookingLink(el)
                            ) {
                              navigate(
                                `/ticketshop/events/${slugify(el?.description)}`
                              );
                            }
                          }}
                          aria-label={getDescription(
                            el?.translation ? el?.translation : el?.translations
                          )}
                        >
                          {/*el.isEventCalendar navigate*/}
                          <div className="search-result">
                            {el.plu & (el?.isModification === true) ? (
                              <img
                                src={getNewImage(el)}
                                alt=""
                                style={{
                                  width: config?.useSmallSearchResult
                                    ? "2.5rem"
                                    : "5rem",
                                  height: config?.useSmallSearchResult
                                    ? "2.5rem"
                                    : "5rem",
                                }}
                              />
                            ) : el.plu && !el.isModification ? (
                              <img
                                src={getNewImage(el)}
                                alt=""
                                style={{
                                  width: config?.useSmallSearchResult
                                    ? "2.5rem"
                                    : "5rem",
                                  height: config?.useSmallSearchResult
                                    ? "2.5rem"
                                    : "5rem",
                                }}
                              />
                            ) : (
                              <img
                                src={getNewImage(el)}
                                alt=""
                                style={{
                                  width: config?.useSmallSearchResult
                                    ? "2.5rem"
                                    : "5rem",
                                  height: config?.useSmallSearchResult
                                    ? "2.5rem"
                                    : "5rem",
                                }}
                              />
                            )}

                            <div className="text-wrapper">
                              <p className="text-header">
                                {getName(
                                  el?.translation
                                    ? el?.translation
                                    : el?.translations
                                )}
                              </p>
                              <p className="text-description">
                                {el?.isEventCalendar || el?.isArticleBooking
                                  ? getMarkdownDescription(el)
                                  : getDescription(
                                      el?.translation
                                        ? el?.translation
                                        : el?.translations
                                    )}
                              </p>
                              {config?.showSearchPrice &&
                              parseInt(el.priceInclVat) > 0 ? (
                                <p className="text-price">{el?.priceInclVat}</p>
                              ) : (
                                ""
                              )}
                              {giftCard(el)}
                            </div>
                          </div>
                          <hr />
                        </button>
                      ) : (
                        ""
                      )}
                    </motion.div>
                  ))
                ) : (
                  <p>loading</p>
                )}
              </motion.div>
            )}
        </ul>
      </div>
    </div>
  );
};

export default SearchResult;
