import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.primary.main,
    "&:focus": {
      outline: "none",
    },
  },
  progress: {
    color: "inherit",
  },
  text: {
    color: "rgba(0,0,0,0.7)",
    marginTop: "16px",
  },
  backdrop: {
    backgroundColor: "rgba(255,255,255,0.8)!important",
  },
}));

const Busy = (props) => {
  const { busy, busyText } = props;
  const classes = useStyles();

  if (busy) {
    return (
      <Modal
        BackdropProps={{ className: classes.backdrop }}
        open={busy}
        disableAutoFocus={true}
      >
        <div className={clsx(classes.container, "busy__container")}>
          <CircularProgress
            classes={{ colorPrimary: classes.progress }}
            size={256}
          />
          <Typography className={classes.text} variant="h5">
            {busyText}
          </Typography>
        </div>
      </Modal>
    );
  }
  return <div />;
};

export default Busy;
