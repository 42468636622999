import { Alert, createStyles } from "@mantine/core";
import React, { useEffect, useState } from "react";
import AlertIcon from "./AlertIcon";
import axios from "axios";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import Markdown from "react-markdown";

const useStyles = createStyles({
  alert: {
    margin: "1rem 0",
    paddingBottom: "1rem",
  },
  alertIconWrapper: {
    ".mantine-Alert-icon": {
      pointerEvents: "none",
    },
  },
});
const DrawerAlert = () => {
  const [alertMsg, alertMsgSet] = useState(null);
  const { language, config, culture } = useSelector((state) => ({
    language: state.language,
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));
  const { classes } = useStyles();

  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/ee-static/shop/${config.shopName}/Texts/cart-below.${culture}.md`
      )
      .then((data) => alertMsgSet(data.data))
      .catch((error) => console.log(error));
  }, []);
  return (
    alertMsg && (
      <div className={classes.alert}>
        <Alert
          icon={<AlertIcon size={16} />}
          title={language.CartDrawerAlertTitle}
          color="red.9"
          radius="md"
          className={classes.alertIconWrapper}
          role="complementary"
        >
          <Markdown
            source={alertMsg}
            escapeHtml={false}
            skipHtml={false}
            className="drawerMsg"
          />
        </Alert>
      </div>
    )
  );
};

export default DrawerAlert;
