import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Link, navigate } from "@reach/router";

const OccasionButton = (props) => {
  const useStyles = makeStyles({
    occasionBtn: {
      minWidth: "11rem",
      width: "100%",
      padding: " 0 2rem",
      height: "3.5rem",
      borderRadius: "10px",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      border: "1.5px solid rgb(75, 75, 75);",
      fontSize: "1rem",
      fontWeight: "600",
      cursor: "pointer",
      transition: "all 0.2s ease",
      "&:hover": {
        opacity: ({ primaryBtn }) => (primaryBtn ? "0.7" : ""),
        background: ({ primaryBtn }) => (primaryBtn ? "" : "#e3e3e3"),
        transform: "translateY(-1px)",
      },
      "&:disabled": {
        pointerEvents: "none",
        opacity: "0.3",
      },
    },
  });
  const classes = useStyles(props);

  return (
    <div>
      {props.click ? (
        <button
          className={`${classes.occasionBtn} primaryButton`}
          onClick={() => props.setStep(props.click)}
          disabled={props.disabled}
          aria-label={props.primaryText}
        >
          {props.primaryText}
        </button>
      ) : (
        ""
      )}

      {props.submit ? (
        <button
          className={classes.occasionBtn}
          disabled={props.disabled}
          aria-label={props.primaryText}
        >
          {props.primaryText}
        </button>
      ) : (
        ""
      )}

      {props.redirect ? (
        <button
          role="link"
          tabIndex={0}
          className="removeBorderBg"
          onClick={() => navigate(props.redirect)}
          aria-label={props.primaryText}
        >
          <div
            className={`${classes.occasionBtn} primaryButton`}
            style={{ display: "flex", alignItems: "center" }}
          >
            {props.primaryText}
          </div>
        </button>
      ) : (
        ""
      )}
      {props.text ? (
        <button
          role="link"
          tabIndex={0}
          className="removeBorderBg"
          onClick={() => navigate(props.secondaryClick)}
          aria-label={props.text}
        >   
          <div
            className={classes.occasionBtn}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.text}
          </div>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default OccasionButton;
