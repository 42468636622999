import slugify from "../../components/common/slugify";

const extendEvent = (event, allotment, config) => {
  const { imageFolderUrl, descriptionFolderUrl } = config;
  return {
    ...allotment,
    name: allotment?.description,
    description: event?.description
      ? `${descriptionFolderUrl}${event.description}`
      : undefined,
    images: event?.images
      ? event.images.map(
          (img) => `${process.env.PUBLIC_URL}${imageFolderUrl}${img}`
        )
      : undefined,
  };
};

export const getEvent = (data, config, id) => {
  if (!data) return undefined;
  const eventConfig = config.eventConfig?.find((c) => slugify(c.name) === id);
  const allotment = data.allotments.find((a) => slugify(a.description) === id);
  return extendEvent(eventConfig, allotment, config);
};
