import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { addModification } from "../../common/sale";
import { Context, TOAST } from "../../context";

const useStyles = makeStyles((theme) => ({
  activePerson: {},
  avatar: {
    width: "160px",
    height: "160px",
  },
  person: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "16px",
    justifyItems: "center",
    marginBottom: "16px",
  },
  name: {
    fontSize: "2rem",
    fontWeight: 900,
    textAlign: "center",
  },
  ticket: { padding: "8px 0", marginBottom: "24px" },
  modificationItem: {
    padding: "8px 0",
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
  },
  ticketName: { lineHeight: 1.3 },
  barcode: { fontSize: "0.8125rem" },
  valid: { fontSize: "0.875rem" },
  modArticleInfo: { flexGrow: 1 },
  modArticleName: { fontWeight: 500 },
  modArticlePrice: { fontSize: "0.8125rem" },
  noTickets: { textAlign: "center" },
}));

const ActivePerson = ({ activePerson }) => {
  const classes = useStyles();
  const { dispatch } = useContext(Context);
  const rdxDispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const add = (model) => {
    rdxDispatch(
      addModification(model, (status, msg) => {
        if (status >= 400) {
          return dispatch({
            type: TOAST,
            payload: {
              text: msg,
              variant: "error",
            },
          });
        }
        return dispatch({
          type: TOAST,
          payload: {
            text: language.Article_Added,
            variant: "success",
          },
        });
      })
    );
  };
  if (!activePerson) return null;
  return (
    <div className={classes.activePerson}>
      <div className={classes.person}>
        <Avatar
          className={classes.avatar}
          alt={activePerson.name}
          src={activePerson.photo}
        />
        <Typography className={classes.name}>{activePerson.name}</Typography>
      </div>
      {activePerson.tickets && activePerson.tickets.length > 0 ? (
        <div className={classes.tickets}>
          {activePerson.tickets.map((t) => {
            console.log(t);
            const validFrom =
              t.validFrom && moment(t.validFrom).format("YYYY-MM-DD");
            const validUntil =
              t.validUntil && moment(t.validUntil).format("YYYY-MM-DD");
            return (
              <div
                key={t.id}
                className={clsx(classes.ticket, "ticket__container")}
              >
                <Typography
                  variant="h6"
                  className={clsx(classes.ticketName, "ticket__article__name")}
                >
                  {t.article.name}
                </Typography>
                <Typography className={clsx(classes.barcode, "secondary-text")}>
                  {t.uniqueIdentifier}
                </Typography>
                <Typography className={clsx(classes.valid, "secondary-text")}>
                  {validFrom} - {validUntil}
                </Typography>
                {t.fundsId && (
                  <Typography className={clsx(classes.valid, "secondary-text")}>
                    {`Saldo: ${t.fundsAmount}:-`}
                  </Typography>
                )}
                {t.applicableModifications.map((a) => {
                  const model = {
                    ticketId: t.id,
                    articleId: t.article.id,
                    plu: a.plu,
                    fundsId: t.fundsId,
                    amount: a.price.amountInclVat,
                  };
                  // const ticketId = t.id;
                  // const articleId = t.article.id;
                  // const plu = a.plu;
                  return (
                    <div
                      key={a.id}
                      className={clsx(
                        classes.modificationItem,
                        "modification__article"
                      )}
                    >
                      <div
                        className={clsx(
                          classes.modArticleInfo,
                          "modification__article__info"
                        )}
                      >
                        <div
                          className={clsx(
                            classes.modArticleName,
                            "primary-text"
                          )}
                        >
                          {a.name}
                        </div>
                        <div
                          className={clsx(
                            classes.modArticlePrice,
                            "secondary-text"
                          )}
                        >
                          {a.priceInclVat}
                        </div>
                      </div>
                      <Button color="primary" onClick={() => add(model)}>
                        KÖP
                      </Button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <Typography variant="h6" className={classes.noTickets}>
          Inga biljetter att förlänga
        </Typography>
      )}
    </div>
  );
};

export default ActivePerson;
