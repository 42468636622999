import * as React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Markdown from "react-markdown";
import { STATE_CULTURE } from "../../common/state";

let source;

const useStyles = makeStyles((theme) => ({
  left: {
    position: "relative",
    "@media(max-width: 1000px)": {
      display: "none",
    },
  },
}));

const Left = (props) => {
  const classes = useStyles();
  const [text, setText] = React.useState("");
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));
  React.useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    axios
      .get(
        `${process.env.PUBLIC_URL}/${config.descriptionFolderUrl}/left.${culture}.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => setText(res.data));
    return () => source.cancel("Component Is Unmounting");
  }, [culture, config.descriptionFolderUrl]);
  return (
    <div className={clsx(classes.left, "left__container")}>
      <Markdown
        source={text}
        escapeHtml={false}
        skipHtml={false}
        className={clsx(classes.leftContent, "left__content")}
      />
    </div>
  );
};

export default Left;
