import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  oldBrowser: {},
  inner: {
    maxWidth: "1200px",
    margin: "0 auto",
    "& a": {
      textDecoration: "underline",
    },
    "& li": {
      marginBottom: "16px",
    },
  },
}));

const OldBrowser = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.oldBrowser}>
      <div className={classes.inner}>
        <h1>Denna webshop kräver en modern webläsare!</h1>
        <p>Tyvärr använder du en gammal webbläsare.</p>
        <h3>Varför bör du uppdatera din webbläsare?</h3>
        <ol>
          <li>
            <b>Säkerhet:</b> Äldre webbläsare använder sämre kryptering av data
            vilket ökar risken för cyberattacker och scams, vilket i sin tur
            ökar risken för du kan bli av med personlig information även på
            "säkra" webbplatser.
          </li>
          <li>
            <b>Hastighet:</b> Varje ny generation av webbläsare blir snabbare
            och bättre än sin föregångare. Vem vill inte ha snabbare internet?
          </li>
          <li>
            <b>Teknik:</b> För att bygga moderna webbsidor med en bra
            användarupplevelse, så krävs en modern webbläsare. Ny teknik
            utvecklas ständigt i webvärlden och för att hänga med krävs det en
            modern webläsare som har stöd för den senaste tekniken.
          </li>
        </ol>
        <h3>Windows</h3>
        <p>
          Om du har Windows 10 som operativsystem på din dator, så har du redan
          en modern webbläsare.
          <br />
          Den heter{" "}
          <a href="https://www.microsoft.com/sv-se/windows/microsoft-edge">
            Microsoft Edge
          </a>
          . Använd den istället för Internet Explorer. Microsoft rekommenderar
          att välja Microsoft Edge istället för Internet Explorer.
        </p>
        <h3>Mac</h3>
        <p>
          Använder du Apple Safari, så behöver du minst version 11.
          <br />
          Du kan uppgradera till den senaste versionen genom att installera de
          senaste uppgraderingarna för din macOS-version.{" "}
          <a href="https://support.apple.com/sv-se/HT204416">Apple Safari</a>
        </p>
        <h4>
          Du kan även använda någon av dessa webbläsare, som är helt gratis och
          fungerar på både Mac och Windows.
        </h4>
        <div>
          <div>
            <a href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=sv">
              Google Chrome
            </a>
          </div>
          <div>
            <a href="https://www.mozilla.org/sv-SE/firefox/new/">
              Mozilla Firefox
            </a>
          </div>
          <div>
            <a href="https://brave.com/download/">Brave</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OldBrowser;
