import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { STATE_CULTURE } from "../../common/state";
import Landing from "../../components/Landing";
import PageLayout from "../../components/Styles/PageLayout";
import PageContent from "../../components/Styles/PageContent";
import Description from "../../components/common/Description";
import SiteHeading from "../../components/common/SiteHeading";

let source;

const LandingPage = () => {
  const [text, setText] = useState("");
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));
  useEffect(() => {
    source = axios.CancelToken.source();
    axios
      .get(
        `${process.env.PUBLIC_URL}/${config.descriptionFolderUrl}landingpage.${culture}.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => setText(res.data));
    return () => source.cancel("Component Is Unmounting");
  }, [culture, config.descriptionFolderUrl]);

  return (
    <>
      {config.showSiteHeadingOnlyOnLanding && <SiteHeading />}
      <PageLayout>
        <PageContent>
          <Description text={text} />
          {config.landingPage && <Landing />}
        </PageContent>
      </PageLayout>
    </>
  );
};

export default LandingPage;
