import React, { useEffect, useMemo, useState } from "react";
import Header from "./components/Header";
import Nav from "./components/common/Nav";
import Cart from "./components/cart";
import SaleProvider from "./components/common/saleProvider";
import CookieBanner from "./components/common/cookieBanner";
import Toast from "./components/common/toast";
import Alert from "./components/common/alert";
import DataProvider from "./components/common/dataProvider";
import AppLayout from "./components/Styles/AppLayout";
import AppContent from "./components/Styles/AppContent";
import OidcClientInitializer from "./common/oidcClientInitializer";
import { useSelector } from "react-redux";
import { STATE_CONSENT, STATE_CULTURE } from "./common/state";
import axios from "axios";

const App = () => {
  const culture = useSelector((state) => state.cookies[STATE_CULTURE]);
  const consent = useSelector((state) => state.cookies[STATE_CONSENT]);
  const config = useSelector((state) => state.config);
  const [newConsent, setNewConsent] = useState([]);

  useEffect(() => {
    let data = [];
    data = consent.toString();
    setNewConsent(data.length > 0 && data.split(","));
  }, [consent]);

  useEffect(() => {
    if (newConsent[0] === "true") {
      let tgm = window.document.createElement("script");
      tgm.setAttribute("type", "text/javascript");
      tgm.setAttribute(
        "src",
        `${process.env.PUBLIC_URL}/ee-static/shop/${config.shopName}/Scripts/tgm.js`
      );
      window.document.getElementsByTagName("head")[0].appendChild(tgm);
    }
  }, [newConsent]);

  // Add Favicon
  useMemo(() => {
    let icon = [
      `${process.env.PUBLIC_URL}/ee-static/shop/${config.shopName}/favicon.ico`,
      `${process.env.PUBLIC_URL}/favicon.ico`,
    ];
  
    const getIcon = (arr, index) => {
      return axios.get(arr[index]).catch((err) => {
        if (index < arr.length - 1) {
          return getIcon(arr, index + 1);
        }
        throw err;
      });
    };
  
    getIcon(icon, 0)
      .then((response) => {
        if (response.status === 200) {
          let link = window.document.createElement("link");
          link.href = response.request.responseURL;
          link.rel = "shortcut icon";
          window.document.getElementsByTagName("head")[0].appendChild(link);
        }
      })
      .catch((err) => console.log(err));
  },[])


  // Set HTML language
  useEffect(() => {
    document.documentElement.lang = culture.split("-")[0];
  }, [culture]);

  return (
    <OidcClientInitializer>
      <DataProvider>
        <SaleProvider>
          <AppLayout>
            <Header />
            <Cart />
            {config?.useNewHeader ? "" : <Nav />}

            <AppContent />
            <CookieBanner />
            <Toast />
            <Alert />
          </AppLayout>
        </SaleProvider>
      </DataProvider>
    </OidcClientInitializer>
  );
};

export default App;
