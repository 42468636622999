import React from "react";
import { Button, createStyles, Text, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import { useNavigate } from "@reach/router";

const useStyles = createStyles((theme) => ({
  container: {},
  titleWrapper: {
    marginBottom: "1rem",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1.5rem",
    "@media screen and (max-width: 768px)": {
      flexDirection: "column",
    },
  },
}));
const Receipt = ({ success, error, onPrevClick }) => {
  const { classes } = useStyles();
  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper} role={!success && "alert"}>
        <Title
          order={2}
          sx={{ fontSize: "1.15rem", fontWeight: 500, textAlign: "center" }}
        >
          {success && language.BookingComplete}
          {!success && language.BookingError}
        </Title>
        <Text component="p" sx={{ textAlign: "center" }}>
          {success && language.BookingDescription}
          {!success && language.BookingErrorDescription}
        </Text>
      </div>
      <div className={classes.buttonWrapper}>
        {!success ? (
          <Button
            onClick={() => onPrevClick()}
            className={"primaryButton"}
            size="md"
          >
            {language.Back}
          </Button>
        ) : (
          <div />
        )}
        <Button
          onClick={() => navigate("/ticketshop")}
          className={"primaryButton"}
          size="md"
        >
          {language.BackToHomepage}
        </Button>
      </div>
    </div>
  );
};

export default Receipt;
