import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import { makeStyles } from "@material-ui/styles";
import "../styles.css";

const SuccessAnimation = (props) => {
  const [hovered, setHovered] = useState(false);
  const [animation, setAnimation] = useState(require("./success"));

  const successAnimationContainer = useRef(null);

  const successAnimation = useRef(null);

  useEffect(() => {
    if (successAnimationContainer.current) {
      setTimeout(() => {
        successAnimation.current = lottie.loadAnimation({
          container: successAnimationContainer.current,
          renderer: "svg",
          loop: false,
          autoplay: true,
          animationData: animation,
        });
        successAnimation.current.setSpeed(1);

        return () => successAnimation.current.destroy();
      }, 200);
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    addedBtnIcon: {
      width: "120px",
      height: "120px",
      margin: "0 auto",
    },
  }));

  const classes = useStyles(props);

  return (
    <div
      className={`${classes.addedBtnIcon} successAnimation`}
      ref={successAnimationContainer}
    />
  );
};
export default SuccessAnimation;
