import {
  ActionIcon,
  Button,
  Container,
  createStyles,
  Divider,
  Grid,
  Group,
  Image,
  List,
  NumberInput,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, addToCartTracking } from "../../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../common/state";
import { getSaleData } from "../util";
import Markdown from "react-markdown";
import { motion } from "framer-motion";
import PersonalTicket from "./PersonalTicket";
import { SaleContext } from "../../common/saleProvider";
import { useMediaQuery } from "@mantine/hooks";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";

const useStyles = createStyles((theme) => ({
  container: {
    "@media screen and (max-width: 768px)": {
      paddingTop: "0.3rem",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "1rem",
    button: {
      width: "fit-content",
    },
  },
  stockWrapper: {
    marginTop: ".5rem",
    "@media screen and (max-width: 768px)": {
      marginTop: ".35rem",
    },
  },
  stockIndicator: {
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
  },
  inStock: {
    backgroundColor: theme.colors.green[1],
  },
  outOfStock: {
    backgroundColor: theme.colors.red[2],
  },
  stockText: {
    fontSize: "1rem",
    fontWeight: "inherit",
  },
  price: {
    display: "flex",
    alignItems: "end",
    justifySelf: "end",
    fontSize: "1.15rem",
    fontWeight: "bold",
  },
  list: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    width: "fit-content",
    justifySelf: "end",
  },
  descriptionTitle: {
    fontSize: "1rem",
  },
  descriptionBody: {
    marginTop: "0.5rem",
    p: {
      marginTop: "-.1rem",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "end",
  },
  actionButton: {
    borderRadius: "50%",
    border: "none",
  },
  actionWrapper: {
    backgroundColor: "#F3F3F4",
    padding: "0.3rem 0.4rem",
    borderRadius: "2rem",
    width: "fit-content",
    minWidth: "8.2rem",
    justifyContent: "space-between !important",
  },
  actionGrid: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    marginTop: "1rem",
    "@media screen and (min-width: 769px)": {
      gap: "2rem",
    },
  },
  alertWrapper: {
    width: "100%",
  },
  hideLabel: {
    opacity: 0,
    position: "absolute",
    width: "0px",
    height: "0px",
  },
}));

const SingleProduct = ({ product, description }) => {
  const { culture, language, currency } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));

  const [quantity, quantitySet] = useState(1);
  const [addedToCart, addedToCartSet] = useState(null);
  const [openPrice, openPriceSet] = useState(100);
  const { classes } = useStyles();
  const handlers = useRef();
  const quantityChangeRef = useRef();
  const rdxDispatch = useDispatch();
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const mobile = useMediaQuery("(max-width: 768px)");

  const getTranslation = product?.translation.filter(
    (el) => el?.culture === culture
  )?.[0];

  const productData = [
    {
      id: product.plu,
      name: product.name,
      price: product.price.amountInclVat,
      quantity: quantity,
    },
  ];

  const handleAdd = () => {
    addToCartTracking(currency, productData);
    announceQuantityChange(quantity);

    const callback = (status, msg) => {
      if (status === 200) {
        quantitySet(1);
        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        quantitySet(1);
        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    let article;
    let values;
    let photo;
    let overrideDate;
    const saleData = !product.isOpenPrice
      ? getSaleData(product, article, values, photo, quantity, overrideDate)
      : {
          plu: product.plu,
          quantity: quantity,
          priceInclVat: openPrice * 100,
        };
    rdxDispatch(addArticle(saleData, callback));
  };
  // const placeholder = `${process.env.PUBLIC_URL}/ee-static/shop/${config?.shopName}/Images/placeholder.png`;
  const disabledIncrement = useMemo(() => {
    if (sale) {
      const qty = sale.items.filter(
        (el) => el.article.plu === product?.plu
      )?.[0]?.quantity;
      const calc = product?.inStock - (qty ? qty : 0);
      if (quantity >= calc) {
        return true;
      }
    }
  }, [sale, product, quantity]);

  const disabledAdd = useMemo(() => {
    if (sale) {
      const qty = sale.items.filter(
        (el) => el.article.plu === product?.plu
      )?.[0]?.quantity;
      const calc = product?.inStock - (qty ? qty : 0);
      if (
        quantity > calc ||
        quantity === undefined ||
        (product.isOpenPrice && !openPrice)
      ) {
        return true;
      }
    }
  }, [sale, product, quantity, openPrice]);

  const isAddedToCart = sale?.items.filter(
    (el) => el.article.plu === product.plu
  );

  const announceQuantityChange = (newQuantity) => {
    quantityChangeRef.current.innerText = `${language.Quantity} ${newQuantity}`;
  };

  const increment = () => {
    handlers.current.increment();
    announceQuantityChange(quantity + 1);
  };

  const decrement = () => {
    handlers.current.decrement();
    announceQuantityChange(quantity - 1);
  };

  return (
    <Container>
      <Grid gutter={28}>
        <Grid.Col sm={12} md={7}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ height: "100%" }}
          >
            <Image
              src={product.images && product.images}
              withPlaceholder
              radius={"sm"}
              fit="cover"
              style={{ height: !product.images && "100%" }}
              height={
                mobile && product.images
                  ? "20rem"
                  : mobile && !product.images
                  ? "20rem"
                  : "25rem"
              }
              alt={`${language.ProductAltLabel} ${getTranslation.name}`}
            />
          </motion.div>
        </Grid.Col>
        <Grid.Col sm={12} md={5} className={classes.container}>
          <div className={classes.titleWrapper}>
            <Title order={1} sx={{ fontSize: "1.5rem" }}>
              {getTranslation.name}
            </Title>
          </div>
          <Grid cols={2} className={`${classes.stockWrapper} stockWrapper`}>
            <Grid.Col span={6}>
              <List
                size="sm"
                center
                className={`${classes.list} stockIndicatorSingle`}
              >
                {product.isProduct && (
                  <List.Item
                    icon={
                      <ThemeIcon
                        color={product.inStock > 0 ? "#40C057" : "#FA5252"}
                        size={19}
                        radius="xl"
                      >
                        <div />
                      </ThemeIcon>
                    }
                  >
                    <p style={{ paddingTop: "0.1rem", marginLeft: "-0.2rem" }}>
                      {product.inStock > 0
                        ? language.In_Stock
                        : language.Not_In_Stock}
                    </p>
                  </List.Item>
                )}
              </List>
            </Grid.Col>
            <Grid.Col
              span={6}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Text className={classes.price} component="span">
                {!product.isOpenPrice ? product.priceInclVat : ""}
              </Text>
            </Grid.Col>
          </Grid>

          {product.isPersonalTicket && <PersonalTicket />}
          {product.isOpenPrice && (
            <div style={{ marginTop: "1rem" }}>
              <NumberInput
                label={language.OpenPrice}
                defaultValue={100}
                stepHoldDelay={500}
                stepHoldInterval={100}
                size="md"
                onChange={(val) => openPriceSet(val)}
                min={1}
                icon={
                  <Text component={"span"} color="gray.7">
                    kr
                  </Text>
                }
                parser={(value) => value.replace(/[$€]\s?|(,*)/g, "")}
                formatter={(value) =>
                  !Number.isNaN(parseFloat(value))
                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
                    : ""
                }
              />
            </div>
          )}
          <div className={classes.actionGrid}>
            <div
              className={classes.hideLabel}
              role="region"
              aria-live="assertive"
              ref={quantityChangeRef}
            />
            <Group
              spacing={5}
              styles={{ height: 42 }}
              className={classes.actionWrapper}
            >
              <ActionIcon
                className={`${classes.actionButton} addToCart`}
                size={35}
                variant="filled"
                disabled={
                  product.inStock <= 0 ||
                  quantity === 1 ||
                  product.isGiftCertificate
                }
                onClick={() => decrement()}
                aria-label={`${getTranslation.name} ${language.DecreaseQuantity}`}
              >
                <div style={{ fontSize: "1.5rem" }}>
                  <HiMinusSm />
                </div>
              </ActionIcon>
              <NumberInput
                hideControls
                value={quantity}
                aria-label={`${getTranslation.name} ${language.Quantity} ${quantity}`}
                onChange={(val) => quantitySet(val)}
                handlersRef={handlers}
                max={product.inStock}
                min={1}
                step={1}
                disabled={
                  product.inStock <= 0 ||
                  (product.isOpenPrice && !openPrice) ||
                  product.isGiftCertificate
                }
                styles={{
                  input: {
                    backgroundColor: "transparent",
                    border: "none",
                    width: 35,
                    textAlign: "center",
                    height: 35,
                    fontWeight: "600",
                    fontSize: "1.25rem",
                    padding: 0,
                  },
                }}
              />
              <ActionIcon
                aria-label={`${getTranslation.name} ${language.AddQuantity}`}
                className={`${classes.actionButton} addToCart`}
                size={35}
                variant="filled"
                disabled={
                  disabledIncrement ||
                  (product.isOpenPrice && !openPrice) ||
                  product.isGiftCertificate
                }
                onClick={() => increment()}
              >
                <div style={{ fontSize: "1.5rem" }}>
                  <HiPlusSm />
                </div>
              </ActionIcon>
            </Group>

            <div
              className={classes.alertWrapper}
              role="region"
              aria-live="polite"
            >
              <Button
                variant="filled"
                size="md"
                className="regularButton add_to_cart_product"
                classNames={{
                  label: "add_to_cart_product",
                  inner: "add_to_cart_product",
                }}
                onClick={handleAdd}
                disabled={disabledAdd}
                sx={{
                  width: "100%",
                  backgroundColor:
                    addedToCart === true
                      ? "#40C057 !important"
                      : addedToCart === false
                      ? "#FA5252 !important"
                      : "",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                {!product.isOpenPrice &&
                disabledAdd &&
                isAddedToCart?.length > 0
                  ? language.MaxInCart
                  : !product.isOpenPrice &&
                    disabledAdd &&
                    language.Not_In_Stock}

                {!disabledAdd && addedToCart
                  ? language.Added_To_Cart
                  : !disabledAdd && language.AddToCartBtn}

                {product.isOpenPrice && disabledAdd && language.AddToCartBtn}
              </Button>
            </div>
          </div>

          <Divider my="sm" mt="xl" mb="xl" />
          <Title order={2} className={classes.descriptionTitle}>
            {language.Description}
          </Title>

          <div className={`${classes.descriptionBody} productDescription`}>
            <Markdown
              source={description ? description : getTranslation.description}
              escapeHtml={false}
              skipHtml={false}
            />
          </div>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default SingleProduct;
