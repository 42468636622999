import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { DataContext } from "../../components/common/dataProvider";
import PageLayout from "../../components/Styles/PageLayout";
import { getEvent } from "./util";
import Event from "../../components/Event";
import slugify from "../../components/common/slugify";
import PageContent from "../../components/Styles/PageContent";
import EventV2 from "../../components/Event/EventV2";
import { Button, Container, Text, Title } from "@mantine/core";
import { useNavigate } from "@reach/router";

const EventPage = ({ id }) => {
  const { data, eventFilter } = useContext(DataContext);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const navigate = useNavigate();
  const allotment = data?.allotments.find(
    (allotment) => slugify(allotment.description) === id
  );
  const event = useMemo(() => getEvent(data, config, id), [data, id, config]);

  if (!allotment)
    return (
      <PageLayout>
        <PageContent>
          <Container
            sx={{ textAlign: "center", margin: "3rem 0", maxWidth: "100%" }}
          >
            <Title mb="lg" order={1}>
              {language.CouldNotFindEventHeader}
            </Title>
            <Text component="p" mb="lg">
              {language.CouldNotFindEventText}
            </Text>
            <Button
              component="a"
              onClick={() => navigate("/ticketshop")}
              className="mantine-Primary-btn primaryButton"
              variant="filled"
              size="md"
              radius="sm"
              color="indigo"
            >
              {language.BackToHomepage}
            </Button>
          </Container>
        </PageContent>
      </PageLayout>
    );

  return (
    <PageLayout>
      <PageContent>
        {config?.useNewEventPage ? (
          <div>
            <EventV2 filter={eventFilter} event={event} />
          </div>
        ) : (
          <Event filter={eventFilter} event={event} />
        )}
      </PageContent>
    </PageLayout>
  );
};

export default EventPage;
