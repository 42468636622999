import React from "react";
import {
  createStyles,
  Divider,
  Image,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useNavigate } from "@reach/router";
import { useSelector } from "react-redux";
import slugify from "../common/slugify";
import { v4 as uuid } from "uuid";
import { useSessionStorage } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "90% 1fr",
    gridGap: "1rem",
    padding: "0rem 1rem",
    width: "100%",
    ":hover": {
      backgroundColor: theme.colors.gray[1],
      p: {
        color: "black",
      },
    },
    "@media screen and (max-width: 768px)": {
      gridGap: "0.5rem",
      padding: "0rem 0.5rem",
      gridTemplateColumns: "auto auto",
    },
  },
  priceWrapper: {
    p: {
      textAlign: "right",
    },
    "&:last-child": {
      justifySelf: "end",
      alignSelf: "center",
    },
  },
  imageWrapper: {},
  title: {
    fontWeight: "bold",
    marginBottom: 0,
    marginTop: "1rem",
    "@media screen and (max-width: 768px)": {},
  },
  price: {
    paddingRight: "1rem",
    marginTop: ".5rem",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    width: "100%",
    padding: "1rem 0rem",
    "@media screen and (max-width: 768px)": { gap: "0.7rem" },
  },
}));

const ListV2 = ({ item, slug }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [selected, setSelected] = useSessionStorage({
    key: "activeLink",
    defaultValue: "",
  });
  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  const link = () => {
    setSelected(item?.text?.primary.toLowerCase());
    const filter = config.landingPage.links.filter(
      (el) => el.name === item.name
    )?.[0];
    if (slug) {
      return slug;
    }
    if (item?.eventCalendar) {
      return `/ticketshop/tickets/${slugify(item.name)}`;
    }
    if (item?.articleBooking) {
      return `/ticketshop/booking/${slugify(item.name)}`;
    }
    if (item?.externalLink) {
      return item?.externalLink;
    }
    if (item?.internalLink) {
      return item?.internalLink;
    }
    if (item?.publicBooking) {
      return `/ticketshop/publicbooking/${slugify(item.name)}`;
    } else {
      if (filter) {
        return `/ticketshop/${slugify(filter.name)}`;
      }
    }
  };

  return (
    <>
      <UnstyledButton
        className={`${classes.wrapper} landing-cardButton`}
        component={"a"}
        onClick={() => !item.externalLink && navigate(link())}
        href={item?.externalLink && item?.externalLink}
        target={item?.externalLink && "_blank"}
        style={{ borderRadius: "8px" }}
        key={uuid()}
        aria-label={`${item?.text?.primary}, ${item?.text?.secondary}`}
        tabIndex={0}
      >
        <div className={classes.grid}>
          <div className={classes.imageWrapper}>
            <Image
              radius="md"
              src={item.image}
              fit={"cover"}
              height={"6rem"}
              width={"6rem"}
              withPlaceholder
              alt={`${language.PhotoOf} ${item?.text?.primary}`}
            />
          </div>
          <div>
            <Text
              className={`${classes.title} landing-cardPrimaryText`}
              weight={500}
              component="p"
              style={{ color: "black" }}
            >
              {item?.text?.primary}
            </Text>
            <Text
              className="landing-cardSecondaryText"
              size="sm"
              color="gray.7"
              lineClamp={4}
              component="p"
              mt={"0"}
              weight={"600"}
            >
              {item?.text?.secondary}
            </Text>
          </div>
        </div>
      </UnstyledButton>
      <Divider color={theme.colors.gray[2]} />
    </>
  );
};

export default ListV2;
