import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Link } from "@reach/router";
import slugify from "../slugify";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { DataContext } from "../dataProvider";
import { STATE_CULTURE } from "../../../common/state";

const useStyles = makeStyles((theme) => ({
  navWrapper: {
    position: "fixed",
    zIndex: 995,
    top: 0,
    backgroundColor: "#fff",
    width: "100vw",
    "@media screen and (max-width: 667px)": {
      height: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      height: (p) => (p.headerHeight ? `${p.headerHeight + 64}px` : ""),
    },
  },
  nav: {
    alignItems: "center",
    backgroundColor: theme.app.background[1],
    borderBottom: `1px solid ${theme.app.borderColor}`,
    color: theme.app.color,
    display: "flex",
    height: "64px",
    zIndex: 998,

    // Fixed subheader settings
    position: "fixed",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      top: (p) => (p.headerHeight ? `${p.headerHeight}px` : "64px"),
    },

    "@media(max-width: 576px)": {
      display: "none",
    },
  },
  tabs: {
    width: "100%",
    "&:focus": {
      border: "3px solid black",
    },
  },
  tabsInner: {
    justifyContent: "center",
    "@media(max-width: 577px)": {
      justifyContent: "flex-start",
    },
  },
  indicator: {
    backgroundColor: "var(--nav-active-color)",
  },
  tab: {
    maxWidth: "none",
    fontSize: "0.8125rem",
  },
}));

const isPageContainsOnlyOneEventType = (page) => {
  if (page.events && page.events.length === 1) {
    return true;
  }
};

const Nav = () => {
  const { data } = useContext(DataContext);
  const location = useLocation();
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const classes = useStyles({ headerHeight: config.headerHeight });

  const { pages, showAllProducts, useAllEvents, useNav } = config;

  const [bookingSlugs, setBookingSlugs] = useState();

  useEffect(() => {
    if (config.ticketConfigurator !== undefined) {
      let bookingLinks = config?.ticketConfigurator;
      const getBookingSlugs = bookingLinks.reduce((acc, curr) => {
        if (!curr?.disabledNav && !curr?.disabled) {
          acc.push({
            name: curr.name,
            slug: curr.slug,
            translations: curr?.translations?.filter(
              (el) => el.culture === culture
            ),
          });
        }
        return acc;
      }, []);
      setBookingSlugs(getBookingSlugs);
    }
  }, []);

  const LinkTab = ({ classname, ...rest }) => (
    <Tab
      button="true"
      component={Link}
      {...rest}
      className={clsx(classes.tab, classes[classname])}
    />
  );

  const ExternalLinkTab = ({ classname, ...rest }) => (
    <Tab
      component="a"
      {...rest}
      className={clsx(classes.tab, classes[classname])}
    />
  );
  const getAllLinks = () => {
    let links = [
      `${process.env.PUBLIC_URL}/`,
      `${process.env.PUBLIC_URL}/all-products`,
    ];
    if (data?.allotments?.length > 0 && useAllEvents) {
      links = [...`${process.env.PUBLIC_URL}/all-events`];
    }

    pages.forEach((n) => {
      links = [...links, `${process.env.PUBLIC_URL}/${slugify(n.name)}`];
    });
    return links;
  };
  if (!useNav) return null;

  const getArticleBookingLinks = (item) => {
    if (item?.translations) {
      const filtered = item?.translations?.filter(
        (el) => el.culture === culture
      );
      return filtered[0]?.name;
    } else {
      return item?.name;
    }
  };

  return (
    <div className={classes.navWrapper}>
      <div className="nav__container_wrapper">
        <div className={clsx(classes.nav, "nav__container")}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable nav"
            value={
              getAllLinks().includes(location.pathname)
                ? location.pathname
                : false
            }
            classes={{
              root: classes.tabs,
              flexContainer: classes.tabsInner,
              indicator: classes.indicator,
            }}
          >
            {config.useLandingPage && !config.homeLink && (
              <LinkTab
                tabIndex={0}
                label={language.Home}
                to={`${process.env.PUBLIC_URL}/`}
                value={`${process.env.PUBLIC_URL}/`}
              />
            )}
            {config.useLandingPage && config.homeLink && (
              <ExternalLinkTab
                tabIndex={0}
                label={language.Home}
                href={config.homeLink}
                value={`${process.env.PUBLIC_URL}/`}
              />
            )}
            {bookingSlugs &&
              bookingSlugs.map((bookingPage, i) => (
                <LinkTab
                  tabIndex={0}
                  label={`${
                    bookingPage?.translations?.[0]
                      ? bookingPage?.translations?.[0]?.name
                      : bookingPage?.name
                  }`}
                  to={`${process.env.PUBLIC_URL}/tickets/${bookingPage.slug}`}
                  value={`${process.env.PUBLIC_URL}/tickets/${bookingPage.slug}`}
                  key={bookingPage.slug + i}
                />
              ))}
            {config.articleBookingConfigurator &&
              config.articleBookingConfigurator.map((el, i) => {
                if (el?.disabled || el?.disabledNav) return null;
                return (
                  <LinkTab
                    tabIndex={0}
                    label={getArticleBookingLinks(el)}
                    to={`${process.env.PUBLIC_URL}/booking/${el.slug}`}
                    value={`${process.env.PUBLIC_URL}/booking/${el.slug}`}
                    key={el.slug + i}
                  />
                );
              })}

            {pages.map((page, index) => {
              if (page.disabled) return null;
              let slug = `${process.env.PUBLIC_URL}/${slugify(page.name)}`;

              if (
                isPageContainsOnlyOneEventType(page) &&
                config.useAutoNavigationOnSingleEvent
              ) {
                slug = `${process.env.PUBLIC_URL}/events/${slugify(page.name)}`;
              }
              const name = language.Nav
                ? language?.Nav[page.name] || page.name
                : page.name;
              return (
                <LinkTab
                  tabIndex={0}
                  key={index}
                  label={name}
                  to={slug}
                  value={slug}
                />
              );
            })}
            {showAllProducts && (
              <LinkTab
                tabIndex={0}
                label={
                  pages.length > 0
                    ? language.All_Products
                    : language.GroupNav.Products
                }
                to={`${process.env.PUBLIC_URL}/all-products`}
                value={`${process.env.PUBLIC_URL}/all-products`}
              />
            )}
            {data?.allotments?.length > 0 && useAllEvents && (
              <LinkTab
                tabIndex={0}
                label={
                  pages.length > 0
                    ? language.All_Events
                    : language.GroupNav.Events
                }
                to={`${process.env.PUBLIC_URL}/all-events`}
                value={`${process.env.PUBLIC_URL}/all-events`}
              />
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Nav;
