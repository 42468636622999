import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.css";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { DataContext } from "../common/dataProvider";
import { extendPage } from "./util";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import ChangeTimeButton from "./ChangeTimeButton";
import AddToCart from "./AddToCart";
import { motion } from "framer-motion";
import { STATE_CURRENCY } from "../../common/state";

const ArticleChooser = ({
  chosenDate,
  scrollIntoView,
  allotmentData,
  allotmentSettings,
  selected,
  setSelected,
  setChooseTime,
  setActiveOptionScreen,
  activeOptionScreen,
  language,
  cultureLocale,
  singleOccasion,
  activeTicketConfigurator,
}) => {
  const [allotments, setAllotments] = useState();
  const [allotmentArticles, setAllotmentArticles] = useState();
  const [optionAllotments, setOptionAllotments] = useState([]);
  const [optionArticles, setOptionArticles] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [optionAllotmentsRemaining, setOptionAllotmentsRemaining] = useState();
  const [options, setOptions] = useState();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [timeSelected, setTimeSelected] = useState();
  const [addedAllotment, setAddedAllotment] = useState(0);
  const [fullAllotment, setFullAllotment] = useState(false);
  const [allotmentItemOutOfStock, setAllotmentItemOutOfStock] = useState([]);
  const [clicked, setClicked] = useState(false);
  const { config, currency } = useSelector((state) => ({
    config: state.config,
    currency: state.cookies[STATE_CURRENCY],
  }));

  const { data } = useContext(DataContext);
  const page = extendPage(data, config);
  const changeTimeBtnRef = useRef(null);

  const resetDate = () => {
    if (
      singleOccasion &&
      activeTicketConfigurator?.hideTimeChooserIfSingleOccasion
    ) {
      scrollIntoView("NewCalendar");
    } else {
      scrollIntoView("TimeChooser");
    }
    setActiveOptionScreen(false);
    setChooseTime("");
    setSelected(false);
    setOptionAllotments();
    setTimeSelected(selected?.timeOccasions);
    setFullAllotment(false);
    setCart([]);
    setAddedAllotment(0);
    setAllotmentItemOutOfStock([]);
  };
  useEffect(() => {
    const optionAllotmentFilter =
      allotmentSettings[0] &&
      data.allotments.filter((el) =>
        allotmentSettings[0].optionAllotmentId.includes(el.id)
      );

    const sortOptionArticlesByPrice = optionAllotmentFilter?.map((el) =>
      el.articles.sort((a, b) => a.price.amountInclVat - b.price.amountInclVat)
    );

    const sortOptionArticles = optionAllotmentFilter?.sort(
      (a, b) =>
        a.articles?.[0].price.amountInclVat -
        b.articles?.[0].price.amountInclVat
    );

    const remainingFilter =
      allotmentData &&
      optionAllotmentFilter &&
      allotmentData.allotments.filter((el) => {
        return optionAllotmentFilter.some((ele) => {
          return el.description === ele.description;
        });
      });

    const mapArticles = page.productGroups.map((el) => el.articles);
    const optionArticleFilter =
      allotmentSettings[0] &&
      mapArticles
        .flat()
        .filter((el) => allotmentSettings[0].optionArticleId.includes(el.id));
    const removeDuplicates = [...new Set(optionArticleFilter)];

    const sortByPrice = removeDuplicates.sort(
      (a, b) => a.price.amountInclVat - b.price.amountInclVat
    );
    setOptionAllotments(optionAllotmentFilter);
    setOptionAllotmentsRemaining(remainingFilter);
    setOptionArticles(sortByPrice);
  }, [selected, allotmentSettings]);

  useEffect(() => {
    const articles = selected?.articles && selected?.articles;
    const sortSelected =
      selected && articles && articles.sort((a, b) => a.plu - b.plu);

    setAllotments(selected);
    setAllotmentArticles(sortSelected);
    setTimeSelected(selected?.timeOccasions);
    setFullAllotment(false);
    setCart([]);
    setAddedAllotment(0);
    setAllotmentItemOutOfStock([]);
  }, [selected, chosenDate]);

  useEffect(() => {
    if (chosenDate !== selected) {
      setActiveOptionScreen(false);
    }
  }, [chosenDate]);

  useEffect(() => {
    if (addedAllotment === allotments?.allotment?.maxPerSale) {
      setDisabledBtn(true);
    }
    if (addedAllotment < allotments?.allotment?.maxPerSale) {
      setDisabledBtn(false);
    }
  }, [addedAllotment]);

  const addItem = (product, timeSelected) => {
    if (calculateQuantity(product) + 1 <= product.inStock) {
      setCart([...cart, product]);
    }
  };

  const addAllotment = (product, timeSelected) => {
    const newProduct = product;
    newProduct.allotmentId = selected.allotment?.id;
    newProduct.timeOccasions = timeSelected;
    if (
      addedAllotment < selected.remaining &&
      addedAllotment < allotments.allotment.maxPerSale
    ) {
      setAddedAllotment((prev) => prev + 1);
      setCart([...cart, newProduct]);
    }
    if (addedAllotment === selected.remaining - 1) {
      setFullAllotment(true);
    }
  };

  const removeAllotment = (el) => {
    setAddedAllotment((prev) => prev - 1);
    let newCart = [...cart];
    let res = cart.findIndex((i) => i.id === el.id);
    newCart.splice(res, 1);
    setCart(newCart);
    if (addedAllotment <= selected.remaining) {
      setFullAllotment(false);
    }
  };
  const addAllotmentItem = (product, timeSelected, occasion) => {
    if (optionAllotments !== undefined) {
      let data = [];
      data = [occasion];
      const newFilter =
        data &&
        data.reduce((acc, curr) => {
          const item = curr.occasions.filter((ele) => {
            return ele.time.split("T")[0] === timeSelected.split("T")[0];
          });
          acc.push(item);
          return acc;
        }, []);

      const newFilterFlat = newFilter[0];
      const time = newFilterFlat[0].time;
      const newProduct = product;
      newProduct.allotmentId = occasion.id;
      newProduct.timeOccasions = time;

      const filterAllotment = optionAllotmentsRemaining.filter(
        (el) => el.description === occasion.description
      );

      const filterTime = filterAllotment[0].occasions.filter(
        (el) => el.time.split("T")[0] === timeSelected.split("T")[0]
      );
      if (
        calculateQuantity(newProduct) + 1 <= filterTime[0].remaining &&
        calculateQuantity(newProduct) + 1 <= filterTime[0].allotment.maxPerSale
      ) {
        setCart([...cart, newProduct]);
      }
      if (
        calculateQuantity(newProduct) + 1 === filterTime[0].remaining ||
        calculateQuantity(newProduct) + 1 === filterTime[0].allotment.maxPerSale
      ) {
        setAllotmentItemOutOfStock([
          ...allotmentItemOutOfStock,
          newProduct.name,
        ]);
      }
    }
  };

  const removeAllotmentItem = (el, occasion) => {
    const filterAllotment = optionAllotmentsRemaining.filter(
      (ele) => ele.description === occasion.description
    );
    const filterTime = filterAllotment[0].occasions.filter(
      (ele) => ele.time.split("T")[0] === timeSelected.split("T")[0]
    );

    let newCart = [...cart];
    let res = cart.findIndex((i) => i.id === el.id);
    newCart.splice(res, 1);
    setCart(newCart);

    if (calculateQuantity(el) <= filterTime[0].remaining) {
      setAllotmentItemOutOfStock(
        allotmentItemOutOfStock.filter((name) => name !== el.name)
      );
    }
  };

  const checkAllotmentItemRemaining = (el, timeSelected) => {
    if (timeSelected !== undefined) {
      const filterAllotment = optionAllotmentsRemaining.filter(
        (item) => item.description === el.description
      );
      const filterTime = filterAllotment[0]?.occasions.filter(
        (item) => item.time.split("T")[0] === timeSelected.split("T")[0]
      );
      if (filterTime && filterTime[0]?.remaining === 0) {
        return "full";
      }
    }
  };

  const removeItem = (el) => {
    let newCart = [...cart];
    let res = cart.findIndex((i) => i.id === el.id);
    newCart.splice(res, 1);
    setCart(newCart);
  };

  const calculateValue = () => {
    const removeChar = (price) => {
      if (price) {
        let newPrice = price && price.replaceAll(/[^0-9,.]/g, "");
        const commaIndex = newPrice.lastIndexOf(",");
        if (commaIndex !== -1) {
          if (commaIndex === 1) {
            newPrice =
              newPrice.substring(0, commaIndex) +
              newPrice.substring(commaIndex + 1);
          } else {
            newPrice =
              newPrice.substring(0, commaIndex) +
              "." +
              newPrice.substring(commaIndex + 1);
          }
        }
        return parseFloat(newPrice);
      }
    };
    let total = 0;
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].price.amountInclVat !== 0) {
        total += parseFloat(
          removeChar(cart[i].priceInclVat)
            ? removeChar(cart[i].priceInclVat)
            : removeChar(cart[i].displayPrice)
            ? removeChar(cart[i].displayPrice)
            : ""
        );
      }
    }
    setCartTotal(total.toFixed(2));
  };
  const calculateQuantity = (el) => {
    const findItems = cart.filter((item) => item.id === el.id);
    return findItems.length;
  };

  useEffect(() => {
    calculateValue();
  }, [cart]);

  useEffect(() => {
    if (optionAllotments !== undefined && timeSelected !== undefined) {
      let results = optionAllotments.filter((x) => {
        let data = x.occasions.some(
          (y) => y.time.split("T")[0] === timeSelected.split("T")[0]
        );
        return data;
      });
      setOptions(results);
    }
  }, [optionAllotments, timeSelected]);

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
  };

  useEffect(() => {
    const calendarTimePicker = document.getElementById(
      "calendar-article-picker"
    );
    if (calendarTimePicker && selected?.timeOccasions) {
      setTimeout(() => {
        calendarTimePicker.focus();
      }, 1000);
    }
  }, [selected]);

  return (
    <div
      id="calendar-article-picker"
      data-isactive={activeOptionScreen === false ? "false" : "true"}
      className={`calendar-timeChooserDate calendar-articleChooserWrapper ${
        activeOptionScreen === false ? "hideScreen" : ""
      }`}
      tabIndex={0}
    >
      <h2 className="calendar-header">{language?.ChooseAllotmentHeader}</h2>

      <div
        className={`${
          activeOptionScreen === false ? "calendar-hideItems" : ""
        }  `}
      >
        <div className={`${clicked === true ? "calendar-hideScreen" : ""}`}>
          {!singleOccasion ? (
            <h3 className="calendar-subHeader calendar-articleSubHeader">
              {selected?.timeOccasions !== undefined &&
                format(new Date(selected?.timeOccasions), "EEEE", {
                  locale: cultureLocale,
                })}
              &nbsp;
              {language?.DateTheText}
              &nbsp;
              {selected?.timeOccasions !== undefined &&
                format(new Date(selected?.timeOccasions), "do MMMM HH:mm", {
                  locale: cultureLocale,
                })}
            </h3>
          ) : (
            <h3 className="calendar-subHeader calendar-articleSubHeader">
              {selected?.timeOccasions !== undefined &&
                format(new Date(selected?.timeOccasions), "EEEE do MMMM", {
                  locale: cultureLocale,
                })}
            </h3>
          )}

          <button
            onClick={() => resetDate()}
            className="removeBorderBg calendar-changeTimeBtn"
            aria-label={
              singleOccasion &&
              activeTicketConfigurator?.hideTimeChooserIfSingleOccasion
                ? language?.ChangeDateBtn
                : language?.ChangeTimeBtn
            }
            ref={changeTimeBtnRef}
          >
            <ChangeTimeButton
              text={
                singleOccasion &&
                activeTicketConfigurator?.hideTimeChooserIfSingleOccasion
                  ? language?.ChangeDateBtn
                  : language?.ChangeTimeBtn
              }
              outline
              selected={selected}
            />
          </button>
          <div className="calendar-allotmentArticleWrapper">
            {allotments &&
              allotments.articles.map((el, i) =>
                allotmentArticles.length > 0 ? (
                  <div className="calendar-articleWrapper" key={el.name + i}>
                    <div className="calendar-articleLeftColumn">
                      <p className="calendar-articleTitle">{el.name}</p>
                      <p className="calendar-articlePrice">{el.priceInclVat}</p>
                    </div>
                    <div className="calendar-articleRightColumnToggle">
                      <motion.div
                        className="calendar-switch"
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        transition={spring}
                        layout
                      />
                      <motion.div
                        className={`calendar-handle ${
                          fullAllotment === true ? "calendar-hideScreen" : ""
                        }`}
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        whileTap={{ scale: 1.2 }}
                        layout
                        transition={spring}
                        style={{
                          pointerEvents: disabledBtn ? "none" : "",
                        }}
                      >
                        <button
                          className={`calendar-articleButton`}
                          onClick={() => addAllotment(el, timeSelected)}
                          style={{
                            opacity: disabledBtn ? "0.2" : "1",
                          }}
                          aria-label={`${language?.AddQuantity} ${el.name}`}
                        >
                          <MdAddCircle className="buttonIcons" />
                        </button>
                      </motion.div>
                      <motion.div
                        className="calendar-count calendar-articleQty"
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        layout
                        transition={spring}
                        role="region"
                        aria-live="assertive"
                      >
                        {calculateQuantity(el)}
                      </motion.div>
                      <motion.div
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        className="calendar-handle2"
                        whileTap={{ scale: 1.2 }}
                        layout
                        transition={spring}
                      >
                        <button
                          className={`calendar-articleButtonRemove ${
                            calculateQuantity(el) === 0
                              ? "calendar-hideScreen"
                              : ""
                          }`}
                          onClick={() => removeAllotment(el)}
                          aria-label={`${language?.DecreaseQuantity} ${el.name}`}
                          disabled={calculateQuantity(el) === 0 ? true : false}
                        >
                          <MdRemoveCircle className="buttonIcons" />
                        </button>
                      </motion.div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
          </div>

          {(options && optionArticles && options.length > 0) ||
          optionArticles.length > 0 ? (
            <h3 className="calendar-articleHeader">{language?.Options}</h3>
          ) : (
            ""
          )}

          {options &&
            options.map((el, i) => (
              <div key={el.name + i}>
                {el.articles.map((ele, i) =>
                  checkAllotmentItemRemaining(el, timeSelected) === "full" ? (
                    <div
                      className="calendar-articleWrapper calendar-hideScreen"
                      key={ele.name + i}
                    >
                      <div className="calendar-articleLeftColumn">
                        <p className="calendar-articleTitle">{ele.name}</p>
                        <p className="calendar-articlePrice">
                          {language?.SoldOut}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="calendar-articleWrapper" key={ele.name + i}>
                      <div className="calendar-articleLeftColumn">
                        <p className="calendar-articleTitle">{ele.name}</p>
                        <p className="calendar-articlePrice">
                          {ele && ele.priceInclVat}
                        </p>
                      </div>
                      <div className="calendar-articleRightColumnToggle">
                        <motion.div
                          className="calendar-switch"
                          data-ison={calculateQuantity(ele) > 0 ? true : ""}
                          transition={spring}
                          layout
                        />
                        <motion.div
                          className={`calendar-handle`}
                          data-ison={calculateQuantity(ele) > 0 ? true : ""}
                          // whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 1.2 }}
                          layout
                          transition={spring}
                          style={{
                            pointerEvents: allotmentItemOutOfStock.includes(
                              ele.name
                            )
                              ? "none"
                              : "",
                          }}
                        >
                          <button
                            className={`calendar-articleButton ${
                              allotmentItemOutOfStock.includes(ele.name)
                                ? "calendar-hideScreen"
                                : ""
                            }`}
                            onClick={() =>
                              addAllotmentItem(ele, timeSelected, el)
                            }
                            aria-label={`${language?.AddProduct} ${ele.name}`}
                          >
                            <MdAddCircle className="buttonIcons" />
                          </button>
                        </motion.div>
                        <motion.div
                          className="calendar-count calendar-articleQty"
                          data-ison={calculateQuantity(ele) > 0 ? true : ""}
                          layout
                          transition={spring}
                        >
                          {calculateQuantity(ele)}
                        </motion.div>
                        <motion.div
                          data-ison={calculateQuantity(ele) > 0 ? true : ""}
                          className="calendar-handle2"
                          whileTap={{ scale: 1.2 }}
                          layout
                          transition={spring}
                        >
                          <button
                            className={`calendar-articleButtonRemove ${
                              calculateQuantity(ele) === 0
                                ? "calendar-hideScreen"
                                : ""
                            }`}
                            onClick={() => removeAllotmentItem(ele, el)}
                            aria-label={`${
                              language?.RemoveProduct
                            } ${calculateQuantity(ele)} ${ele.name}`}
                            // disabled={
                            //   calculateQuantity(el) === 0 ? true : false
                            // }
                          >
                            <MdRemoveCircle className="buttonIcons" />
                          </button>
                        </motion.div>
                      </div>
                    </div>
                  )
                )}
              </div>
            ))}
          {optionArticles && optionArticles !== null
            ? optionArticles.map((el, i) => (
                <div
                  className={`calendar-articleWrapper ${
                    el.inStock === 0 ? "calendar-hideScreen" : ""
                  }`}
                  key={el.name + i}
                >
                  <div className="calendar-articleLeftColumn">
                    <p className="calendar-articleTitle">{el.name}</p>
                    <p className="calendar-articlePrice">
                      {el.inStock === 0 ? language?.SoldOut : el.priceInclVat}
                    </p>
                  </div>
                  {el.inStock === 0 ? (
                    ""
                  ) : (
                    <div className="calendar-articleRightColumnToggle">
                      <motion.div
                        className="calendar-switch"
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        transition={spring}
                        layout
                      />
                      <motion.div
                        className="calendar-handle"
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        // whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 1.2 }}
                        layout
                        transition={spring}
                        style={{
                          pointerEvents:
                            calculateQuantity(el) === el.inStock ? "none" : "",
                        }}
                      >
                        <button
                          className={`calendar-articleButton ${
                            calculateQuantity(el) === el.inStock
                              ? "calendar-hideScreen"
                              : ""
                          }`}
                          onClick={() => addItem(el)}
                          aria-label={`${language?.AddProduct} ${el.name}`}
                        >
                          <MdAddCircle className="buttonIcons" />
                        </button>
                      </motion.div>
                      <motion.div
                        className="calendar-count calendar-articleQty"
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        layout
                        transition={spring}
                      >
                        {calculateQuantity(el)}
                      </motion.div>
                      <motion.div
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        className="calendar-handle2"
                        layout
                        whileTap={{ scale: 1.2 }}
                        transition={spring}
                      >
                        <button
                          className={`calendar-articleButtonRemove ${
                            calculateQuantity(el) === 0
                              ? "calendar-hideScreen"
                              : ""
                          }`}
                          onClick={() => removeItem(el)}
                          aria-label={`${
                            language?.RemoveProduct
                          } ${calculateQuantity(el)} ${el.name}`}
                          disabled={calculateQuantity(el) === 0 ? true : false}
                        >
                          <MdRemoveCircle className="buttonIcons" />
                        </button>
                      </motion.div>
                    </div>
                  )}
                </div>
              ))
            : ""}
          {cartTotal > 0 ? (
            <p className="calendar-articleTotalCost">
              {language?.ToPay} {cartTotal} {currency}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="calendar-articleBuyWrapper">
          <AddToCart
            cart={cart}
            setCart={setCart}
            allotmentId={selected}
            clicked={clicked}
            setClicked={setClicked}
            language={language}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleChooser;
