import moment from "moment";

export const getMonths = (start, duration) => {
  let months = [];
  let currentMonth = `${start}01`;
  const end = moment(`${start}01`)
    .add(duration - 1, "months")
    .format("YYYYMMDD");
  const addMonths = () => {
    if (
      Number(moment(end).format("YYYYMM")) >=
      Number(moment(currentMonth).format("YYYYMM"))
    ) {
      months = [...months, moment(currentMonth).format("YYYYMM")];
      currentMonth = moment(currentMonth).add(1, "month").format("YYYYMMDD");
      addMonths();
    }
  };
  addMonths();
  return months;
};

export const getMonthPickerMonths = () => {
  let yearMonths = [];
  const duration = 24; //Number of years in picker
  const months = getMonths(moment().startOf("year").format("YYYYMM"), duration);
  months.forEach((m) => {
    if (
      yearMonths.length < 1 ||
      !yearMonths.some((y) => y.year === moment(`${m}01`).format("YYYY"))
    ) {
      return (yearMonths = [
        ...yearMonths,
        {
          year: moment(`${m}01`).format("YYYY"),
          yearValue: moment(`${m}01`).year(),
          months: [{ date: moment(`${m}01`).toISOString(), value: Number(m) }],
        },
      ]);
    }
    if (yearMonths.some((y) => y.year === moment(`${m}01`).format("YYYY"))) {
      let newYear = yearMonths.find(
        (y) => y.year === moment(`${m}01`).format("YYYY")
      );
      newYear = {
        ...newYear,
        months: [
          ...newYear.months,
          { date: moment(`${m}01`).toISOString(), value: Number(m) },
        ],
      };
      yearMonths = yearMonths.map((year) => {
        if (year.year === newYear.year) {
          return newYear;
        }
        return year;
      });
    }
  });

  return yearMonths;
};

export const getOccasionMonths = (events) => {
  if (!events) return [];
  let occasionMonths = [];
  events.forEach((e) => {
    e.occasions.forEach((o) => {
      const formattedMonth = moment(o.time).format("YYYYMM");
      if (!occasionMonths.includes(formattedMonth)) {
        occasionMonths = [...occasionMonths, formattedMonth];
      }
    });
  });
  return occasionMonths;
};

export const hasMonthEvent = (month, events) => {
  const occasionMonths = getOccasionMonths(events);
  return occasionMonths.includes(month);
};
