import uuid from "uuid";
import { getDisplayPrice } from "../../components/ProductList/util";
import slugify from "../../components/common/slugify";

const makeId = () => uuid.v4();

const getAllArticles = (tags) => {
  let ids = [];
  let articles = [];
  tags.forEach((t) => {
    t.articles.forEach((a) => {
      if (!ids.includes(a.id)) {
        ids = [...ids, a.id];
        articles = [...articles, a];
      }
    });
  });
  return articles;
};

const extendArticle = (p, a = {}, config) => {
  const { imageFolderUrl } = config;
  // If its a product that only exists in config
  const plu = a.plu ? a.plu : p.plu;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  return {
    id: a.id ? a.id : makeId(),
    name: a.name ? a.name : p.name,
    plu,
    images: a.imageFilename
      ? [`${uri}/articleimage/${a.imageFilename}`]
      : p.images
      ? p.images.map(
          (img) => `${process.env.PUBLIC_URL}${imageFolderUrl}${img}`
        )
      : undefined,
    price: a.price,
    priceInclVat: a.priceInclVat,
    displayPrice: a.priceInclVat,
    slug: `products/${plu}`,
  };
};

const extendEvents = (page, allotments, config) => {
  if (!page.events) return [];
  let extendedEvents = [];
  page.events.forEach((e) => {
    const allotment = allotments.find((a) => a.description === e);
    const event = config.eventConfig?.find((cfg) => cfg.name === e);
    if (event) {
      if (allotment) {
        const displayPrice = getDisplayPrice(allotment.articles);
        extendedEvents = [
          ...extendedEvents,
          {
            id: allotment.id,
            name: event.name,
            images: event.images?.map(
              (img) => `${config.imageFolderUrl}${img}`
            ),
            listImages: event.listImages?.map(
              (img) => `${config.imageFolderUrl}${img}`
            ),
            displayPrice,
            slug: `events/${slugify(event.name)}`,
          },
        ];
      } else {
        console.log(`No event with name ${e} is published`);
      }
    } else {
      console.log(`No config for event: ${e}`);
    }
  });
  return extendedEvents;
};

const extendProductGroups = (page, tags, config) =>
  page.productGroups.map((g) => {
    const tag = tags.find((t) => t.name === g);
    const groupConfig = config.productGroupConfig.find((c) => c.name === g);
    if (tag) {
      let products = [];
      tag.articles.forEach((a) => {
        const p = config.productConfig.find((c) => c.plu === a.plu);
        if (p) {
          products = [...products, extendArticle(p, a, config)];
        } else {
          products = [...products, extendArticle({}, a, config)];
        }
      });
      if (groupConfig.extraProducts?.length > 0) {
        groupConfig.extraProducts.forEach((p) => {
          const cfg = config.productConfig.find((c) => c.plu === p);
          const art = getAllArticles(tags).find((a) => a.plu === p);

          if (cfg) {
            products = [...products, extendArticle(cfg, art, config)];
          }
        });
      }
      return {
        name: g,
        images: groupConfig?.images || [],
        isGroup: true,
        description: groupConfig?.description,
        allProductsShow: groupConfig?.allProductsShow || true,
        isInfo: groupConfig?.isInfo || false,
        products: products.sort((a, b) => a.plu - b.plu),
      };
    }
    return {
      name: g,
      images: [],
      description: "",
      products: [],
      message: `${g} is not a published article group`,
    };
  });

export const getPage = (data, config, page) => {
  if (!page || !data) return;
  const productGroups = extendProductGroups(page, data.tags, config);
  const events = extendEvents(page, data.allotments, config);

  return { ...page, productGroups, events };
};
