import React from "react";
import { Link } from "@reach/router";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { STATE_THEME } from "../../common/state";

const useStyles = makeStyles((theme) => ({
  logo: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    flexGrow: 1,
  },
  logoLink: { lineHeight: 1 },
}));

const Logo = () => {
  const { config, theme } = useSelector((state) => ({
    config: state.config,
    theme: state.cookies[STATE_THEME],
  }));
  const logoSrc = `${process.env.PUBLIC_URL}/ee-static/shop/${
    config.shopName
  }/Images/${
    config.logoImg
      ? config.logoImg
      : theme === "light"
      ? "logo.svg"
      : "logo_dark.svg"
  }`;

  const logoLink = `${process.env.PUBLIC_URL}/`;

  const classes = useStyles();
  return (
    <div className={classes.logo}>
      {config.logoLink ? (
        <a
          className={clsx(classes.logoLink, "header__logo_link")}
          href={config.logoLink}
        >
          <img src={logoSrc} className={"header__logo-img"} alt="logo" />
        </a>
      ) : (
        <Link
          to={logoLink}
          className={clsx(classes.logoLink, "header__logo_link")}
        >
          <img src={logoSrc} className={"header__logo-img"} alt="logo" />
        </Link>
      )}
    </div>
  );
};

export default Logo;
