import { setCookie, expireCookie, setCookieConsent } from "./cookies";
import { loadLanguage } from "./language";

export const STATE_CURRENCY = "shop-currency";
export const STATE_CULTURE = "shop-culture";
export const STATE_SALE_ID = "shop-saleId";
export const STATE_TAGFILTER = "tag-filter";
export const STATE_COOKIEBANNER = "cookie-banner";
export const STATE_THEME = "shop-theme";
export const STATE_CONSENT = "shop-consent";

export const keys = () => {
  return [
    STATE_CURRENCY,
    STATE_CULTURE,
    STATE_SALE_ID,
    STATE_TAGFILTER,
    STATE_COOKIEBANNER,
    STATE_THEME,
    STATE_CONSENT,
  ];
};

export const setCurrency = (currency) => {
  return setCookie(STATE_CURRENCY, currency);
};

export const setCulture = (culture, config) => (dispatch) => {
  loadLanguage(culture, config, dispatch);

  dispatch(setCookie(STATE_CULTURE, culture));
};

export const setSaleId = (saleId) => {
  return setCookie(STATE_SALE_ID, saleId);
};

export const setTagFilter = (filter) => {
  return setCookie(STATE_TAGFILTER, filter);
};

export const setCookieBanner = () => {
  return setCookie(STATE_COOKIEBANNER, false);
};

export const setTheme = (theme) => {
  return setCookie(STATE_THEME, theme);
};

export const clearSaleId = () => {
  return expireCookie(STATE_SALE_ID);
};

export const setConsent = (value) => (dispatch) => {
  dispatch(setCookieConsent(STATE_CONSENT, value));
};
