import React from "react";
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  toDate,
  isBefore,
  isAfter,
} from "date-fns";
import "../styles.css";
import "./calendarStyles.css";

const WeekDay = ({
  currentDate,
  selectedDate,
  setSelectedDate,
  firstOccasion,
  occasion,
  chosenOldOccasion,
  cultureLocale,
}) => {
  const monthStart = startOfMonth(currentDate, { locale: cultureLocale });
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart, { locale: cultureLocale });
  const endDate = endOfWeek(monthEnd);
  const dateFormat = "d";
  const regularDateFormat = "yyyy-MM-dd";
  const rows = [];

  let days = [];
  let day = startDate;
  let formattedDate = "";
  let today = new Date();
  let yesterday = today.setDate(today.getDate() - 1);

  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  const getAvailability = (day, chosenOldOccasion) => {
    if (
      occasion !== undefined &&
      day !== undefined &&
      chosenOldOccasion !== undefined
    ) {
      let data = occasion;

      const filter = data[0]?.occasions.filter(
        (el) => el.remaining >= chosenOldOccasion.numberOfTickets
      );

      const removeBeforeNow = filter.filter((el) =>
        isAfter(new Date(el.time), new Date())
      );

      const newFilter =
        removeBeforeNow.filter(
          (el) => el.time.split("T")[0].toString() === day.toString()
        ).length > 0;

      if (newFilter === true) {
        return "available";
      } else {
        return "null";
      }
    }
  };

  getAvailability(chosenOldOccasion);

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      const cloneDay = day;

      days.push(
        <button
          className={`removeBorderBg ${
            getAvailability(
              format(day, regularDateFormat),
              chosenOldOccasion
            ) === "available"
              ? "available"
              : "disabled"
          } column cell ${
            !isSameMonth(day, monthStart)
              ? "disabled"
              : isSameDay(day, selectedDate)
              ? "selected"
              : ""
          } ${isBefore(day, yesterday) ? "disabled" : ""}`}
          key={day}
          disabled={
            !isSameMonth(day, monthStart)
              ? true : false ||
              getAvailability(
                  format(day, regularDateFormat),
                  chosenOldOccasion
                ) === "available"
                  ? false
                  : true
          }
          onClick={() => onDateClick(toDate(cloneDay))}
        >
          <span className="number">{formattedDate}</span>
        </button>
      );
      day = addDays(day, 1);
    }

    rows.push(
      <div className="row" key={day}>
        {days}
      </div>
    );
    days = [];
  }
  return <div className="body">{rows}</div>;
};
export default WeekDay;
