import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  selectContainer: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },
  selectControl: {
    "@media(max-width: 667px)": {
      display: "none",
    },
  },
  select: {
    minWidth: "250px",
  },
  nativeSelect: {
    display: "none",
    "@media(max-width: 667px)": {
      display: "block",
    },
  },
  addButtonContainer: {
    alignSelf: "flex-end",
    marginLeft: "16px",
  },
});

const getText = (a, group, useGroupsAsItems, language, notInStock) => {
  if (notInStock) {
    return `${
      useGroupsAsItems ? a.name.replace(`${group.name} `, "") : a.name
    } - ${a.priceInclVat} - ${language.Not_In_Stock}`;
  }
  return `${
    useGroupsAsItems ? a.name.replace(`${group.name} `, "") : a.name
  } - ${a.priceInclVat}`;
};

const ArticleSelect = (props) => {
  const {
    useGroupsAsItems,
    group,
    add,
    articles,
    language,
    personal,
    value,
    onSelect,
    disabled,
    submitForm,
    selectArticle,
  } = props;
  const classes = useStyles();
  const [article, setArticle] = useState("");
  const sortedArticles = articles.sort((a, b) => a.plu - b.plu);
  return (
    <div
      className={classNames(
        classes.selectContainer,
        "group-detail__article-select-container"
      )}
    >
      <FormControl variant="filled" className={classes.selectControl}>
        <InputLabel shrink={true}>{language.Articles}</InputLabel>
        <Select
          displayEmpty
          className={classNames(classes.select, "group-detail__article-select")}
          value={personal ? value : article}
          onChange={personal ? onSelect : (e) => setArticle(e.target.value)}
          disabled={disabled}
        >
          <MenuItem value="">
            <em>{language.Choose}</em>
          </MenuItem>
          {sortedArticles.map((a) => {
            const notInStock = a.isProduct && a.inStock < 1;
            return (
              <MenuItem disabled={notInStock} key={a.id} value={a.id}>
                {getText(a, group, useGroupsAsItems, language, notInStock)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <NativeSelect
        className={classNames(
          classes.nativeSelect,
          "group-detail__article-select"
        )}
        value={personal ? value : article}
        onChange={personal ? onSelect : (e) => setArticle(e.target.value)}
        disabled={disabled}
      >
        <option value="">{language.Choose_Product}</option>
        {sortedArticles.map((a) => {
          const notInStock = a.isProduct && a.inStock < 1;
          return (
            <option disabled={notInStock} key={a.id} value={a.id}>
              {getText(a, group, useGroupsAsItems, language, notInStock)}
            </option>
          );
        })}
      </NativeSelect>
      <div className={classes.addButtonContainer}>
        <Button
          disabled={personal ? !selectArticle : !article}
          onClick={personal ? submitForm : () => add(group, article)}
          className={classNames(classes.addButton, "details__addButton")}
          color="primary"
        >
          {language.Article_Add || ""}
        </Button>
      </div>
    </div>
  );
};

export default ArticleSelect;
