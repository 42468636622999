import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ReactComponent as Placeholder } from "../../images/placeholder_list.svg";
import { getDisplayPrice } from "./util";
import slugify from "../common/slugify";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:nth-child(even)": {
      backgroundColor: theme.app.tableHighlight,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    "@media(max-width: 667px)": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
    "&:focus": {
      border: "3px solid black",
    },
    "&:active": {
      border: "none",
    },
  },
  imageContainer: {
    lineHeight: 1,
  },
  listItemText: {
    paddingLeft: "16px",
  },
  image: {
    width: "100px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "4px",
    backgroundColor: theme.app.background[2],
    "@media(max-width: 667px)": {
      width: "75px",
    },
  },
}));

const ListRow = (props) => {
  const { product, isEventCalendar, isEvent, culture } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [price, setPrice] = useState();

  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));

  let productName = product.name;
  if (isEvent) {
    const uri = config?.apiUriOverride?.startsWith("http")
      ? config.apiUriOverride
      : config.apiUri;
    productName =
      product.translation.find((t) => t.culture === culture)?.name ??
      language[product.name] ??
      product.name;
    product.imageFileName
      ? (product.images = [`${uri}/allotmentimage/${product.imageFileName}`])
      : (product.images = undefined);
  }

  if (isEventCalendar) {
    product.images = [
      `${process.env.PUBLIC_URL}/${config?.imageFolderUrl}${product.image}`,
    ];
  }

  const productGroupConfig = config.productGroupConfig.find(
    (x) => x.name === product.name
  );

  const displayPrice =
    productGroupConfig?.useGroupsAsItems && !isEvent
      ? product.isInfo
        ? " "
        : getDisplayPrice(product.products)
      : product.displayPrice;

  useEffect(() => {
    const giftCard = config?.productConfig?.filter((el) =>
      el.description?.includes("presentkort")
    );
    const filterGiftCard = giftCard?.filter((el) => el.plu === product.plu);

    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length > 0
    ) {
      setPrice(
        `${language.FromCost} ${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 0
    ) {
      setPrice(language.OpenPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 1
    ) {
      setPrice(
        `${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    } else if (filterGiftCard.length === 0) {
      setPrice(displayPrice);
    }
  }, [config, product, displayPrice]);

  const getCalendarBookingLinks = (item) => {
    const eventCalendarLinks = config.eventConfig.filter(
      (el) => el.eventCalendar && el?.name === item?.name
    );
    const articleBookingLinks = config.eventConfig.filter(
      (el) => el.articleBooking && el?.name === item?.name
    );
    if (eventCalendarLinks && eventCalendarLinks[0]) {
      return navigate(`tickets/${product.slug}`);
    }
    if (articleBookingLinks && articleBookingLinks[0]) {
      return navigate(`booking/${slugify(articleBookingLinks[0]?.name)}`);
    }
    if (!eventCalendarLinks[0] && !articleBookingLinks[0]) {
      return navigate(`/ticketshop/${product.slug}`);
    }
  };

  return (
    <ListItem
      button
      onClick={() => getCalendarBookingLinks(product)}
      className={clsx(classes.listItem, "listrow__container")}
      divider
    >
      <div className={clsx(classes.imageContainer, "listrow__image-container")}>
        {product.images && product.images?.length > 0 ? (
          <img
            className={clsx(
              classes.image,
              "listrow__image",
              `item-image-${product.id}`
            )}
            src={
              product.isGroup
                ? `${process.env.PUBLIC_URL}${product.images[0]}`
                : `${product.images[0]}`
            }
            alt="product"
          />
        ) : (
          <Placeholder className={classes.image} />
        )}
      </div>
      <ListItemText
        primary={productName}
        secondary={price}
        className={clsx(classes.listItemText, "listrow__text-container")}
        classes={{
          primary: clsx(classes.primary, "listrow__primary-text"),
          secondary: clsx(classes.secondary, "listrow__secondary-text"),
        }}
      />
    </ListItem>
  );
};

export default ListRow;
