import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CART_ARTICLE_ADD, Context, OPEN_ALERT } from "../../context";
import { addArticleBatch, addToCartTracking } from "../../common/sale";
import { navigate } from "@reach/router";
import AddToCartButton from "./AddToCartButton";
import { STATE_CURRENCY } from "../../common/state";

const AddToCart = ({
  articlesData,
  cart,
  setCart,
  config,
  setAddedOrder,
  language,
  settingsData,
}) => {
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const { currency } = useSelector((state) => ({
    currency: state.cookies[STATE_CURRENCY],
  }));

  useEffect(() => {
    const checkCartForTickets = () => {
      let newCart = cart;
      const filter = newCart.filter((el) => {
        return articlesData.some((ele) => {
          return ele.id === el.id;
        });
      });
      if (filter.length > 0) {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    };
    checkCartForTickets();
  }, [cart]);

  const productPrice = (item) => {
    if (item && item.length > 0) {
      const regex = /[$€£kr]/g;
      let newPrice = item.replace(regex, "");
      if (newPrice.trim().split(".")[1] === "00") {
        return newPrice.split(".")[0];
      } else {
        return newPrice;
      }
    }
  };

  const reduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          plu: curr.plu,
          quantity: 1,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const trackReduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          id: curr.plu,
          quantity: 1,
          price: curr.priceInclVat
            ? parseFloat(productPrice(curr.priceInclVat).replace(",", "."))
            : parseFloat(productPrice(curr.displayPrice).replace(",", ".")),
          name: curr.name,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const add = () => {
    setLoading(true);
    rdxDispatch(addArticleBatch(reduceCart, cb));
  };

  const linkToCart = config.self.split("/").slice(-1);

  const redirectToCart = () => {
    if (settingsData.goToCartOnSubmit === true) {
      window.scrollTo(0, 0);
      navigate(`/${linkToCart}/cart`);
    }
  };

  const cb = (status, msg) => {
    setLoading(false);

    if (status >= 400) {
      dispatch({
        type: OPEN_ALERT,
        payload: {
          text: language.ArticleBooking.ErrorMsg,
          severity: "error",
        },
      });
      setCart([]);
    } else {
      dispatch({
        type: CART_ARTICLE_ADD,
        payload: reduceCart,
      });
      setCart([]);
      setAddedOrder(true);
      addToCartTracking(currency, trackReduceCart);
      redirectToCart();
    }
  };

  return (
    <button
      className={`add_to_cart_product_ticket articleAddToCartButton ${
        disabledBtn === true ? "" : ""
      }`}
      style={{ pointerEvents: loading ? "none" : "", border: "none" }}
      disabled={disabledBtn}
      onClick={() => add()}
      aria-label={language.AddToCartBtn}
    >
      <AddToCartButton
        primary
        disabled={disabledBtn}
        loading={loading}
        text={
          disabledBtn
            ? language.ArticleBooking.EmptyCartButton
            : language.ArticleBooking.CartButton
        }
      />
    </button>
  );
};

export default AddToCart;
