import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Context, OPEN_CART } from "../../context";
import { ReactComponent as Placeholder } from "../../images/placeholder.svg";
import { STATE_CULTURE } from "../../common/state";

const useStyles = makeStyles((theme) => ({
  addedProduct: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.app.borderColor}`,
    borderTop: "none",
    padding: "16px",
    borderRadius: "0 0 4px 4px",
    width: "300px",
  },
  title: { textAlign: "center", color: theme.palette.text.primary },
  image: {
    width: "200px",
    margin: "0 auto",
    display: "block",
  },
  divider: { margin: "16px 0 8px" },
  cartTotal: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    color: theme.palette.text.secondary,
  },
  totalText: { fontSize: "0.8125rem" },
  amount: { textAlign: "right", fontSize: "0.8125rem" },
}));

const AddedProduct = (props) => {
  const { sale, prevQuantity, setPrevQuantity, cartOpen } = props;
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(Context);
  const article = state.cartArticle;
  const { itemQuantity, sumInclVat } = sale;

  useEffect(() => {
    if (itemQuantity !== prevQuantity) {
      setPrevQuantity(itemQuantity);
    }
  }, [itemQuantity, prevQuantity, setPrevQuantity]);
  useEffect(() => {
    if (prevQuantity < itemQuantity && !cartOpen && article) {
      dispatch({ type: OPEN_CART });
    }
  }, [prevQuantity, dispatch, itemQuantity, cartOpen, article]);

  if (article) {
    article.imageUri = article.imageFilename
      ? `${article.apiUri}/articleimage/${article.imageFilename}`
      : article.eventImage
      ? `${article.apiUri}/allotmentimage/${article.eventImage}`
      : undefined;
    article.translatedName =
      article.translation?.find((t) => t.culture === culture)?.name ||
      article.translation?.find((t) => t.culture === "sv-SE")?.name ||
      article.name;
  }

  if (!article || article?.[0] || config?.useCartDrawer) return <div />;
  return (
    <div className={`${classes.addedProduct} addedProductPopup`}>
      <Typography variant="h6" className={classes.title}>
        {language.Added_To_Cart}
      </Typography>
      {article.imageUri ? (
        <img
          src={article.imageUri}
          alt={article.name}
          className={classes.image}
        />
      ) : (
        <Placeholder className={classes.image} />
      )}
      <Typography className={classes.title}>
        {article.translatedName}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.cartTotal}>
        <Typography className={classes.totalText}>Subtotal:</Typography>
        <Typography className={classes.amount}>{sumInclVat}</Typography>
      </div>
    </div>
  );
};

export default AddedProduct;
