import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Markdown from "react-markdown";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { STATE_CULTURE } from "../../../common/state";
import { useElementSize, useLocalStorage } from "@mantine/hooks";

let source;

const useStyles = makeStyles({
  container: {
    marginTop: "16px",
  },
  bottomText: {
    textAlign: "center",
    fontSize: "0.8rem",
    marginBottom: "-1rem",
  },
});

const Footer = (props) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const { culture, config } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
    config: state.config,
  }));

  const { ref, height } = useElementSize();
  const [value, setValue] = useLocalStorage({
    key: "footerHeight",
    defaultValue: 0,
  });
  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    axios
      .get(
        `${process.env.PUBLIC_URL}${config.descriptionFolderUrl}footer.${culture}.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => setText(res.data));
    return () => source.cancel("Footer Is Unmounting");
  }, [culture, config.descriptionFolderUrl]);

  useEffect(() => {
    if (height > 0) {
      setValue(height);
    }
  }, [height]);

  return (
    <footer className={clsx(classes.container, "footer__container")} ref={ref}>
      <Markdown source={text} escapeHtml={false} skipHtml={false} />
      {config?.showFooterBottomText && (
        <div className={`${classes.bottomText} footer__bottomtext`}>
          <span>
            Skapad av <a href="https://entryevent.se">Entry Event Sweden AB</a>
          </span>
        </div>
      )}
    </footer>
  );
};

export default Footer;
