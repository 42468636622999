import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  sideMenu: {},
  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  avatar: {
    width: "40px",
    height: "40px",
    marginRight: "8px",
  },
  name: {
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  active: { color: theme.palette.secondary.main },
}));

const SideMenu = ({ ticketOwners, activePerson, setActivePerson }) => {
  const classes = useStyles();
  if (ticketOwners.length < 2) {
    return null;
  }
  return (
    <div className={classes.sideMenu}>
      {ticketOwners.map((o) => {
        const isActive = o.id === activePerson?.id;

        return (
          <ButtonBase
            onClick={() => setActivePerson(o)}
            key={o.id}
            className={classes.item}
          >
            <Avatar className={classes.avatar} alt={o.name} src={o.photo} />
            <Typography
              className={clsx(classes.name, isActive && classes.active)}
            >
              {o.name}
            </Typography>
          </ButtonBase>
        );
      })}
    </div>
  );
};

export default SideMenu;
