import React, { useEffect, useRef } from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import NavContentV2 from "./NavContentV2";
import Settings from "../../Settings";
import Login from "../login";
import FocusTrap from "./hooks/useFocusTrap";
import useClickDetector from "./hooks/useClickDetector";
import { useLocation } from "@reach/router";

const MobileMenuOverlay = ({
  open,
  setOpen,
  config,
  language,
  changeBackground,
  culture,
}) => {
  const ref = useRef(null);
  useClickDetector(ref, open, setOpen);
  useEffect(() => {});
  const location = useLocation();

  const variants = {
    show: {
      opacity: 1,
      x: "0%",
      y: "0",
      transition: {
        delay: 0.1,
        duration: 0.25,
        ease: "easeInOut",
      },
    },
    hidden: {
      opacity: 0,
      x: "-100%",
      y: "0",
      transition: {
        delay: 0.25,
        duration: 0.4,
        ease: "easeInOut",
      },
    },
  };

  const overlayVariant = {
    show: {
      opacity: 1,
      transition: {
        delay: 0,
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        delay: 0.3,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <AnimateSharedLayout>
      <AnimatePresence>
        {open && (
          <>
            <motion.div
              className="mobileMenuOverlay"
              variants={variants}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <FocusTrap>
                <button
                  className="mobileCloseButton"
                  onClick={() => setOpen(!open)}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: "1rem",
                    width: "5rem",
                    height: "5rem",
                    background: "none",
                    border: "none",
                    zIndex: "2900",
                  }}
                  aria-expanded={open}
                  aria-label={language.Close}
                />
                <NavContentV2
                  config={config}
                  language={language}
                  changeBackground={changeBackground}
                  culture={culture}
                  open={open}
                />
                <div className="mobileSettingsWrapper">
                  <Settings />
                </div>
                {config?.showLogin ? (
                  <div className="mobileLoginWrapper">
                    <Login changeBackground={changeBackground} />
                  </div>
                ) : (
                  ""
                )}
              </FocusTrap>
            </motion.div>
            <motion.div
              className="mobileMenuBackground"
              variants={overlayVariant}
              initial="hidden"
              animate="show"
              exit="hidden"
              ref={ref}
            ></motion.div>
          </>
        )}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
};

export default MobileMenuOverlay;
