import uuid from "uuid";
import slugify from "../../components/common/slugify";
import { getData } from "../../components/Page/util";
const makeId = () => uuid.v4();

const getArticlesFromTags = (tags) => {
  if (!tags || tags.length < 1) return [];
  let articles = [];
  let articleIds = [];
  tags.forEach((t) =>
    t.articles.forEach((a) => {
      if (!articleIds.includes(a.id)) {
        articleIds = [...articleIds, a.id];
        articles = [...articles, a];
      }
    })
  );
  return articles;
};

export const extendProduct = (p, a = {}, config) => {
  const { imageFolderUrl } = config;
  const productGroupConfig = config.productGroupConfig.find(
    (x) => x.name === p.name
  );
  if (productGroupConfig?.useGroupsAsItems && !p.allProductsShow) return null;
  if (productGroupConfig?.useGroupsAsItems && !a.name) {
    console.log(`no published articleGroup with name ${p.name}`);
    return null;
  }
  if (!!a.id && !!p.isModification) return null;
  // If its a product that only exists in config
  const plu = a.plu ? a.plu : p.plu;
  return {
    id: a.id ? a.id : makeId(),
    name: a.name ? a.name : p.name,
    plu,
    images: p.images
      ? p.images.map((img) => `${imageFolderUrl}${img}`)
      : undefined,
    products: a.articles ? a.articles : null,
    displayPrice: a.priceInclVat,
    slug: productGroupConfig?.useGroupsAsItems
      ? `products/${slugify(p.name)}`
      : `products/${plu}`,
    isInfo: p?.isInfo || false,
    allProductsSort: p.allProductsSort,
  };
};

export const getAllProducts = (data, config) => {
  if (!data) return [];
  let products = [];
  let noConfig = [];
  let unpublished = [];
  data.tags.forEach((tag) =>
    tag.articles.forEach((a) => {
      const p = config.productConfig.find((c) => c.plu === a.plu);
      if (!p) {
        noConfig = [...noConfig, { plu: a.plu, name: a.name }];
      }
    })
  );
  if (noConfig.length > 0) {
    console.log(`missing config for products:`, noConfig);
  }
  let publishedArticles = getArticlesFromTags(data.tags);
  if (!data || !config) return [];
  const { productConfig, productGroupConfig, useEventsAsProducts } = config;

  const { dataProductPlus, dataTags } = getData(data);

  productGroupConfig.forEach((g) => {
    if (g.useGroupsAsItems) {
      if (!dataTags.includes(g.name)) {
        unpublished = [...unpublished, { name: g.name, type: "productGroup" }];
        return;
      }
      const tag = data.tags.find((t) => t.name === g.name);
      const extendedProduct = extendProduct(g, tag, config);
      products = extendedProduct ? [...products, extendedProduct] : products;
    }
  });

  productConfig.forEach((p) => {
    if(!productGroupConfig.find(x => x.useGroupsAsItems && p.name.includes(x.name))) {
      let productConfig = config.productConfig.find((x) => x.plu === p.plu);
      let value =
        productConfig.isModification !== undefined
          ? productConfig.isModification
          : false;
      if (!dataProductPlus.includes(p.plu) && !value) {
        unpublished = [
          ...unpublished,
          { plu: p.plu, name: p.name, type: "product" },
        ];
        return;
      }
      const article = publishedArticles.find((a) => a.plu === p.plu);
      const extendedProduct = extendProduct(p, article, config);
      products = extendedProduct ? [...products, extendedProduct] : products;

    }

  });

  if (useEventsAsProducts) {
    // TODO
    // console.log(eventConfig);
  }
  return products.sort((a, b) => a.allProductsSort - b.allProductsSort);
};

export const extendPage = (data, config) => {
  return {
    name: "Alla Produkter",
    productGroups: data.tags
      .map((t) => {
        const groupConfig = config.productGroupConfig.find(
          (g) => g.name === t.name
        );
        const products = t.articles.map((a) => {
          const product = config.productConfig.find((p) => p.plu === a.plu);
          return product ? extendProduct(product, a, config) : a;
        });
        return { ...t, ...groupConfig, products };
      })
      .sort((a, b) => a.allProductsSort - b.allProductsSort),
  };
};
