import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Card from "./card";
import List from "./list";
import slugify from "../common/slugify";
import CardV2 from "./CardV2";
import ListV2 from "./ListV2";

const useStyles = makeStyles((theme) => ({
  landingContent: {
    display: (p) => (p.list === "CARD" ? "grid" : "block"),
    gridTemplateColumns: "repeat(3, 1fr)",
    "@media screen and (max-width: 768px)": {
      gridTemplateColumns: (p) => p.list === "CARD" && "1fr",
    },
    gridGap: (p) => (p.list === "CARD" ? "0.25rem" : "1rem"),
  },
}));

const getPagesWithOnlyOneEvent = (pages) => {
  let pagesWithOnlyOneEvent = [];

  pages.map((page) =>
    page.events && page.events.length === 1
      ? pagesWithOnlyOneEvent.push(page.name)
      : null
  );

  return pagesWithOnlyOneEvent;
};

const LandingPage = () => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));

  const { pages } = config;
  const pagesWithOnlyOneEvent = getPagesWithOnlyOneEvent(pages);

  const { landingPage } = config;
  const { listType, links } = landingPage;
  const list = window.innerWidth > 667 ? listType.lg : listType.sm || "CARD";
  const classes = useStyles({ list });
  return (
    <div className={clsx(classes.landingContent, "landing__content")}>
      {links.map((landingPage, index) => {
        if (landingPage.disabled) return null;
        const landingTexts = language.LandingTexts;
        const text = landingTexts
          ? landingTexts[landingPage.name]
          : { primary: null, secondary: null };
        const image = `${process.env.PUBLIC_URL}${config.imageFolderUrl}${
          landingPage.image ? landingPage.image : "placeholder.jpg"
        }`;
        const item = { ...landingPage, image, text };

        if (
          pagesWithOnlyOneEvent.includes(item.name) &&
          config.useAutoNavigationOnSingleEvent
        ) {
          const eventSlug = `${process.env.PUBLIC_URL}/events/${slugify(
            item.name
          )}`;
          return list === "CARD" ? (
            config?.useNewCard ? (
              <CardV2 slug={eventSlug} item={item} key={index} />
            ) : (
              <Card slug={eventSlug} item={item} key={index} />
            )
          ) : config?.useNewList ? (
            <ListV2 slug={eventSlug} item={item} key={index} />
          ) : (
            <List slug={eventSlug} item={item} key={index} />
          );
        } else {
          return list === "CARD" ? (
            config?.useNewCard ? (
              <CardV2 item={item} key={index} />
            ) : (
              <Card item={item} key={index} />
            )
          ) : config?.useNewList ? (
            <ListV2 item={item} key={index} />
          ) : (
            <List item={item} key={index} />
          );
        }
      })}
    </div>
  );
};

export default LandingPage;
