import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PrevIcon from "@material-ui/icons/ChevronLeft";
import NextIcon from "@material-ui/icons/ChevronRight";
import ImageGallery from "react-image-gallery";
import Markdown from "react-markdown";
import Calendar from "../common/Calendar";
import Articles from "./articles";
import { getOccasionTimes } from "./util";
import SocialShare from "../common/socialShare";
import { STATE_CULTURE } from "../../common/state";
import { ReactComponent as Placeholder } from "../../images/placeholder.svg";

let source;
const useStyles = makeStyles((theme) => ({
  header: {
    height: "54px",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    marginBottom: "16px",
  },
  titleText: {
    flexGrow: 1,
    "@media(max-width: 576px)": {
      fontSize: "1.125rem",
    },
  },
  backContainer: {
    marginBottom: "16px",
  },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "450px 1fr",
    gridGap: "16px",
    "@media(max-width: 768px)": {
      gridTemplateColumns: "350px 1fr",
    },
    "@media(max-width: 576px)": {
      gridTemplateColumns: "1fr",
    },
  },
  calendarContainer: {
    marginBottom: "24px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "1px",
  },
  calendar: {
    width: "100%",
    "@media(max-width: 667px)": {
      width: "100%",
    },
  },
  description: {
    color: theme.app.secondaryColor,
  },
  carouselButton: {
    backgroundColor: "rgba(0,0,0,0.23)",
    color: "white",
  },
  left: {
    position: "absolute",
    zIndex: 100,
    left: 0,
    top: "50%",
    transform: "translate(0, -50%)",
  },
  right: {
    position: "absolute",
    zIndex: 100,
    right: 0,
    top: "50%",
    transform: "translate(0, -50%)",
  },
  placeholder: {
    background: theme.app.background[2],
    border: `1px solid ${theme.app.borderColor}`,
    borderRadius: "4px",
  },
  image: {
    maxWidth: "100%",
  },
}));

const Event = (props) => {
  const { filter, event } = props;
  const location = useLocation();
  const classes = useStyles();
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const name =
    event.translation.find((t) => t.culture === culture)?.name ??
    language[event.name] ??
    event.name;
  const [text, setText] = useState("");
  const [from, setFrom] = useState();
  const [until, setUntil] = useState();
  const [singleOccasion, setSingleOccasion] = useState(false);

  useEffect(() => {
    if (event.occasions.length < 2) {
      setSingleOccasion(true);
      const singleOccasionTimes = getOccasionTimes(event.occasions[0].time);
      setFrom(singleOccasionTimes.from);
      setUntil(singleOccasionTimes.until);
    }
  }, [event.occasions]);

  let filterOverride = null;
  if (
    event.occasions.length > 1 &&
    (filter === undefined || (Array.isArray(filter) && filter.length === 0))
  ) {
    const occasions = event.occasions.map((a) => a.time);
    const orderedOccasions = [...occasions].sort();
    const firstOccasion = moment(orderedOccasions[0]).format("YYYYMM");
    console.log(
      "--> Set event date filter override to first coming month with occasion:",
      firstOccasion
    );
    filterOverride = firstOccasion;
  }

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    if (event) {
      setText(
        event.translation.find((t) => t.culture === culture)?.description ?? ""
      );
    }
    return () => source.cancel("Event Component Is Unmounting");
  }, [culture, event]);

  const handleDateChange = (date) => {
    const occasionTimes = getOccasionTimes(date);
    setFrom(occasionTimes.from);
    setUntil(occasionTimes.until);
  };
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const images = event.imageFileName && [
    {
      original: `${uri}/allotmentimage/${event.imageFileName}`,
    },
  ];

  const initialMonth = () =>
    filterOverride != null
      ? moment(`${filterOverride}01`)
      : filter
      ? moment(`${filter}01`)
      : moment();

  const highlightedDates = event.occasions.map((o) => {
    return moment(o.time).format("YYYY-MM-DD");
  });
  return (
    <div className={classNames(classes.event, "events__container")}>
      <div
        className={classNames(
          classes.header,
          "events__detail__header-container"
        )}
      >
        <Typography
          variant="h5"
          className={classNames(classes.titleText, "events__title")}
        >
          {name}
        </Typography>
        <Button onClick={() => window.history.back()}>
          {language.Back_To_List}
        </Button>
      </div>
      <Divider className={classes.divider} />
      <Typography
        component="div"
        className={classNames(
          classes.description,
          "events__detail__description"
        )}
      >
        <Markdown source={text} escapeHtml={false} skipHtml={false} />
      </Typography>
      {text.length > 0 && <Divider className={classes.divider} />}
      <div className={classes.contentContainer}>
        <div className={classes.information}>
          {event.imageFileName ? (
            <ImageGallery
              showThumbnails={false}
              showPlayButton={false}
              showFullscreenButton={false}
              items={images}
              renderLeftNav={(onClick, disabled) => (
                <IconButton
                  className={classNames(classes.carouselButton, classes.left)}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <PrevIcon />
                </IconButton>
              )}
              renderRightNav={(onClick, disabled) => (
                <IconButton
                  className={classNames(classes.carouselButton, classes.right)}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <NextIcon />
                </IconButton>
              )}
            />
          ) : (
            <Placeholder className={classes.placeholder} />
          )}
          {config.socialShareButtons &&
            config.socialShareButtons.length > 0 && (
              <SocialShare
                config={config}
                url={location.href}
                language={language}
              />
            )}
        </div>
        <div className={classes.calendar}>
          {!singleOccasion && (
            <div className={classes.calendarContainer}>
              {filter || filterOverride ? (
                <Calendar
                  initialVisibleMonth={initialMonth}
                  highlightedDates={highlightedDates}
                  occasionDates={highlightedDates}
                  onDateChange={handleDateChange}
                  startdateConfig={config.overrideStartdate}
                />
              ) : (
                <Calendar />
              )}
            </div>
          )}
          <Articles from={from} until={until} event={event} />
        </div>
      </div>
    </div>
  );
};

export default Event;
