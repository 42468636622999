import React, { useEffect, useRef, useState } from "react";
import NavV2 from "./NavV2";
import "./styles.css";

const HeaderV2 = ({ config, language, culture }) => {
  const [changeBackground, setChangeBackground] = useState(false);
  const [height, setHeight] = useState(null);
  const [homepage, setHomepage] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight + "px");
    }
  }, []);

  useEffect(() => {
    let url =
      window.location.pathname === "/ticketshop/" ||
      window.location.pathname === "/ticketshop";
    setHomepage(url);
  }, [window.location.pathname]);

  const scrollListener = (e) => {
    let scroll;
    scroll = window.scrollY;
    let siteHeadingHeight = localStorage.getItem("height");
    let innerHeight = window.innerHeight;
    if (siteHeadingHeight) {
      if (siteHeadingHeight && siteHeadingHeight * 0.5 <= scroll) {
        setChangeBackground(true);
      } else {
        setChangeBackground(false);
      }
    }
    if (!siteHeadingHeight) {
      if (innerHeight && innerHeight * 0.3 <= scroll) {
        setChangeBackground(true);
      } else {
        setChangeBackground(false);
      }
    }
  };

  useEffect(() => {
    if (
      config?.useFixedHeader &&
      config?.useTransparentHeader &&
      (homepage || !config?.showSiteHeadingOnlyOnLanding)
    ) {
      setChangeBackground(false);
      window.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    }
    if (!homepage && config?.showSiteHeadingOnlyOnLanding) {
      setChangeBackground(true);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    }
    if (config?.useTransparentHeader) {
      setChangeBackground(false);
    }
  }, [homepage]);

  const headerHeight = () => {
    if (config?.headerHeight) {
      return config?.headerHeight;
    } else {
      return "auto";
    }
  };
  return (
    <>
      {config?.useTransparentHeader && config?.useSiteHeading ? (
        <header
          ref={ref}
          className={`headerV2`}
          style={{
            height: headerHeight(),
            marginBottom:
              config?.showSiteHeadingOnlyOnLanding &&
              config?.useSiteHeading &&
              homepage
                ? "-" + height
                : config?.useSiteHeading &&
                  !config?.showSiteHeadingOnlyOnLanding
                ? "-" + height
                : "",
          }}
        >
          <NavV2
            config={config}
            language={language}
            culture={culture}
            changeBackground={changeBackground}
          />
        </header>
      ) : (
        <header
          ref={ref}
          className={`headerV2`}
          style={{
            height: headerHeight(),
          }}
        >
          <NavV2
            config={config}
            language={language}
            culture={culture}
            changeBackground={changeBackground}
          />
        </header>
      )}
    </>
  );
};

export default HeaderV2;
