import uuid from "uuid";
import slugify from "../../components/common/slugify";
import { getData } from "../../components/Page/util";

const makeId = () => uuid.v4();

const getArticlesFromTags = (tags) => {
  if (!tags || tags.length < 1) return [];
  let articles = [];
  let articleIds = [];
  tags.forEach((t) =>
    t.articles.forEach((a) => {
      if (!articleIds.includes(a.id)) {
        articleIds = [...articleIds, a.id];
        articles = [...articles, a];
      }
    })
  );
  return articles;
};

export const extendProduct = (p, a = {}, config, imageUri) => {
  const { imageFolderUrl } = config;
  const productGroupConfig = config.productGroupConfig.find(
    (x) => x.name === p.name
  );
  if (productGroupConfig?.useGroupsAsItems && !p.allProductsShow) return null;
  if (productGroupConfig?.useGroupsAsItems && !a.name) {
    console.log(`no published articleGroup with name ${p.name}`);
    return null;
  }
  if (!!a.id && !!p.isModification) return null;
  // If its a product that only exists in config
  const plu = a.plu ? a.plu : p.plu;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  return {
    id: a.id ? a.id : makeId(),
    name: a.name ? a.name : p.name,
    plu,
    imageUri,
    images: imageUri
      ? [`${uri}/articleimage/${imageUri}`]
      : p.images
      ? p.images.map(
          (img) => `${process.env.PUBLIC_URL}${imageFolderUrl}${img}`
        )
      : undefined,
    products: a.articles ? a.articles : null,
    displayPrice: a.priceInclVat,
    slug: productGroupConfig?.useGroupsAsItems
      ? `products/${slugify(p.name)}`
      : `products/${plu}`,
    isInfo: p?.isInfo || false,
    allProductsSort: p.allProductsSort,
  };
};

export const extendArticleFromApi = (a, config, imageUri, culture) => {
  if (!a) return null;
  const plu = a.plu;
  const name =
    a.translation.find((t) => t.culture === culture)?.name ||
    a.translation.find((t) => t.culture === "sv-SE")?.name ||
    `Product Name (${plu})`;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  return {
    id: a.id ? a.id : makeId(),
    name,
    plu,
    imageUri,
    images: imageUri ? [`${uri}/articleimage/${imageUri}`] : undefined,
    displayPrice: a.priceInclVat,
    slug: `products/${plu}`,
    inStock: a.inStock,
  };
};

export const getAllProducts = (data, config, culture) => {
  if (!data) return [];
  let products = [];
  let unpublished = [];
  let publishedArticles = getArticlesFromTags(data.tags);
  if (!data || !config) return [];
  const { productConfig, productGroupConfig } = config;
  const { dataProductPlus, dataTags } = getData(data);
  productGroupConfig.forEach((g) => {
    if (g.useGroupsAsItems) {
      if (!dataTags.includes(g.name)) {
        unpublished = [...unpublished, { name: g.name, type: "productGroup" }];
        return;
      }
      const tag = data.tags.find((t) => t.name === g.name);
      const extendedProduct = extendProduct(g, tag, config);
      products = extendedProduct ? [...products, extendedProduct] : products;
    }
  });

  productConfig.forEach((p) => {
    if (
      !productGroupConfig.find(
        (x) => x.useGroupsAsItems && p.name.includes(x.name)
      )
    ) {
      let productConfig = config.productConfig.find((x) => x.plu === p.plu);
      let value =
        productConfig.isModification !== undefined
          ? productConfig.isModification
          : false;
      if (!dataProductPlus.includes(p.plu) && !value) {
        unpublished = [
          ...unpublished,
          { plu: p.plu, name: p.name, type: "product" },
        ];
        return;
      }
      const article = publishedArticles.find((a) => a.plu === p.plu);
      const imageUri =
        article &&
        data.articles.find((a) => a.plu === article.plu)?.imageFilename;
      const extendedProduct = extendProduct(p, article, config, imageUri);
      products = extendedProduct ? [...products, extendedProduct] : products;
    }
  });

  data.articles.forEach((article) => {
    if (products.some((p) => p.id === article.id)) return;
    const imageUri =
      article && data.articles.find((a) => a.plu === article.plu).imageFilename;
    const extendedProduct = extendArticleFromApi(
      article,
      config,
      imageUri,
      culture
    );
    products = extendedProduct ? [...products, extendedProduct] : products;
  });

  return products.sort((a, b) => a.allProductsSort - b.allProductsSort);
};

export const extendPage = (data, config) => {
  return {
    name: "Alla Produkter",
    productGroups: data.tags
      // Loops over all products groups (tags) from GraphQL
      .map((t) => {
        const groupConfig = config.productGroupConfig.find(
          (g) => g.name === t.name
        );
        const products = t.articles
          // Loops over each product under each product group
          .map((a) => {
            const product = config.productConfig.find((p) => p.plu === a.plu);
            const imageUri =
              product &&
              data.articles.find((article) => article.plu === product.plu)
                ?.imageFilename;
            // If article exists in config, return an extended object
            return product ? extendProduct(product, a, config, imageUri) : a;
          });
        return { ...t, ...groupConfig, products };
      })
      .sort((a, b) => a.allProductsSort - b.allProductsSort),
  };
};
