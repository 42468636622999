import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Placeholder } from "../../images/placeholder.svg";
import { getDisplayPrice } from "./util";
import slugify from "../common/slugify";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    width: "100%",
    "@media(max-width: 1024px)": {
      margin: "8px",
    },
    "&:focus": {
      border: "3px solid black",
    },
    "&:active": {
      border: "none",
    },
  },
  img: {
    width: "100%",
    maxWidth: "100%",
    height: "auto",
    background: theme.app.background[2],
  },
  image: {
    height: 0,
    paddingTop: "100%", // aspect ratio 1:1
  },
  content: {
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  productName: {
    fontSize: "1.2rem",
    lineHeight: 1.3,
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
  },
  productPrice: {
    display: "flex",
    alignItems: "flex-end",
  },
  buttonDisabled: {
    color: "rgba(255,255,255,0.5)!important",
  },
  button: {},
}));

const CardItem = ({
  product,
  isEvent,
  isEventCalendar,
  setPosition,
  culture,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [price, setPrice] = useState();

  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  let productName = product.name;
  if (isEvent) {
    const uri = config?.apiUriOverride?.startsWith("http")
      ? config.apiUriOverride
      : config.apiUri;
    productName =
      product.translation.find((t) => t.culture === culture)?.name ??
      language[product.name] ??
      product.name;
    product.imageFileName
      ? (product.images = [`${uri}/allotmentimage/${product.imageFileName}`])
      : (product.images = undefined);
  }
  if (isEventCalendar) {
    product.images = [
      `${process.env.PUBLIC_URL}/${config?.imageFolderUrl}${product.image}`,
    ];
  }

  const productGroupConfig = config.productGroupConfig.find(
    (x) => x.name === product.name
  );
  const displayPrice =
    productGroupConfig?.useGroupsAsItems && !isEvent
      ? product.isInfo
        ? " "
        : getDisplayPrice(product.products)
      : product.displayPrice;

  useEffect(() => {
    const giftCard = config?.productConfig?.filter((el) =>
      el.description?.includes("presentkort")
    );
    const filterGiftCard = giftCard?.filter((el) => el.plu === product.plu);

    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length > 0
    ) {
      setPrice(
        `${language.FromCost} ${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 0
    ) {
      setPrice(language.OpenPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 1
    ) {
      setPrice(
        `${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    } else if (filterGiftCard.length === 0) {
      setPrice(displayPrice);
    }
  }, [config, product, displayPrice]);

  return (
    <Card
      className={clsx(
        classes.card,
        `card-item list-item__${product.id} item__${slugify(product.name)}`
      )}
    >
      <CardActionArea
        onClick={() => {
          setPosition();
          if (isEventCalendar) {
            navigate(`tickets/${product.slug}`);
          } else {
            navigate(`${product.slug}`);
          }
        }}
        classes={{ root: classes.button }}
      >
        {product.images && product.images?.length > 0 ? (
          <CardMedia
            className={clsx(classes.image, "card-item__image")}
            image={
              product.isGroup
                ? `${process.env.PUBLIC_URL}${product.images[0]}`
                : `${product.images[0]}`
            }
            title={product.name}
          />
        ) : (
          <Placeholder className={classes.img} />
        )}
        <CardContent className={classes.content}>
          <Typography
            gutterBottom
            variant="h6"
            className={clsx(
              classes.productName,
              "list-item__product-name primary-text"
            )}
          >
            {productName}
          </Typography>
          <Typography
            component="p"
            className={clsx(classes.productPrice, "secondary-text")}
          >
            {price}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardItem;
