import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Left from "../Left";
import Right from "../Right";

const useStyles = makeStyles((theme) => ({
  page: {
    // minHeight: `${theme.height - 168}px`,
    display: (p) => (p.useToc || p.useLeft || p.useRight ? "grid" : "block"),
    gridTemplateColumns: "1fr 1000px 1fr",
    maxWidth: (p) => (p.useToc ? "100%" : "1100px"),
    width: "100%",
    margin: "16px auto 0",

    "@media(max-width: 667px)": {
      marginTop: 0,
      padding: 0,
    },
  },
}));

const PageLayout = ({ children, useToc }) => {
  const config = useSelector((state) => state.config);
  const classes = useStyles({
    useToc,
    useLeft: config.useLeft,
    useRight: config.useRight,
  });

  if (config.useLeft || (config.useRight)) {
    return (
      <div
        className={clsx(classes.page, "page__container")}
      >
        {config.useLeft ? <Left /> : <div />}
        {children}
        {config.useRight ? <Right /> : <div />}
      </div>
    );
  }
  return (
    <div className={clsx(classes.page, "page__container")}>{children}</div>
  );
};

export default PageLayout;
