import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles({
  formControl: {
    margin: 0,
    minWidth: "64px",
  },
  select: {
    textAlign: "center",
  },
});

const QuantitySelect = (props) => {
  const {
    max = 10,
    handleOnChange,
    value = 1,
    disabled,
    language,
    hideLabel,
  } = props;
  const classes = useStyles();
  let quantityArr = [];
  for (let i = 1; i < max + 1; i++) {
    quantityArr = [...quantityArr, i];
  }
  return (
    <FormControl className={classes.formControl}>
      {!hideLabel && <InputLabel shrink>{language.Quantity}</InputLabel>}
      <Select
        className={classes.select}
        disabled={disabled}
        onChange={(e) => handleOnChange(e.target.value)}
        value={value}
      >
        {quantityArr.map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

QuantitySelect.propTypes = {
  max: PropTypes.number,
  handleOnChange: PropTypes.func.isRequired,
};

export default QuantitySelect;
