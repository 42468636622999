import React from "react";
import { useSelector } from "react-redux";

const LogoV2 = ({ changeBackground }) => {
  const { config } = useSelector((state) => ({
    config: state.config,
  }));

  const logoUrl = `${process.env.PUBLIC_URL}/ee-static/shop/${
    config.shopName
  }/Images/${config.logoImg ? config.logoImg : "logo.svg"}`;

  const logoWhite = `${process.env.PUBLIC_URL}/ee-static/shop/${
    config.shopName
  }/Images/${
    config.logoImgWhite
      ? config.logoImgWhite
      : config?.logoImg
      ? config?.logoImg
      : "logo.svg"
  }`;
  return (
    <a
      className="header__logo_link"
      style={{ height: "auto", width: "auto" }}
      href={config?.logoLink ? config?.logoLink : process.env.PUBLIC_URL + "/"}
    >
        <img
          className="header__logo-img logoV2"
          src={changeBackground ? logoUrl : logoWhite}
          alt="logo"
          style={{
            width: "100%",
            maxHeight: config?.headerHeight ? config?.headerHeight : "76px",
          }}
        />
    </a>
  );
};

export default LogoV2;
