import React from "react";
import { createStyles, Title } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    marginTop: "1rem",
    borderRadius: "6px",
  },
  section: {
    marginTop: "1.25rem",
  },
}));
const BookingSection = ({ label, subLabel, children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Title order={2} style={{ fontSize: "1.15rem" }}>
          {label} <span style={{ fontWeight: 400 }}>{subLabel}</span>
        </Title>
      </div>
      <section className={classes.section}>{children}</section>
    </div>
  );
};

export default BookingSection;
