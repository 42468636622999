import { ActionIcon, createStyles, Group, NumberInput } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseQuantity, increaseQuantity } from "../../common/sale";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";

const useStyles = createStyles({
  actionButton: {
    borderRadius: "50%",
    border: "none",
  },
  actionWrapper: {
    backgroundColor: "#F3F3F4",
    padding: "0rem 0.38rem",
    borderRadius: "1.25rem",
    width: "fit-content",
  },
});
const QuantityInput = ({ inStock, item, id, quantity, name }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const { classes } = useStyles();
  const rdxDispatch = useDispatch();

  const increase = () => {
    rdxDispatch(increaseQuantity(id));
  };

  const decrease = () => {
    rdxDispatch(decreaseQuantity(id));
  };
  return (
    <div>
      <Group
        spacing={5}
        className={classes.actionWrapper}
        role="region"
        aria-live="polite"
      >
        <ActionIcon
          className={`${classes.actionButton} addToCart`}
          size={30}
          variant="filled"
          disabled={!item.canChangeQuantity || item.isGiftCertificate}
          onClick={() => decrease()}
          aria-label={`${name} ${language.DecreaseQuantity}`}
        >
          <div style={{ fontSize: "1.5rem" }}>
            <HiMinusSm />
          </div>
        </ActionIcon>
        <NumberInput
          aria-label={`${language.Quantity} ${quantity}`}
          hideControls
          value={quantity}
          max={0}
          min={1}
          step={1}
          tabIndex={-1}
          styles={{
            input: {
              backgroundColor: "transparent",
              border: "none",
              width: 30,
              textAlign: "center",
              height: 30,
              fontWeight: "600",
              fontSize: "1.25rem",
              padding: 0,
              pointerEvents: "none",
            },
          }}
        />
        <ActionIcon
          className={`${classes.actionButton} addToCart`}
          aria-label={`${name} ${language.AddQuantity}`}
          size={30}
          variant="filled"
          disabled={
            !item.canChangeQuantity ||
            inStock === quantity ||
            item.isGiftCertificate
          }
          onClick={() => increase()}
        >
          <div style={{ fontSize: "1.5rem" }}>
            <HiPlusSm />
          </div>
        </ActionIcon>
      </Group>
    </div>
  );
};

export default QuantityInput;
