import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import { makeStyles } from "@material-ui/styles";
import "./styles.css";

const AddToCartButton = (props) => {
  const [hovered, setHovered] = useState(false);
  const [hover, setHover] = useState(false);
  const [spinner, setSpinner] = useState(require("./animations/cartwhite"));
  const animationContainer = useRef(null);
  const animationContainer2 = useRef(null);
  const anim = useRef(null);
  const anim2 = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: spinner,
      });
      anim.current.setSpeed(1.25);

      return () => anim.current.destroy();
    }
  }, []);

  useEffect(() => {
    if (animationContainer2.current) {
      anim2.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: spinner,
      });
      anim2.current.setSpeed(1.25);

      return () => anim2.current.destroy();
    }
  }, []);

  const useStyles = makeStyles({
    btn: {
      position: "relative",
      width: "45px",
      height: "45px",
    },

    wrapper: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0 auto",
      padding: "3px 23px",
      minWidth: "10px",
      maxWidth: "280px",
      backgroundColor: "white",
      border: ({ outline }) =>
        outline ? "solid 2px black" : "solid 2px white",
      background: ({ outline }) => (outline ? "white" : "black"),
      color: ({ outline }) => (outline ? "black" : "white"),
      letterSpacing: "0.7px",
      fontSize: "17px",
      cursor: "pointer",
      transition: "all 0.3s ease ",
      borderRadius: ({ round }) => (round ? "50px" : "10px"),
    },
    btnText: {
      fontWeight: "600",
      fontSize: "1rem",
      margin: "0",
      padding: "0",
    },
  });

  const classes = useStyles(props);

  return (
    <div
      className={`${classes.wrapper} addToCartButtonWrapper`}
      onMouseEnter={() => {
        setHovered(!hovered);
        setHover(true);
        anim.current.setDirection(hovered ? -1 : 1);
        anim.current.play();
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        maxWidth: props.chosenAllotment?.length === 0 ? "300px" : "280px",
      }}
    >
      <div className={classes.btn}>
        <div
          className={`${hover ? "" : "iconDefault"} cartIcon`}
          ref={animationContainer}
        />
      </div>

      <p
        className={`${hover ? "btnTextInvertCart" : "btnTextDefaultCart"} ${
          classes.btnText
        }`}
      >
        {props.text}
      </p>
    </div>
  );
};
export default AddToCartButton;
