import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  version: {},
  versionText: {
    fontSize: "0.8125rem",
    color: theme.app.secondaryColor,
  },
}));

const Version = () => {
  const classes = useStyles();
  const {
    config: { version },
  } = useSelector((state) => ({
    config: state.config,
  }));
  return (
    <div className={classes.version}>
      <Typography gutterBottom variant="subtitle1">
        Version
      </Typography>
      <Typography className={classes.versionText}>
        Entry Event Sweden AB - {version}
      </Typography>
    </div>
  );
};

export default Version;
