import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Cultures from "./cultures";
import Currencies from "./currencies";
import Version from "./version";
import Flag from "./flag";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";

const useStyles = makeStyles((theme) => ({
  settingsButton: {
  },
  divider: { margin: "16px 0px" },
}));

const Settings = (props) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { language, culture } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        aria-label="Byt språk"
        onClick={() => setSettingsOpen(!settingsOpen)}
        tabIndex={0}
        className={clsx(classes.settingsButton, "header__settings-button")}
        color="inherit"
      >
        <Flag culture={culture} />
      </ButtonBase>
      <Dialog
        fullWidth
        fullScreen={window.innerWidth < 577}
        open={settingsOpen}
        onClose={() => setSettingsOpen(!settingsOpen)}
        style={{ zIndex: 2000 }}
      >
        <DialogTitle>{language.Settings}</DialogTitle>
        <DialogContent>
          <div>
            <Cultures />
            <Divider className={classes.divider} />
            <Currencies />
            <Divider className={classes.divider} />
            <Version />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSettingsOpen(!settingsOpen)}>
            {language?.Cancel || "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Settings;
