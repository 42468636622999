import { useLocation } from "@reach/router";
import { AnimateSharedLayout } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import slugify from "../../common/slugify";
import MenuItemV2 from "./MenuItemV2";
import { useSessionStorage } from "@mantine/hooks";

const NavContentV2 = ({
  config,
  language,
  changeBackground,
  culture,
  open,
}) => {
  const [links, setLinks] = useState();
  const [updatedLinks, setUpdatedLinks] = useState();
  const [addedLinks, setAddedLinks] = useState([]);
  const [selected, setSelected] = useSessionStorage({
    key: "activeLink",
    defaultValue: "",
  });
  const location = useLocation();

  useMemo(() => {
    let getLinks = [];
    getLinks = config?.pages;
    let filter = getLinks?.filter((el) => !el.disabled);
    setLinks(filter);
  }, []);

  useMemo(() => {
    if (config?.newHeaderDropDown) {
      let newLinks = [];
      let originalLinks = links;
      let dropDownItems = config?.newHeaderDropDown;
      newLinks.push(originalLinks, dropDownItems);
      let flatLinks = newLinks.flat();
      const filter = flatLinks.filter((el) => !el?.disabled);
      setUpdatedLinks(filter);
    } else {
      setUpdatedLinks(links);
    }
  }, [links]);

  useEffect(() => {
    let pathName = location.pathname;
    const home =
      pathName === "/ticketshop" ||
      pathName === "ticketshop" ||
      pathName === "/ticketshop/";
    let path = pathName.split("/").pop();

    const getSubLinks =
      config?.newHeaderDropDown &&
      config?.newHeaderDropDown.reduce((acc, curr) => {
        updatedLinks.some((el) => {
          return curr.subLinks.some((ele) => {
            return el.name.toLowerCase() === ele.name.toLowerCase() &&
              slugify(ele.name) === slugify(path)
              ? acc.push(curr)
              : "";
          });
        });
        return acc;
      }, []);

    const getPath = updatedLinks.filter((el) => slugify(el.name) === path)?.[0]
      ?.name;

    if (getSubLinks && getSubLinks.length > 0) {
      setSelected(getSubLinks?.[0]?.name);
    }

    if (
      (getPath && !getSubLinks) ||
      (getSubLinks && getSubLinks.length === 0 && getPath)
    ) {
      setSelected(getPath);
    }
    if (home) {
      setSelected("Hem");
    }
  }, [location.pathname]);

  useMemo(() => {
    if (updatedLinks) {
      let arr = updatedLinks;
      let home = {
        name: "Hem",
        disabled: false,
      };
      let allProducts = {
        name: "Alla produkter",
        disabled: false,
      };

      let allEvents = {
        name: "Alla evenemang",
        disabled: false,
      };
      let getAllProducts = config?.showAllProducts;
      let getAllEvents = config?.useAllEvents;

      if (
        getAllProducts ||
        getAllEvents ||
        !home.disabled ||
        config?.newHeaderDropDown
      ) {
        if (getAllProducts && getAllEvents) {
          arr.push(allProducts, allEvents);
          arr.unshift(home);
        }
        if (getAllProducts && !getAllEvents) {
          arr.push(allProducts);
          arr.unshift(home);
        }
        if (!getAllProducts && getAllEvents) {
          arr.push(allEvents);
          arr.unshift(home);
        }
        if (!getAllProducts && !getAllEvents) {
          arr.unshift(home);
        }

        const filterDropDownLinks = arr.filter((el) => !el?.useInDropDown);
        let sortByConfiguration = filterDropDownLinks.sort((a, b) =>
          a.sortInMenu ? -1 : b.sortInMenu ? 1 : 0
        );
        let sortHome = sortByConfiguration.sort((a, b) =>
          a.name === "Hem" ? -1 : b.name === "Hem" ? 1 : 0
        );
        setAddedLinks(filterDropDownLinks);
      }
    }
  }, [updatedLinks]);

  return (
    <ul className={`navList`}>
      <AnimateSharedLayout>
        {addedLinks &&
          addedLinks.map((el, i) => (
            <React.Fragment key={el?.name + i}>
              <MenuItemV2
                key={"linkitem" + i}
                data={el}
                config={config}
                language={language}
                culture={culture}
                changeBackground={changeBackground}
                selected={selected.toLowerCase() === el?.name.toLowerCase()}
                onClick={() => setSelected(el?.name.toLowerCase())}
                setSelected={setSelected}
                selectedIndex={el?.name}
                open={open}
              />
            </React.Fragment>
          ))}
      </AnimateSharedLayout>
    </ul>
  );
};
export default NavContentV2;
