import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  createStyles,
  Group,
  Modal,
  Text,
  Title,
} from "@mantine/core";
import { HiOutlineClock } from "react-icons/hi";
import { MdEmojiPeople } from "react-icons/md";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    height: "100%",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },

  rating: {
    position: "absolute",
    top: theme.spacing.xs,
    right: theme.spacing.xs + 2,
    pointerEvents: "none",
  },

  title: {
    fontSize: "1.15rem",
    display: "block",
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xs / 2,
  },
  subTitle: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  footer: {
    marginTop: "1rem",
    marginBottom: "1.5rem",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Type = ({
  id,
  name,
  description,
  articlesToApply,
  occasionLengthInMinutes,
  maxParticipants,
  minParticipants,
  form,
  tag,
  ...props
}) => {
  const [opened, openedSet] = useState(false);
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const { classes, cx } = useStyles();

  return (
    <>
      <Card withBorder radius="md" className={cx(classes.card)}>
        {/*<Card.Section>*/}
        {/*      <Image src={image} height={180}/>*/}
        {/*</Card.Section>*/}

        {tag && <Badge className={classes.rating}>{tag}</Badge>}
        <div className={classes.wrapper}>
          <div className={classes.textContainer}>
            <Title order={3} className={classes.title} weight={500}>
              {name}
            </Title>

            <Text
              component="span"
              className={classes.subTitle}
              size="sm"
              lineClamp={5}
            >
              {description}
            </Text>
            {description.length > 200 && (
              <Button variant="subtle" onClick={() => openedSet(true)}>
                Läs mer
              </Button>
            )}
          </div>
          <div>
            <Group position="apart" className={classes.footer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdEmojiPeople
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  />
                  <Text m={0} component="span" className={classes.subTitle}>
                    {minParticipants} - {maxParticipants} {language.Persons}
                  </Text>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HiOutlineClock
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  />
                  <Text m={0} className={classes.subTitle}>
                    {occasionLengthInMinutes} {language.Minutes}
                  </Text>
                </div>
              </div>
              {/*<Group spacing={8} mr={0}>*/}
              {/*  <ActionIcon className={classes.action}>*/}
              {/*    <FaFacebook />*/}
              {/*  </ActionIcon>*/}
              {/*</Group>*/}
            </Group>
            <Button
              fullWidth
              mt={16}
              variant={`${props.value === id ? "filled" : "outline"}`}
              className={`${
                props.value === id ? "primaryButton" : "primaryButtonOutline"
              }`}
              style={{ width: "100%" }}
              onClick={() => form.setFieldValue("publicBookingTypeId", id)}
              aria-label={`${
                props.value !== id ? language.ChooseType : language.Chosen
              } ${name}`}
            >
              {props.value !== id ? language.ChooseType : language.Chosen}
            </Button>
          </div>
        </div>
      </Card>
      <Modal
        className="publicBookingModal"
        size="lg"
        centered
        opened={opened}
        onClose={() => openedSet(false)}
        title={name}
        closeButtonLabel={language.Close}
      >
        {description}
        <Group mt="xl">
          <Button
            fullWidth
            size="md"
            className="primaryButton"
            onClick={() => openedSet(false)}
          >
            {language.Close}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Type;
