import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { STATE_CULTURE } from "../../common/state";
import Description from "../common/Description";
import { DataContext } from "../common/dataProvider";

let source;

const useStyles = makeStyles((theme) => ({
  productDescription: {},
}));

const ProductDescription = ({ id, description }) => {
  const [text, setText] = useState("");
  const culture = useSelector((state) => state.cookies[STATE_CULTURE]);
  const classes = useStyles();
  const { data } = useContext(DataContext);
  const article = data.articles.find((article) => article.id === id);

  const articleDescription =
    article?.translation.find((translation) => translation.culture === culture)
      ?.description ?? "";
  const useGroupsAsProduct = !articleDescription;

  useEffect(() => {
    source = axios.CancelToken.source();
    if (article) {
      setText(articleDescription);
    } else if (useGroupsAsProduct) {
      axios
        .get(`${process.env.PUBLIC_URL}/${description}.${culture}.md`, {
          cancelToken: source.token,
        })
        .then((res) => setText(res.data));
    }
    return () => source.cancel("Product Is Unmounting");
  }, [culture, description, article, articleDescription, useGroupsAsProduct]);

  return (
    <div className={classes.productDescription}>
      <Description text={text} />
    </div>
  );
};

export default ProductDescription;
