import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import axios from "axios";

let source;

const Security = () => {
  const [text, setText] = useState("");
  const { klarnaTerms, shopName } = useSelector((state) => state.config);
  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();

    axios
      .get(
        `${process.env.PUBLIC_URL}/ee-static/shop/${shopName}/Texts/cookies.md`
      )
      .then((res) => setText(res.data));

    return () => source.cancel("Component Is Unmounting");
  }, [shopName]);

  return (
    <div className="cookies__container">
      <Markdown
        source={text}
        escapeHtml={false}
        skipHtml={false}
        className="cookies-text"
      />
      {klarnaTerms && (
        <span>
          <h3>Klarna</h3>
          <p>
            Our checkout solution is provided by Klarna (Klarna AB, Sveavägen
            46, 111 34 Stockholm, Sweden) and uses cookies to offer you the best
            possible, tailored, online experience when you use Klarna's
            checkout. The exact cookies and purposes for which they are used can
            be found here:
            <br />
            <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/sv_se/cookie_purchase">
              Klarnas användande av spårningsteknologier i webbsidans kassa
            </a>{" "}
            <br />
            <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/sv_se/privacy">
              Klarnas dataskyddsinformation
            </a>
          </p>
        </span>
      )}
    </div>
  );
};

export default Security;
