import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "@reach/router";
import { makeStyles } from "@material-ui/styles";
import { Twitter, Facebook, Mail } from "react-social-sharing";

export const socalShareMeta = (config) => {
  // Add social share title
  if (config.socialShareTitle) {
    let title = document.createElement("meta");
    title.setAttribute("property", "og:title");
    title.content = config.socialShareTitle;
    document.getElementsByTagName("head")[0].appendChild(title);
  }
  // Add social share description
  if (config.socialShareDescription) {
    let description = document.createElement("meta");
    description.setAttribute("property", "og:description");
    description.content = config.socialShareDescription;
    document.getElementsByTagName("head")[0].appendChild(description);
  }
  // Add social share image
  if (config.socialShareImage) {
    let image = document.createElement("meta");
    image.setAttribute("property", "og:image");
    image.content = config.socialShareImage;
    document.getElementsByTagName("head")[0].appendChild(image);
  }
};

const useStyles = makeStyles({
  container: {
    padding: "8px 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& a": {
      margin: 0,
      marginRight: "8px",
    },
  },
});

const SocialShare = () => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const location = useLocation();
  const url = location.href;
  const classes = useStyles();
  if (!config.socialShareButtons || config.socialShareButtons.length < 1)
    return null;
  const buttons = config.socialShareButtons;
  return (
    <div className={classes.container}>
      {buttons.includes("Twitter") && (
        <Twitter
          solid
          small
          link={url}
          label={(serviceName) => `${language.Share} ${serviceName}`}
        />
      )}
      {buttons.includes("Facebook") && (
        <Facebook
          solid
          small
          link={url}
          label={(serviceName) => `${language.Share} ${serviceName}`}
        />
      )}
      {buttons.includes("Mail") && (
        <Mail
          solid
          small
          subject="Titta på det här"
          link={url}
          label={(serviceName) => `${language.Share} ${serviceName}`}
        />
      )}
    </div>
  );
};

export default SocialShare;
