import red from "@material-ui/core/colors/red";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";

const dangerColor = red[500];
const dangerHoverColor = "rgba(244, 67, 54, 0.2)";
const warningColor = amber[500];
const successColor = green[500];
const infoColor = blue[500];

// Dark theme
const darkTheme = {
  themeType: "dark",
  primaryColors: {
    light: "rgb(187, 222, 251)",
    main: "rgb(144, 202, 249)",
    dark: "rgb(100, 181, 246)",
  },
  secondaryColors: {
    light: "rgb(248, 187, 208)",
    main: "rgb(244, 143, 177)",
    dark: "rgb(240, 98, 146)",
  },
  badge: {
    background: "rgba(255,255,255,0.54)",
    color: "rgba(0,0,0,0.87)",
  },
  background: [
    "rgb(33, 33, 33)",
    "rgb(48, 48, 48)",
    "rgb(66, 66, 66)",
    "rgb(77, 77, 77)",
    "rgb(92, 92, 92)",
    "rgb(108, 108, 108)",
    "rgb(128, 128, 128)",
    "rgb(144, 144, 144)",
    "rgb(172, 172, 172)",
    "rgb(192, 192, 192)",
    "rgb(212, 212, 212)",
    "rgb(224, 224, 224)",
    "rgb(232, 232, 232)",
    "rgb(242, 242, 242)",
    "rgb(255, 255, 255)",
  ],
  borderColor: "rgba(255,255,255,0.12)",
  labelColor: "rgba(255,255,255,0.7)",
  calendarSelectColor: "magenta",
  color: "rgb(255,255,255)",
  hoverBackground: [
    "rgb(66, 66, 66)",
    "rgb(77, 77, 77)",
    "rgb(92, 92, 92)",
    "rgb(108, 108, 108)",
    "rgb(128, 128, 128)",
    "rgb(144, 144, 144)",
    "rgb(172, 172, 172)",
    "rgb(192, 192, 192)",
    "rgb(212, 212, 212)",
    "rgb(224, 224, 224)",
    "rgb(232, 232, 232)",
    "rgb(242, 242, 242)",
    "rgb(255, 255, 255)",
  ],
  iconColor: "rgba(255,255,255,0.5)",
  iconDisabledColor: "rgba(255,255,255,0.3)",
  iconHoverColor: "rgba(255,255,255,0.05)",
  linkColor: "rgba(255,255,255,0.54)",
  secondaryColor: "rgba(255,255,255,0.54)",
  dangerColor,
  dangerHoverColor,
  warningColor,
  successColor,
  infoColor,
  progressTrailColor: "rgb(33,33,33)",
  tableHighlight: "rgba(255,255,255,0.02)",
  tooltipBackground: "rgb(33,33,33)",
  tooltipColor: "rgba(255,255,255, 0.7)",
  tooltipSecondary: "rgba(255,255,255,0.54)",
  scrollbar: "rgb(92,92, 92)",
  editorButtonColor: "rgba(255,255,255,0.23)",
};

// Darkest theme
const pitchBlack = {
  themeType: "dark",
  primaryColors: {
    light: "rgb(187, 222, 251)",
    main: "rgb(144, 202, 249)",
    dark: "rgb(100, 181, 246)",
  },
  secondaryColors: {
    light: "rgb(248, 187, 208)",
    main: "rgb(244, 143, 177)",
    dark: "rgb(240, 98, 146)",
  },
  badge: {
    background: "rgba(255,255,255,0.54)",
    color: "rgba(0,0,0,0.87)",
  },
  background: [
    "rgb(4, 5, 7)",
    "rgb(12, 18, 23)",
    "rgb(16, 24, 30)",
    "rgb(20, 30, 37)",
    "rgb(24, 36, 44)",
    "rgb(32, 47, 57)",
    "rgb(64, 92, 112)",
    "rgb(72, 103, 125)",
    "rgb(128, 159, 182)",
    "rgb(144, 171, 191)",
    "rgb(164, 186, 203)",
    "rgb(238, 242, 245)",
  ],
  borderColor: "rgba(255,255,255,0.12)",
  labelColor: "rgba(255,255,255,0.7)",
  calendarSelectColor: "magenta",
  color: "rgb(255,255,255)",
  hoverBackground: [
    "rgb(16, 24, 30)",
    "rgb(20, 30, 37)",
    "rgb(24, 36, 44)",
    "rgb(32, 47, 57)",
  ],
  iconColor: "rgba(255,255,255,0.5)",
  iconDisabledColor: "rgba(255,255,255,0.3)",
  iconHoverColor: "rgba(255,255,255,0.05)",
  linkColor: "rgba(255,255,255,0.54)",
  secondaryColor: "rgba(255,255,255,0.54)",
  dangerColor,
  dangerHoverColor,
  warningColor,
  successColor,
  infoColor,
  progressTrailColor: "rgb(4,5,7)",
  tableHighlight: "rgba(255,255,255,0.02)",
  tooltipBackground: "rgb(4, 5, 7)",
  tooltipColor: "rgba(255,255,255, 0.7)",
  tooltipSecondary: "rgba(255,255,255,0.54)",
  scrollbar: "rgb(32, 47, 57)",
  editorButtonColor: "rgba(255,255,255,0.23)",
};

// Light theme
const lightTheme = {
  themeType: "light",
  primaryColors: {
    light: "rgb(66, 165, 245)",
    main: "rgb(33, 150, 243)",
    dark: "rgb(30, 136, 229)",
  },
  secondaryColors: {
    light: "rgb(244, 143, 177)",
    main: "rgb(233, 30, 99)",
    dark: "rgb(216, 27, 96)",
  },
  badge: {
    background: "rgba(0,0,0,0.54)",
    color: "rgba(255,255,255)",
  },
  background: [
    "rgb(245, 245, 245)",
    "rgb(255, 255, 255)",
    "rgb(255, 255, 255)",
  ],
  borderColor: "rgba(0,0,0,0.12)",
  labelColor: "rgba(0,0,0,0.7)",
  calendarSelectColor: "magenta",
  color: "rgb(0,0,0,0.87)",
  hoverBackground: ["rgb(192,192,192)", "rgb(176,176,176)"],
  iconColor: "rgb(0,0,0,0.54)",
  iconDisabledColor: "rgba(0,0,0,0.3)",
  iconHoverColor: "rgba(0,0,0,0.23)",
  linkColor: "rgb(0,0,0,0.54)",
  secondaryColor: "rgba(0,0,0,0.54)",
  dangerColor,
  dangerHoverColor,
  warningColor,
  successColor,
  infoColor,
  progressTrailColor: "rgb(245,245,245)",
  tableHighlight: "rgba(0,0,0,0.02)",
  tooltipBackground: "rgb(224,224,224)",
  tooltipColor: "rgba(0,0,0,0.87)",
  tooltipSecondary: "rgba(0,0,0,0.54)",
  scrollbar: "rgb(192,192,192)",
  editorButtonColor: "rgba(0,0,0,0.23)",
};

const getThemeVariation = (type) => {
  switch (type) {
    case "dark":
      window.document.documentElement.style.setProperty(
        "--border-color",
        "rgba(255,255,255,0.12)"
      );
      window.document.documentElement.style.setProperty(
        "--paper-background",
        "rgb(48,48,48)"
      );
      window.document.documentElement.style.setProperty(
        "--text-primary",
        "rgb(255,255,255)"
      );
      window.document.documentElement.style.setProperty(
        "--text-secondary",
        "rgba(255,255,255,0.7)"
      );
      return darkTheme;
    case "pitch-black":
      window.document.documentElement.style.setProperty(
        "--border-color",
        "rgba(255,255,255,0.12)"
      );
      window.document.documentElement.style.setProperty(
        "--paper-background",
        "rgb(12, 18, 23)"
      );
      window.document.documentElement.style.setProperty(
        "--text-primary",
        "rgb(255,255,255)"
      );
      window.document.documentElement.style.setProperty(
        "--text-secondary",
        "rgba(255,255,255,0.7)"
      );
      return pitchBlack;
    case "light":
      window.document.documentElement.style.setProperty(
        "--border-color",
        "rgba(0,0,0,0.12)"
      );
      window.document.documentElement.style.setProperty(
        "--paper-background",
        "white"
      );
      window.document.documentElement.style.setProperty(
        "--text-primary",
        "rgba(0,0,0,0.87)"
      );
      window.document.documentElement.style.setProperty(
        "--text-secondary",
        "rgba(0,0,0,0.54)"
      );
      return lightTheme;
    default:
      throw new Error(
        `Wrong theme type: ${type}. Should be light, dark or pitch-black`
      );
  }
};

export const getTheme = (type) => getThemeVariation(type || "dark");
