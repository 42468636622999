import React, { useContext, useEffect } from "react";
import { createStyles, Drawer, Portal, useMantineTheme } from "@mantine/core";
import { CLOSE_CART, Context, OPEN_CART } from "../../context";
import { useSelector } from "react-redux";
import "./styles.css";
import DrawerList from "./DrawerList";
import DrawerAlert from "./DrawerAlert";
import DrawerFooter from "./DrawerFooter";

const useStyles = createStyles((theme) => ({
  drawerTitle: {
    fontSize: "1.5rem",
    marginTop: "-3.3rem",
    marginBottom: "2rem",
    fontWeight: 700,
  },
  cart: {
    overflowY: "auto",
    flexDirection: "column",
    display: "flex",
    "@media screen and (max-width: 768px)": {
      width: "90vw !important",
    },
  },
  cartBody: {},
  itemWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "-1rem",
  },
}));

const DrawerCart = ({ sale, prevQuantity, setPrevQuantity, cartOpen }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const theme = useMantineTheme();
  const { dispatch } = useContext(Context);
  const { classes } = useStyles();

  useEffect(() => {
    if (
      sale?.itemQuantity &&
      prevQuantity &&
      sale?.itemQuantity !== prevQuantity
    ) {
      setPrevQuantity(sale?.itemQuantity);
    }
  }, [sale, prevQuantity, setPrevQuantity]);

  useEffect(() => {
    if (prevQuantity !== null && sale?.itemQuantity !== 0 && !cartOpen) {
      dispatch({ type: OPEN_CART });
    }
  }, [sale]);

  if (prevQuantity === null || !config.useCartDrawer) return null;

  return (
    <Portal>
      <Drawer
        opened={cartOpen}
        onClose={() => dispatch({ type: CLOSE_CART })}
        padding="xl"
        size="xl"
        position="right"
        transitionDuration={400}
        overlayColor={theme.colors.dark[9]}
        overlayOpacity={0.3}
        className={classes.cart}
        aria-label={`${language.CartIsOpen}, ${language.Cart_Quantity} ${sale?.itemQuantity} ${language.Articles}`}
        aria-describedby="drawer-body"
        closeButtonLabel={language.CloseCart}
        withinPortal={false}
      >
        <h1 className={classes.drawerTitle}>{language.Cart}</h1>
        <div className={classes.itemWrapper}>
          <div aria-live="polite">
            <DrawerAlert />
            {sale?.items.length === 0 && <p>{language.EmptyCart}</p>}
            <DrawerList sale={sale} />
          </div>
          <DrawerFooter sale={sale} />
        </div>
      </Drawer>
    </Portal>
  );
};

export default DrawerCart;
