import { Button, createStyles } from "@mantine/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import {
  STATE_CULTURE,
  STATE_CURRENCY,
  STATE_SALE_ID,
} from "../../common/state";
import { isItReal, pixelCheckoutData } from "../../common/util";
import { checkout } from "../../common/sale";
import { CLOSE_CART, Context } from "../../context";

const useStyles = createStyles({
  footer: {
    borderTop: "1px solid lightgrey",
    paddingBottom: "1rem",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1.25rem 0rem",
  },
  shippingWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "-1.25rem 0 .8rem 0",
    paddingBottom: "0.25rem",
    fontSize: "1rem",
    h4: {
      fontSize: "1rem",
    },
  },
  toPay: {
    padding: 0,
    fontSize: "1.25rem",
  },
  sum: {
    padding: 0,
    fontSize: "1.25rem",
  },
  continueShopping: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
});
const DrawerFooter = ({ sale }) => {
  const { language, currency, config, saleId, culture } = useSelector(
    (state) => ({
      language: state.language,
      currency: state.cookies[STATE_CURRENCY],
      config: state.config,
      saleId: state.cookies[STATE_SALE_ID],
      culture: state.cookies[STATE_CULTURE],
    })
  );
  const { classes } = useStyles();
  const [cart, setCart] = useState([]);
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const shippingCost = useCallback(() => {
    const shipping = sale.items.filter((el) => el.type === "shipping_fee");
    if (shipping.length > 0) {
      return (
        <div className={classes.shippingWrapper}>
          <h4>{language.ShippingCost}</h4>
          <h4>{shipping?.[0]?.totalPriceInclVat}</h4>
        </div>
      );
    }
  }, [sale]);
  const serviceFee = useCallback(() => {
    const serviceFee = sale.items.filter((el) => el.type === "service_fee");
    if (serviceFee.length > 0) {
      return (
        <div className={classes.shippingWrapper}>
          <h4>{language.ServiceFee}</h4>
          <h4>{serviceFee?.[0]?.totalPriceInclVat}</h4>
        </div>
      );
    }
  }, [sale]);

  const productPrice = (item) => {
    if (item && item.length > 0) {
      const regex = /[$€£kr]/g;
      let newPrice = item.replace(regex, "");
      if (newPrice.trim().split(".")[1] === "00") {
        return newPrice.split(".")[0];
      } else {
        return newPrice;
      }
    }
  };

  const trackReduceCart = Object.values(
    cart.reduce((acc, curr) => {
      acc[curr.key] = {
        id: curr.article.plu,
        quantity: curr.quantity,
        price: curr.pricePerPiece.amountInclVat,
        name: curr.article.name,
      };
      return acc;
    }, {})
  );

  const goToCheckoutTracking = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency,
          value: parseFloat(
            productPrice(sale.sumInclVat.replace(/\s/g, "").replace(",", "."))
          ),
          items: trackReduceCart,
        },
      });
    }
  };

  const goToCheckout = () => {
    if (sale) {
      sale.items.map((item) => {
        const { name, plu } = item.article;
        const gaItem = {
          name,
          sku: plu,
          price: item.pricePerPiece.amountInclVat,
          quantity: item.quantity,
          currency,
          saleId,
        };
        ReactGA.plugin.require("ecommerce");
        ReactGA.plugin.execute("ecommerce", "addItem", gaItem);
        return ReactGA.plugin.execute("ecommerce", "send");
      });
      if (config.facebookPixelId && isItReal("pixel", config.facebookPixelId)) {
        pixelCheckoutData(sale.items, currency);
      }
    }
    goToCheckoutTracking();
    return rdxDispatch(checkout());
  };

  useEffect(() => {
    if (sale && sale.items.length > 0) {
      setCart(sale.items);
    }
  }, [sale]);

  return (
    <div className={classes.footer}>
      <div className={classes.textWrapper}>
        <h2 className={classes.toPay}>{language.ToPay}</h2>
        <h3 className={classes.sum}>{sale?.sumInclVat}</h3>
      </div>
      {shippingCost()}
      {serviceFee()}
      <Button
        fullWidth
        size="lg"
        className={`${classes.continueShopping} secondaryButton`}
        onClick={() => dispatch({ type: CLOSE_CART })}
      >
        {language.ArticleBooking.ModalSecondaryButton}
      </Button>
      <Button
        fullWidth
        size="lg"
        className="regularButton begin_checkout"
        classNames={{
          label: "begin_checkout",
          inner: "begin_checkout",
        }}
        disabled={sale?.items.length === 0}
        onClick={() => goToCheckout()}
      >
        {language.GoToCheckout}
      </Button>
    </div>
  );
};

export default DrawerFooter;
