import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "64px",
    },
    [theme.breakpoints.up("sm")]: {
      height: (p) => (p.headerHeight ? `${p.headerHeight + 64}px` : "64px"),
    },
  },
  headerLayout: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
    color: theme.palette.color,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: "64px",
    },

    [theme.breakpoints.up("sm")]: {
      height: (p) => (p.headerHeight ? `${p.headerHeight}px` : "64px"),
    },

    position: "relative",
    width: "100%",
    zIndex: theme.zIndex.appBar,
    padding: "0 16px",
    zIndex: 1999,

    // Fixed header settings
    "@media(min-width: 768px)": {
      position: "fixed",
      top: 0,
      zIndex: 999,
    },

    "@media(max-width: 768px)": {
      position: "fixed",
    },

    "@media(max-width: 667px)": {
      padding: "0px 8px",
    },
  },
}));

const HeaderLayout = ({ children }) => {
  const config = useSelector((state) => state.config);
  const classes = useStyles({ headerHeight: config.headerHeight });
  return (
    <div className={classes.headerWrapper}>
      <div className={clsx(classes.headerLayout, "header__container")}>
        {children}
      </div>
    </div>
  );
};

export default HeaderLayout;
