import React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import QuantitySelect from "../common/quantitySelect";

const useStyles = makeStyles((theme) => ({
  container: {},
  list: {
    "@media(max-width: 667px)": {
      "& li": {
        borderBottom: `1px solid ${theme.app.borderColor}`,
      },
    },
  },
  listItem: {
    paddingRight: "150px",
    "@media(max-width: 667px)": {
      display: "grid",
      gridTemplateColumns: "1fr",
      padding: "0 0 24px",
      borderBottom: "none",
    },
  },
  listItemText: {},
  listItemAction: {
    display: "flex",
    "@media(max-width: 667px)": {
      position: "relative",
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  addButton: {
    alignSelf: "flex-end",
    marginLeft: "16px",
  },
  disabledButton: {
    alignSelf: "flex-end",
    marginLeft: "16px",
    color: "rgba(0, 0, 0, 0.26) !important",
  },
  buttonProgress: {
    position: "absolute",
    color: "white",
  },
}));

const ArticlesList = (props) => {
  const {
    add,
    articles,
    language,
    isOverrideStartDate,
    overrideStartDate,
    quantity,
    setQuantity,
    isLoading,
  } = props;
  const classes = useStyles();
  const disabled = (a) => {
    if ((isOverrideStartDate && !overrideStartDate) || isLoading.loading) {
      return true;
    }
    return false;
  };

  const sortedArticles = articles.sort((a, b) => a.plu - b.plu);

  return (
    <div className={classes.container}>
      <List className={classes.list}>
        {sortedArticles.map((a, i) => {
          const notInStock = a.isProduct && a.inStock < 1;
          return (
            <ListItem divider key={i} className={classes.listItem}>
              <ListItemText
                className={classes.listItemText}
                primary={a.name.length > 0 ? a.name : `Product Name (${a.plu})`}
                secondary={
                  notInStock
                    ? `${a.priceInclVat} - ${language.Not_In_Stock}`
                    : a.priceInclVat
                }
              />
              <ListItemSecondaryAction className={classes.listItemAction}>
                <QuantitySelect
                  disabled={disabled() || notInStock}
                  handleOnChange={(v) => setQuantity(v)}
                  language={language}
                  value={quantity}
                />
                <Button
                  className={
                    isLoading.loading || notInStock
                      ? classes.disabledButton
                      : classes.addButton
                  }
                  disabled={
                    disabled() ||
                    notInStock ||
                    (isLoading.loading && isLoading.id === a.plu)
                  }
                  onClick={() => add(a)}
                  color="primary"
                >
                  {language?.Article_Add || "Köp"}
                  {isLoading.loading && isLoading.id === a.plu && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ArticlesList;
