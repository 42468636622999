import {
  Badge,
  createStyles,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import Ticket from "./icons/Ticket";
import React from "react";
import { differenceInDays } from "date-fns";
import { useSelector } from "react-redux";

const ICON_SIZE = 60;

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.xl,
    paddingTop: theme.spacing.xl * 1.5 + ICON_SIZE / 3,
    border: "1px solid #8A8C93",
  },

  icon: {
    position: "absolute",
    top: -ICON_SIZE / 3,
    left: `calc(50% - ${ICON_SIZE / 2}px)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },
}));

const ExtensionCard = ({ card }) => {
  const { language, config } = useSelector((state) => ({
    language: state.language,
    config: state.config,
  }));
  const { classes } = useStyles();

  return (
    <>
      <Title order={5}>{language.YourTicket}</Title>
      <Paper
        radius="md"
        withBorder
        className={`${classes.card}`}
        mt={ICON_SIZE / 3}
      >
        <ThemeIcon
          className={`${classes.icon} regularButton`}
          size={ICON_SIZE}
          radius={ICON_SIZE}
        >
          <Ticket size={34} stroke={1.5} />
        </ThemeIcon>

        <Text align="center" weight={700} className={classes.title}>
          {card.owner?.firstname} {card.owner?.lastname}
        </Text>
        <Text color="black" align="center" size="sm" mt="xs">
          {card.validFrom.split("T")[0]} - {card.validUntil.split("T")[0]}
        </Text>
        <Group position="apart" mt="md">
          <Text size="sm">{card.article.name}</Text>
          <Badge size="sm">
            {differenceInDays(new Date(card.validUntil), new Date())}{" "}
            {language.DaysLeft}
          </Badge>
        </Group>
      </Paper>
    </>
  );
};

export default ExtensionCard;
