import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import AlertControl from "@material-ui/lab/Alert";
import { Context, CLOSE_ALERT } from "../../context";

const Alert = (props) => {
  const {
    state: { alertOpen, alertText, alertSeverity },
    dispatch,
  } = useContext(Context);
  const handleClose = () => dispatch({ type: CLOSE_ALERT });
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={alertOpen}
      onClose={handleClose}
    >
      <AlertControl onClose={handleClose} severity={alertSeverity}>
        {alertText}
      </AlertControl>
    </Snackbar>
  );
};

export default Alert;
