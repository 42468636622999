import React from "react";
import { createStyles, Text, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const useStyles = createStyles((theme) => ({
  container: {},
  titleWrapper: {
    marginBottom: "1rem",
  },
  listWrapper: {
    display: "grid",
    gridTemplateColumns: "30% 70%",
    gap: "3rem",
    "@media screen and (max-width: 768px)": {
      gridTemplateColumns: "1fr",
      gap: "0rem",
    },
    "& ul": {
      marginTop: "-.3rem",
      listStyle: "none",
      padding: 0,
      paddingRight: "2rem",
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      "& li": {
        wordBreak: "break-word",
      },
    },
    "& span": {
      fontWeight: 400,
    },
  },
}));

const Confirmation = ({ form, data, name }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const { classes } = useStyles();

  const fieldText = (field) => {
    if (field?.stringVal) {
      return <span style={{ marginRight: "0.5rem" }}>{field?.stringVal}</span>;
    } else if (field?.multiVal && field?.multiVal?.length > 0) {
      return (
        field?.multiVal &&
        field?.multiVal.map((el, i) => (
          <span key={i} style={{ marginRight: "0.5rem" }}>
            {el}
          </span>
        ))
      );
    } else if (field?.intVal) {
      return <span style={{ marginRight: "0.5rem" }}>{field?.intVal}</span>;
    } else {
      return <span>-</span>;
    }
  };
  const fields = (field) => {
    const getData = data && data?.formFields.filter((el) => el.id === field.id);
    if (getData) {
      const getValue = form.values.formFields.filter(
        (el) => el.id === getData?.[0].id
      );
      return (
        <li key={field.id}>
          <b>{getData?.[0].name}</b>
          <br />
          <div>{fieldText(getValue?.[0])}</div>
        </li>
      );
    }
  };
  const dataFields = () => {
    const type = data?.publicBookingTypes.filter(
      (el) => el.id === form.values.publicBookingTypeId
    )?.[0];
    if (form) {
      return Object.entries(form.values).map((field) => {
        if (field?.[0] === "activityId") {
          return (
            <li key={field?.[0]}>
              <div style={{ marginBottom: "0.39rem" }}>
                <b>{language.Type}</b>
                <br />
                <span>{type?.name}</span>
              </div>
              <div>
                <b>{language.Length}</b>
                <br />
                <span>
                  {type?.occasionLengthInMinutes} {language.Minutes}
                </span>
              </div>
            </li>
          );
        }
        if (field?.[0] === "occasion") {
          return (
            <li key={field?.[0]}>
              <div>
                <b>{language.Occasion}</b>
                <br />
                <span>{format(new Date(field?.[1]), "yyyy-MM-dd HH:mm")}</span>
              </div>
            </li>
          );
        }
        if (field?.[0] === "numberOfParticipants") {
          return (
            <li key={field?.[0]}>
              <div>
                <b>{language.AmountOfParticipants}</b>
                <br />
                <span>{field?.[1]}</span>
              </div>
            </li>
          );
        }
        if (field?.[0] === "responsibleId") {
          return (
            <li key={field?.[0]}>
              <div>
                <b>{language.Responsible}</b>
                <br />
                <span>{name}</span>
              </div>
            </li>
          );
        }
      });
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <Title order={3} sx={{ fontSize: "1rem", fontWeight: 500 }}>
          {language.Summary}
        </Title>
        <Text component="p" mt={0}>
          {language.CheckSummary}
        </Text>
      </div>
      <div className={classes.listWrapper}>
        <ul>{dataFields()}</ul>
        <div>
          <Text component="p" mt={0} sx={{ fontSize: "1rem", fontWeight: 500 }}>
            {language.ExtraFields}
          </Text>
          <ul>{form.values.formFields.map((el) => fields(el))}</ul>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
