import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "@reach/router";
import { makeStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { arrowGenerator } from "../common/util";
import { getOccasionsByDay } from "./util";
import { ReactComponent as Placeholder } from "../../images/placeholder_list.svg";
import slugify from "../common/slugify";

const useStyles = makeStyles((theme) => ({
  link: {
    lineHeight: 1,
  },
  listItem: {
    padding: "8px",
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  content: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: "16px",
    alignItems: "center",
    "@media(max-width: 667px)": {
      gridTemplateColumns: "1fr",
      gridGap: "0px",
    },
  },
  eventInfo: {},
  title: {
    lineHeight: 1,
    marginBottom: "0.2em",
    color: theme.app.labelColor,
  },
  imageContainer: {
    "@media(max-width: 667px)": {
      display: "none",
    },
  },
  image: {
    maxWidth: "100px",
    borderRadius: "4px",
  },
  exerpt: {
    "& p": {
      color: theme.app.secondaryColor,
      display: "box",
      lineClamp: 1,
      boxOrient: "vertical",
      overflow: "hidden",
      margin: 0,
      fontSize: "0.8125rem",
      fontStyle: "italic",
    },
  },
  actions: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "4px",
  },
  occasions: {
    display: "flex",
    flexWrap: "wrap",
    "& p": {
      lineHeight: 1.2,
      color: theme.app.secondaryColor,
      fontSize: "0.875rem",
    },
  },
  occasionDate: {
    color: "inherit",
  },
  occasionTime: {
    "&:not(:last-child):after": {
      content: "', '",
      whiteSpace: "pre",
    },
  },
  occasionTimes: {
    maxWidth: "200px",
    display: "flex",
    flexWrap: "wrap",
  },
  tootltipTime: {
    color: "inherit",
    fontSize: "0.8125rem",
    opacity: "0.9",
    "&:not(:last-child):after": {
      content: "', '",
      whiteSpace: "pre",
    },
  },
  tooltipDivider: {
    margin: "8px 0",
    backgroundColor: "rgba(255,255,255,0.6)",
  },
  htmlPopper: arrowGenerator("black"),
  htmlTooltip: {
    backgroundColor: "black",
    color: "white",
    maxWidth: 220,
    minWidth: 200,
    fontSize: "12px",
    border: "1px solid black",
    "& b": {
      fontWeight: 500,
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 6,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  placeholder: {
    maxWidth: "100px",
    borderRadius: "4px",
    border: `1px solid ${theme.app.borderColor}`,
    width: "100%",
    background: theme.app.background[2],
  },
}));

const ListItem = (props) => {
  const { eventConfig, occasionsInMonth, event, config, culture } = props;
  const classes = useStyles();
  const [arrowRef, handleArrowRef] = useState(null);
  const language = useSelector((state) => state.language);
  const occasionsByDay = getOccasionsByDay(occasionsInMonth);
  const description =
    event.translation.find((t) => t.culture === culture)?.name ??
    language[event.description] ??
    event.description;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const imageUri = event.imageFileName
    ? `${uri}/allotmentimage/${event.imageFileName}`
    : undefined;

  const getCalendarBookingLinks = (item) => {
    const eventCalendarLinks = config.eventConfig.filter(
      (el) => el.eventCalendar && el?.name === item?.name
    );
    const articleBookingLinks = config.eventConfig.filter(
      (el) => el.articleBooking && el?.name === item?.name
    );
    if (eventCalendarLinks && eventCalendarLinks[0]) {
      return `/ticketshop/tickets/${slugify(eventCalendarLinks[0].name)}`;
    }
    if (articleBookingLinks && articleBookingLinks[0]) {
      return `/ticketshop/booking/${slugify(articleBookingLinks[0]?.name)}`;
    }
    if (!eventCalendarLinks[0] && !articleBookingLinks[0]) {
      return `${process.env.PUBLIC_URL}${eventConfig.slug}`;
    }
  };
  return (
    <>
      <Link className={classes.link} to={getCalendarBookingLinks(eventConfig)}>
        <div className={classes.listItem}>
          <div className={classes.content}>
            <div className={classes.imageContainer}>
              {imageUri ? (
                <img src={imageUri} className={classes.image} alt="event" />
              ) : (
                <Placeholder className={classes.placeholder} />
              )}
            </div>
            <div>
              <div className={classes.eventInfo}>
                <Typography className={classes.title} variant="h6">
                  {description}
                </Typography>
              </div>
              <div className={classes.occasions}>
                {occasionsInMonth.length < 4 ? (
                  occasionsInMonth.map((o, i) => (
                    <Typography className={classes.occasionTime} key={i}>
                      {moment(o.time).format("D MMM HH:mm")}
                    </Typography>
                  ))
                ) : (
                  <Tooltip
                    interactive
                    classes={{
                      popper: classes.htmlPopper,
                      tooltip: classes.htmlTooltip,
                    }}
                    PopperProps={{
                      popperOptions: {
                        modifiers: {
                          arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef,
                          },
                        },
                      },
                    }}
                    title={
                      <>
                        <div>
                          {occasionsByDay.map((o, i) => {
                            return (
                              <div key={i}>
                                <Typography className={classes.occasionDate}>
                                  <b>{moment(o.day).format("D MMM")}</b>
                                </Typography>
                                <div className={classes.occasionTimes}>
                                  {o.times.map((t) => {
                                    return (
                                      <Typography
                                        key={t}
                                        className={classes.tootltipTime}
                                      >
                                        {t}
                                      </Typography>
                                    );
                                  })}
                                </div>
                                <Divider className={classes.tooltipDivider} />
                              </div>
                            );
                          })}
                        </div>
                        <span className={classes.arrow} ref={handleArrowRef} />
                      </>
                    }
                  >
                    <Typography>{`${occasionsInMonth.length} ${language.Allotment_Occasions}`}</Typography>
                  </Tooltip>
                )}
              </div>
              <Typography component="div" className={classes.exerpt}>
                <p>{eventConfig.exerpt}</p>
              </Typography>
            </div>
          </div>
        </div>
      </Link>
      <Divider />
    </>
  );
};

export default ListItem;
