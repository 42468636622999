import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";

const FormikTextField = (props) => {
  const {
    placeholder,
    min,
    type,
    max,
    required,
    disabled,
    autoFocus,
    label,
    name,
  } = props;
  const [field, meta] = useField(name);
  return (
    <TextField
      {...field}
      className="form-control"
      type={type}
      placeholder={placeholder}
      min={min}
      variant="filled"
      max={max}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      margin="dense"
      helperText={meta.error && meta.touched && <span>{meta.error}</span>}
      error={meta.error && meta.touched && meta.error.length > 0}
    />
  );
};

export default FormikTextField;
