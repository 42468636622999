import {
  ActionIcon,
  createStyles,
  Divider,
  Grid,
  Image,
  Loader,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeArticle, removeFromCartTracking } from "../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { DataContext } from "../common/dataProvider";
import QuantityInput from "./QuantityInput";
import TrashIcon from "./TrashIcon";
import { Context } from "../../context";

const useStyles = createStyles({
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    "@media screen and (max-width: 768px)": {
      gap: "0.5rem",
    },
  },
  image: {
    width: "6rem",
    height: "5rem",
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  leftWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textWrapper: {
    display: "flex",
    gap: "0.7rem",
  },
  text: {
    fontWeight: "bold",
    margin: 0,
    lineHeight: "normal",
  },
  textSmall: {
    margin: 0,
    lineHeight: "normal",
    fontStyle: "italic",
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",
  },
  price: {
    fontWeight: "bold",
    margin: 0,
    textAlign: "right",
  },
  divider: {
    opacity: 0.5,
  },
  loadingWrapper: {
    opacity: 0.5,
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
  },
  hideAriaLive: {
    position: "absolute",
    width: "0px",
    height: "0px",
    opacity: 0,
  },
});

const DrawerItem = ({ item, sale }) => {
  const [loading, loadingSet] = useState(null);
  const [allotmentRemaining, allotmentRemainingSet] = useState(null);
  const { config, culture, language, currency } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { data } = useContext(DataContext);
  const { classes } = useStyles();
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const image = `${uri}/articleimage/${item?.article?.imageFilename}`;

  const remove = () => {
    loadingSet(true);
    rdxDispatch(
      removeArticle(item.key, (cb, msg) => {
        if (cb.status === 200) {
          const reducedItem = {
            id: item.article.plu,
            quantity: item.quantity,
            price: item.pricePerPiece.amountInclVat,
            name: item.article.name,
          };
          removeFromCartTracking(
            currency,
            reducedItem,
            item.totalPrice.amountInclVat
          );
        }
      })
    );
  };

  const getName = () => {
    const getTranslation = item?.article?.translation.filter(
      (el) => el.culture === culture
    );
    return getTranslation?.[0]?.name;
  };

  const getStock =
    item.article.plu &&
    data &&
    data.articles.filter((el) => el.plu === item.article.plu)?.[0]?.inStock;

  const allotment =
    item.allotmentId &&
    data &&
    data.allotments.reduce((acc, curr) => {
      if (curr.id === item.allotmentId) {
        let occasion = curr.occasions.filter((el) => el.time === item.occasion);
        acc = occasion;
      }
      return acc;
    }, {});

  useEffect(() => {
    if (allotment && allotment.length > 0) {
      allotmentRemainingSet(allotment[0].remaining);
    }
  }, [allotment]);

  useEffect(() => {
    loadingSet(false);
    return () => loadingSet(null);
  }, [sale.items]);

  return (
    <div className={loading ? classes.loadingWrapper : undefined}>
      <div className={classes.wrapper}>
        {loading && <Loader variant="dots" className={classes.loader} />}
        <div className={classes.itemWrapper}>
          <Image
            withPlaceholder
            className={classes.image}
            radius="md"
            src={image}
            fit="cover"
            width={"5rem"}
            height={"5rem"}
            alt={getName()}
          />
          <Grid className={classes.rowWrapper}>
            <Grid.Col span={12}>
              <div className={classes.leftWrapper}>
                <div className={classes.textWrapper}>
                  <p className={classes.text}>{getName()}</p>
                  {item?.ticketOwners?.[0]?.name && (
                    <p className={classes.textSmall}>
                      {item?.ticketOwners?.[0]?.name}
                    </p>
                  )}
                </div>
                {loading && (
                  <div className={classes.hideAriaLive} aria-live="polite">
                    {language.Removed}
                  </div>
                )}
                <ActionIcon
                  color="dark"
                  className={`${classes.trashButton} trashButton`}
                  aria-label={`${language.RemoveProduct} ${getName()}`}
                  onClick={() => remove()}
                >
                  <TrashIcon />
                </ActionIcon>
              </div>
            </Grid.Col>
            <Grid.Col span={12}>
              <div className={classes.rightWrapper}>
                <QuantityInput
                  name={getName()}
                  inStock={
                    getStock
                      ? getStock
                      : allotmentRemaining
                      ? allotmentRemaining
                      : ""
                  }
                  item={item}
                  id={item.key}
                  quantity={item.quantity}
                />
                <p component="p" className={classes.price}>
                  {item.totalPriceInclVat}
                </p>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </div>
      <Divider my="lg" size="sm" className={classes.divider} />
    </div>
  );
};

export default DrawerItem;
