import Cookies from "browser-cookies";
import uuid from "uuid";
//import InitialState from "../reducers/initialState";

const makeId = () => uuid.v4();

const initialState = {
  culture: "sv-SE",
  currency: "SEK",
  cookieBanner: "true",
  theme: "light",
  shopId: makeId(),
  consent: "null",
};

export const setCookie = (key, value) => {
  return {
    type: "@EE/SET_COOKIE",
    key,
    value,
  };
};

export const expireCookie = (key) => {
  return {
    type: "@EE/EXPIRE_COOKIE",
    key,
  };
};

export const setCookieConsent = (key, value) => {
  return {
    type: "@EE/SET_CONSENT",
    key,
    value,
  };
};

export const cookieReducer = (keys, config) => (state, action) => {
  state = state || getInitialState(keys, config);

  switch (action.type) {
    case "@EE/EXPIRE_COOKIE": {
      const c = {};
      c[action.key] = null;

      return Object.assign({}, state, c);
    }
    case "@EE/SET_COOKIE": {
      const c = {};
      c[action.key] = action.value;
      return Object.assign({}, state, c);
    }
    case "@EE/SET_CONSENT": {
      const c = {};
      c[action.key] = action.value;
      return Object.assign({}, state, c);
    }
    default:
      return state;
  }
};

export const cookieMiddleware = (store) => (next) => (action) => {
  if (action.type === "@EE/SET_COOKIE") {
    Cookies.set(action.key, action.value.toString(), { samesite: "lax" });
  }
  if (action.type === "@EE/SET_CONSENT") {
    Cookies.set(
      action.key,
      action.value.toString(),
      { expires: 365 },
      { samesite: "lax" }
    );
  } else if (action.type === "@EE/EXPIRE_COOKIE") {
    Cookies.erase(action.key);
  }

  return next(action);
};

function getInitialState(keys, config) {
  const state = {};

  //Check if culture, currency or tag filter is in cookie
  if (Cookies.get("shop-saleId") == null) {
    Cookies.set("shop-saleId", initialState.shopId, {
      samesite: "lax",
    });
  }
  if (Cookies.get("shop-culture") == null) {
    Cookies.set("shop-culture", config.defaultCulture || initialState.culture, {
      samesite: "lax",
    });
  }
  if (Cookies.get("shop-currency") == null) {
    Cookies.set("shop-currency", initialState.currency, { samesite: "lax" });
  }
  if (Cookies.get("cookie-banner") == null) {
    Cookies.set("cookie-banner", initialState.cookieBanner, {
      samesite: "lax",
    });
  }
  if (Cookies.get("shop-theme") == null) {
    Cookies.set("shop-theme", initialState.theme, { samesite: "lax" });
  }
  if (Cookies.get("shop-consent") == null) {
    Cookies.set("shop-consent", initialState.consent, { samesite: "lax" });
  }

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];
    state[key] = Cookies.get(key);
  }

  return state;
}
