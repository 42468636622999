import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { addModification } from "../../common/sale";
import { Context, TOAST, CART_ARTICLE_ADD } from "../../context";
import { getCartArticle } from "../cart/util";

const useStyles = makeStyles((theme) => ({
  article: {},
}));

const Article = ({ ticket, article, onAdded }) => {
  const classes = useStyles();
  const rdxDispatch = useDispatch();
  const { language, config } = useSelector((state) => ({
    language: state.language,
    config: state.config,
  }));

  const { dispatch } = useContext(Context);
  const ticketId = ticket.id;
  const articleId = ticket.article.id;
  const plu = article.plu;
  const fundsId = ticket.fundsId;
  const amount = article.price?.amountInclVat;

  const add = () => {
    const model = {
      ticketId,
      articleId,
      plu,
      fundsId,
      amount,
    };
    rdxDispatch(
      addModification(model, (status, msg) => {
        if (status >= 400) {
          return dispatch({
            type: TOAST,
            payload: {
              text: msg,
              variant: "error",
            },
          });
        }

        onAdded();
        const cartArticle = getCartArticle(article, config);
        return dispatch({ type: CART_ARTICLE_ADD, payload: cartArticle });
      })
    );
  };

  return (
    <ListItem className={classes.article} divider>
      <ListItemText
        classes={{
          primary: clsx(classes.primary, "primary-text"),
          secondary: clsx(classes.secondary, "secondary-text"),
        }}
        primary={article.name}
        secondary={article.priceInclVat}
      />
      <ListItemSecondaryAction>
        <Button color="primary" onClick={add}>
          {language.Buy}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default Article;
