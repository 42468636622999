import React from "react";
import { useNavigate } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ReactComponent as Placeholder } from "../../images/placeholder_list.svg";
import slugify from "../common/slugify";
import useLocalStorage from "../Header/HeaderV2/hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:nth-child(even)": {
      backgroundColor: theme.app.tableHighlight,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    "@media(max-width: 667px)": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  imageContainer: {
    lineHeight: 1,
  },
  listItemText: {
    paddingLeft: "16px",
  },
  image: {
    width: "100px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "4px",
    objectFit: "cover",
    backgroundColor: theme.app.background[2],
    "@media(max-width: 667px)": {
      width: "75px",
    },
  },
}));

const List = ({ item, slug }) => {
  const [selected, setSelected] = useLocalStorage();
  const classes = useStyles();
  const navigate = useNavigate();
  let itemSlug =
    item?.eventCalendar === true
      ? `${process.env.PUBLIC_URL}/tickets/${slugify(item.name)}`
      : item?.articleBooking === true
      ? `${process.env.PUBLIC_URL}/booking/${slugify(item.name)}`
      : slug
      ? slug
      : item?.externalLink
      ? item?.externalLink
      : `${process.env.PUBLIC_URL}/${slugify(item.name)}`;
  return (
    <>
      {item?.externalLink ? (
        <a href={item?.externalLink} target="_blank">
          <ListItem
            className={clsx(classes.listItem, "landing__group-item__list")}
            divider
          >
            <div
              className={clsx(
                classes.imageContainer,
                "landing__list__image-container"
              )}
            >
              {item.image ? (
                <img className={classes.image} src={item.image} alt="product" />
              ) : (
                <Placeholder className={classes.img} />
              )}
            </div>
            <ListItemText
              primary={
                item?.text?.primary
                  ? item?.text.primary
                  : "Add landing primary text in language config"
              }
              secondary={
                item?.text?.secondary
                  ? item?.text.secondary
                  : "Add landing secondary text in language config"
              }
              className={clsx(
                classes.listItemText,
                "landing__list__text-container"
              )}
              classes={{
                primary: clsx(classes.primary, "landing__list__primary-text"),
                secondary: clsx(
                  classes.secondary,
                  "landing__list__secondary-text"
                ),
              }}
            />
          </ListItem>
        </a>
      ) : (
        <ListItem
          button
          onClick={() => {
            localStorage.setItem("selected", item?.name);
            navigate(itemSlug);
          }}
          className={clsx(classes.listItem, "landing__group-item__list")}
          divider
        >
          <div
            className={clsx(
              classes.imageContainer,
              "landing__list__image-container"
            )}
          >
            {item.image ? (
              <img className={classes.image} src={item.image} alt="product" />
            ) : (
              <Placeholder className={classes.img} />
            )}
          </div>
          <ListItemText
            primary={
              item?.text?.primary
                ? item?.text.primary
                : "Add landing primary text in language config"
            }
            secondary={
              item?.text?.secondary
                ? item?.text.secondary
                : "Add landing secondary text in language config"
            }
            className={clsx(
              classes.listItemText,
              "landing__list__text-container"
            )}
            classes={{
              primary: clsx(classes.primary, "landing__list__primary-text"),
              secondary: clsx(
                classes.secondary,
                "landing__list__secondary-text"
              ),
            }}
          />
        </ListItem>
      )}
    </>
  );
};

export default List;
