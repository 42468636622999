import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ArticleFilter = ({ page, onChange }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));

  const [availableGroups, setAvailableGroups] = useState(
    page.productGroups.map((group) => group.name)
  );

  // Filter result based on availableGroups
  useEffect(() => {
    const filter = { ...page };

    // filter.productGroups becomes the opposite of availableGroups
    filter.productGroups = page.productGroups.filter(
      (group) => !availableGroups.includes(group.name)
    );

    // Call the callback-function onChange with or w/o filter
    if (filter.productGroups.length > 0) {
      onChange(filter);
    } else {
      onChange(page);
    }
  }, [availableGroups, page]);

  // After checkbox-change --> update availableGroups
  const handleChange = (e) => {
    const groupName = e.target.name;

    if (e.target.checked) {
      // If checkbox is now checked, remove the group from availableGroups
      setAvailableGroups((prev) => prev.filter((g) => g !== groupName));
    } else {
      // If checkbox is now unchecked, add the group back to availableGroups
      setAvailableGroups((prev) => [...prev, groupName]);
    }
  };

  const clearFilter = () => {
    // Set all checkboxes to unchecked using DOM manipulation
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((filter) => (filter.checked = false));

    // Reset availableGroups
    setAvailableGroups(page.productGroups.map((group) => group.name));
  };

  const translateProductGroup = (originalName) => {
    const translatedName = Object.entries(language.Categories).find(
      (category) => originalName === category[0]
    );

    return translatedName ? translatedName[1] : originalName;
  };

  return (
    <div>
      {config.pages.find((p) => p.name === page.name)
        .showProductGroupFilter && (
        <div className="filter-container">
          {page.productGroups.map((group, key) => {
            return (
              <label
                htmlFor={key}
                key={key}
                className={`filter-input ${
                  availableGroups.includes(group.name)
                    ? "filter-input-unmarked"
                    : "filter-input-marked"
                }`}
              >
                <input
                  type="checkbox"
                  id={key}
                  name={group.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {translateProductGroup(group.name)}
              </label>
            );
          })}
        </div>
      )}
      <div className="clear-filter">
        <button className="clear-filter-btn" onClick={() => clearFilter()}>
          {language.Clear_Filter || "Clear filter"}
        </button>
      </div>
    </div>
  );
};

export default ArticleFilter;
