import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { getFormConfig } from "./util";
import initialValues from "../CustomerForm/Form/initialValues";
import validate from "../CustomerForm/Form/validate";
import CustomerForm from "../CustomerForm/Form";
import ArticleSelect from "./articleSelect";

const useStyles = makeStyles((theme) => ({
  container: {},
  appbar: {
    backgroundColor: theme.app.background[1],
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "var(--nav-active-color)",
  },
  addContainer: {
    marginTop: "8px",
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  noMarginLeft: {
    marginLeft: 0,
  },
  divider: {
    marginBottom: "8px",
  },
}));

const Personal = (props) => {
  const { product, photo, handleAdd, savePhoto, photoOpen, setPhotoOpen } =
    props;
  const classes = useStyles();
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [article, setArticle] = useState("");
  const formConfig = getFormConfig(config.formConfig, product);
  const photoIsRequired = formConfig.requiredFields.includes("photo");
  const noPhotoDisabled = photoIsRequired && !photo;
  const productGroupConfig = config.productGroupConfig.find(
    (c) => c.name === product.name
  );

  return (
    <Formik
      initialValues={initialValues(formConfig.visibleFields)}
      onSubmit={(values, actions) => {
        // console.log(values);
        handleAdd(
          productGroupConfig?.useGroupsAsItems ? article : product,
          values,
          actions
        );
      }}
      validate={(values) => validate(values, formConfig, language)}
    >
      {(props) => {
        const { isValid, dirty, submitForm } = props;
        const submitDisabled =
          !isValid ||
          noPhotoDisabled ||
          (productGroupConfig?.useGroupsAsItems && !article) ||
          !dirty;

        const articleSelectDisabled = !dirty || !isValid;
        // console.log(props);
        return (
          <div className={classNames.container}>
            <div>
              <Typography className={classes.viewContainer} component="div">
                <CustomerForm
                  language={language}
                  config={formConfig}
                  image={photo}
                  savePhoto={savePhoto}
                  photoOpen={photoOpen}
                  setPhotoOpen={setPhotoOpen}
                />
              </Typography>
            </div>
            <div className={classes.addContainer}>
              <Divider className={classes.divider} />
              {productGroupConfig?.useGroupsAsItems ? (
                <ArticleSelect
                  articles={product.articles}
                  onSelect={(e) => setArticle(e.target.value)}
                  value={article}
                  language={language}
                  useGroupsAsItems={productGroupConfig?.useGroupsAsItems}
                  group={product}
                  personal={true}
                  disabled={articleSelectDisabled}
                  submitForm={submitForm}
                  selectArticle={article}
                />
              ) : (
                <div
                  className={classNames(
                    classes.addButtonContainer,
                    !productGroupConfig?.useGroupsAsItems &&
                      classes.noMarginLeft,
                    "personal__addButtonContainer"
                  )}
                >
                  <Button
                    disabled={submitDisabled}
                    onClick={submitForm}
                    className={classNames(
                      classes.addButton,
                      "personal__addButton"
                    )}
                    color="primary"
                  >
                    {language?.Article_Add || "Köp"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Personal;
