import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "@reach/router";
import Button from "@material-ui/core/Button";
import { Context } from "../../context";
import SideMenu from "./sideMenu";
import ActivePerson from "./activePerson";

const useStyles = makeStyles((theme) => ({
  user: {
    position: "relative",
    minHeight: `${theme.height - 168}px`,
    height: "100%",
  },
  content: {
    display: (p) => (p.sideMenu ? "grid" : "block"),
    gridTemplateColumns: (p) => (p.sideMenu ? "200px 600px 200px" : null),
    "@media(max-width: 800px)": {
      gridTemplateColumns: (p) => (p.sideMenu ? "200px 1fr" : null),
    },
    "@media(max-width: 667px)": {
      gridTemplateColumns: (p) => p.sideMenu && null,
      display: (p) => p.sideMenu && "block",
    },
  },
  actions: {
    position: "sticky",
    top: "100%",
    display: "flex",
    "& button": { marginRight: "8px" },
    "& a": { marginRight: "8px" },
  },
}));

const User = ({ user: { whoami, ticketowners } }) => {
  const [activePerson, setActivePerson] = useState();
  const classes = useStyles({
    sideMenu: ticketowners && ticketowners.length > 1,
  });
  const navigate = useNavigate();
  const { handleLogout } = useContext(Context);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  useEffect(() => {
    if (!activePerson) {
      const person = ticketowners.find((o) => o.id === whoami.id);
      setActivePerson(person);
    }
  }, [whoami, ticketowners, activePerson, setActivePerson]);

  return (
    <div className={classes.user}>
      <div className={classes.content}>
        <SideMenu
          ticketOwners={ticketowners}
          activePerson={activePerson}
          setActivePerson={setActivePerson}
        />
        <ActivePerson activePerson={activePerson} />
      </div>
      <div className={classes.actions}>
        <Button onClick={() => navigate(-1)}>{language.Back}</Button>
        <Button component="a" href={config.membersUri}>
          Ändra uppgifter
        </Button>
        <Button onClick={handleLogout}>{language.Log_Off}</Button>
      </div>
    </div>
  );
};

export default User;
