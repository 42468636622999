import {
  Button,
  createStyles,
  Group,
  Image,
  SimpleGrid,
  Slider,
  Text,
} from "@mantine/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import Upload from "./icons/Upload";
import { useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../PhotoEditor/cropImage";
import { Context, TOAST } from "../../../context";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    marginBottom: 30,
    marginTop: "0",
  },
  dropzone: {
    borderWidth: 1,
    paddingBottom: 100,
  },
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },
  control: {
    position: "absolute",
    width: 250,
    left: "calc(50% - 125px)",
    bottom: 30,
  },
  cropperWrapper: {},
  sliderWrapper: {
    marginTop: "-1rem",
    position: "relative",
    width: "100%",
    span: {
      fontWeight: "500",
      paddinBottom: "0.5rem",
    },
  },
  slider: {},
  resetButton: {
    marginTop: "1rem",
  },
}));

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      (e) => {
        resolve(e.target.result);
      },
      false
    );
    reader.readAsDataURL(file);
  });
};

const PersonalImage = ({ photo, photoSet }) => {
  const [file, fileSet] = useState([]);
  const [fileUrl, fileUrlSet] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { dispatch } = useContext(Context);

  const { classes, theme } = useStyles();
  const openRef = useRef(null);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        fileUrl,
        croppedAreaPixels,
        rotation
      );
      photoSet(croppedImage);
      fileUrlSet(null);
      fileSet([]);
    } catch (e) {
      console.error(e);
    }
  };

  const resizeImage = (img) => {
    console.log("Selected image width: ", img.width);

    let canvas = document.createElement("canvas");
    let originalCtx = canvas.getContext("2d");
    originalCtx.drawImage(img, 0, 0);

    const MAX_WIDTH = 1920;
    const MAX_HEIGHT = 1080;

    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;

    let resizedCtx = canvas.getContext("2d");
    resizedCtx.drawImage(img, 0, 0, width, height);
    let dataUrl = canvas.toDataURL("image/png");

    console.log("Resized image width: ", width);

    return dataUrl;
  };

  const resizeAndSetImage = (img) => {
    let dataurl = resizeImage(img);
    fileUrlSet(dataurl);
  };

  const readImg = async () => {
    let img = document.createElement("img");
    let imgSrc = await readFile(file[0]);

    img.src = imgSrc;
    if (img.complete) {
      resizeAndSetImage(img);
    } else {
      img.addEventListener("load", () => resizeAndSetImage(img));
    }
  };
  useEffect(() => {
    if (file.length !== 0) {
      readImg();
    }
  }, [file]);

  const preview = () => {
    if (photo) {
      return <Image src={photo} alt={language.PersonalPhotoAltLabel} />;
    }
  };

  const openFile = (open) => {
    if (!photo && open) {
      open();
    } else {
      fileUrlSet(null);
      fileSet([]);
      photoSet(null);
      open();
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Dropzone
          openRef={openRef}
          onDrop={fileSet}
          className={classes.dropzone}
          radius="md"
          accept={IMAGE_MIME_TYPE}
          maxSize={5 * 1024 ** 2}
          maxFiles={1}
          onReject={() => {
            dispatch({
              type: TOAST,
              payload: {
                text: language.Form_Error_Photo,
                variant: "error",
              },
            });
          }}
        >
          {photo ? (
            preview()
          ) : (
            <div style={{ pointerEvents: "none" }}>
              <Group position="center">
                <Dropzone.Accept>
                  <Upload
                    size={50}
                    color={
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black
                    }
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <Upload
                    size={50}
                    color={
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black
                    }
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <Upload
                    size={50}
                    color={
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black
                    }
                    stroke={1.5}
                  />
                </Dropzone.Idle>
              </Group>

              <Text align="center" weight={700} size="lg" mt="xl">
                <Dropzone.Accept>{language.DropPhoto}</Dropzone.Accept>
                <Dropzone.Reject>{language.FileNotSupported}</Dropzone.Reject>
                <Dropzone.Idle>{language.UploadPhoto}</Dropzone.Idle>
              </Text>
              <Text align="center" size="sm" mt="xs" color="dimmed">
                {language.DragFile} {language.MaxSize}
              </Text>
            </div>
          )}
        </Dropzone>

        <Button
          className={`${classes.control}`}
          label={!photo ? language.Choose_Photo : language.ChangeImage}
          variant="filled"
          color="dark"
          size="md"
          radius="sm"
          onClick={() => openFile(openRef.current)}
        >
          {!photo ? language.Choose_Photo : language.ChangeImage}
        </Button>

        {fileUrl && (
          <>
            <div className={classes.cropperWrapper}>
              <Cropper
                image={fileUrl}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={() => setZoom(zoom)}
              />
            </div>
          </>
        )}
      </div>

      {fileUrl && (
        <div className={classes.sliderWrapper}>
          <span>Zoom</span>
          <Slider
            className={classes.slider}
            value={zoom.toFixed(1)}
            step={0.1}
            min={1}
            max={3}
            onChange={setZoom}
            size="lg"
            radius="xl"
            aria-label="Zoom"
            thumbLabel={`${language.ZoomWith} ${zoom.toFixed(1)}`}
          />
          <span>{language.Rotate}</span>
          <Slider
            className={classes.slider}
            value={rotation}
            step={1}
            min={-180}
            max={180}
            onChange={setRotation}
            size="lg"
            radius="xl"
            aria-label="Rotation"
            thumbLabel={`${language.RotateWith} ${rotation}`}
          />
          <SimpleGrid cols={3}>
            <Button
              variant="default"
              aria-label={language.ChangeImage}
              className={classes.resetButton}
              size="md"
              onClick={() => {
                fileSet([]);
                fileUrlSet(null);
              }}
            >
              {language.ChangeImage}
            </Button>
            <Button
              variant="default"
              className={classes.resetButton}
              aria-label={language.Reset}
              size="md"
              onClick={() => {
                setRotation(0);
                setZoom(1);
                setCrop({ x: 0, y: 0 });
              }}
            >
              {language.Reset}
            </Button>
            <Button
              variant="default"
              className={`${classes.resetButton} regularButton`}
              size="md"
              aria-label={language.Save}
              onClick={() => saveCroppedImage()}
            >
              {language.Save}
            </Button>
          </SimpleGrid>
        </div>
      )}
    </>
  );
};

export default PersonalImage;
