import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Article from "./article";

const useStyles = makeStyles((theme) => ({
  modification: {},
  barcodeInputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: "16px",
    alignItems: "baseline",
    "@media(max-width: 667px)": {
      gridTemplateColumns: "1fr",
    },
  },
  ticket: { marginTop: "16px" },
  addButton: {
    alignSelf: "flex-end",
    marginLeft: "16px",
  },
  disabledButton: {
    alignSelf: "flex-end",
    marginLeft: "16px",
    color: "rgba(0, 0, 0, 0.26) !important",
  },
  progressButton: {
    position: "absolute",
  },
  alert: {
    marginTop: "16px",
  },
}));

const TICKET_BY_BARCODE = gql`
  query ticketByBarcode($barcode: String, $posId: Int) {
    ticketByBarcode(barcode: $barcode, posId: $posId) {
      id
      applicableModifications {
        id
        name
        priceInclVat
        plu
      }
      article {
        id
      }
      validFrom
      validUntil
      owner {
        firstname
        lastname
      }
      uniqueIdentifier
      article {
        id
        name
      }
    }
  }
`;

const Modification = (props) => {
  const classes = useStyles();

  //Input field
  const [barcode, setBarcode] = useState("");

  //Loaded ticket
  const [ticket, setTicket] = useState();

  //Prevent double searches
  const [canSearchAgain, setCanSearchAgain] = useState(true);

  const { language, config } = useSelector((state) => ({
    language: state.language,
    config: state.config,
  }));
  const { posId } = config;
  const [getTicket, { loading, error, data }] = useLazyQuery(
    TICKET_BY_BARCODE,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const onAdded = () => {
    setTicket();
    setBarcode("");
  };

  const executeSearch = () => {
    setCanSearchAgain(false);
    setTicket();
    getTicket({
      variables: { barcode, posId },
    });
  };

  useEffect(() => {
    setCanSearchAgain(true);
  }, [barcode]);

  useEffect(() => {
    if (loading === false && error !== true) {
      //API has result (non error)
      if (data?.ticketByBarcode?.length > 0) {
        //Ticket found
        setTicket(data.ticketByBarcode[0]);
      } else {
        //Ticket not found
        if (barcode !== undefined && barcode.length > 0) {
          setTicket(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className={classes.modification}>
      <div className={classes.barcodeInputContainer}>
        <TextField
          label="streckkod"
          placeholder="Ange streckkod"
          variant="filled"
          value={barcode}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setBarcode(e.target.value)}
          fullWidth
          type="search"
        />
        <Button
          onClick={executeSearch}
          disabled={barcode.length < 2 || loading || !canSearchAgain}
          className={
            barcode.length < 2 || loading || !canSearchAgain
              ? classes.disabledButton
              : classes.addButton
          }
          color="primary"
        >
          {language?.Fetch_Ticket || "Hämta biljett"}
          {loading && (
            <CircularProgress size={24} className={classes.progressButton} />
          )}
        </Button>
      </div>

      {error && (
        <Alert severity="warning" className={classes.alert}>
          Ett fel uppstod vid sökning efter biljett med den aktuella
          streckkoden.
        </Alert>
      )}

      {!error && ticket === false && (
        <Alert severity="warning" className={classes.alert}>
          Hittade ingen biljett med den aktuella streckkoden.
        </Alert>
      )}

      {ticket && (
        <div className={classes.ticket}>
          <Typography variant="h6">{ticket.article.name}</Typography>
          {ticket.owner && (
            <Typography>{`${ticket.owner.firstname} ${ticket.owner.lastname}`}</Typography>
          )}
          {(ticket.validFrom || ticket.validUntil) && (
            <Typography>
              {ticket.validFrom && moment(ticket.validFrom).format("ll")} -{" "}
              {ticket.validUntil && moment(ticket.validUntil).format("ll")}
            </Typography>
          )}
          {ticket.applicableModifications.length < 1 && (
            <Alert severity="warning" className={classes.alert}>
              Biljetten går inte att förlänga just nu, applicerbar
              förlängningsartikel saknas.
            </Alert>
          )}
          <List>
            {ticket.applicableModifications.map((a) => (
              <Article
                key={a.id}
                article={a}
                ticket={ticket}
                onAdded={() => onAdded()}
              />
            ))}
          </List>
        </div>
      )}
    </div>
  );
};

export default Modification;
