import { Button, Container, createStyles } from "@mantine/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import Extension from "./Extension";
import GroupProduct from "./GroupProduct";
import PersonalTicket from "./PersonalTicket";
import SingleProduct from "./SingleProduct";
import { navigate } from "@reach/router";

const useStyles = createStyles((theme) => ({
  container: {
    marginTop: "-0rem",
    "@media screen and (max-width: 768px)": {
      marginTop: 0,
    },
  },
  button: {
    border: "1px solid #8A8C93",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    paddingTop: 0,
    paddingBottom: ".5rem",
  },
}));
const ProductV2 = ({ product }) => {
  const [description, descriptionSet] = useState(null);
  const { language, culture } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const { classes } = useStyles();

  useEffect(() => {
    if (product.description !== undefined) {
      axios
        .get(`${process.env.PUBLIC_URL}/${product.description}.${culture}.md`)
        .then((res) => descriptionSet(res.data))
        .catch((err) => console.log(err));
    }
    return () => descriptionSet(null);
  }, [product]);

  return (
    <div className={classes.container}>
      <Container className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          variant="default"
          mb={2}
          onClick={() => navigate(-1)}
        >
          {language.Back}
        </Button>
      </Container>
      {product?.articles || product?.openPriceValues?.length > 0 ? (
        <GroupProduct product={product} description={description} />
      ) : !product.isPersonalTicket && !product.isModification ? (
        <SingleProduct product={product} description={description} />
      ) : product.isModification ? (
        <Extension product={product} />
      ) : (
        <PersonalTicket product={product} />
      )}
    </div>
  );
};

export default ProductV2;
