import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Article from "./article";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles(
  (theme) =>
    console.log(theme) || {
      container: {},
      timeContainer: {
        width: "320px",
        margin: "0 auto",
      },
      timePickerContainer: {
        width: "320px",
        margin: "0 auto",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridGap: "1px",
        boxShadow: theme.shadows[1],
        backgroundColor: "#e5e5e5",
        marginBottom: "24px",
      },
      timePickerItem: {
        backgroundColor: theme.app.background[1],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50px",
        cursor: "pointer",
        transition: theme.transitions.create(["background-color"], {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeInOut,
        }),
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:focus": {
          border: "3px solid black",
          borderRadius: "3px",
        },
      },
      selected: {
        backgroundColor: "black",
        color: "white",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      },
      disabled: {
        pointerEvents: "none",
        color: "black",
        opacity: "0.6",
      },
      ticketsContainer: {},
      ticketsHeading: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridGap: "16px",
        alignItems: "baseline",
      },
      headingInfo: { textAlign: "right" },
      remaining: {
        fontSize: "0.625rem",
        color: "inherit",
      },
      remainingSelected: { color: "white" },
    }
);

const TimePicker = (props) => {
  const { occasions, eventImage } = props;
  const classes = useStyles();
  const { language, config } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [occasion, setOccasion] = useState();
  const [filteredTickets, setFilteredTickets] = useState();
  useEffect(() => {
    if (occasion) {
      setFilteredTickets(null);
      const filteredOccasion = occasions.find((o) => o.time === occasion.time);
      if (filteredOccasion) {
        const articles = filteredOccasion.articles.sort(
          (a, b) => a.plu - b.plu
        );
        setFilteredTickets(articles);
      }
    }
  }, [occasion, occasions]);

  const getRemainingText = (remaining) => {
    if (remaining < 1) return language.Full;
    if (remaining < 2)
      return `${language.ApproximatelyShort} ${remaining} ${language.Spot}`;
    return `${language.ApproximatelyShort} ${remaining} ${language.Spots}`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.timeContainer}>
        <Typography>{language.Choose_Time}</Typography>
      </div>
      <div className={classes.timePickerContainer}>
        {occasions.map((o, i) => {
          const selected = occasion ? occasion.time === o.time : false;
          const disabled = o.remaining < 1;
          return (
            <ButtonBase
              className={classNames(
                classes.timePickerItem,
                selected && classes.selected,
                disabled && classes.disabled,
                disabled && "timepicker-disabled"
              )}
              key={i}
              onClick={() => setOccasion(o)}
              disabled={disabled}
            >
              <div>
                <Typography color="inherit">
                  {moment(o.time).format("HH:mm")}
                </Typography>
                {config.showRemaining && (
                  <Typography
                    className={classNames(
                      classes.remaining,
                      selected && classes.remainingSelected
                    )}
                  >
                    {getRemainingText(o.remaining)}
                  </Typography>
                )}
              </div>
            </ButtonBase>
          );
        })}
      </div>
      {filteredTickets ? (
        <div className={classes.ticketsContainer}>
          <div className={classes.ticketsHeading}>
            <Typography
              variant="h6"
              className={classNames(
                classes.heading,
                "product-detail__articles__heading"
              )}
            >
              {language.Tickets}
            </Typography>
            <Typography
              className={classNames(
                classes.headingInfo,
                "product-detail__articles__heading-info"
              )}
            >
              {moment(occasion.time).format("llll")}
            </Typography>
          </div>
          <div>
            {filteredTickets.map((a) => (
              <Article
                key={a.id}
                a={a}
                occasion={occasion}
                eventImage={eventImage}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={classes.ticketsHeading}>
          <Typography
            variant="h6"
            className={classNames(
              classes.heading,
              "product-detail__articles__heading"
            )}
          >
            {language.Tickets}
          </Typography>
          <Typography
            className={classNames(
              classes.headingInfo,
              "product-detail__articles__heading-info"
            )}
          >
            {language.Choose_Time}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
