import React from "react";
import { createStyles, NumberInput } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: "1rem !important",
  },
  description: {
    fontSize: "1rem",
    marginTop: "-0.35rem",
    paddingBottom: ".5rem",
    color: "black",
  },
}));

const NumberField = ({
  form,
  index,
  placeholder,
  label,
  description,
  required,
  min,
  max,
}) => {
  const { classes } = useStyles();
  return (
    <NumberInput
      classNames={{ label: classes.label, description: classes.description }}
      // placeholder={`${description} (${min}-${max})`}
      description={`${description} (${min}-${max})`}
      label={label}
      withAsterisk={required}
      style={{ width: "100%" }}
      min={min}
      max={max}
      {...form.getInputProps(`formFields.${index}.intVal`)}
    />
  );
};

export default NumberField;
