import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import Cropper from "react-easy-crop";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import ResetIcon from "@material-ui/icons/SettingsBackupRestore";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import getCroppedImg from "./cropImage";

const useStyles = makeStyles((theme) => ({
  editor: {},
  selectButton: {
    "& svg": {
      marginRight: "8px",
    },
  },
  cropper: {
    position: "relative",
    maxWidth: "100%",
    height: (p) => (p.preview ? "auto" : "300px"),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  controls: {},
  controlLabel: {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: theme.app.labelColor,
    textTransform: "uppercase",
  },
  controlValue: {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: theme.app.secondaryColor,
    textAlign: "center",
  },
  sliderContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 50px auto",
    gridGap: "8px",
    alignItems: "center",
    marginBottom: "16px",
  },
  resetButton: {
    borderRadius: "50%",
    color: theme.app.labelColor,
    "&:disabled": { opacity: 0.5 },
  },
  previewImage: {
    width: "360px",
    display: "block",
    margin: "8px auto",
    "@media(max-width: 500px)": {
      width: "320px",
    },
  },
}));

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      (e) => {
        resolve(e.target.result);
      },
      false
    );
    reader.readAsDataURL(file);
  });
};

const PhotoEditor = ({ savePhoto, preview }) => {
  const classes = useStyles({ preview });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      savePhoto(croppedImage);
      setImage(null);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image, savePhoto]);

  const photoSelectRef = React.createRef();
  const openPhotoSelect = (node) => {
    return node.click();
  };

  const resizeImage = (img) => {
    console.log("Selected image width: ", img.width);

    let canvas = document.createElement("canvas");
    let originalCtx = canvas.getContext("2d");
    originalCtx.drawImage(img, 0, 0);

    const MAX_WIDTH = 1920;
    const MAX_HEIGHT = 1080;

    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;

    let resizedCtx = canvas.getContext("2d");
    resizedCtx.drawImage(img, 0, 0, width, height);
    let dataUrl = canvas.toDataURL("image/png");

    console.log("Resized image width: ", width);

    return dataUrl;
  };

  const resizeAndSetImage = (img) => {
    let dataurl = resizeImage(img);
    setImage(dataurl);
  };

  const handleFileSelect = async (e) => {
    if (!e.target.files[0]) return;

    let img = document.createElement("img");
    let imgSrc = await readFile(e.target.files[0]);

    img.src = imgSrc;
    if (img.complete) {
      resizeAndSetImage(img);
    } else {
      img.addEventListener("load", () => resizeAndSetImage(img));
    }

    return;
  };

  return (
    <>
      {image || preview ? (
        <div className={classes.editor}>
          <div className={classes.cropper}>
            {preview ? (
              <div>
                <img
                  src={preview}
                  alt="preview"
                  className={classes.previewImage}
                />
              </div>
            ) : (
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>
          <div className={classes.actions}>
            <Button
              onClick={() => {
                openPhotoSelect(photoSelectRef.current);
                savePhoto("");
              }}
            >
              Välj Nytt foto
            </Button>
            {!preview && (
              <Button color="primary" onClick={saveCroppedImage}>
                Spara foto
              </Button>
            )}
          </div>
          {!preview && (
            <div className={classes.controls}>
              <Typography className={classes.controlLabel}>Zoom</Typography>
              <div className={classes.sliderContainer}>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
                <Typography className={classes.controlValue}>
                  {Number(zoom).toFixed(1)}
                </Typography>
                <ButtonBase
                  title="Reset"
                  className={classes.resetButton}
                  onClick={() => setZoom(1)}
                  disabled={zoom === 1}
                >
                  <ResetIcon />
                </ButtonBase>
              </div>
              <Typography className={classes.controlLabel}>Rotation</Typography>
              <div className={classes.sliderContainer}>
                <Slider
                  value={rotation}
                  min={-180}
                  max={180}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                />
                <Typography className={classes.controlValue}>
                  {rotation}
                </Typography>
                <ButtonBase
                  title="Reset"
                  className={classes.resetButton}
                  onClick={() => setRotation(0)}
                  disabled={rotation === 0}
                >
                  <ResetIcon />
                </ButtonBase>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Button
          onClick={() => openPhotoSelect(photoSelectRef.current)}
          className={classes.selectButton}
          variant="outlined"
        >
          <PhotoIcon />
          Välj Foto
        </Button>
      )}
      <input
        ref={photoSelectRef}
        style={{ display: "none" }}
        type="file"
        name="new-photo"
        onChange={handleFileSelect}
      />
    </>
  );
};

export default PhotoEditor;
