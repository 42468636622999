import React from "react";
import HeaderLayout from "../Styles/HeaderLayout";
import HeaderContent from "../Styles/HeaderContent";
import MobileMenu from "./mobileMenu";
import Logo from "./logo";
import Login from "./login";
import Cart from "./cart";
import CountdownContainer from "./CountdownContainer";
import Settings from "../Settings";
import SearchV2 from "../common/Search/SearchV2";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import HeaderV2 from "./HeaderV2/HeaderV2";

const Header = (props) => {
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  return (
    <>
      <a href="#main-page-content" id="main-content-link">
        {language.SkipToMainContent}
      </a>
      {config?.useNewHeader ? (
        <HeaderV2 config={config} language={language} culture={culture} />
      ) : (
        <HeaderLayout>
          <MobileMenu />
          <Logo />
          <HeaderContent>
            {/* <Search /> */}
            <SearchV2 />
            <Settings />
            <Cart />
            <CountdownContainer />
            <Login />
          </HeaderContent>
        </HeaderLayout>
      )}
    </>
  );
};

export default Header;
