import React from "react";
import SearchV2 from "../../common/Search/SearchV2";
import Settings from "../../Settings";
import Cart from "../cart";
import CartV2 from "../CartV2";
import CountdownContainer from "../CountdownContainer";
import Login from "../login";
import LogoV2 from "./LogoV2";
import MobileMenuV2 from "./MobileMenuV2";
import NavContentV2 from "./NavContentV2";
import "./styles.css";

const NavV2 = React.memo(({ changeBackground, config, language, culture }) => {
  const headerStyling = () => {
    if (config?.useFixedHeader) {
      return "headerFixed";
    } else {
      return "headerDefault";
    }
  };
  const headerHeight = () => {
    if (config?.headerHeight) {
      return config?.headerHeight + "px";
    } else {
      return "70px";
    }
  };

  return (
    <>
      {config?.useTransparentHeader ? (
        <nav
          className={`navV2 ${headerStyling()} headerTransparent`}
          data-isactive={changeBackground}
          style={{
            backgroundColor: changeBackground
              ? "var(--header-background)"
              : "rgb(0,0,0,0)",
            color: changeBackground
              ? "var(--header-color)"
              : "var(--header-transparent-color)",
            maxHeight: headerHeight(),
            height: headerHeight(),
          }}
        >
          <div
            className="navMenuWrapper"
            style={{ maxHeight: headerHeight(), height: headerHeight() }}
          >
            <div
              className="navLeftColumn"
              style={{ maxHeight: headerHeight(), height: headerHeight() }}
            >
              <LogoV2 changeBackground={changeBackground} />
            </div>

            <div className="navMiddleColumnWrapper">
              <div
                className="navMiddleColumn"
                style={{ maxHeight: headerHeight(), height: headerHeight() }}
              >
                <NavContentV2
                  config={config}
                  language={language}
                  culture={culture}
                  changeBackground={changeBackground}
                />
              </div>
            </div>
            <div
              className="navRightColumn"
              style={{ maxHeight: headerHeight(), height: headerHeight() }}
            >
              <SearchV2 changeBackground={changeBackground} />
              <Settings />
              {config?.useCartDrawer ? (
                <CartV2 changeBackground={changeBackground} />
              ) : (
                <Cart />
              )}

              <CountdownContainer changeBackground={changeBackground} />
              {config?.showLogin ? (
                <Login changeBackground={changeBackground} />
              ) : (
                ""
              )}
            </div>
          </div>
          <MobileMenuV2
            config={config}
            language={language}
            culture={culture}
            changeBackground={changeBackground}
          />
        </nav>
      ) : (
        <nav
          className={`navV2 ${headerStyling()}`}
          style={{
            backgroundColor: "var(--header-background)",
            color: "var(--header-color)",
            maxHeight: headerHeight(),
          }}
        >
          <div
            className="navMenuWrapper"
            style={{ maxHeight: headerHeight(), height: headerHeight() }}
          >
            {/* <Logo /> */}
            <div
              className="navLeftColumn"
              style={{ maxHeight: headerHeight(), height: headerHeight() }}
            >
              <LogoV2 changeBackground={changeBackground} />
            </div>

            <div className="navMiddleColumnWrapper">
              <div
                className="navMiddleColumn"
                style={{ maxHeight: headerHeight(), height: headerHeight() }}
              >
                <NavContentV2
                  config={config}
                  language={language}
                  culture={culture}
                  changeBackground={changeBackground}
                />
              </div>
            </div>

            <div
              className="navRightColumn"
              style={{ height: headerHeight(), maxHeight: headerHeight() }}
            >
              <SearchV2 changeBackground={changeBackground} />
              <Settings />
              {config?.useCartDrawer ? (
                <CartV2 changeBackground={changeBackground} />
              ) : (
                <Cart />
              )}
              <CountdownContainer changeBackground={changeBackground} />
              {config?.showLogin ? (
                <Login changeBackground={changeBackground} />
              ) : (
                ""
              )}
            </div>
          </div>
          <MobileMenuV2
            changeBackground={changeBackground}
            config={config}
            language={language}
            culture={culture}
          />
        </nav>
      )}
    </>
  );
});

export default NavV2;
