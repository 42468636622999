import {
  clearSaleId,
  setSaleId,
  STATE_CULTURE,
  STATE_CURRENCY,
  STATE_SALE_ID,
} from "./state";
import uuid from "uuid";

const callApi = (command, method, payload, message, then, cb) => {
  return {
    type: "@EE/API_CALL",
    method,
    command,
    payload,
    message,
    then,
    cb,
  };
};

const saleState = (id, isActive) => {
  return {
    type: "@EE/SALE_STATE",
    id,
    isActive,
  };
};

export const saleMiddleware =
  ({ apiUri, posId }) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === "@EE/API_CALL" && !action.promise) {
      const state = getState();
      const sale = state.sale;
      const language = state.language;
      const id = sale.id || state.cookies[STATE_SALE_ID] || uuid.v1();
      const uri = `${apiUri}/sales/${id}/${action.command}`;
      const isFormData = action.payload instanceof FormData;
      var headers = new Headers();
      var user = state.oidc.user;
      console.log(uri);

      if (user && user.access_token) {
        headers.append("Authorization", "Bearer " + user.access_token);
      }

      if (!isFormData) {
        headers.set("Content-Type", "application/json; charset=utf-8");
      }

      const execute = () => {
        return fetch(uri, {
          method: action.method,
          body: isFormData
            ? action.payload
            : action.payload
            ? JSON.stringify(action.payload)
            : null,
          headers: headers,
        }).then((response) => {
          if (response.status === 406) {
            response.text().then((msg) => {
              action.cb(response.status, language[msg] || msg);
              if (msg === "Sale is cancelled") {
                console.log("Sale has been cancelled - forcing reload");
                window.location.reload();
              }
            });
          } else if (response.status >= 400) {
            response.text().then((msg) => {
              action.cb(response.status, language[msg] || msg);
            });
          } else {
            action.cb && action.cb(response.status, "ok");
          }

          return response;
        });
      };

      const promise = sale.isActive
        ? execute()
        : fetch(`${apiUri}/pos/${posId}/sales/${id}`, {
            method: "POST",
          }).then((response) => {
            if (response.status !== 201 && response.status !== 200) {
              return new Promise((resolve, reject) =>
                reject("Could not create or activate sale!")
              );
            } else {
              dispatch(setSaleId(id));
              dispatch(saleState(id, true));
              return execute();
            }
          });

      dispatch({
        type: action.type,
        promise,
        then: action.then,
      });
    } else {
      next(action);
    }
  };

export const validateSaleId = (api, reset) => (dispatch, getState) => {
  //Ensures current saleid is an open sale, otherwise clears from cookies
  var id = getState().cookies[STATE_SALE_ID];
  if (!id) {
    return;
  }

  const promise = fetch(`${api}/sales/${id}`).then((response) => {
    if (response.status === 410) {
      dispatch(clearSaleId());
      reset();
    }
  });

  return {
    type: "@EE/VALIDATE_SALE_ID",
    promise,
  };
};

export const add = (model, cb) => {
  return callApi("articles", "POST", model, "Article_Added", null, cb);
};

export const addBatch = (model, cb) => {
  return callApi("articles/batch", "POST", model, "Article_Added", null, cb);
};

export const addArticle = (saleData, then) => {
  return (dispatch) => dispatch(add(saleData, then));
};

export const addArticleBatch = (saleData, then) => {
  return (dispatch) => dispatch(addBatch(saleData, then));
};

export const addAllotment = (saleData, then) => {
  return (dispatch) => {
    dispatch(add(saleData, then));
  };
};

export const addMod = (model, cb) => {
  return callApi("modifications", "POST", model, "Article_Added", null, cb);
};

export const addModification = (model, cb) => (dispatch) =>
  dispatch(addMod(model, cb));

export const addFunds = (mod, cb) => {
  return (dispatch) => dispatch(addMod(mod, cb));
};

export const setQuantity = (key, quantity) => {
  return callApi(`articles/${key}/quantity/${quantity}`, "PUT");
};

export const removeArticle = (key, cb) => {
  return callApi("articles/" + key, "DELETE", null, "Article_Removed", cb);
};

export const discount = (id, info) => {
  return callApi("discount", "POST", {
    id,
    info,
  });
};

export const increaseQuantity = (key, cb) => {
  return callApi(
    `articles/${key}/increasequantity`,
    "POST",
    null,
    null,
    null,
    cb
  );
};

export const decreaseQuantity = (key, cb) => {
  return callApi(
    `articles/${key}/decreasequantity`,
    "POST",
    null,
    null,
    null,
    cb
  );
};

export const checkout = () => (dispatch, getState) => {
  var state = getState();
  var payload = {
    currency: state.cookies[STATE_CURRENCY],
    culture: state.cookies[STATE_CULTURE],
  };

  dispatch(
    callApi("checkout", "POST", payload, null, (r) => {
      if (r.status === 202) {
        const url = r.headers.get("Location");
        console.log("url", url);
        window.location.assign(url);
      }
    })
  );
};

const nextVersion = () => {
  return new Date().getTime();
};

export const saleReducer = (state = { version: nextVersion() }, action) => {
  switch (action.type) {
    case "@EE/API_CALL": {
      var s = {
        ...state,
        version:
          action.readyState === "success" ? nextVersion() : state.version,
      };

      return s;
    }
    case "@EE/SALE_STATE": {
      return {
        id: action.id,
        isActive: action.isActive,
        isError: action.isError,
        error: action.error,
        version: nextVersion(),
      };
    }

    default:
      return state;
  }
};

export const addToCartTracking = (currency, products) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currencyCode: currency,
        add: {
          products: products,
        },
      },
    });
  }
};

export const removeFromCartTracking = (currency, products, totalPrice) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency,
        value: totalPrice,
        items: products,
      },
    });
  }
};
