import React, { useEffect, useMemo, useState } from "react";
import { Calendar } from "@mantine/dates";
import { createStyles, Loader, Text, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mantine/hooks";
import "../styles.css";
import "dayjs/locale/sv";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/da";
import {
  addYears,
  endOfMonth,
  format,
  getDate,
  isSameMonth,
  startOfMonth,
} from "date-fns";
import { STATE_CULTURE } from "../../../common/state";

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
    marginBottom: "1rem",
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "3.9rem",
    bottom: 0,
    margin: "auto",
    zIndex: 1,
  },
  calendarWrapper: {
    position: "relative",
  },
  alertWrapper: {
    display: "flex",
    marginTop: "10rem",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    span: {
      marginBottom: "9rem",
    },
  },

  titleWrapper: {
    marginBottom: "1rem",
  },
  calendar: {
    backgroundColor: "white",
    borderRadius: "6px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
  calendarBase: {
    // width: "fit-content",
    padding: "1rem",
    maxWidth: "100%",
  },
  center: {
    textAlign: "center",
  },
}));
const DateChooser = ({
  initialData,
  initialLoading,
  occasions,
  form,
  calendarDatesSet,
}) => {
  const { language, culture } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const [value, valueSet] = useState(null);
  const [month, onMonthChange] = useState(new Date());
  const matches = useMediaQuery("(max-width: 768px)");

  const { classes } = useStyles();

  const exclude = (date) => {
    const check =
      occasions &&
      occasions.some(
        (el) =>
          isSameMonth(date, new Date(el)) &&
          date.getDate() === getDate(new Date(el))
      );
    if (!check) return true;
  };

  useEffect(() => {
    if (value) {
      form.setFieldValue("occasion", format(value, "yyyy-MM-dd"));
    }
  }, [value]);

  useEffect(() => {
    if (form.values.occasion) {
      valueSet(new Date(form.values.occasion));
    }
    calendarDatesSet({
      from: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      to: format(addYears(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
    });
  }, []);

  useEffect(() => {
    calendarDatesSet({
      from: startOfMonth(month),
      to: endOfMonth(month),
    });
  }, [month]);

  const getLastOccasion = useMemo(() => {
    return (
      initialData &&
      initialData?.publicBookingOccasions.sort(
        (a, b) => new Date(a) - new Date(b)
      )
    );
  }, [initialData]);

  useEffect(() => {
    if (occasions && occasions.length > 0 && initialData && !value) {
      onMonthChange(new Date(initialData?.publicBookingOccasions[0]));
    }

    if (occasions && occasions.length === 0 && initialData && !value) {
      onMonthChange(new Date(initialData?.publicBookingOccasions[0]));
    }

    if (value) {
      onMonthChange(value);
    }
  }, [initialData, value]);

  return (
    <div className={classes.container} style={{ width: matches ? "100%" : "" }}>
      {initialLoading && <Loader variant="dots" className={classes.loader} />}

      <div className={classes.titleWrapper}>
        <Title order={3} sx={{ fontSize: "1rem", fontWeight: 500 }}>
          {language.Date}
        </Title>
        <Text>{language.ChooseDateToContinue}</Text>
      </div>
      <div className={classes.calendarWrapper}>
        <Calendar
          className={`${classes.calendar} pbCalendar`}
          classNames={{
            calendarBase: classes.calendarBase,
            calendarCell: classes.center,
          }}
          size={"md"}
          value={value}
          onChange={valueSet}
          fullWidth
          locale={culture.split("-")[0]}
          excludeDate={(date) => exclude(date)}
          minDate={new Date(getLastOccasion?.[0])}
          maxDate={new Date(getLastOccasion?.[getLastOccasion.length - 1])}
          month={month}
          onMonthChange={onMonthChange}
          hideOutsideDates
          style={{ opacity: initialLoading && 0.5 }}
        />

        {!initialLoading && occasions && occasions.length === 0 && (
          <div className={classes.alertWrapper}>
            <Text component="span" role="alert">
              {language.NoOccasionsAvailable}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateChooser;
