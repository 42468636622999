import React from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";

const SubmitButton = (props) => {
  const useStyles = makeStyles({
    submitBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      minWidth: "11rem",
      // width: "100%",
      padding: " 0 2rem",
      height: "3.5rem",
      borderRadius: "10px",
      fontSize: "1rem",
      fontWeight: "600",
      cursor: "pointer",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",

      transition: "all 0.2s ease",
      "&:hover": {
        transform: "translateY(-1px)",
      },
    },
  });
  const classes = useStyles(props);

  return (
    <div>
      <button
        type="submit"
        className={`${classes.submitBtn} submitButton ${props.disabled ? "disabled" : ""}`}
        aria-label={props.language?.SubmitBtn}
      >
        {props.loading === true ? (
          <CircularProgress
            className="loadingspinner"
            style={{ color: "rgb(255, 255, 255)", transform: "scale(0.8)" }}
          />
        ) : (
          props.primaryText
        )}
      </button>
    </div>
  );
};

export default SubmitButton;
