import React, { useEffect } from "react";

const useClickDetector = (ref, open, setOpen) => {
  useEffect(() => {
    const handleClick = (event) => {
      if(ref?.current && ref?.current.contains(event.target)) {
          if(open) {
            setOpen(false)
          }
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, open]);
};

export default useClickDetector;
