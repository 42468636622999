import React, { useContext } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ReactGA from "react-ga";
import Button from "@material-ui/core/Button";
import {
  STATE_CULTURE,
  STATE_CURRENCY,
  STATE_SALE_ID,
} from "../../common/state";
import { SaleContext } from "../common/saleProvider";
import {
  checkout,
  decreaseQuantity,
  increaseQuantity,
  removeArticle,
} from "../../common/sale";
import CartItemList from "./cartItemList";
import { pixelCheckoutData, isItReal } from "../../common/util";
import { useLocation } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  cart: {
    //minHeight: `${theme.height - 180}px`,
    minHeight: "10px",
    maxWidth: "1000px",
    width: "100%",
    margin: "0 auto",
    borderRadius: "4px",
    position: "relative",
  },
  actions: {
    height: "60px",
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: "16px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    position: "sticky",
    top: "100%",
  },
  checkoutButton: { display: "flex", justifyContent: "flex-end" },
}));

const Cart = (props) => {
  const classes = useStyles();
  const { state } = useContext(SaleContext);
  const { sale } = state;
  const rdxDispatch = useDispatch();
  const increase = (key, then) => rdxDispatch(increaseQuantity(key, then));
  const decrease = (key, then) => rdxDispatch(decreaseQuantity(key, then));
  const remove = (key) => rdxDispatch(removeArticle(key));
  const { language, config, locale, culture, currency, saleId } = useSelector(
    (state) => ({
      language: state.language,
      config: state.config,
      locale: state.cookies[STATE_CULTURE].substring(0, 2).toLowerCase(),
      culture: state.cookies[STATE_CULTURE],
      currency: state.cookies[STATE_CURRENCY],
      saleId: state.cookies[STATE_SALE_ID],
    })
  );

  let location = useLocation()

  const goToCheckout = () => {
    if (sale) {
      sale.items.map((item) => {
        const { name, plu } = item.article;
        const gaItem = {
          name,
          sku: plu,
          price: item.pricePerPiece.amountInclVat,
          quantity: item.quantity,
          currency,
          saleId,
        };
        ReactGA.plugin.require("ecommerce");
        ReactGA.plugin.execute("ecommerce", "addItem", gaItem);
        return ReactGA.plugin.execute("ecommerce", "send");
      });
      if (config.facebookPixelId && isItReal("pixel", config.facebookPixelId)) {
        pixelCheckoutData(sale.items, currency);
      }
    }
    return rdxDispatch(checkout());
  };
  return (
    <div className={classNames(classes.cart, "cart__page")}>
      <Typography variant="h4">{language.Cart}</Typography>
      {sale ? (
        <CartItemList
          sale={sale}
          remove={remove}
          increase={increase}
          decrease={decrease}
          locale={locale}
          culture={culture}
          language={language}
          config={config}
        />
      ) : (
        <div />
      )}
      <div className={classes.actions}>
        <div>
          <Button
            onClick={() => window.history.back()}
            id="goBackFromCartButton"
          >
            {language?.Back_To_List || "Tillbaka"}
          </Button>
        </div>
        <div className={classes.checkoutButton}>
          <Button
            disabled={sale && sale.itemQuantity < 1}
            color="primary"
            id="goToCheckoutButton"
            onClick={goToCheckout}
          >
            {language?.GoToCheckout || "Till Betalning"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
