import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  createStyles,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import slugify from "../common/slugify";
import { getSaleData } from "../Product/util";
import { addArticle, addToCartTracking } from "../../common/sale";
import { DataContext } from "../common/dataProvider";
import { SaleContext } from "../common/saleProvider";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { getProduct } from "../../Pages/ProductPage/util";
import "./styles.css";

const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: "none",
    position: "relative",
    padding: "0rem",
    width: "100%",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    cursor: "pointer",
    transition: "0.2s",
    "&:hover": {
      // opacity: 0.9,
      //   transform: "translateY(-2px)",
    },
  },

  imageButton: {
    transition: "0.2s",
    borderRadius: "8px",
    "&:hover": {
      opacity: 0.9,
    },
  },

  rating: {
    position: "absolute",
    top: theme.spacing.xs,
    right: theme.spacing.xs + 2,
    pointerEvents: "none",
  },

  title: {
    display: "block",
    marginTop: theme.spacing.sm,
    marginBottom: 0,
    fontSize: "1rem",
    textAlign: "left",
  },

  action: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    }),
  },
  price: {
    marginTop: 0,
    fontSize: "1rem",
    "@media screen and (max-width: 768px)": {
      fontWeight: "bold",
      fontSize: "1rem",
      width: "100%",
      textAlign: "start",
    },
  },
  footer: {
    cursor: "default",
    marginTop: theme.spacing.md,
    button: {
      "@media screen and (max-width: 768px)": {
        width: "100%",
      },
    },
  },
  skeleton: {
    width: "100%",
    padding: "1rem 0.7rem",
  },
  addToCart: {
    width: "100%",
  },
}));

const CardItemV2 = ({ product }) => {
  const { config, language, culture, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));
  const [addedToCart, addedToCartSet] = useState(null);
  const [clicked, clickedSet] = useState(false);
  const [price, priceSet] = useState(null);
  const { classes, theme, cx } = useStyles();
  const navigate = useNavigate();
  const { data } = useContext(DataContext);
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;

  const rdxDispatch = useDispatch();
  const regex = /\D/g;

  const reduce =
    product.products &&
    product.products.reduce((acc, curr) => {
      acc.push(curr.priceInclVat);
      return acc;
    }, []);
  const reduceChooseValue =
    product.openPriceValues &&
    product.openPriceValues.reduce((acc, curr) => {
      acc.push(curr);
      return acc;
    }, []);

  const sortPrice = (prices) => {
    if (prices && typeof prices?.[0] === typeof 0) {
      const sortOpenPrice = prices.sort((a, b) => a - b);
      return sortOpenPrice;
    } else if (prices && typeof prices?.[0] === typeof "") {
      const sortStringValue = prices.sort(
        (a, b) => a.replace(regex, "") - b.replace(regex, "")
      );
      return sortStringValue;
    }
  };

  const links = (item) => {
    const eventCalendarLinks = config.eventConfig.filter(
      (el) => el.eventCalendar && el?.name === item?.name
    );
    const articleBookingLinks = config.eventConfig.filter(
      (el) => el.articleBooking && el?.name === item?.name
    );
    if (eventCalendarLinks && eventCalendarLinks[0]) {
      return navigate(`tickets/${slugify(eventCalendarLinks[0].name)}`);
    }
    if (articleBookingLinks && articleBookingLinks[0]) {
      return navigate(`booking/${slugify(articleBookingLinks[0]?.name)}`);
    }
    if (!eventCalendarLinks[0] && !articleBookingLinks[0]) {
      return navigate(`/ticketshop/${product.slug}`);
    }
  };

  useEffect(() => {
    const giftCard = config?.productConfig?.filter((el) =>
      el.description?.includes("presentkort")
    );
    const filterGiftCard = giftCard?.filter((el) => el.plu === product.plu);

    if (product?.displayPrice && !reduce && !reduceChooseValue) {
      priceSet(product.displayPrice);
    }

    if (product?.priceInclVat && !reduce && !reduceChooseValue) {
      priceSet(product.priceInclVat);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length > 0
    ) {
      priceSet(
        `${language.FromCost} ${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 0
    ) {
      priceSet(language.OpenPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 1
    ) {
      priceSet(
        `${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (reduce) {
      priceSet(
        `${sortPrice(reduce)?.[0]} - ${
          sortPrice(reduce)?.[sortPrice(reduce).length - 1]
        }`
      );
    }

    if (reduceChooseValue) {
      priceSet(
        `${sortPrice(reduceChooseValue)?.[0]} kr - ${
          sortPrice(reduceChooseValue)?.[
            sortPrice(reduceChooseValue).length - 1
          ]
        } kr`
      );
    }
  }, [config, product]);

  const trackProductData = [
    {
      id: product.plu,
      name: product.name,
      price: product?.price?.amountInclVat,
      quantity: 1,
    },
  ];

  const handleAdd = () => {
    addToCartTracking(currency, trackProductData);
    clickedSet(true);
    const callback = (status, msg) => {
      if (status === 200) {
        clickedSet(false);
        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        clickedSet(false);
        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    let article;
    let values;
    let photo;
    let overrideDate;
    let quantity = 1;
    const saleData = getSaleData(
      product,
      article,
      values,
      photo,
      quantity,
      overrideDate
    );
    rdxDispatch(addArticle(saleData, callback));
  };

  const image = useMemo(() => {
    if (product?.images?.[0].startsWith("http")) {
      return product?.images?.[0];
    }
    if (product?.images?.[0].startsWith("/ticketshop/")) {
      return `${process.env.PUBLIC_URL}${product?.images?.[0].replace(
        "/ticketshop/",
        "/"
      )}`;
    }
    if (product?.images?.[0]) {
      return `${process.env.PUBLIC_URL}${product?.images?.[0]}`;
    } else {
      return "";
    }
  }, [product.images]);

  const productData = useMemo(() => {
    if (sale) {
      return getProduct(data, config, product.plu, culture);
    }
  }, [data, product, config, culture, sale]);

  if (!product || !productData)
    return (
      <div className={classes.skeleton}>
        <Skeleton height={245} width="100%" radius="md" />
        <Skeleton height={25} mt={12} width="75%" radius="sm" />
        <Skeleton height={20} mt={5} mb={16} width="25%" radius="sm" />
        <Skeleton height={36} width="100%" radius="sm" />
      </div>
    );
  return (
    <Card radius="sm" className={cx(classes.card)} px={"0.7rem"}>
      <UnstyledButton
        className={`${classes.imageButton} cardItemButton`}
        component="a"
        onClick={() => links(product)}
        sx={{ width: "100%" }}
      >
        <Card.Section mx="0">
          <Image
            src={image}
            height={244}
            fit="cover"
            withPlaceholder={image === ""}
            radius="md"
            alt={`${language.ProductAltLabel} ${product?.name}`}
          />
        </Card.Section>

        <Text className={classes.title} weight={700} component="p">
          {product?.name}
        </Text>
      </UnstyledButton>
      <SimpleGrid className={classes.footer} mt={0} pb={"0.1rem"}>
        <Text
          className={classes.price}
          component={"p"}
          color={"gray.7"}
          weight={"500"}
          lineClamp={4}
          sx={{ textAlign: "left" }}
          m={0}
          mb={"0.15rem"}
        >
          {price}
        </Text>
        {productData?.isProduct ? (
          <div role="region" aria-live="assertive">
            <Button
              className={`add_to_cart_product ${classes.addToCart} ${
                !addedToCart && "regularButton"
              }`}
              classNames={{
                label: "add_to_cart_product",
                inner: "add_to_cart_product",
              }}
              size="sm"
              onClick={() => handleAdd()}
              color={addedToCart && "green"}
              disabled={product.inStock <= 0}
              loading={clicked}
            >
              {!addedToCart && (!product.inStock || product.inStock >= 1)
                ? language.AddToCartBtn
                : !addedToCart && product.inStock <= 0
                ? language.SoldOut
                : addedToCart && language.Added_To_Cart}
            </Button>
          </div>
        ) : (
          <Button
            className="productChooseBtn"
            size="sm"
            onClick={() => links(product)}
            variant="outline"
            aria-label={`${language.Choose} ${product.name}`}
          >
            {language.Choose}
          </Button>
        )}
      </SimpleGrid>
    </Card>
  );
};

export default CardItemV2;
