import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import Markdown from "react-markdown";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PageLayout from "../../components/Styles/PageLayout";
import PageContent from "../../components/Styles/PageContent";
import Cart from "../../components/cart/cartPage";

let source;

const cartBelowStyles = makeStyles((theme) => ({
  pageContent: {
    color: theme.palette.color,
    background: theme.palette.background.paper,
    marginTop: "16px",
    padding: "16px",
    borderRadius: "4px",
    "@media(max-width: 667px)": {
      padding: "0px",
    },
  },
}));

const CartPage = () => {
  const cartBelowClasses = cartBelowStyles();

  const [text, setText] = useState("");
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));

  useEffect(() => {
    source = axios.CancelToken.source();
    axios
      .get(
        `${process.env.PUBLIC_URL}/${config.descriptionFolderUrl}/cart-below.${culture}.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => setText(res.data))
      .catch(() => {
        // Left blank
      });
    return () => source.cancel("Cart Page Is Unmounting");
  }, [culture, config.descriptionFolderUrl]);

  return (
    <PageLayout>
      <PageContent>
        <Cart />
      </PageContent>
      {text !== undefined && text.length > 0 && (
        <div
          className={clsx(
            cartBelowClasses.pageContent,
            "cart__below-container"
          )}
        >
          <Markdown source={text} escapeHtml={false} skipHtml={false} />
        </div>
      )}
    </PageLayout>
  );
};

export default CartPage;
