import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import animationData from "./animations/clockblack.json";
import { makeStyles } from "@material-ui/styles";

const ChangeTimeButton = (props) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const animationContainer2 = useRef(null);
  const anim = useRef(null);

  useEffect(() => {
    if (animationContainer2.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer2.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData,
      });
      anim.current.setSpeed(1);

      return () => anim.current.destroy();
    }
  }, []);

  useEffect(() => {
    setOpen(!open);
    anim.current.setDirection(open ? -1 : 1);
    anim.current.play();
  }, [props.selected]);

  const useStyles = makeStyles({
    btn: {
      position: "relative",
      width: "45px",
      height: "45px",
    },

    wrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
      padding: "0 0.5rem",
      minWidth: "10px",
      // maxWidth: "190px",
      backgroundColor: "white",
      border: ({ outline }) =>
        outline ? "solid 2px black" : "solid 2px white",
      background: ({ outline }) => (outline ? "white" : "black"),
      color: ({ outline }) => (outline ? "black" : "white"),
      letterSpacing: "0.7px",
      fontSize: "17px",
      cursor: "pointer",
      transition: ".3s ease ",

      borderRadius: ({ round }) => (round ? "50px" : "10px"),
      // "&:hover": {
      //   // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      //   // filter: "invert(1)",
      // },
    },
    btnText: {
      fontWeight: "600",
      fontSize: "1rem",
      padding: "0",
      margin: "0 1rem 0 0",
    },
  });

  const classes = useStyles(props);

  return (
    <div
      className={classes.wrapper}
      onMouseEnter={() => {
        setOpen(!open);
        setHover(true);
        anim.current.setDirection(open ? -1 : 1);
        anim.current.play();
      }}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`${hover ? "iconInvert" : "iconDefault"} ${classes.btn}`}
        ref={animationContainer2}
      />
      <p
        className={`${hover ? "btnTextInvert" : "btnTextDefault"} ${
          classes.btnText
        }`}
      >
        {props.text}
      </p>
    </div>
  );
};
export default ChangeTimeButton;
