const initialValues = (fields) => {
  let initialData = {};
  fields.forEach((f) => {
    if (f !== "photo") {
      initialData[f] = "";
    }
  });
  return initialData;
};

export default initialValues;
