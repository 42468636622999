import React, { useContext } from "react";
import { useNavigate } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Badge from "@material-ui/core/Badge";
import CartIcon from "@material-ui/icons/ShoppingCart";
import { SaleContext } from "../common/saleProvider";

const useStyles = makeStyles((theme) => ({
  cartButton: {
    padding: "0 12px",
    "&:focus": {
      border: "2px solid black",
    },
  },
  disabled: { opacity: theme.palette.action.disabledOpacity },
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
  secondary: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.paper,
    fontFeatureSettings: "tnum",
    fontVariantNumeric: "tabular-nums",
  },
}));

const Cart = (props) => {
  const classes = useStyles();
  const {
    state: { sale },
  } = useContext(SaleContext);
  const navigate = useNavigate();
  if (sale === null || sale === undefined) {
    return <CartIcon className={classes.disabled} />;
  }

  return (
      <ButtonBase
        disabled={sale?.itemQuantity < 1}
        onClick={() => navigate(`${process.env.PUBLIC_URL}/cart`)}
        aria-label="Cart"
        tabIndex={0}
        className={clsx(classes.cartButton, "header__cart-button")}
        color="inherit"
        classes={{ disabled: classes.disabled }}
      >
        {sale?.itemQuantity > 0 ? (
          <Badge
            classes={{
              badge: classes.badge,
              colorSecondary: clsx(classes.secondary, "header__cart__badge"),
            }}
            badgeContent={sale.itemQuantity}
            color="secondary"
          >
            <CartIcon />
          </Badge>
        ) : (
          <CartIcon />
        )}
      </ButtonBase>
  );
};

export default Cart;
