import React, { useContext, useEffect, useState } from "react";
import AddToCartButton from "./AddToCartButton";
import { addArticleBatch, addToCartTracking } from "../../common/sale";
import { useDispatch, useSelector } from "react-redux";
import { CART_ARTICLE_ADD, Context, OPEN_ALERT } from "../../context";
import { getCartArticle } from "../cart/util";
import AddedButton from "./AddedButton";
import { navigate } from "@reach/router";
import { STATE_CURRENCY } from "../../common/state";

const AddToCart = ({
  cart,
  setCart,
  allotmentId,
  clicked,
  setClicked,
  language,
}) => {
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const { config, currency } = useSelector((state) => ({
    config: state.config,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const [product, setProduct] = useState([]);
  const [text, setText] = useState(null);

  useEffect(() => {
    setProduct(groupByTimes(cart));
  }, [cart]);

  const reduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const chosenAllotment =
    cart &&
    allotmentId &&
    cart.filter((el) => {
      return allotmentId.articles.some((ele) => {
        return el.id === ele.id;
      });
    });

  const groupByTimes = (data) =>
    Object.values(
      data.reduce(
        (data, { id, plu }) =>
          Object.assign({}, data, {
            [id]: data[id]
              ? { id, plu: [...data[id].plu, plu] }
              : { id, plu: [plu] },
          }),
        {}
      )
    );

  const cartArticle = product && getCartArticle(product, config);

  const productPrice = (item) => {
    if (item && item.length > 0) {
      const regex = /[$€£kr]/g;
      let newPrice = item.replace(regex, "");
      if (newPrice.trim().split(".")[1] === "00") {
        return newPrice.split(".")[0];
      } else {
        return newPrice;
      }
    }
  };

  const trackReduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          id: curr.plu,
          quantity: 1,
          price: parseFloat(productPrice(curr.priceInclVat).replace(",", ".")),
          name: curr.name,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const add = () => {
    setClicked(true);
    rdxDispatch(addArticleBatch(reduceCart, cb));
  };

  const cb = (status, msg) => {
    if (status >= 400) {
      dispatch({
        type: OPEN_ALERT,
        payload: {
          text: language.ErrorMsgCouldNotAddToCart,
          severity: "error",
        },
      });
      setClicked(false);
      setCart([]);
    } else {
      dispatch({
        type: CART_ARTICLE_ADD,
        payload: cartArticle,
      });
      setCart([]);
      setClicked(false);
      setText(language.Added_To_Cart);
      setTimeout(() => {
        setText(null);
      }, 3000);
      config?.redirectEventCalendar && navigate("/ticketshop/cart");
      addToCartTracking(currency, trackReduceCart);
    }
  };

  return (
    <>
      <button
        onClick={() => add()}
        className={`removeBorder add_to_cart_ticket ${
          chosenAllotment?.length > 0 ? "" : "hideScreen"
        }`}
        disabled={chosenAllotment?.length === 0 ? true : false}
        style={{ transition: "all 0.25s ease" }}
        aria-live="assertive"
      >
        {clicked === false ? (
          <AddToCartButton
            text={text ? text : language.AddToCartBtn}
            chosenAllotment={chosenAllotment}
            language={language}
          />
        ) : (
          <AddedButton round />
        )}
      </button>
    </>
  );
};

export default AddToCart;
