import React from "react";
import { Form } from "formik";
import { makeStyles } from "@material-ui/styles";
import RowLayout from "../../Styles/RowLayout";
import FormikTextField from "./formikTextField";
import PhotoEditor from "../../PhotoEditor";

const useStyles = makeStyles((theme) => ({
  photo: { margin: "16px 0" },
}));

const CustomerForm = (props) => {
  const { language, config, image, savePhoto } = props;
  const classes = useStyles();

  return (
    <Form>
      <div>
        <RowLayout cols={2}>
          {(!config ||
            (config && config.visibleFields.includes("firstName"))) && (
            <FormikTextField
              name="firstName"
              type="text"
              label={language.Form_FirstName}
              placeholder={language.Form_FirstName}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("firstName")
                  ? true
                  : false
              }
            />
          )}
          {(!config ||
            (config && config.visibleFields.includes("lastName"))) && (
            <FormikTextField
              name="lastName"
              type="text"
              label={language.Form_LastName}
              placeholder={language.Form_LastName}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("lastName")
                  ? true
                  : false
              }
            />
          )}
          {(!config ||
            (config &&
              config.visibleFields.includes("civicRegistrationNumber"))) && (
            <FormikTextField
              name="civicRegistrationNumber"
              type="text"
              label={language.Form_CivicRegistrationNumber}
              placeholder={language.Form_CivicRegistrationNumber_Placeholder}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("civicRegistrationNumber")
                  ? true
                  : false
              }
            />
          )}
          {(!config || (config && config.visibleFields.includes("email"))) && (
            <FormikTextField
              name="email"
              type="text"
              label={language.Form_Email}
              placeholder={language.Form_Email}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("email")
                  ? true
                  : false
              }
            />
          )}
          {(!config ||
            (config && config.visibleFields.includes("cellPhone"))) && (
            <FormikTextField
              name="cellPhone"
              type="text"
              label={language.Form_CellPhone}
              placeholder={language.Form_CellPhone}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("cellPhone")
                  ? true
                  : false
              }
            />
          )}
        </RowLayout>
        {(!config || (config && config.visibleFields.includes("photo"))) && (
          <div className={classes.photo}>
            <PhotoEditor savePhoto={savePhoto} preview={image} />
          </div>
        )}
        <RowLayout gtc="3fr 1fr">
          {(!config || (config && config.visibleFields.includes("street"))) && (
            <FormikTextField
              name="street"
              type="text"
              label={language.Form_Street}
              placeholder={language.Form_Street}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("street")
                  ? true
                  : false
              }
            />
          )}
          {(!config ||
            (config && config.visibleFields.includes("streetNumber"))) && (
            <FormikTextField
              name="streetNumber"
              type="text"
              label={language.Form_StreetNumber}
              placeholder={language.Form_StreetNumber}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("streetNumber")
                  ? true
                  : false
              }
            />
          )}
        </RowLayout>
        <RowLayout gtc="1fr 3fr">
          {(!config ||
            (config && config.visibleFields.includes("zipCode"))) && (
            <FormikTextField
              name="zipCode"
              type="text"
              label={language.Form_ZipCode}
              placeholder={language.Form_ZipCode}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("zipCode")
                  ? true
                  : false
              }
            />
          )}
          {(!config || (config && config.visibleFields.includes("city"))) && (
            <FormikTextField
              name="city"
              type="text"
              label={language.Form_City}
              placeholder={language.Form_City}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("city")
                  ? true
                  : false
              }
            />
          )}
        </RowLayout>
        <RowLayout cols={2}>
          {(!config ||
            (config && config.visibleFields.includes("countryCode"))) && (
            <FormikTextField
              name="countryCode"
              type="text"
              label={language.Form_Country}
              placeholder={language.Form_Country}
              required={
                !config
                  ? true
                  : config.requiredFields.includes("countryCode")
                  ? true
                  : false
              }
            />
          )}
        </RowLayout>
      </div>
    </Form>
  );
};

export default CustomerForm;
