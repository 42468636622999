import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Markdown from "react-markdown";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { STATE_CULTURE } from "../../../common/state";
import useLocalStorage from "../../Header/HeaderV2/hooks/useLocalStorage";
let source;

const useStyles = makeStyles({
  container: {
    height: "45vh",
  },
});

const SiteHeading = (props) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [heroHeight, setHeroHeight] = useLocalStorage("height", "")
  const { culture, config } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
    config: state.config,
  }));

  const ref = useRef(null);

  useEffect(() => {
    if (config?.showSiteHeadingOnlyOnLanding) {
      if (ref.current && window.location.pathname === "/ticketshop/") {
        let heroHeight = ref.current.offsetHeight;
        localStorage.setItem("height", heroHeight);
        setHeroHeight(heroHeight)
      }
    }
  }, [ref]);

  useEffect(() => {
    if (config.useSiteHeading) {
      let CancelToken = axios.CancelToken;
      source = CancelToken.source();
      axios
        .get(
          `${process.env.PUBLIC_URL}/${config.descriptionFolderUrl}siteheading.${culture}.md`,
          {
            cancelToken: source.token,
          }
        )
        .then((res) => setText(res.data));
      return () => source.cancel("Site heading Is Unmounting");
    }
  }, [culture, config.descriptionFolderUrl, config.useSiteHeading]);

  if (!config.useSiteHeading) return null;

  return (
      <section
        className={classNames(classes.container, "siteheading__container")}
        ref={ref}
        // style={{
        //   top:
        //     config?.useNewHeader && !config?.useTransparentHeader
        //       ? config?.headerHeight
        //       : "",
        // }}
      >
        <Markdown
          source={text}
          escapeHtml={false}
          skipHtml={false}
          className="site-heading__content"
        />
      </section>
  );
};

export default SiteHeading;
