import React from "react";
import { makeStyles } from "@material-ui/styles";
import slugify from "../common/slugify";

const useStyles = makeStyles((theme) => ({
  toc: {
    position: "sticky",
    top: 0,
    padding: "0 16px",
    textAlign: "right",
  },
  list: { listStyle: "none" },
}));

const Toc = ({ groups }) => {
  const classes = useStyles();
  console.log(groups);
  const handleClick = (group) => console.log(group);
  return (
    <div>
      <div className={classes.toc}>
        <ul className={classes.list}>
          {groups.map((g, i) => (
            <li key={i} onClick={() => handleClick(slugify(g.name))}>
              {g.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Toc;
