import React from "react";
import { makeStyles } from "@material-ui/styles";
import LoadingAnimation from "./animations/LoadingAnimation";

const AddToCartButton = (props) => {
  const useStyles = makeStyles({
    cartBtn: {
      maxWidth: "15rem",
      minWidth: "14rem",
      padding: "0 2rem",
      marginBottom: "0.5rem",
      height: "3.5rem",
      borderRadius: "10px",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      fontSize: "1rem",
      fontWeight: "600",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:disabled": {
        pointerEvents: "none",
        opacity: "0.3",
      },
      "@media screen and (max-width: 768px)": {
        width: "100%",
        padding: 0,
        maxWidth: "100%"
      },
    },

    btnBackground: {
      "&:hover": {
        opacity: ({ primaryBtn }) => (primaryBtn ? "0.7" : ""),
      },
    },
    loadingBtn: {
      transition: "all 5s ease",
      width: "70px",
    },
  });
  const classes = useStyles(props);
  return (
      <div
        className={`${classes.cartBtn} ${classes.btnBackground} ${
          props.loading
            ? "articleBookingCartButtonClicked"
            : "articleBookingCartButton"
        }`}
        style={{pointerEvents: props.disabled ? "none" : "", opacity: props.disabled ? 0.3 : ""}}
      >
       {props.loading === false ?  <p>{props.text}</p> : <LoadingAnimation />}
      </div>
  );
};

export default AddToCartButton;
