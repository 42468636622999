import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import CardItem from "./cardItem";
import ListItem from "./listItem";
import { STATE_CULTURE } from "../../common/state";
import ListItemV2 from "./ListItemV2";
import CardItemV2 from "./CardItemV2";

const useStyles = makeStyles((theme) => ({
  cardList: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "1rem 0rem",
    justifyItems: "center",
    padding: "16px 0",
    "@media(max-width: 667px)": {
      gridTemplateColumns: "1fr 1fr",
      gridGap: "1rem 0rem",
    },
    "@media(max-width: 300px)": {
      gridTemplateColumns: "1fr",
      gridGap: "1rem 0rem",
    },
  },
  list: {},
}));

const ProductList = ({
  originalPage,
  products,
  events,
  listType,
  setPosition,
  data,
}) => {
  const [eventCalendar, eventCalendarSet] = useState(null);
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));
  const classes = useStyles();
  const isCardList = listType
    ? listType === "CARD"
    : window.innerWidth < 667
    ? config.articleListType?.sm === "CARD"
    : config.articleListType?.lg === "CARD";

  // insert imageFileName and translations into the "events" object, using data from API
  if (events) {
    events.forEach((event) => {
      event.imageFileName = data.allotments.find(
        (allotment) => event.id === allotment.id
      ).imageFileName;
      event.translation = data.allotments.find(
        (allotment) => event.id === allotment.id
      ).translation;
    });
  }

  useEffect(() => {
    if (originalPage && originalPage?.events && config?.ticketConfigurator) {
      const filter = config?.ticketConfigurator.filter((ele) => {
        return originalPage?.events.some((el) => {
          return el === ele.name;
        });
      });

      if (filter && filter.length > 0) {
        eventCalendarSet(filter);
      }
      return () => eventCalendarSet(null);
    }
  }, [config, originalPage]);

  return (
    <div
      className={clsx(
        isCardList ? classes.cardList : classes.list,
        "product-list__container"
      )}
    >
      {eventCalendar &&
        eventCalendar.map((ec, key) =>
          isCardList ? (
            config?.useNewCard ? (
              <React.Fragment key={ec.id}>
                <CardItemV2 product={ec} />
              </React.Fragment>
            ) : (
              <CardItem
                key={key}
                product={ec}
                isEventCalendar
                setPosition={setPosition}
                culture={culture}
              />
            )
          ) : (
            <React.Fragment key={ec.id}>
              {config?.useNewList ? (
                <ListItemV2 product={ec} culture={culture} />
              ) : (
                <ListItem
                  key={key}
                  product={ec}
                  isEventCalendar
                  setPosition={setPosition}
                  culture={culture}
                />
              )}
            </React.Fragment>
          )
        )}

      {events?.map((event) =>
        isCardList ? (
          config?.useNewCard ? (
            <React.Fragment key={event.id}>
              <CardItemV2 product={event} />
            </React.Fragment>
          ) : (
            <CardItem
              key={event.id}
              product={event}
              isEvent
              setPosition={setPosition}
              culture={culture}
            />
          )
        ) : config?.useNewList ? (
          <React.Fragment key={event.id}>
            <ListItemV2 product={event} culture={culture} />
          </React.Fragment>
        ) : (
          <ListItem
            key={event.id}
            product={event}
            isEvent
            setPosition={setPosition}
            culture={culture}
          />
        )
      )}
      {products.map((product) =>
        isCardList ? (
          config?.useNewCard ? (
            <React.Fragment key={product.id}>
              <CardItemV2 product={product} />
            </React.Fragment>
          ) : (
            <CardItem
              key={product.id}
              product={product}
              setPosition={setPosition}
              culture={culture}
            />
          )
        ) : config?.useNewList ? (
          <React.Fragment key={product.id}>
            <ListItemV2 product={product} culture={culture} />
          </React.Fragment>
        ) : (
          <ListItem
            key={product.id}
            product={product}
            setPosition={setPosition}
            culture={culture}
          />
        )
      )}
    </div>
  );
};

export default ProductList;
