import React from "react";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Text } from "@mantine/core";

const ArticleButtons = ({
  cart,
  cartSet,
  ticketData,
  chosenOccasion,
  availableOccasions,
  name,
}) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
  };
  let disabledBtn = false;

  const addAllotment = (product) => {
    let newProduct = product;
    newProduct.allotmentId = chosenOccasion.allotment.id;
    newProduct.timeOccasions = chosenOccasion.time;
    cartSet([...cart, product]);
  };

  const removeAllotment = (ticketData) => {
    let newCart = [...cart];
    let findAllotment = cart.findIndex((i) => i.id === ticketData.id);
    newCart.splice(findAllotment, 1);
    cartSet(newCart);
  };

  const checkIfAvailable = () => {
    let timeData = availableOccasions.filter(
      (el) => chosenOccasion.time === el.time
    );
    if (
      timeData?.[0]?.remaining <= cart.length ||
      timeData?.[0]?.allotment.maxPerSale === cart.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  const calculateQuantity = (ticketData) => {
    const findItems = cart.filter((item) => item.id === ticketData.id);
    return findItems.length;
  };

  return (
    <div className="articleRightColumnToggle" style={{ margin: 0 }}>
      <motion.div
        className="switch"
        data-ison={calculateQuantity(ticketData) > 0 ? true : ""}
        transition={spring}
        layout
      />
      <motion.div
        className={`handle ${checkIfAvailable() ? "event-hideScreen" : ""}`}
        data-ison={calculateQuantity(ticketData) > 0 ? true : ""}
        whileTap={{ scale: 1.2 }}
        layout
        transition={spring}
        style={{
          pointerEvents: disabledBtn ? "none" : "",
        }}
      >
        <button
          className={`articleButton`}
          onClick={() => addAllotment(ticketData)}
          aria-label={`${language?.AddQuantity} ${name}`}
          style={{ border: "none", background: "none" }}
        >
          <MdAddCircle className="buttonIcons" />
        </button>
      </motion.div>
      <motion.div
        className="event-count articleQty"
        data-ison={calculateQuantity(ticketData) > 0 ? true : false}
        layout
        transition={spring}
        aria-live="assertive"
      >
        <Text
          size="xl"
          weight={"bold"}
          component="span"
          aria-label={`${name} ${language.Quantity} ${calculateQuantity(
            ticketData
          )}`}
        >
          {calculateQuantity(ticketData)}
        </Text>
      </motion.div>
      <motion.div
        data-ison={calculateQuantity(ticketData) > 0 ? true : ""}
        className="handle2"
        whileTap={{ scale: 1.2 }}
        layout
        transition={spring}
      >
        <button
          className={`articleButtonRemove ${
            calculateQuantity(ticketData) === 0 ? "event-hideScreen" : ""
          }`}
          onClick={() => removeAllotment(ticketData)}
          aria-label={`${language?.DecreaseQuantity} ${name}`}
          disabled={calculateQuantity(ticketData) === 0 ? true : false}
          style={{ border: "none", background: "none" }}
        >
          <MdRemoveCircle className="buttonIcons" />
        </button>
      </motion.div>
    </div>
  );
};

export default ArticleButtons;
