import {
  Button,
  Container,
  createStyles,
  Grid,
  Image,
  Loader,
  Popover,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addModification } from "../../../common/sale";
import { STATE_CULTURE } from "../../../common/state";
import Markdown from "react-markdown";
import { motion } from "framer-motion";
import { SaleContext } from "../../common/saleProvider";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import ExtensionCard from "./ExtensionCard";
import ExtensionModifications from "./ExtensionModifications";
import { useMediaQuery } from "@mantine/hooks";
import { Context, OPEN_CART, TOAST } from "../../../context";

const useStyles = createStyles((theme) => ({
  container: {
    "@media screen and (max-width: 768px)": {
      paddingTop: "0.3rem",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "1rem",
    button: {
      width: "fit-content",
    },
  },
  inputWrapper: {
    margin: "1rem 0rem",
  },
  stockWrapper: {
    marginTop: ".5rem",
    "@media screen and (max-width: 768px)": {
      marginTop: ".35rem",
    },
  },
  stockIndicator: {
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
  },
  inStock: {
    backgroundColor: theme.colors.green[1],
  },
  outOfStock: {
    backgroundColor: theme.colors.red[2],
  },
  stockText: {
    fontSize: "1rem",
    fontWeight: "inherit",
  },
  price: {
    display: "flex",
    alignItems: "end",
    justifySelf: "end",
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
  list: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    width: "fit-content",
    justifySelf: "end",
  },
  descriptionTitle: {
    fontSize: "1rem",
  },
  descriptionBody: {
    marginTop: "0.5rem",
    p: {
      marginTop: "-.1rem",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "end",
  },
  actionButton: {
    borderRadius: "50%",
    border: "none",
  },
  actionWrapper: {
    backgroundColor: "#F3F3F4",
    padding: "0.5rem",
    borderRadius: "2rem",
    width: "fit-content",
    minWidth: "8.2rem",
  },
  actionGrid: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    marginTop: "1rem",
    "@media screen and (min-width: 769px)": {
      gap: "2rem",
    },
  },
  alertWrapper: {
    marginTop: "1.25rem",
    width: "100%",
  },
  addToCart: {
    justifySelf: "end",
  },
}));

const GET_INTERNAL_FUNDS = gql`
  query InternalFundsQuery($barcode: String) {
    internalFundsLookup(barcode: $barcode) {
      hasInternalFunds
      fundsAmount
      isRefundable
      minimumAmount
      maximumAmount
      articleId
      fundsId
      ticketId
    }
  }
`;

const TICKET_BY_BARCODE = gql`
  query ticketByBarcode($barcode: String, $posId: Int) {
    ticketByBarcode(barcode: $barcode, posId: $posId) {
      id
      applicableModifications {
        id
        name
        priceInclVat
        plu
      }
      article {
        id
      }
      validFrom
      validUntil
      owner {
        firstname
        lastname
      }
      uniqueIdentifier
      article {
        id
        name
      }
    }
  }
`;

const Extension = ({ product, description }) => {
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));

  const [value, valueSet] = useState(null);
  const [checked, checkedSet] = useState(null);
  const [quantity, quantitySet] = useState(1);
  const [addedToCart, addedToCartSet] = useState(null);
  const [openPrice, openPriceSet] = useState(100);

  const { classes } = useStyles();
  const handlers = useRef();
  const rdxDispatch = useDispatch();
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const { posId } = config;
  const mobile = useMediaQuery("(max-width: 768px)");
  const { dispatch } = useContext(Context);

  const [getTicket, { loading, error, data }] = useLazyQuery(
    TICKET_BY_BARCODE,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const [
    getFunds,
    { loading: loadingFunds, error: errorFunds, data: dataFunds },
  ] = useLazyQuery(GET_INTERNAL_FUNDS, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const getProduct = config.productConfig.filter(
    (el) => el?.plu === product?.plu
  )?.[0];
  const getTranslation =
    getProduct &&
    getProduct?.translation.filter((el) => el.culture === culture)?.[0];

  const getImage = `${process.env.PUBLIC_URL}/${config?.imageFolderUrl}${getProduct.images?.[0]}`;

  const callback = (status, msg) => {
    if (status === 200) {
      dispatch({ type: OPEN_CART });
    }
    if (status !== 200) {
      return dispatch({
        type: TOAST,
        payload: {
          text: msg,
          variant: "error",
        },
      });
    }
  };
  const ticketId = data?.ticketByBarcode?.[0]?.id;
  const articleId = data?.ticketByBarcode?.[0]?.article.id;
  const plu = checked?.plu;
  const fundsId = dataFunds ? dataFunds?.internalFundsLookup?.fundsId : null;
  const amount = dataFunds
    ? checked?.priceInclVat.replace(/[^0-9\.]+/g, "")
    : null;
  const handleAdd = () => {
    const model = {
      ticketId,
      articleId,
      plu,
      fundsId,
      amount,
    };

    rdxDispatch(
      addModification(model, (status, msg) => {
        callback(status, msg);
      })
    );
  };
  const getExtensions = () => {
    getTicket({ variables: { barcode: value, posId: posId } });
    getFunds({ variables: { barcode: value, posId: posId } });
  };

  const disabledAdd = useMemo(() => {
    if (sale) {
      const qty = sale.items.filter(
        (el) => el.article.plu === product?.plu
      )?.[0]?.quantity;
      const calc = product?.inStock - (qty ? qty : 0);
      if (quantity > calc || quantity === undefined) {
        return true;
      }
    }
  }, [sale, product, quantity]);

  return (
    <Container>
      <Grid gutter={28}>
        <Grid.Col sm={12} md={7}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ height: "100%" }}
          >
            <Image
              src={getImage}
              alt="bild"
              withPlaceholder
              radius={"sm"}
              height={
                mobile && !product.images ? "20rem" : mobile ? "20rem" : "25rem"
              }
              fit="cover"
            />
          </motion.div>
        </Grid.Col>
        <Grid.Col sm={12} md={5} className={classes.container}>
          <div className={classes.titleWrapper}>
            <Title order={1} sx={{ fontSize: "1.5rem" }}>
              {getTranslation.name}
            </Title>
          </div>
          <div className={classes.descriptionBody}>
            <Markdown
              source={description ? description : getTranslation.description}
              escapeHtml={false}
              skipHtml={false}
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextInput
              placeholder={language.ExtendBody}
              required={true}
              classNames={"classes"}
              value={value}
              onChange={(e) => valueSet(e.currentTarget.value)}
              label={language.Extend}
            />
          </div>
          <div className={classes.alertWrapper}>
            <Button
              variant="filled"
              size="md"
              className="regularButton"
              onClick={getExtensions}
              disabled={!value || value === ""}
              sx={{
                width: "100%",
                backgroundColor:
                  addedToCart === true
                    ? "#40C057 !important"
                    : addedToCart === false
                    ? "#FA5252 !important"
                    : "",
                transition: "all 0.2s ease-in-out",
              }}
            >
              {loading ? <Loader size={"sm"} /> : language.Fetch_Ticket}
            </Button>
            <Popover
              width="target"
              withArrow
              shadow="md"
              opened={data?.ticketByBarcode.length === 0}
            >
              <Popover.Target>
                <div className={classes.alertContainer} />
              </Popover.Target>
              <Popover.Dropdown>
                <Text size="md" component="p">
                  {language.CouldNotFindTicket}
                </Text>
              </Popover.Dropdown>
            </Popover>
          </div>
          {/* </SimpleGrid> */}
          {/* <Divider my="sm" mt="xl" mb="xl" /> */}
          {/* <Title order={2} className={classes.descriptionTitle}>
            {language.Description}
          </Title> */}
        </Grid.Col>
      </Grid>
      {data && data?.ticketByBarcode.length > 0 && (
        <Grid mt="lg">
          <Grid.Col span={mobile ? 12 : 6}>
            <ExtensionCard card={data.ticketByBarcode[0]} />
          </Grid.Col>

          <Grid.Col span={mobile ? 12 : 6}>
            {data?.ticketByBarcode.length > 0 && (
              <Title order={5} mb="lg">
                {language.AvailableModifications}
              </Title>
            )}
            {data &&
              data?.ticketByBarcode.length > 0 &&
              data.ticketByBarcode[0].applicableModifications.length > 0 &&
              data.ticketByBarcode[0].applicableModifications.map((el) => (
                <ExtensionModifications
                  modification={el}
                  id={el.id}
                  title={el.name}
                  price={el.priceInclVat}
                  checked={checked}
                  checkedSet={checkedSet}
                />
              ))}
          </Grid.Col>
          <Grid.Col span={12} sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              size="md"
              className={`${classes.addToCart} regularButton add_to_cart_product`}
              classNames={{
                label: "add_to_cart_product",
                inner: "add_to_cart_product",
              }}
              disabled={!checked}
              onClick={() => handleAdd()}
            >
              {language.AddToCartBtn}
            </Button>
          </Grid.Col>
        </Grid>
      )}
    </Container>
  );
};

export default Extension;
