import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  productContent: {
    display: "grid",
    gridTemplateColumns: "400px 1fr",
    gridGap: "16px",
    "@media(max-width: 768px)": {
      gridTemplateColumns: "300px 1fr",
    },
    "@media(max-width: 667px)": {
      gridTemplateColumns: "1fr",
      padding: "0 8px 16px",
    },
  },
}));

const ProductContent = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.productContent, "product__content-container")}>
      {children}
    </div>
  );
};

export default ProductContent;
