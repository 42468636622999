import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "react-dates/lib/css/_datepicker.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css";
import "./App.css";
import Main from "./main";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "apollo-boost";
import { cookieMiddleware, cookieReducer } from "./common/cookies";
import { keys, STATE_CULTURE } from "./common/state";
import { saleMiddleware, saleReducer, validateSaleId } from "./common/sale";
import { promiseMiddleware, thunkMiddleware } from "./common/middleware";
import { busyReducer } from "./common/busy";
import { languageReducer, loadLanguage } from "./common/language";
import { CONFIG_LOADED, configReducer } from "./common/config";
import createOidcMiddleware, {
  OidcProvider,
  reducer as oidcReducer,
} from "redux-oidc";
import { getUserManager } from "./common/auth";
import { Router } from "@reach/router";
import Callback from "./common/callback";
import SilentRenew from "./common/silentRenew";
import Security from "./components/common/security";
import Terms from "./components/common/terms";
import Privacy from "./components/common/privacy";
import OldBrowser from "./components/common/oldBrowser";
import ContextProvider from "./context";

const dummyMiddleware = () => (next) => (action) => {
  return next(action);
};

if (!window.CSS) {
  const app = window.document.getElementById("root");
  const heading = window.document.createElement("h1");
  const headingText = window.document.createTextNode("Gammal Webbläsare!");
  heading.appendChild(headingText);
  app.appendChild(heading);
  console.log(app);
} else {
  fetch(process.env.PUBLIC_URL + "/config.json")
    .then((response) => response.json())
    .then((config) => {
      const getJson = (f) => {
        return fetch(f).then((x) => x.json());
      };

      const merge = (o, rest) => {
        var next = rest.pop();
        if (!next) return o;

        const merged = {
          ...o,
          ...next,
        };
        return merge(merged, rest);
      };

      const configUri = process.env.PUBLIC_URL + "/config.json";
      const localUri = `${process.env.PUBLIC_URL}/ee-static/shop/${config.shopName}/local.json`;
      const files = [configUri, localUri].map((f) => getJson(f));

      Promise.all(files)
        .then((response) => merge({}, response))
        .then((config) => {
          console.log(config);
          const {
            apiUri,
            apiUriOverride,
            posId,
            version,
            shopName,
            pageTitle,
          } = config;
          const uri = apiUriOverride?.startsWith("http")
            ? apiUriOverride
            : apiUri;
          console.log(uri);
          if (pageTitle) {
            window.document.title = pageTitle;
          } else {
            window.document.title = shopName;
          }
          console.log("version " + version);
          //Add local stylesheet
          let link = window.document.createElement("link");
          link.href = `${process.env.PUBLIC_URL}/ee-static/shop/${config.shopName}/style.css`;
          link.rel = "stylesheet";
          window.document.getElementsByTagName("head")[0].appendChild(link);

          const userManager = getUserManager(config);

          const reducers = combineReducers({
            cookies: cookieReducer(keys(), config),
            sale: saleReducer,
            busy: busyReducer,
            language: languageReducer,
            config: configReducer,
            oidc: oidcReducer,
          });

          const oidcMiddleware = !userManager.error
            ? createOidcMiddleware(
                userManager,
                () => true,
                false,
                `${process.env.PUBLIC_URL}/callback`
              )
            : dummyMiddleware;

          const middleware = applyMiddleware(
            cookieMiddleware,
            saleMiddleware({ apiUri: uri, posId }),
            promiseMiddleware,
            thunkMiddleware,
            oidcMiddleware
          );

          const enhancers = compose(
            middleware,
            window.__REDUX_DEVTOOLS_EXTENSION__
              ? window.__REDUX_DEVTOOLS_EXTENSION__()
              : (f) => f
          );
          const store = createStore(reducers, {}, enhancers);

          store.dispatch({ type: CONFIG_LOADED, payload: config });
          store.dispatch(validateSaleId(uri, () => console.log("reset")));

          //Create Apollo client and auth
          const httpLink = new HttpLink({ uri: `${uri}/graphql` });
          const authLink = new ApolloLink((operation, forward) => {
            const { oidc } = store.getState();

            const token = oidc.user ? oidc.user.access_token : null;
            if (token) {
              operation.setContext({
                headers: {
                  authorization: token ? `Bearer ${token}` : null,
                },
              });
            }

            return forward(operation);
          });

          const client = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
          });

          let culture = store.getState().cookies[STATE_CULTURE];
          loadLanguage(culture, config, store.dispatch);

          if (userManager.error) {
            ReactDOM.render(
              <ApolloProvider client={client}>
                <Main />
              </ApolloProvider>,
              document.getElementById("root")
            );
          } else {
            if (!window.CSS)
              return ReactDOM.render(
                <OldBrowser />,
                document.getElementById("root")
              );
            if (!CSS.supports("display", "grid"))
              return ReactDOM.render(
                <OldBrowser />,
                document.getElementById("root")
              );
            ReactDOM.render(
              <ApolloProvider client={client}>
                <Provider store={store}>
                  <OidcProvider store={store} userManager={userManager}>
                    <ContextProvider userManager={userManager}>
                      <Router basepath={process.env.PUBLIC_URL}>
                        <Callback path="/callback" userManager={userManager} />
                        <SilentRenew path="/silent_renew" />
                        <Security path="/security" />
                        <Terms path="/terms" />
                        <Privacy path="/privacy" />
                        <OldBrowser path="/oldbrowser" />
                        <Main path="/*" />
                      </Router>
                    </ContextProvider>
                  </OidcProvider>
                </Provider>
              </ApolloProvider>,
              document.getElementById("root")
            );
          }
        });
    });
}
