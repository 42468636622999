import React, { useEffect, useMemo, useState } from "react";
import EventFilter from "./EventFilter";
import _ from "lodash";
import EventWrapper from "./EventWrapper";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { useSessionStorage } from "@mantine/hooks";

const AllEventsV2 = ({ events }) => {
  const getPriceRange = events.reduce((acc, curr) => {
    acc.push(curr.articles);
    return acc.flat();
  }, []);
  const maxDate = events.reduce((acc, curr) => {
    acc.push(curr.occasions);
    return acc.flat().sort((a, b) => new Date(a.time) - new Date(b.time));
  }, []);
  const articles = getPriceRange.sort(
    (a, b) => parseFloat(a.priceInclVat) - parseFloat(b.priceInclVat)
  );
  const [items, itemsSet] = useState([]);
  const [priceBeforeChange, priceBeforeChangeSet] = useState([
    0,
    parseFloat(articles?.[articles.length - 1]?.priceInclVat + 100),
  ]);
  const [priceVal, priceValSet] = useSessionStorage({
    key: "priceVal",
    defaultValue: [
      parseFloat(articles?.[0]?.priceInclVat),
      parseFloat(articles?.[articles.length - 1]?.priceInclVat),
    ],
  });
  const [text, textSet] = useSessionStorage({
    key: "text",
    defaultValue: "",
  });
  const [date, dateSet] = useSessionStorage({
    key: "date",
    defaultValue: [
      new Date(maxDate?.[0]?.time),
      new Date(maxDate?.[maxDate.length - 1]?.time),
    ],
    serialize: (value) => {
      return [...value];
    },
    deserialize: (value) => {
      if (value !== ",") {
        let split = value.split(",");
        let newArr = [];
        newArr.push(new Date(split[0]));
        newArr.push(new Date(split[1]));
        return newArr;
      } else {
        return [
          new Date(maxDate?.[0]?.time),
          new Date(maxDate?.[maxDate.length - 1]?.time),
        ];
      }
    },
  });

  const checkPrice = (item) => {
    if (
      item.price.amountInclVat >= priceVal?.[0] &&
      item.price.amountInclVat <= priceVal?.[1]
    ) {
      return true;
    }
  };

  const checkName = (item) => {
    if (item.name.toLowerCase().includes(text.toLowerCase())) {
      return true;
    }
  };

  const checkDateRange = (item) => {
    let occasion = item?.occasions;
    const filter = occasion.some((el) => {
      if (
        (isSameDay(new Date(el.time), date[0]) ||
          isAfter(new Date(el.time), date[0])) &&
        (isSameDay(new Date(el.time), date[1]) ||
          isBefore(new Date(el.time), date[1]))
      ) {
        return true;
      }
    });

    if (filter) {
      return true;
    }
  };

  const filterByPrice = useMemo(() => {
    return (
      events &&
      events.reduce((acc, curr) => {
        // Price
        const amount = () => {
          for (let price of curr.articles) {
            if (checkPrice(price)) {
              return true;
            }
          }
        };

        const translation = () => {
          for (let translation of curr.translation) {
            if (checkName(translation)) {
              return true;
            }
          }
        };

        const dateRange = () => {
          if (checkDateRange(curr)) {
            return true;
          }
        };

        if (amount() && translation() && dateRange()) {
          acc.push(curr);
        }
        return _.uniq(acc);
      }, [])
    );
  }, [events, priceVal, text, date]);

  useEffect(() => {
    if (filterByPrice && filterByPrice.length !== items.length) {
      itemsSet(filterByPrice);
    }
  }, [priceVal, text, date]);
  useEffect(() => {
    if (date === "") {
      itemsSet(events);
    }
  }, []);

  if (!text && !priceVal && !date && !events) return null;

  return (
    <>
      {items && (
        <>
          <EventFilter
            priceVal={priceVal}
            priceValSet={priceValSet}
            priceBeforeChange={priceBeforeChange}
            priceBeforeChangeSet={priceBeforeChangeSet}
            events={events}
            text={text}
            textSet={textSet}
            date={date}
            dateSet={dateSet}
          />
          <EventWrapper date={date} items={items} events={events} />
        </>
      )}
    </>
  );
};

export default AllEventsV2;
