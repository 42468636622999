import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Calendar from "../common/Calendar";
import { getInitialMonth } from "./util";

const useStyles = makeStyles((theme) => ({
  overrideStartDate: {},
}));

const OverrideStartDate = (product, setOverrideDate) => {
  const classes = useStyles();
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const handleSetOverrideDate = (date) =>
    setOverrideDate(moment(date).hour(6).toISOString());
  return (
    <div className={classes.overrideStartDate}>
      <Typography
        variant="subtitle1"
        className={clsx(
          classes.calendarHeader,
          "product__detail__calendar-header"
        )}
      >
        {language.Choose_Date}
      </Typography>
      <div
        className={clsx(
          classes.calendarContainer,
          "product__detail__calendar-container"
        )}
      >
        <Calendar
          onDateChange={handleSetOverrideDate}
          isOverrideStartdate={true}
          startdateConfig={config.overrideStartdate}
          initialVisibleMonth={() => getInitialMonth(config, product)}
        />
      </div>
    </div>
  );
};

export default OverrideStartDate;
