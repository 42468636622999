import React, { useEffect } from "react";
import {
  addMonths,
  format,
  isBefore,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

const Header = ({
  currentDate,
  setCurrentDate,
  allotmentSettings,
  firstDate,
  latestDate,
  cultureLocale,
  pathname,
  language,
}) => {
  const monthStart = startOfMonth(new Date(firstDate?.time));
  const monthFormat = "MMMM";
  const yearFormat = "yyyy";
  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  useEffect(() => {
    setCurrentDate(new Date(firstDate?.time));
  }, [pathname, firstDate]);

  const lastMonthLabel = format(subMonths(currentDate, 1), "MMMM", {
    locale: cultureLocale,
  });
  const nextMonthLabel = format(addMonths(currentDate, 1), "MMMM", {
    locale: cultureLocale,
  });

  return (
    <>
      {allotmentSettings[0] && (
        <div
          className="calendarHeader row"
          style={{ backgroundColor: allotmentSettings[0].headerBgColor }}
        >
          <div className="column">
            {!isBefore(startOfMonth(currentDate), new Date(firstDate?.time)) ? (
              <button
                className="icon removeBorderBg"
                onClick={prevMonth}
                aria-label={`${language.ShowLastMonth} ${lastMonthLabel}`}
              >
                <IoIosArrowDropleftCircle
                  style={{ color: allotmentSettings[0].headerTextColor }}
                />
              </button>
            ) : (
              <div className="icon">
                <IoIosArrowDropleftCircle
                  style={{
                    opacity: "0",
                    cursor: "default",
                    color: allotmentSettings[0].headerTextColor,
                  }}
                />
              </div>
            )}
          </div>

          <div
            className="column col-center"
            style={{ color: allotmentSettings[0].headerTextColor }}
          >
            <span className="monthtext">
              {format(currentDate, monthFormat, { locale: cultureLocale })}{" "}
            </span>
            <span className="yeartext">{format(currentDate, yearFormat)}</span>
          </div>

          <div className="column col-end">
            {!isSameMonth(new Date(currentDate), new Date(latestDate?.time)) ? (
              <button
                className="icon removeBorderBg"
                onClick={nextMonth}
                aria-label={`${language.ShowNextMonth} ${nextMonthLabel}`}
              >
                <IoIosArrowDroprightCircle
                  style={{ color: allotmentSettings[0].headerTextColor }}
                />
              </button>
            ) : (
              <div className="icon">
                <IoIosArrowDropleftCircle
                  style={{
                    opacity: "0",
                    cursor: "default",
                    color: allotmentSettings[0].headerTextColor,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
