import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "grid",
    gridTemplateColumns: (p) =>
      p.gtc ? p.gtc : p.cols ? `repeat(${p.cols}, 1fr)` : "1fr",
    gridGap: (p) => (p.gap ? p.gap : "8px"),
    "@media(max-width: 768px)": {
      gridTemplateColumns: (p) => "1fr",
    },
  },
}));

const RowLayout = (props) => {
  const { gtc, cols, gap, children } = props;
  const classes = useStyles({ gtc, cols, gap });
  return <div className={classes.row}>{children}</div>;
};

export default RowLayout;
