import { Divider, Grid, Stack, Text } from "@mantine/core";
import React from "react";
import ArticleButtons from "./ArticleButtons";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";

const Article = ({
  ticketData,
  cart,
  cartSet,
  chosenOccasion,
  availableOccasions,
}) => {
  const { culture } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
  }));
  const getName = () => {
    const getTranslation = ticketData.translation.filter(
      (el) => el.culture === culture
    );
    return getTranslation?.[0]?.name;
  };

  return (
    <>
      {ticketData && (
        <Grid justify="space-between">
          <Grid.Col span={6}>
            <Stack>
              <Text weight={500}>{getName()}</Text>
              <Text
                color="dimmed"
                size="sm"
                weight={500}
                sx={{ marginTop: "-1rem" }}
              >
                {ticketData.priceInclVat}
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6} sx={{ textAlign: "right", flexBasis: "0%" }}>
            <ArticleButtons
              chosenOccasion={chosenOccasion}
              cart={cart}
              cartSet={cartSet}
              ticketData={ticketData}
              availableOccasions={availableOccasions}
              name={getName()}
            />
          </Grid.Col>
        </Grid>
      )}
      <Divider size="xs" my={"-0.5rem"} />
    </>
  );
};

export default Article;
