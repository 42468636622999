import {
  Button,
  Container,
  Grid,
  Loader,
  MediaQuery,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATE_CURRENCY } from "../../../common/state";
import Article from "./Article";
import { CART_ARTICLE_ADD, Context, OPEN_ALERT } from "../../../context";
import { addArticleBatch, addToCartTracking } from "../../../common/sale";

const ArticleList = ({ chosenOccasion, availableOccasions }) => {
  const [cart, cartSet] = useState([]);
  const [cartTotal, cartTotalSet] = useState(0);
  const [isLoading, isLoadingSet] = useState(false);
  const [addedToCart, addedToCartSet] = useState(null);
  const { language, currency } = useSelector((state) => ({
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { dispatch } = useContext(Context);
  const rdxDispatch = useDispatch();

  useEffect(() => {
    cartSet([]);
  }, [availableOccasions, chosenOccasion]);

  const total = (item) => {
    switch (currency) {
      case "SEK":
        return item + " kr";
        break;
      case "EUR":
        return "€" + item;
        break;
      case "DKK":
        return item + " kr";
        break;
      case "GBP":
        return "£" + item;
        break;
      case "USD":
        return "$" + item;
        break;
      case "NOK":
        return item + " kr";
        break;
    }
  };

  const productPrice = (item) => {
    if (item && item.length > 0) {
      const regex = /[$€£kr]/g;
      let newPrice = item.replace(regex, "");
      if (newPrice.trim().split(".")[1] === "00") {
        return newPrice.split(".")[0];
      } else {
        return newPrice;
      }
    }
  };

  const calcCartValue = () => {
    const removeChar = (price) => {
      if (price) {
        let newPrice = price && price.replaceAll(/[^0-9\,.]/g, "");
        return parseFloat(newPrice.replace(/[^\d.-]/g, ""));
      }
    };
    let total = 0;
    for (let i = 0; i < cart.length; i++) {
      total += parseFloat(
        removeChar(cart[i].priceInclVat)
          ? removeChar(cart[i].priceInclVat)
          : removeChar(cart[i].displayPrice)
          ? removeChar(cart[i].displayPrice)
          : ""
      );
    }
    cartTotalSet(total.toFixed(2));
  };

  useEffect(() => {
    calcCartValue();
  }, [cart]);

  const reduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const trackReduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          id: curr.plu,
          quantity: 1,
          price: parseFloat(productPrice(curr.priceInclVat).replace(",", ".")),
          name: curr.name,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const cb = (status, msg) => {
    if (status >= 400) {
      dispatch({
        type: OPEN_ALERT,
        payload: {
          text: language.ErrorMsgCouldNotAddToCart,
          severity: "error",
        },
      });
      cartSet([]);
      isLoadingSet(false);
    } else {
      dispatch({
        type: CART_ARTICLE_ADD,
        payload: reduceCart,
      });
      cartSet([]);
      addedToCartSet("success");
      isLoadingSet(false);
    }
  };

  useEffect(() => {
    let addedToCartTimer = setTimeout(() => {
      addedToCartSet(null);
    }, 3000);
    return () => {
      clearTimeout(addedToCartTimer);
    };
  }, [addedToCart]);

  const add = () => {
    addToCartTracking(currency, trackReduceCart);

    isLoadingSet(true);
    setTimeout(() => {
      rdxDispatch(addArticleBatch(reduceCart, cb));
    }, 300);
  };
  return (
    <Container px="0" my="sm" style={{ position: "relative" }}>
      {chosenOccasion && (
        <Stack>
          {chosenOccasion.articles.map((el, i) => (
            <Article
              key={el.id}
              chosenOccasion={chosenOccasion}
              ticketData={el}
              cart={cart}
              cartSet={cartSet}
              availableOccasions={availableOccasions}
            />
          ))}
        </Stack>
      )}
      <Container px="0" py="xl">
        <Grid>
          <Grid.Col md={6} lg={6} xl={6}>
            {cart.length > 0 && (
              <>
                <MediaQuery
                  query="(max-width: 993px)"
                  styles={{ textAlign: "right" }}
                >
                  <Stack sx={{ gap: 0, marginTop: "-0.35rem" }}>
                    <Title order={5}>{language.Total}</Title>
                    <Text
                      size="md"
                      weight={500}
                      component="p"
                      sx={{ margin: 0 }}
                    >
                      {total(productPrice(cartTotal))}
                    </Text>
                  </Stack>
                </MediaQuery>
              </>
            )}
          </Grid.Col>
          <Grid.Col md={6} lg={6} xl={6}>
            <Stack aria-live="assertive">
              <Button
                className={`add_to_cart_event ${
                  addedToCart === "success"
                    ? "mantine-Primary-btn-success"
                    : "mantine-Primary-btn"
                }`}
                classNames={{
                  label: "add_to_cart_event",
                  inner: "add_to_cart_event",
                }}
                size="md"
                radius="sm"
                aria-label={language.AddToCartBtn}
                color={addedToCart === "success" ? "green" : "indigo"}
                disabled={addedToCart !== "success" && cart.length === 0}
                onClick={() => add()}
                sx={{
                  opacity: isLoading ? 0.7 : 1,
                  transition: "all 0.2s ease-in-out",
                  marginTop: "0.1rem",
                  pointerEvents: cart.length === 0 ? "none" : "",
                  width: addedToCart === "success" && "100%",
                }}
              >
                {addedToCart === "success" && !isLoading ? (
                  language.Added_To_Cart
                ) : !isLoading ? (
                  language.AddToCartBtn
                ) : (
                  <Loader
                    color="white"
                    size={"sm"}
                    aria-label={language.Loading}
                  />
                )}
              </Button>
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
    </Container>
  );
};

export default ArticleList;
