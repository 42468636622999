import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import SiteHeading from "../common/SiteHeading";
import Footer from "../common/Footer";
import Routes from "../../routes";
import { ref } from "airbnb-prop-types";

const useStyles = makeStyles((theme) => ({
  appContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // height: (p) =>
    //   p.headerHeight
    //     ? p.useNav
    //       ? `${theme.height - p.headerHeight - 64}px`
    //       : `${theme.height - p.headerHeight}px`
    //     : p.useNav
    //     ? `${theme.height - 128}px`
    //     : `${theme.height - 64}px`,
    // overflowY: "auto",
    // "@media(min-width: 768px)": {
    //   overflowY: "scroll",
    //   "-webkit-overflow-scrolling": "touch",
    // },
    // height: "auto",
    // "@media(max-width: 667px)": {
    //   height: (p) =>
    //     p.headerHeight
    //       ? `${theme.height - p.headerHeight}px`
    //       : `${theme.height - 64}px`,
    // },
  },
}));

const AppContent = () => {
  const location = useLocation();
  const [scrollPos, setScrollPos] = useState({});
  const [height, setHeight] = useState();
  const [totalHeight, setTotalHeight] = useState();
  const contentRef = React.createRef();
  const config = useSelector((state) => state.config);
  const classes = useStyles({
    useNav: config.useNav,
    headerHeight: config.headerHeight,
  });

  useEffect(() => {
    let calc = `calc(100vh - ${
      config?.headerHeight ? config?.headerHeight : "60px"
    }px)`;
    setTotalHeight(calc);
  }, [config]);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref.current]);

  useEffect(() => {
    if (Object.keys(scrollPos).includes(location.pathname)) {
      contentRef.current.scrollTop = scrollPos[location.pathname];
    } else {
      contentRef.current.scrollTop = 0;
    }
  }, [scrollPos, contentRef, location.pathname]);

  const setPosition = () => {
    const page = location.pathname;
    const position = contentRef.current.scrollTop;
    setScrollPos({ [page]: position });
  };
  return (
    <div
      className={clsx(classes.appContent, "app__content-container")}
      id="AppBody"
      ref={contentRef}
      style={{
        marginTop: height,
        minHeight: totalHeight ? totalHeight : "100vh",
      }}
    >
      <main>
        {!config.showSiteHeadingOnlyOnLanding && <SiteHeading />}
        <Routes setPosition={setPosition} />
      </main>
      <Footer />
    </div>
  );
};

export default AppContent;
