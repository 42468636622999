import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { Link } from "@reach/router";
import { makeStyles } from "@material-ui/styles";
import { motion } from "framer-motion";
import {
  STATE_COOKIEBANNER,
  setCookieBanner,
  setConsent,
  STATE_CONSENT,
} from "../../common/state";
import { Title } from "@mantine/core";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "700px",
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#f8f8f8",
    margin: "2rem auto",
    borderRadius: "20px",
    textAlign: "left",
    color: "black",
    alignItems: "center",
    padding: "2rem",
    zIndex: 10000,
  },
  cookieText: {
    textAlign: "left",
  },
  cookieButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  actionButtonContainer: {
    "& Button": {
      margin: "0 0.5rem",
      padding: "0.55rem 1rem",
      textWeight: "600",
      borderRadius: "10px",

      [theme.breakpoints.down("sm")]: {
        marginTop: "1rem",
        marginBottom: "1rem",
      },
    },
  },
}));

const CookieBanner = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = () => dispatch(setCookieBanner());
  const getConsentValue = useSelector((state) => state.cookies[STATE_CONSENT]);
  const language = useSelector((state) => state.language);
  const showConsentBanner = useSelector((state) => state.config);
  const showCookieBanner = useSelector(
    (state) => state.cookies[STATE_COOKIEBANNER]
  );
  const handleConsent = (value) => dispatch(setConsent(value));
  const handleConsentDialog = (value) => {
    handleConsent(value);
    handleClick();
  };
  if (getConsentValue !== "null") return null;
  return (
    <>
      {showCookieBanner !== "false" &&
      getConsentValue === "null" &&
      showConsentBanner.useTagmanager === true ? (
        <div
          className={`${classes.container} cookieContainer`}
          role="dialog"
          aria-modal="false"
          aria-labelledby="cookie-box-title"
          aria-describedby="cookie-box-message"
        >
          <Title order={2} size="large" id="cookie-box-title">
            {language.CookieSettings}
          </Title>
          <p className={classes.cookieText} id="cookie-box-message">
            {language.WeUseCookies}{" "}
            <Link
              className="cookieLink"
              to={`${process.env.PUBLIC_URL}/security`}
            >
              {language.Cookies}
            </Link>{" "}
            {language.CookieConsentBody}
          </p>
          <div className={classes.cookieButtonContainer}>
            <motion.div
              whileTap={{ scale: 1.2 }}
              className={classes.actionButtonContainer}
            >
              <Button
                color="secondary"
                className="declineButton"
                onClick={() => handleConsentDialog(false)}
              >
                {language.CookieDecline}
              </Button>
            </motion.div>

            <motion.div
              whileTap={{ scale: 1.2 }}
              className={classes.actionButtonContainer}
            >
              <Button
                className="consentButton"
                onClick={() => handleConsentDialog(true)}
              >
                {language.CookieConsent}
              </Button>
            </motion.div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showCookieBanner !== false &&
      showCookieBanner !== "false" &&
      showConsentBanner.useTagmanager === false ? (
        <div
          className={`${classes.container} cookieContainer`}
          role="dialog"
          aria-modal="false"
          aria-labelledby="cookie-box-title"
          aria-describedby="cookie-box-message"
        >
          <Title order={2} size="large" id="cookie-box-title">
            {language.CookieSettings}
          </Title>
          <p className={classes.cookieText} id="cookie-box-message">
            {language.FunctionalCookies}{" "}
            <Link
              className="cookieLink"
              to={`${process.env.PUBLIC_URL}/security`}
            >
              {language.Cookies}
            </Link>{" "}
            {language.OnSiteCookies}
          </p>
          <div className={classes.cookieButtonContainer}>
            <motion.div
              whileTap={{ scale: 1.2 }}
              className={classes.actionButtonContainer}
            >
              <Button className="consentButton" onClick={handleClick}>
                {language.CookieSimpleConsent}
              </Button>
            </motion.div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CookieBanner;
