import {
  Button,
  Container,
  createStyles,
  Image,
  MediaQuery,
  SimpleGrid,
  Skeleton,
  Space,
  Text,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { isAfter, isSameMonth } from "date-fns";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import TimePicker from "./TimePicker";
import "./styles.css";
import _ from "lodash";
import EventCalendarV2 from "./Calendar";
import { navigate, useLocation } from "@reach/router";

const useStyles = createStyles((theme) => ({
  button: {
    border: "1px solid #8A8C93",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    paddingTop: 0,
    paddingBottom: ".5rem",
    paddingLeft: 0,
    "@media screen and (max-width: 768px)": {
      paddingLeft: "1rem",
    },
  },
}));

const EventV2 = (props) => {
  const [isLoading, isLoadingSet] = useState(true);
  const [text, textSet] = useState("");
  const [event, eventSet] = useState(null);
  const [day, daySet] = useState(null);
  const [lastOccasion, lastOccasionSet] = useState();
  const [firstOccasion, firstOccasionSet] = useState();
  const [month, monthSet] = useState(new Date());
  const [initialMonth, initialMonthSet] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [cultureLocale, setCultureLocale] = useState();
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const image = `${uri}/allotmentimage/${props?.event?.imageFileName}`;
  const location = useLocation();
  const filter = location.state;
  let pathname = location.pathname;
  const { classes } = useStyles();

  useEffect(() => {
    firstOccasionSet(_.first(props.event.occasions));
    lastOccasionSet(_.last(props.event.occasions));
  }, [props.event.occasions]);

  useEffect(() => {
    daySet(null);
    if (props.event.occasions) {
      const getEventByMonth =
        props.event.occasions &&
        props.event.occasions.filter((el) =>
          isSameMonth(new Date(el.time), new Date(currentDate))
        );
      if (getEventByMonth) {
        eventSet(getEventByMonth);
      }
    }
  }, [currentDate, pathname, month]);

  useEffect(() => {
    if (Object.keys(props.event).length > 1) {
      isLoadingSet(false);
    }
    const filterTextByCulture = props.event.translation.filter(
      (el) => el.culture === culture
    );
    if (filterTextByCulture) {
      textSet({
        name: filterTextByCulture[0]?.name,
        description: filterTextByCulture[0]?.description,
      });
    }
  }, [props, culture]);

  useEffect(() => {
    if (filter && props.event.occasions.length > 0) {
      const reduce = props.event.occasions.reduce((acc, curr) => {
        if (isAfter(new Date(curr.time), filter[0])) {
          acc.push(curr.time);
        }
        return acc;
      }, []);
      if (reduce && reduce.length > 0) {
        initialMonthSet(new Date(reduce[0]));
      }
    }
  }, [filter, props.event.occasions]);

  return (
    event && (
      <>
        <Container className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            variant="default"
            mb={2}
            onClick={() => navigate(-1)}
          >
            {language.Back}
          </Button>
        </Container>
        <MediaQuery query="(min-width: 768px)" styles={{ padding: "0rem" }}>
          <Container>
            <Skeleton visible={isLoading}>
              <SimpleGrid
                spacing={"xl"}
                cols={2}
                breakpoints={[
                  {
                    maxWidth: 768,
                    cols: 1,
                    spacing: "sm",
                  },
                ]}
              >
                <div style={{ height: "20rem" }}>
                  <Image
                    src={image}
                    withPlaceholder
                    height={!props.event.ImageFileName ? "20rem" : "100%"}
                    alt={`${language.EventAltLabel} ${text?.name}`}
                  />
                </div>
                <Container sx={{ padding: 0, width: "100%" }}>
                  <Title mb="lg" order={1}>
                    {text.name}
                  </Title>
                  <Markdown
                    source={text.description}
                    escapeHtml={false}
                    skipHtml={false}
                  />
                </Container>
              </SimpleGrid>
            </Skeleton>
            <Space h="xl" />
            <Space h="xl" />

            <SimpleGrid
              spacing="xl"
              cols={2}
              breakpoints={[{ maxWidth: 768, cols: 1 }]}
            >
              <SimpleGrid cols={1} spacing="xs" sx={{ height: "fit-content" }}>
                <Title order={2} style={{ fontSize: "1.25rem" }}>
                  {language.ChooseDateHeader}
                </Title>
                <Text
                  size="s"
                  component="small"
                  color="gray.7"
                  weight={400}
                  sx={{ marginTop: "-0.5rem", marginBottom: "1rem" }}
                >
                  {language.ChooseDateDescription}
                </Text>

                <EventCalendarV2
                  cultureLocale={cultureLocale}
                  setCultureLocale={setCultureLocale}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  firstOccasion={firstOccasion}
                  lastOccasion={lastOccasion}
                  occasions={event}
                  month={month}
                  monthSet={monthSet}
                  day={day}
                  daySet={daySet}
                  initialMonth={initialMonth}
                />
              </SimpleGrid>

              <div style={{ opacity: !day ? 0.35 : 1 }}>
                <TimePicker
                  day={day}
                  occasions={event}
                  allotment={props.event}
                  cultureLocale={cultureLocale}
                />
              </div>
            </SimpleGrid>
          </Container>
        </MediaQuery>
      </>
    )
  );
};
export default EventV2;
