import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { Link } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Delete";
import IncreaseIcon from "@material-ui/icons/ExpandLess";
import DecreaseIcon from "@material-ui/icons/ExpandMore";
import { getProductConfig } from "./util";
import Placeholder from "../../images/placeholder_thumbnail.jpg";
import { Context, OPEN_ALERT } from "../../context";
import { DataContext } from "../common/dataProvider";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 500,
  },
  tableRow: {
    "& th": {
      padding: "2px 4px",
      fontSize: "0.8125rem",
    },
    "& td": {
      padding: "6px 4px",
      lineHeight: 1.2,
      "& button": {},
    },
  },
  articleInfo: {
    fontSize: "0.75rem",
    lineHeight: 1.5,
  },
  iconButton: {
    padding: "8px",
  },
  footerRow: {
    height: "auto",
  },
  deleteCell: {
    paddingRight: "8px",
    "&:last-child": {
      paddingRight: "8px",
    },
  },
  footerCell: {
    padding: 0,
    paddingRight: "8px",
    borderBottom: "none",
    "&:last-child": {
      paddingRight: "8px",
    },
  },
  quantityCell: {
    textAlign: "center",
  },
  changeQuantity: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width: 667px)": {
      display: "grid",
    },
  },
  changeQuantityText: {
    padding: "0 4px",
  },
  articleContainer: {
    display: "flex",
    alignItems: "center",
  },
  articleImageContainer: {
    paddingRight: "10px",
  },
  articleImage: {
    maxWidth: "100px",
    borderRadius: "4px",
  },
  articleNameContainer: {
    padding: "0 6px",
    flexGrow: "1",
  },
  articleName: {},
  noWrap: {
    whiteSpace: "nowrap",
  },
  styleArticleName: {
    fontStyle: "italic",
    textDecoration: "line-through",
  },
}));

const CartItemList = (props) => {
  const {
    sale,
    remove,
    increase,
    decrease,
    locale,
    culture,
    language,
    config,
  } = props;
  const {
    data: { allotments },
  } = useContext(DataContext);
  const classes = useStyles();
  const { dispatch } = useContext(Context);
  const fees = () => sale.items.filter((item) => item.article.isFee);
  const notFees = () => sale.items.filter((item) => !item.article.isFee);
  const [forceRerender, setForceRerender] = useState(false);

  const cb = (status, msg) => {
    if (status === 406) {
      return dispatch({
        type: OPEN_ALERT,
        payload: {
          text: language.Could_Not_Reserve,
          severity: "error",
        },
      });
    }
  };

  useEffect(() => {
    if (forceRerender === true) {
      setForceRerender(false);
    }
  }, [forceRerender]);

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader padding="none">
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell>{language.Cart_Article}</TableCell>
            <TableCell className={classes.quantityCell}>
              {language.Cart_Quantity}
            </TableCell>
            <TableCell align="right">{language.Cart_Price}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {notFees().map((item, i) => {
            const allotment =
              item.allotmentId &&
              allotments.find((a) => a.id === item.allotmentId);

            const allotmentImage = allotment?.imageFileName;
            const allotmentName =
              allotment?.translation.find((t) => t.culture === culture)?.name ||
              allotment?.translation.find((t) => t.culture === "sv-SE")?.name ||
              allotment?.description;

            const { thumbnail, slug } = getProductConfig(
              config,
              item,
              config.imageFolderUrl,
              process.env.PUBLIC_URL,
              allotmentImage
            );

            const ref = React.createRef();
            const name =
              item.article.translation.find((t) => t.culture === culture)
                ?.name ||
              item.article.translation.find((t) => t.culture === "sv-SE")
                ?.name ||
              item.article.name;
            const hasOccasion = item.occasion && item.occasion.length > 0;
            const occasion = moment(item.occasion).isSameOrAfter(
              moment(),
              "day"
            )
              ? moment(item.occasion).locale(locale).format("lll")
              : null;
            const hasInformation = item.information.length > 0;

            let styleArticleName;

            if (moment().isAfter(item.expires)) {
              styleArticleName = classes.styleArticleName;
            } else {
              styleArticleName = null;
            }
            if (
              moment().isAfter(sale.firstArticleExpiresAt) &&
              forceRerender === false
            ) {
              setForceRerender(true);
            }

            return (
              <TableRow className={classes.tableRow} key={i}>
                <TableCell>
                  <div className={classes.articleContainer}>
                    <div className={classes.articleImageContainer}>
                      <img
                        ref={ref}
                        src={thumbnail ? thumbnail : Placeholder}
                        onError={() => (ref.current.src = Placeholder)}
                        alt="article"
                        className={clsx(
                          classes.articleImage,
                          "cart__thumbnail"
                        )}
                      />
                    </div>
                    <div className={styleArticleName}>
                      {slug ? (
                        <Link
                          to={slug}
                          className={
                            (classes.articleName,
                            "cart__article-name primary-text")
                          }
                        >
                          {name}
                        </Link>
                      ) : (
                        <div
                          className={
                            (classes.articleName,
                            "cart__article-name primary-text")
                          }
                        >
                          {name}
                        </div>
                      )}
                      {hasOccasion && (
                        <div
                          className={clsx(
                            classes.articleInfo,
                            "cart__article-info secondary-text"
                          )}
                        >
                          {allotmentName}
                          <br />
                          {occasion}
                        </div>
                      )}
                      {item.ticketOwners.map((o, i) => (
                        <div
                          key={i}
                          className={clsx(
                            classes.articleInfo,
                            "cart__article-info secondary-text"
                          )}
                        >
                          {o.name}
                        </div>
                      ))}
                      {hasInformation && (
                        <div
                          className={clsx(
                            classes.articleInfo,
                            "cart__article-info secondary-text"
                          )}
                        >
                          {item.information}
                        </div>
                      )}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.quantityCell}>
                  {item.canChangeQuantity ? (
                    <div className={classes.changeQuantity}>
                      <IconButton
                        onClick={() => increase(item.key, cb)}
                        className={classes.iconButton}
                      >
                        <IncreaseIcon />
                      </IconButton>
                      <div
                        className={clsx(
                          classes.changeQuantityText,
                          "cart__item-quantity primary-text"
                        )}
                      >
                        {item.quantity}
                      </div>
                      <IconButton
                        onClick={() => decrease(item.key, () => null)}
                        className={classes.iconButton}
                      >
                        <DecreaseIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        classes.changeQuantityText,
                        "cart__item-quantity primary-text"
                      )}
                    >
                      {item.quantity}
                    </div>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  className={clsx(classes.noWrap, "primary-text")}
                >
                  {item.pricePerPieceInclVat}
                </TableCell>
                <TableCell className={classes.deleteCell} align="right">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => remove(item.key)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          {fees().map((fee, i) => {
            return (
              <TableRow key={i} className={classes.footerRow}>
                <TableCell
                  className={classes.footerCell}
                  colSpan={3}
                  align="right"
                >
                  {`${fee.article.name}:`}
                </TableCell>
                <TableCell
                  className={classes.footerCell}
                  colSpan={3}
                  align="right"
                >
                  {fee.totalPriceInclVat}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow className={classes.footerRow}>
            <TableCell
              className={clsx(classes.footerCell, "primary-text")}
              colSpan={3}
              align="right"
            >
              {language.Cart_Sum}
            </TableCell>
            <TableCell
              className={clsx(
                classes.footerCell,
                classes.noWrap,
                "primary-text"
              )}
              colSpan={1}
              align="right"
            >
              {sale.sumInclVat}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CartItemList;
