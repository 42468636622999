import { createStyles, Image, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import "./styles.css";

const useStyles = createStyles((theme) => ({
  chooseVariant: {
    fontWeight: "bold",
  },
  chooseArticle: {
    color: theme.colors.gray[7],
    marginTop: "-.5rem",
    fontSize: "0.8rem",
    fontWeight: "400",
  },
  produtSelectorWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: ".8rem",
  },
  productImageSelector: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(7rem, 1fr))",
    gap: "1rem",
    "@media screen and (max-width: 768px)": {
      gridTemplateColumns: "repeat(auto-fit, minmax(6rem, 1fr))",
    },
  },
  productWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  container: {
    marginTop: "1rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    gap: ".8rem",
    marginTop: "0.5rem",
  },

  imageButton: {
    width: "100%",
    border: "none",
    background: "none",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },

  productImage: {
    height: "6rem",
    width: "7rem",
    objectFit: "cover",
    "@media screen and (max-width: 768px)": { height: "6rem", width: "6rem" },
  },
}));
const ProductSelector = ({ product, selected, selectedSet }) => {
  const [value, valueSet] = useState(null);
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));
  const { classes } = useStyles();

  const itemsArr =
    !product.openPriceValues &&
    product.articles.reduce((acc, curr, index) => {
      acc.push({
        key: index,
        value: curr.id,
        label:
          curr.inStock <= 0
            ? curr.name + " " + language.ArticleBooking.OutOfStock
            : curr.name + " " + curr.priceInclVat,
        disabled: curr.inStock <= 0,
      });
      return acc;
    }, []);

  const openPriceValues =
    product.openPriceValues &&
    product.openPriceValues.reduce((acc, curr, index) => {
      acc.push({
        key: index,
        value: curr,
        label: curr + " kr",
      });
      return acc;
    }, []);

  useEffect(() => {
    if (value && product.articles) {
      const getItem = product.articles.filter((el) => el.id === value);
      if (getItem) {
        selectedSet(getItem);
      } else {
        selectedSet(null);
      }
    }
    if (value && product.openPriceValues) {
      selectedSet({ plu: product.plu, priceInclVat: value, quantity: 1 });
    }
  }, [product, value]);

  const getName = (item) => {
    return item.translation.filter((el) => el.culture === culture)[0].name;
  };

  const image = (item) => {
    if (item.imageFilename) {
      const uri = config?.apiUriOverride?.startsWith("http")
        ? config.apiUriOverride
        : config.apiUri;
      return `${uri}/articleimage/${item.imageFilename}`;
    }
    return;
  };

  return (
    <div className={classes.container}>
      <div className={`productSelector ${classes.wrapper}`}>
        {config?.useProductImageSelector && !product?.openPriceValues ? (
          <div className={classes.produtSelectorWrapper}>
            <span className={classes.chooseVariant}>
              {language.ChooseVariant}
            </span>
            <span className={classes.chooseArticle}>
              {language.ChooseArticle}
            </span>
            <div className={classes.productImageSelector}>
              {!product?.openPriceValues &&
                product.articles.map((el, i) => (
                  <div className={classes.productWrapper} key={i}>
                    <button
                      className={classes.imageButton}
                      onClick={() => valueSet(el.id)}
                      aria-label={`${language.ChooseVariant} ${getName(el)} ${
                        el?.id === value ? language.Chosen : ""
                      }`}
                    >
                      <Image
                        className={`${classes.productImage} groupImage`}
                        src={image(el)}
                        withPlaceholder
                        alt={`${language.Product} ${getName(el)} ${
                          language.AddToCartBtn
                        }`}
                        radius="sm"
                        caption={getName(el)}
                        data-isselected={el?.id === value}
                      />
                    </button>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          !product?.openPriceValues &&
          itemsArr &&
          itemsArr.length > 0 && (
            <Select
              label={language.ChooseVariant}
              placeholder={language.Product}
              size="md"
              value={value}
              onChange={valueSet}
              data={itemsArr}
              style={{ paddingTop: 0 }}
            />
          )
        )}
        {product?.openPriceValues && (
          <Select
            label={language.ChooseVariant}
            placeholder={language.Product}
            size="md"
            value={value}
            onChange={valueSet}
            data={openPriceValues}
            style={{ paddingTop: 0 }}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSelector;
