import moment from "moment";
import slugify from "../common/slugify";

export const getEventConfig = (config, name) => {
  const { eventConfig, imageFolderUrl } = config;
  const event = eventConfig.find((e) => e.name === name);
  if (!event) {
    return {
      name: name,
      slug: `/events/${slugify(name)}`,
      images: null,
      listImages: null,
      description: "",
    };
  }
  return {
    name: event.name,
    slug: `/events/${slugify(event.name)}`,
    images:
      event.images.length < 1
        ? null
        : event.images.map((img) => `${imageFolderUrl}${img}`),
    listImages: event.listImages
      ? event.listImages.length < 1
        ? null
        : event.listImages.map((img) => `${imageFolderUrl}${img}`)
      : null,
    description: event.description || "",
    exerpt: event.exerpt,
  };
};

export const getMonths = (events) => {
  let filters = [];
  let months = [];

  events.forEach((e) => {
    e.occasions.forEach((o) => {
      let filter = moment(o.time).format("YYYYMM");

      if (!filters.includes(filter)) {
        const occ = {
          year: moment(o.time).format("YYYY"),
          month: Number(moment(o.time).format("MM") - 1),
          filter: Number(filter),
        };
        filters = [...filters, filter];
        months = [...months, occ];
      }
    });
  });
  const sortedMonths = months.sort((a, b) => a.filter - b.filter);

  return sortedMonths;
};

export const getMonthsByYear = (events) => {
  const months = getMonths(events);

  let years = [];
  let byYear = [];
  months.forEach((m) => {
    if (!years.includes(m.year)) {
      years = [...years, m.year];
    }
  });
  years.forEach((y) => {
    const monthsInYear = months.filter((m) => m.year === y);
    byYear = [...byYear, { year: y, months: monthsInYear }];
  });
  return byYear;
};

export const getFirstOccasionInFilter = (occasions, filter) => {
  const filterMonth = filter ? moment(`${filter}01`).month() : null;
  const occasionsInFilter = occasions.filter(
    (o) => moment(o.time).month() === filterMonth
  );
  let occasionDates = [];
  occasionsInFilter.forEach((o) => {
    occasionDates = [...occasionDates, new Date(o.time)];
  });
  return moment(occasionDates.sort((a, b) => a - b));
};

export const filterEvents = (events, filter) => {
  if (!filter || !events) return [];

  //event that have occasions in filter month
  let filterEvents = [];

  events.forEach((e) => {
    e.occasions.forEach((o) => {
      if (Number(moment(o.time).format("YYYYMM")) === filter) {
        if (!filterEvents.includes(e.discription)) {
          filterEvents = [...filterEvents, e.description];
        }
      }
    });
  });
  const filteredEvents = events.filter((e) =>
    filterEvents.includes(e.description)
  );
  // sort by first event
  filteredEvents.sort(
    (a, b) =>
      moment(a.occasions[0].time).toDate() -
      moment(b.occasions[0].time).toDate()
  );
  return filteredEvents;
};

export const getOccasionMonths = (events) => {
  let occasionMonths = [];
  events.forEach((e) => {
    e.occasions.forEach((o) => {
      const formattedMonth = moment(o.time).format("YYYYMM");
      if (!occasionMonths.includes(formattedMonth)) {
        occasionMonths = [...occasionMonths, formattedMonth];
      }
    });
  });
  return occasionMonths.sort();
};

export const hasMonthEvent = (month, events) => {
  const occasionMonths = getOccasionMonths(events);
  return occasionMonths.includes(month);
};

export const getOccasionTimes = (time) => ({
  from: moment(time).hour(0).minute(0).seconds(0).toISOString(),
  until: moment(time).hour(23).minute(59).seconds(59).toISOString(),
});

export const getOccasionsByDay = (occasions) => {
  let byDay = [];
  let days = [];
  occasions.forEach((o) => {
    const day = moment(o.time).format("YYYYMMDD");
    if (!days.includes(day)) {
      days = [...days, day];
      byDay = [...byDay, { day, times: [] }];
    }
  });

  return byDay.map((d) => {
    let newTimes = [];
    occasions.forEach((o) => {
      const oTime = moment(o.time).format("HH:mm");
      const oDay = moment(o.time).format("YYYYMMDD");
      if (d.day === oDay) {
        newTimes = [...newTimes, oTime];
      }
    });
    return { ...d, times: newTimes };
  });
};
