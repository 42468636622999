import React, { useState, useEffect, useContext, useRef } from "react";
import { BiSearch } from "react-icons/bi";
import { STATE_CULTURE } from "../../../common/state";
import useClickDetector from "./hooks/useClickDetector";
import "./search.css";
import { useSelector } from "react-redux";
import SearchInput from "./SearchInput";
import SearchResult from "./SearchResult";
import { DataContext } from "../dataProvider";
import { useFocusTrap } from "@mantine/hooks";

const SearchV2 = ({ changeBackground, mobile }) => {
  const [toggleSearch, setToggleSearch] = useState(false);
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const { data } = useContext(DataContext);
  const [allotments, setAllotments] = useState([]);
  const [articles, setArticles] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const focusTrapRef = useFocusTrap(toggleSearch ? true : false);
  const ref = useRef(null);
  useClickDetector(ref, toggleSearch, setToggleSearch, setSearchValue);

  useEffect(() => {
    setAllotments(data?.allotments);
    setArticles(data?.articles);
  }, [data]);

  const calcHeight = () => {
    let headerHeight = config?.headerHeight;
    let calc = headerHeight / 3.5 + "px";
    return calc;
  };

  return (
    <>
      <div data-isactive={toggleSearch} className="search-backdrop" />
      <div
        data-isactive={toggleSearch}
        className="search-wrapper"
        ref={ref}
        style={{ top: toggleSearch && !mobile ? calcHeight() : "" }}
        role="search"
      >
        <button
          className="search-button"
          onClick={() => setToggleSearch(true)}
          data-isactive={toggleSearch}
          aria-label={language?.Search}
        >
          {config?.useNewHeader ? (
            <BiSearch
              className={`search-icon ${
                changeBackground ? "darkColor" : "lightColor"
              }`}
              data-isactive={toggleSearch}
              data-ison={mobile}
              viewBox="0 0 23 23"
            />
          ) : (
            <BiSearch
              className={`search-icon
          }`}
              viewBox="0 0 23 23"
              data-isactive={toggleSearch}
            />
          )}
        </button>
        <div ref={focusTrapRef} className="search-inner-wrapper">
          <SearchInput
            toggleSearch={toggleSearch}
            setToggleSearch={setToggleSearch}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            language={language}
          />
          <SearchResult
            toggleSearch={toggleSearch}
            setToggleSearch={setToggleSearch}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            allotments={allotments}
            articles={articles}
            loading={loading}
            setLoading={setLoading}
            culture={culture}
          />
        </div>
      </div>
    </>
  );
};

export default SearchV2;
