import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import { makeStyles } from "@material-ui/styles";
import "./styles.css";
import { useSelector } from "react-redux";

const AddedButton = (props) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const [hovered, setHovered] = useState(false);
  const [spinner, setSpinner] = useState(require("./animations/loaderwhite"));

  const animationContainer2 = useRef(null);

  const anim2 = useRef(null);

  useEffect(() => {
    if (animationContainer2.current) {
      anim2.current = lottie.loadAnimation({
        container: animationContainer2.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: spinner,
      });
      anim2.current.setSpeed(1.25);

      return () => anim2.current.destroy();
    }
  }, []);

  const useStyles = makeStyles({
    btn: {
      position: "relative",
      width: "45px",
      height: "45px",
    },

    wrapper2: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
      padding: "3px 23px",
      minWidth: "10px",
      fontFamily: "Roboto, arial",
      backgroundColor: "rgb(135, 218, 97)",
      color: ({ outline }) => (outline ? "black" : "white"),
      letterSpacing: "0.7px",
      fontSize: "17px",
      cursor: "pointer",
      transition: "all 0.3s ease ",
      borderRadius: ({ round }) => (round ? "50px" : "10px"),
    },
    btnText: {
      fontWeight: "600",
      fontSize: "1rem",
      margin: "0",
      padding: "0",
    },
  });

  const classes = useStyles(props);

  return (
    <div
      className={`${classes.wrapper2} addedButton`}
      onMouseEnter={() => {
        anim2.current.setDirection(hovered ? -1 : 1);
        anim2.current.play();
      }}
      aria-label={language.Loading}
    >
      <div className={classes.btn}>
        <div className="cartIcon" ref={animationContainer2} />
      </div>
    </div>
  );
};
export default AddedButton;
