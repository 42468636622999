import React from "react";
import { createStyles, Radio } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: "1rem !important",
  },
  description: {
    fontSize: "1rem",
    marginTop: "0.25rem",
    paddingBottom: ".5rem",
    color: "black",
  },
  radio: {
    textAlign: "left",
  },
}));

const RadioGroupField = ({
  form,
  label,
  description,
  values,
  required,
  index,
}) => {
  const { classes } = useStyles();

  return (
    <div className={"pbCheckbox"}>
      <Radio.Group
        classNames={{ label: classes.label, description: classes.description }}
        label={label}
        description={description}
        withAsterisk={required}
        {...form.getInputProps(`formFields.${index}.stringVal`)}
      >
        {values.map((el) => (
          <Radio
            classNames={{ label: classes.radio }}
            key={el}
            label={el}
            value={el}
            color="indigo"
          />
        ))}
      </Radio.Group>
    </div>
  );
};

export default RadioGroupField;
