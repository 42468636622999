import { useEffect, useState } from "react";

const Refetch = (props) => {
  const { version, culture, currency, auth, refetch } = props;
  useEffect(() => {
    refetch();
  }, [version, culture, currency, auth, refetch]);
  return null;
};

export default Refetch;

export const RefetchSaleQuery = (props) => {
  const [prevVersion, setVersion] = useState();
  const { version, refetch } = props;
  useEffect(() => {
    if (prevVersion !== version) {
      // Timeout to wait for server
      window.setTimeout(() => {
        refetch();
      }, 300);
      setVersion(version);
    }
  }, [version, prevVersion, refetch]);
  return null;
};
