import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "@reach/router";
import { makeStyles } from "@material-ui/styles";
import Collapse from "@material-ui/core/Collapse";
import { Context, CLOSE_CART, CART_ARTICLE_REMOVE } from "../../context";
import AddedProduct from "./addedProduct";
import { SaleContext } from "../common/saleProvider";
import { useSelector } from "react-redux";
import DrawerCart from "../Drawer";

const useStyles = makeStyles({
  cartContainer: {
    position: "fixed",
    top: "var(--header-height)",
    right: "0px",
    zIndex: 1200,
  },
  paper: {
    width: "100%",
  },
});

let cartTimeout;
const Cart = (props) => {
  const classes = useStyles();
  const [prevQuantity, setPrevQuantity] = useState(null);
  const [abortClose, setAbortClose] = useState(false);
  const navigate = useNavigate();
  const {
    state: { cartOpen },
    dispatch,
  } = useContext(Context);
  const { config } = useSelector((state) => ({
    config: state.config,
  }));
  const saleContext = useContext(SaleContext);

  const { sale } = saleContext.state;
  useEffect(() => {
    if (cartOpen && config?.timeoutDrawer) {
      if (abortClose) {
        window.clearTimeout(cartTimeout);
      } else {
        cartTimeout = window.setTimeout(() => {
          dispatch({ type: CLOSE_CART });
        }, 5000);
      }
    }
  }, [cartOpen, abortClose, dispatch]);

  if (!sale) return <div />;
  return (
    <>
      <Collapse
        in={sale.itemQuantity > 0 && cartOpen}
        className={classes.cartContainer}
        onMouseEnter={() => setAbortClose(true)}
        onMouseLeave={() => {
          dispatch({ type: CLOSE_CART });
          setAbortClose(false);
        }}
        onClick={() => navigate(`${process.env.PUBLIC_URL}/cart`)}
        onExited={() => dispatch({ type: CART_ARTICLE_REMOVE })}
      >
        <div>
          <AddedProduct
            cartOpen={cartOpen}
            sale={sale}
            prevQuantity={prevQuantity}
            setPrevQuantity={setPrevQuantity}
          />
        </div>
      </Collapse>
      <DrawerCart
        cartOpen={cartOpen}
        sale={sale}
        prevQuantity={prevQuantity}
        setPrevQuantity={setPrevQuantity}
      />
    </>
  );
};

export default Cart;
