import { createStyles, Indicator } from "@mantine/core";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Context, OPEN_CART } from "../../context";
import { SaleContext } from "../common/saleProvider";

const useStyles = createStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "0.7rem",
    marginRight: "0.9rem",
    paddingTop: "0.3rem",
  },
  button: {
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  shoppingBag: {
    minWidth: "1.6rem",
    minHeight: "1.6rem",
    // color: "var(--header-link-color-light)",
    color: "inherit",
    "@media screen and (max-width: 768px)": {},
    strokeWidth: "2px",
  },
  indicator: {
    fontWeight: "500",
  },
});

const CartV2 = ({ changeBackground }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const {
    state: { sale },
  } = useContext(SaleContext);
  const { classes } = useStyles();
  const { dispatch } = useContext(Context);

  const openCart = () => {
    dispatch({ type: OPEN_CART });
  };

  return (
    <div className={`${classes.wrapper} cart`}>
      <Indicator
        inline
        label={sale?.itemQuantity}
        size={20}
        className={classes.indicator}
      >
        <button
          className={classes.button}
          onClick={() => openCart()}
          aria-label={language.OpenCart}
        >
          {sale?.itemQuantity > 0 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className={`${classes.shoppingBag} ${
                changeBackground ? "darkColor" : "lightColor"
              }`}
            >
              <path
                fillRule="evenodd"
                d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`${classes.shoppingBag} ${
                changeBackground ? "darkColor" : "lightColor"
              }`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              />
            </svg>
          )}
        </button>
      </Indicator>
    </div>
  );
};

export default CartV2;
