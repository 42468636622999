import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import UserIcon from "@material-ui/icons/AccountCircle";
import { Context } from "../../context";

const useStyles = makeStyles((theme) => ({
  // userButton: {
  //   "@media(max-width: 667px)": {
  //     display: "none",
  //   },
  // },
  // loginButton: {
  //   marginLeft: "4px",
  //   color: "inherit",
  //   "@media(max-width: 667px)": {
  //     display: "none",
  //   },
  // },
}));

const Login = ({ changeBackground }) => {
  const classes = useStyles();
  const { handleLogin } = useContext(Context);
  const { config, auth, language } = useSelector((state) => ({
    auth: state.oidc.user !== null && !state.oidc.user.expired,
    language: state.language,
    config: state.config,
  }));
  return auth ? (
    <Link className="nav-headerLoginButton" to={`${process.env.PUBLIC_URL}/user`} title={language.Account}>
      <IconButton
        className={clsx(
          !config?.useNewHeader ? classes.userButton : "",
          "header__user-button"
        )}
        style={{ color: changeBackground ? "black" : "white" }}
        aria-label="User"
      >
        <UserIcon />
      </IconButton>
    </Link>
  ) : (
    <Button
      className={clsx(
        !config?.useNewHeader ? classes.userButton : "",
        "header__login-button"
      )}
      style={{
        color: config?.useNewHeader
          ? changeBackground
            ? "black"
            : "white"
          : "",
      }}
      onClick={handleLogin}
    >
      {(language && language.Log_On) || ""}
    </Button>
  );
};

export default Login;
