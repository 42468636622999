import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link, navigate, useLocation } from "@reach/router";

const ChosenButton = (props) => {
  const [activeBtn, setActiveBtn] = useState(false);
  const useStyles = makeStyles({
    occasionBtn: {
      minWidth: "11rem",
      width: "100%",
      padding: " 0 2rem",
      height: "3.5rem",
      borderRadius: "10px",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      fontSize: "1rem",
      fontWeight: "600",
      cursor: "pointer",

      transition: "all 0.1s ease",
      "&:disabled": {
        pointerEvents: "none",
        opacity: "0.3",
      },
      "&:hover": {
        transform: "translateY(-1px)",
      },
    },
    btnBackground: {
      "&:hover": {
        opacity: ({ primaryBtn }) => (primaryBtn ? "0.7" : ""),
      },
    },
  });
  const classes = useStyles(props);
  const location = useLocation()
  const click = (data) => {
    setActiveBtn(data);
    props.setIsVisible(false);
    
    setTimeout(() => {
      props.setSection(data);
    }, 50);

  };

  useEffect(() => {
      setActiveBtn(props.data)
      sessionStorage.setItem("articleBookingBtn", props.data)
      props.setSection(props.data)
      props.setIsVisible(true)
  },[props.data])


  
  // useEffect(() => {
  //   if (props.section.length === 0) {
  //     setActiveBtn(false);
  //   }
  //   if (props.text === props.section.name) {
  //     setActiveBtn(true);
  //   } else {
  //     setActiveBtn(false);
  //   }
  
  // }, [props.section]);

  // useEffect(() => {
  //   props.setIsVisible(true);

  //   setTimeout(() => {
  //   props.scrollIntoView("IntroductionSection")

  //   }, 70);
  // }, [props.section]);

  // useEffect(() => {
  //   setActiveBtn("")
  // },[location.pathname])

  return (
    <div>
      <button
        className={`${classes.occasionBtn}  ${
          activeBtn ? "activeArticleButton" : "articleBookingButton"
        }`}
        onClick={() => click(props.data)}
        // disabled={props.disabled}
        style={{ pointerEvents: activeBtn ? "none" : "" }}
        aria-label={props.text}
      >
        {props.text}
      </button>
    </div>
  );
};

export default ChosenButton;
