import React from "react";
import Modal from "react-modal";
import ModalButton from "./ModalButton";
import { motion } from "framer-motion";
import SuccessAnimation from "./animations/SuccessAnimation";

const ModalDialog = ({ addedOrder, setAddedOrder, language, selfUrl }) => {
  const style = {
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    paddingLeft: "20px",
    zIndex: "2000",
    position: "absolute",
    backgroundColor: "rgb(0, 0, 0, 0.5)",
  };
  const variants = {
    initial: {
      opacity: 0,
      transition: {
        duration: 1,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };
  return (
    <Modal
      isOpen={addedOrder}
      style={style}
      className="modalContent modalOverlay"
      appElement={document.getElementById("root") || undefined}
    >
      <motion.div
        className="modal"
        variants={variants}
        initial="initial"
        exit="initial"
        animate="visible"
      >
        <h2>{language.ArticleBooking.ModalHeader}</h2>

        <div className="animationWrapper">
          <SuccessAnimation />
        </div>
        
        <p>{language.ArticleBooking.ModalText}</p>
        <div className="modalBtnWrapper">
          <ModalButton
            text={language.ArticleBooking.ModalSecondaryButton}
            setAddedOrder={setAddedOrder}
          />
          <ModalButton
            primaryBtn
            text={language.ArticleBooking.ModalPrimaryButton}
            redirect
            setAddedOrder={setAddedOrder}
            selfUrl={selfUrl}
          />
        </div>
      </motion.div>
    </Modal>
  );
};

export default ModalDialog;
