import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import { makeStyles } from "@material-ui/styles";
import "../styles.css";

const LoadingAnimation = (props) => {
  const [hovered, setHovered] = useState(false);
  const [spinner, setSpinner] = useState(require("./loaderwhite"));

  const animationContainer3 = useRef(null);

  const anim3 = useRef(null);

  useEffect(() => {
    if (animationContainer3.current) {
      anim3.current = lottie.loadAnimation({
        container: animationContainer3.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: spinner,
      });
      anim3.current.setSpeed(1.25);

      return () => anim3.current.destroy();
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    addedbtn: {
      position: "relative",
      width: "45px",
      height: "45px",
    },

    btnText: {
      fontWeight: "600",
      fontSize: "1rem",
      margin: "0",
      padding: "0",
    },

    addedBtnIcon: {
      width: "45px",
      height: "45px",
      margin: "0 auto"

    },
  }));

  const classes = useStyles(props);

  return <div className={classes.addedBtnIcon} ref={animationContainer3} />;
};
export default LoadingAnimation;
