import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  app: {
    position: "relative",
    background: theme.app.background[0],
    "& ::-webkit-scrollbar": {
      width: "10px",
    },
    "& ::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "& ::-webkit-scrollbar-thumb": {
      backgroundColor: theme.app.scrollbar,
      outline: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const AppLayout = ({ children }) => {
  const classes = useStyles();
  return <div className={clsx(classes.app, "App")}>{children}</div>;
};

export default AppLayout;
