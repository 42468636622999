import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useSelector } from "react-redux";
import { SaleContext } from "../common/saleProvider";
import CountdownContent from "./CountdownContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Context, OPEN_CART } from "../../context";

const useStyles = makeStyles((theme) => ({
  countDownText: {
    marginLeft: "4px",
    color: "inherit",
  },
}));

const TimerWarning = (props) => {
  const forceReload = (isOpenModal) => {
    closeModal(isOpenModal);
    window.location.reload();
  };

  const { isOpenModal, closeModal, language } = props;
  return (
    <Dialog open={isOpenModal}>
      <DialogTitle>
        {language ? language.Information : "Information"}
      </DialogTitle>
      <DialogContent>
        {language.Article_Expired
          ? language.Article_Expired
          : "Reservationstiden för ditt köp har utgått och din varukorg har uppdaterats. Kontrollera din varukorg och försök igen."}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => forceReload(!isOpenModal)}>
          {language ? language.Close : "Stäng"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CountdownContainer = ({ changeBackground }) => {
  const classes = useStyles();
  const { dispatch } = useContext(Context);
  const {
    state: { sale },
  } = useContext(SaleContext);
  const navigate = useNavigate();

  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [itemQuantity, setItemQuantity] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (sale && sale?.itemQuantity) {
      setItemQuantity(sale.itemQuantity);
    } else {
      setItemQuantity(0);
    }
  }, [sale]);

  const isAllotment = sale?.items.some((el) => el.allotmentId);

  if (!isAllotment) return null;
  if (isOpenModal) {
    return (
      <TimerWarning
        isOpenModal={isOpenModal}
        closeModal={setIsOpenModal}
        language={language}
      />
    );
  } else {
    if (itemQuantity > 0) {
      return (
        <ButtonBase
          className={`${clsx(classes.countDownText)} countDownText`}
          onClick={() =>
            config?.useCartDrawer
              ? dispatch({ type: OPEN_CART })
              : navigate(`${process.env.PUBLIC_URL}/cart`)
          }
          disabled={sale.itemQuantity < 1}
          classes={{ disabled: classes.disabled }}
          aria-label={language.OpenCart}
          style={{
            width: "fit-content",
            height: "fit-content",
            padding: 0,
            marginRight: "1rem",
            marginBottom: "0.2rem",
          }}
        >
          <CountdownContent
            sale={sale}
            language={language}
            openModal={setIsOpenModal}
            changeBackground={changeBackground}
          />
        </ButtonBase>
      );
    } else {
      return null;
    }
  }
};

export default CountdownContainer;
