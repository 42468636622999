import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Event from "./Event";
import { Container, createStyles, Skeleton } from "@mantine/core";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  skeletonWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
}));

const EventWrapper = ({ date, items, events }) => {
  const { config } = useSelector((state) => ({
    config: state.config,
  }));
  const [itemsArr, itemsArrSet] = useState([]);
  const { classes } = useStyles();
  const listType =
    window.innerWidth < 768
      ? config?.allEventsListType?.sm
      : config?.allEventsListType?.lg;
  const isCard = listType
    ? listType === "CARD"
    : window.innerWidth < 768
    ? config.articleListType?.sm === "CARD"
    : config.articleListType?.lg === "CARD";

  useEffect(() => {
    itemsArrSet(items);
  }, [items]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.2,
        staggerChildren: 0.08,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, scale: 0.95 },
    show: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {itemsArr.length > 0 ? (
        <Container
          component={motion.div}
          variants={container}
          initial="hidden"
          animate="show"
          exit="exit"
          sx={{
            maxWidth: "100%",
            display: isCard ? "grid" : "grid",
            gridGap: isCard ? "2rem 0rem" : ".5rem",
            gridTemplateColumns: isCard && "repeat(3, 1fr)",
            "@media (max-width: 768px)": {
              gridTemplateColumns: isCard && "1fr",
            },
          }}
          p={0}
          m={0}
        >
          {itemsArr.map((el, i) => (
            <motion.div
              variants={item}
              key={el.id}
              layout
              transition={{
                type: "spring",
                duration: 0.3,
                layout: {
                  type: "spring",
                  damping: 50,
                  stiffness: 600,
                },
              }}
            >
              <Event event={el} date={date} />
            </motion.div>
          ))}
        </Container>
      ) : (
        events.length === 0 && (
          <div className={classes.skeletonWrapper}>
            <Skeleton width={"7.5rem"} height={"6rem"} radius="md" mb="xl" />
            <div style={{ width: "100%", marginTop: "1rem" }}>
              <Skeleton height={16} radius="xl" />
              <Skeleton height={12} mt={6} radius="xl" />
              <Skeleton height={12} mt={6} radius="xl" />
            </div>
            <Skeleton
              width={"7rem"}
              height={"3rem"}
              radius="md"
              mb="xl"
              mt={"lg"}
            />
          </div>
        )
      )}
      {itemsArr.length === 0 && events.length > 0 && (
        <p>Inga resultat matchar kriterierna, ändra filtret och försök igen</p>
      )}
    </AnimatePresence>
  );
};

export default EventWrapper;
