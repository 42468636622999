import React from "react";
import { useSelector } from "react-redux";
import { Router, Redirect } from "@reach/router";
import Cart from "./Pages/CartPage";
import LandingPage from "./Pages/LandingPage";
import Product from "./Pages/ProductPage";
import Event from "./Pages/EventPage";
import AllProducts from "./Pages/AllProductsPage";
import AllEvents from "./Pages/AllEventsPage";
import SitePage from "./Pages/SitePage";
import User from "./Pages/UserPage";
import TicketPage from "./Pages/TicketPage";
import MoveOccasionsPage from "./Pages/MoveOccasionsPage";
import ArticleBookingPage from "./Pages/ArticleBookingPage";
import ScrollToTop from "./components/common/ScrollToTop";
import PublicBookingPage from "./Pages/PublicBookingPage";

const Routes = ({ setPosition }) => {
  const { auth, config } = useSelector((state) => ({
    auth: state.oidc.user !== null && !state.oidc.user.expired,
    config: state.config,
  }));

  const Private = ({ component: Component, path }) =>
    auth ? (
      <Component path={path} />
    ) : (
      <Redirect from="" to={`${process.env.PUBLIC_URL}/`} noThrow />
    );

  return (
    <>
      <ScrollToTop />
      <Router primary={false}>
        {config && !config.useLandingPage && (
          <Redirect
            noThrow
            from="/"
            to={`${process.env.PUBLIC_URL}/all-products`}
          />
        )}
        <LandingPage path="/" />
        <Event path="/events/:id" />
        <Product path="/products/:id" />
        <Cart path="/cart" />
        <AllProducts path="/all-products" setPosition={setPosition} />
        <AllEvents path="/all-events" />
        <Private path="/user" component={User} />
        <SitePage path="/:id" setPosition={setPosition} />
        <ArticleBookingPage path="/booking/:id" />
        <TicketPage path="/tickets/:id" />
        <PublicBookingPage path="/publicbooking/:id" />
        <MoveOccasionsPage path="/sale/:id" />
      </Router>
    </>
  );
};

export default Routes;
