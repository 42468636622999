import React from "react";
import { useNavigate } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Placeholder } from "../../images/placeholder.svg";
import slugify from "../common/slugify";
import useLocalStorage from "../Header/HeaderV2/hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    "@media(max-width: 1024px)": {
      margin: "8px",
    },
  },
  img: {
    width: "100%",
    maxWidth: "100%",
    height: "auto",
  },
  content: {
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    width: "100%",
    // height: "100%",
    display: "grid",
    alignItems: "end",
  },
  primary: {
    fontSize: "1rem",
    lineHeight: 1.1,
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
  },
  secondary: {
    fontSize: "0.8125rem",
    lineHeight: 1,
  },
  buttonDisabled: {
    color: "rgba(255,255,255,0.5)!important",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    // height: "100%",
    justifyContent: "flex-start",
  },
}));

const CardItem = ({ item, slug }) => {
  const [selected, setSelected] = useLocalStorage();
  const classes = useStyles();
  const navigate = useNavigate();
  let itemSlug =
    item?.eventCalendar === true
      ? `${process.env.PUBLIC_URL}/tickets/${slugify(item.name)}`
      : item?.articleBooking === true
      ? `${process.env.PUBLIC_URL}/booking/${slugify(item.name)}`
      : slug
      ? slug
      : item?.externalLink
      ? item?.externalLink
      : `${process.env.PUBLIC_URL}/${slugify(item.name)}`;
  return (
    <Card className={clsx(classes.card, "landing__group-item__card card-item")}>
      {item?.externalLink ? (
        <a href={item?.externalLink} target="_blank">
          <CardActionArea classes={{ root: classes.button }}>
            {item.image ? (
              <img className={classes.img} src={item.image} alt="product" />
            ) : (
              <Placeholder className={classes.img} />
            )}
            <CardContent className={classes.content}>
              <Typography
                gutterBottom
                variant="h6"
                className={clsx(
                  classes.primary,
                  "landing__group-item__primary"
                )}
              >
                {item?.text?.primary
                  ? item?.text?.primary
                  : "Add landing primary text in language config"}
              </Typography>
              <Typography
                component="p"
                className={clsx(
                  classes.secondary,
                  "landing__group-item__secondary"
                )}
              >
                {item?.text?.secondary
                  ? item?.text.secondary
                  : "Add landing secondary text in language config"}
              </Typography>
            </CardContent>
          </CardActionArea>
        </a>
      ) : (
        <CardActionArea
          onClick={() => {
            localStorage.setItem("selected", item?.name);
            navigate(itemSlug);
          }}
          classes={{ root: classes.button }}
        >
          {item.image ? (
            <img className={classes.img} src={item.image} alt="product" />
          ) : (
            <Placeholder className={classes.img} />
          )}
          <CardContent className={classes.content}>
            <Typography
              gutterBottom
              variant="h6"
              className={clsx(classes.primary, "landing__group-item__primary")}
            >
              {item?.text?.primary
                ? item?.text.primary
                : "Add landing primary text in language config"}
            </Typography>
            <Typography
              component="p"
              className={clsx(
                classes.secondary,
                "landing__group-item__secondary"
              )}
            >
              {item?.text?.secondary
                ? item?.text.secondary
                : "Add landing secondary text in language config"}
            </Typography>
          </CardContent>
        </CardActionArea>
      )}
      {/* <CardActionArea
        onClick={() => {
          localStorage.setItem("selected", item?.name)
          navigate(itemSlug)
        }}
        classes={{ root: classes.button }}
      >
        {item.image ? (
          <img className={classes.img} src={item.image} alt="product" />
        ) : (
          <Placeholder className={classes.img} />
        )}
        <CardContent className={classes.content}>
          <Typography
            gutterBottom
            variant="h6"
            className={clsx(classes.primary, "landing__group-item__primary")}
          >
            {item?.text?.primary
              ? item?.text.primary
              : "Add landing primary text in language config"}
          </Typography>
          <Typography
            component="p"
            className={clsx(
              classes.secondary,
              "landing__group-item__secondary"
            )}
          >
            {item?.text?.secondary
              ? item?.text.secondary
              : "Add landing secondary text in language config"}
          </Typography>
        </CardContent>
      </CardActionArea> */}
    </Card>
  );
};

export default CardItem;
