import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import App from "./App";
import { getTheme } from "./theme";
import { STATE_THEME } from "./common/state";

const theme = (myTheme) =>
  createTheme({
    app: myTheme,
    height: myTheme.height,
    palette: {
      type: myTheme.themeType,
      primary: {
        light: myTheme.primaryColors.light,
        main: myTheme.primaryColors.main,
        dark: myTheme.primaryColors.dark,
      },
      secondary: {
        light: myTheme.secondaryColors.light,
        main: myTheme.secondaryColors.main,
        dark: myTheme.secondaryColors.dark,
      },
      background: {
        paper: myTheme.background[1],
        default: myTheme.background[0],
      },
    },
  });

const Main = (props) => {
  const [prevHeight, setPrevHeight] = useState(window.innerHeight);
  const selectedTheme = useSelector((state) => state.cookies[STATE_THEME]);
  const myTheme = getTheme(selectedTheme);
  window.addEventListener("resize", () => {
    if (window.innerHeight !== prevHeight) {
      setPrevHeight(window.innerHeight);
    }
  });
  return (
    <ThemeProvider theme={theme({ ...myTheme, height: prevHeight - 1 })}>
      <App />
    </ThemeProvider>
  );
};

export default Main;
