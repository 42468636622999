import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Flag from "./flag";
import { setCulture, STATE_CULTURE } from "../../common/state";
import { DataContext } from "../common/dataProvider";

const useStyles = makeStyles((theme) => ({
  container: {},
  button: {
    marginRight: "8px",
    minWidth: "100px",
  },
  buttonText: { marginLeft: "8px" },
  active: {
    "& svg": {
      opacity: 0.6,
    },
  },
}));

const Cultures = () => {
  const { data, refetch } = useContext(DataContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSetCulture = (culture, config) => {
    dispatch(setCulture(culture, config));
    refetch();
  };
  const { language, config, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const cultures = data?.cultures;
  const active = culture;
  if (!cultures) return null;
  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" gutterBottom>
        {language.Culture}
      </Typography>
      {cultures.map((c, i) => {
        const isActive = c.name === active;
        return (
          <Button
            key={i}
            onClick={() => handleSetCulture(c.name, config)}
            className={classes.button}
            classes={{ disabled: classes.active }}
            disabled={isActive}
            data-testid={`button_${c.name}`}
          >
            <Flag culture={c.name} />
            <span className={classes.buttonText}>{c.description}</span>
          </Button>
        );
      })}
    </div>
  );
};

export default Cultures;
