import { createUserManager } from "redux-oidc";
import { WebStorageStateStore } from "oidc-client";

export const getUserManager = (cfg) => {
  const config = {
    client_id: "entryevent-browser",
    redirect_uri: `${cfg.self}/callback`,
    response_type: "id_token token",
    scope: "openid profile ShopApi entryevent",
    authority: cfg.authUri,
    post_logout_redirect_uri: `${cfg.self}`,
    filterProtocolClaims: false,
    loadUserInfo: true,
    silent_redirect_uri: `${cfg.self}/silent_renew`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };

  //console.log('usrmgr-config', config);

  try {
    return createUserManager(config, () => true, true);
  } catch (err) {
    console.log(err);
    return { error: true };
  }
};

export function apolloMiddleware(getState) {
  return {
    applyMiddleware: (req, next) => {
      const { oidc } = getState();
      const { user } = oidc;

      if (user && user.access_token) {
        if (!req.options.headers) {
          req.options.headers = {}; // Create the header object if needed.
        }

        const token = user.access_token;

        req.options.headers["Authorization"] = "Bearer " + token;

        console.log("apollo req", req);
      }
      next();
    },
  };
}
