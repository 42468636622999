import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import Collapse from "@material-ui/core/Collapse";
import ButtonBase from "@material-ui/core/ButtonBase";
import DownArrowIcon from "@material-ui/icons/ArrowDropDown";
import UpArrowIcon from "@material-ui/icons/ArrowDropUp";
import Markdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  description: {
    "& p": {
      color: theme.palette.text.secondary,
      // "&:first-child": { marginTop: "0px" }
    },
    "@media(max-width: 667px)": {
      padding: "8px",
      "& h4": {
        fontSize: "1.2rem",
      },
    },
  },
  descriptionContainer: {
    position: "relative",
  },
  fade: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "20px",
    background:
      "linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 33%,rgba(255,255,255,1) 53%,rgba(255,255,255,1) 100%);",
  },
  button: {
    display: "none",
    padding: "4px 0",
    color: theme.app.labelColor,
    "@media(max-width: 768px)": {
      display: "inline-flex",
    },
    "@media(max-width: 667px)": {
      paddingLeft: "8px",
    },
  },
}));

const Description = ({ text }) => {
  const classes = useStyles();
  const { language, config } = useSelector((state) => ({
    language: state.language,
    config: state.config,
  }));
  const [open, setOpen] = useState(
    config.disableDescriptionFold
      ? true
      : window.innerWidth < 768
      ? false
      : true
  );
  const toggleOpen = () => setOpen(open ? false : true);
  if (text.length < 1) return <div />;
  return (
    <div>
      <div className={classes.descriptionContainer}>
        <Collapse in={open} collapsedSize="85px">
          <Markdown
            source={text}
            escapeHtml={false}
            skipHtml={false}
            className={classNames(classes.description, "description")}
          />
        </Collapse>
        {!open && <div className={classes.fade} />}
      </div>
      {!config.disableDescriptionFold && (
        <ButtonBase onClick={toggleOpen} className={classes.button}>
          {open ? language.Show_Less : language.Show_More}{" "}
          {open ? <UpArrowIcon /> : <DownArrowIcon />}
        </ButtonBase>
      )}
    </div>
  );
};

export default Description;
