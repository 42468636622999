import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "@reach/router";
import { green } from "@material-ui/core/colors";

const Button = (props) => {
  const [activeBtn, setActiveBtn] = useState(false);
  const useStyles = makeStyles({
    occasionBtn: {
      minWidth: "11rem",
      width: "100%",
      padding: " 0 2rem",
      height: "3.5rem",
      borderRadius: "10px",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      fontSize: "1rem",
      fontWeight: "600",
      cursor: "pointer",

      transition: "all 0.1s ease",
      "&:disabled": {
        pointerEvents: "none",
        opacity: "0.3",
      },
      "&:focus": {
        outline : "2px solid black",      
      },
      "&:hover": {
        transform: "translateY(-1px)",
      },
      "&:active": {
        outline: "none",
      }
    },
    btnBackground: {
      "&:hover": {
        opacity: ({ primaryBtn }) => (primaryBtn ? "0.7" : ""),
      },
    },
  });
  const classes = useStyles(props);
  const location = useLocation();
  const click = (data) => {
    setActiveBtn(data);
    props.setIsVisible(false);
    sessionStorage.setItem("articleBookingBtn", data);
    setTimeout(() => {
      props.setSection(data);
    }, 50);
    setTimeout(() => {
      props.scrollIntoView("IntroductionSection");
    }, 70);
  };

  useEffect(() => {
    props.setSection("");
  }, [location.pathname]);

  useEffect(() => {
    props.setIsVisible(true);
    if (props.section.length === 0) {
      setActiveBtn(false);
    }
    if (props.text === props.section.name) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [props.section]);

  useEffect(() => {
    setActiveBtn("");
    sessionStorage.setItem("articleBookingBtn", "notactive");
  }, [location.pathname]);

  return (
    <>
      <button
        className={`${classes.occasionBtn}  ${
          activeBtn ? "activeArticleButton" : "articleBookingButton"
        }`}
        onClick={() => click(props.data)}
        // disabled={props.disabled}
        style={{ pointerEvents: activeBtn ? "none" : "" }}
      >
        {props.text}
      </button>
    </>
  );
};

export default Button;
