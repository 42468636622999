import {
  Container,
  createStyles,
  Grid,
  Image,
  Popover,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, addToCartTracking } from "../../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../common/state";
import { getSaleData } from "../util";
import Markdown from "react-markdown";
import { motion } from "framer-motion";
import PersonalTicketForm from "./PersonalTicketForm";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  container: {
    "@media screen and (max-width: 768px)": {
      paddingTop: "0.3rem",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "1rem",
    button: {
      width: "fit-content",
    },
  },
  stockWrapper: {
    marginTop: ".5rem",
    "@media screen and (max-width: 768px)": {
      marginTop: ".35rem",
    },
  },
  stockIndicator: {
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
  },
  inStock: {
    backgroundColor: theme.colors.green[1],
  },
  outOfStock: {
    backgroundColor: theme.colors.red[2],
  },
  stockText: {
    fontSize: "1rem",
    fontWeight: "inherit",
  },
  price: {
    display: "flex",
    alignItems: "end",
    justifySelf: "end",
    fontSize: "1.25rem",
    fontWeight: "bold",
    alignSelf: "center",
  },
  list: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    width: "fit-content",
    justifySelf: "end",
  },
  descriptionTitle: {
    fontSize: "1rem",
    marginTop: "1rem",
  },
  descriptionBody: {
    marginTop: "0.5rem",
    p: {
      marginTop: "-.1rem",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "end",
  },
  actionButton: {
    borderRadius: "50%",
    border: "none",
  },
  actionWrapper: {
    backgroundColor: "#F3F3F4",
    padding: "0.5rem",
    borderRadius: "2rem",
    width: "fit-content",
    minWidth: "8.2rem",
  },
  actionGrid: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    marginTop: "1rem",
    "@media screen and (min-width: 769px)": {
      gap: "2rem",
    },
  },
  alertWrapper: {
    width: "100%",
  },
  imageWrapper: {
    height: "100%",
    "@media screen and (max-width: 990px)": {
      // minHeight: "25rem",
    },
  },
  addToCart: {
    display: "flex",
    justifyContent: "end",
  },
  image: {
    height: "100%",
    maxHeight: "20rem",
    // "@media screen and (max-width: 990px)": {
    //   minHeight: "25rem",
    // },
  },
}));
const PersonalTicket = ({ product }) => {
  const [addedToCart, addedToCartSet] = useState(null);
  const [photo, photoSet] = useState(null);
  const [values, valuesSet] = useState([]);
  const { culture, config, language, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));

  const rdxDispatch = useDispatch();
  const { classes } = useStyles();
  const matches = useMediaQuery("(max-width: 990px)");

  const getTranslation = product.translation.filter(
    (el) => el.culture === culture
  )[0];

  const productData = [
    {
      id: product.plu,
      name: product.name,
      price: product.price.amountInclVat,
      quantity: 1,
    },
  ];

  const handleAdd = (values) => {
    const callback = (status, msg) => {
      if (status === 200) {
        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    const article = product.id;
    let quantity = 1;
    let overrideDate;
    const saleData = getSaleData(
      product,
      article,
      values,
      photo,
      quantity,
      overrideDate
    );
    addToCartTracking(currency, productData);
    rdxDispatch(addArticle(saleData, callback));
  };

  return (
    <div>
      <Container style={{ height: "100%" }}>
        <Grid gutter={28} style={{ height: "100%" }}>
          <Grid.Col
            sm={12}
            md={12}
            className={classes.imageWrapper}
            style={{ height: "100%" }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ height: "100%" }}
            >
              <Image
                src={product.images && product.images}
                withPlaceholder
                radius={"sm"}
                className={`${classes.image} personalImage`}
                style={{ height: matches ? "25rem" : "20rem" }}
                alt={language.TicketPhotoAltLabel}
              />
            </motion.div>
          </Grid.Col>
          <Grid.Col sm={12} md={12} className={classes.container}>
            <div className={classes.titleWrapper}>
              <Title order={1} sx={{ fontSize: "1.5rem" }}>
                {getTranslation.name}
              </Title>
            </div>
            <SimpleGrid cols={1} className={classes.stockWrapper}>
              <Text className={classes.price} component="span">
                {!product.isOpenPrice ? product.priceInclVat : ""}
              </Text>
            </SimpleGrid>
            <Title order={2} className={classes.descriptionTitle}>
              {language.Description}
            </Title>
            <div className={classes.descriptionBody}>
              <Markdown
                source={getTranslation.description}
                escapeHtml={false}
                skipHtml={false}
              />
            </div>
            <PersonalTicketForm
              values={values}
              valuesSet={valuesSet}
              photo={photo}
              photoSet={photoSet}
              handleAdd={handleAdd}
              addedToCart={addedToCart}
            />

            <div className={classes.actionGrid}>
              <div className={classes.alertWrapper}>
                <Popover
                  width="target"
                  withArrow
                  shadow="md"
                  opened={addedToCart === false}
                >
                  <Popover.Target>
                    <div className={classes.alertContainer} />
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="md" component="p">
                      {language.CouldNotAddProduct}
                    </Text>
                  </Popover.Dropdown>
                </Popover>
              </div>
            </div>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};

export default PersonalTicket;
