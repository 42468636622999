import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { DataContext } from "../../components/common/dataProvider";
import PageContent from "../../components/Styles/PageContent";
import PageLayout from "../../components/Styles/PageLayout";
import Ticket from "../../components/Ticket";
import { getEvent } from "../EventPage/util";
import slugify from "../../components/common/slugify";
import { Button, Container, Text, Title } from "@mantine/core";
import { useNavigate } from "@reach/router";

const TicketPage = ({ id }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const { data, eventFilter } = useContext(DataContext);
  const config = useSelector((state) => state.config);
  const ticketData = config.ticketConfigurator;
  const event = useMemo(() => getEvent(data, config, id), [data, id, config]);
  const configurator = config?.ticketConfigurator.find(
    (el) => slugify(el.slug) === id
  );
  const navigate = useNavigate();

  if (!configurator || configurator?.disabled)
    return (
      <PageLayout>
        <PageContent>
          <Container
            sx={{ textAlign: "center", margin: "3rem 0", maxWidth: "100%" }}
          >
            <Title mb="lg" order={1}>
              {language.CouldNotFindEventHeader}
            </Title>
            <Text component="p" mb="lg">
              {language.CouldNotFindEventText}
            </Text>
            <Button
              component="a"
              onClick={() => navigate("/ticketshop")}
              className="mantine-Primary-btn"
              variant="filled"
              size="md"
              radius="sm"
              color="indigo"
            >
              {language.BackToHomepage}
            </Button>
          </Container>
        </PageContent>
      </PageLayout>
    );

  return (
    <PageLayout>
      <PageContent>
        <Ticket
          shopName={config.shopName}
          event={event}
          filter={eventFilter}
          ticketData={ticketData}
        />
      </PageContent>
    </PageLayout>
  );
};

export default TicketPage;
