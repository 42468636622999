import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    color: theme.palette.color,
    background: theme.palette.background.paper,
    padding: "16px",
    borderRadius: "4px",
    "@media(max-width: 667px)": {
      padding: "0px",
    },
  },
}));

const PageContent = ({ children }) => {
  const classes = useStyles();
  return (
    <section id="main-page-content" className={clsx(classes.pageContent, "page__content")}>{children}</section>
  );
};

export default PageContent;
