import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "54px",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.app.borderColor}`,
    "@media(max-width: 667px)": {
      padding: "0 8px",
      position: "sticky",
      top: (p) => (p.headerHeight ? `${p.headerHeight}px` : "64px"),
      zIndex: 1100,
      background: theme.palette.background.paper,
    },
  },
  titleText: {
    flexGrow: 1,
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    "@media(max-width: 576px)": {
      fontSize: "1.125rem",
    },
  },
}));

const ProductHeader = ({ text }) => {
  const classes = useStyles();
  const language = useSelector((state) => (state.language, state.config));
  const navigate = useNavigate();
  return (
    <div className={`${classes.header} productHeader`}>
      <Typography
        variant="h5"
        className={clsx(classes.titleText, "product__detail__title")}
      >
        {text}
      </Typography>
      <Button onClick={() => navigate(-1)}>
        {language.Back_To_List || "Tillbaka"}
      </Button>
    </div>
  );
};

export default ProductHeader;
