import { createStyles } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import DrawerItem from "./DrawerItem";
import { motion } from "framer-motion";

const useStyles = createStyles({
  wrapper: {
    marginTop: "1rem",
  },
});

const DrawerList = ({ sale }) => {
  const [items, itemsSet] = useState([]);
  const { classes } = useStyles();
  const shippingArticle = useCallback(() => {
    const filter = sale.items.filter(
      (el) => el.type === "shipping_fee" || el.type === "service_fee"
    );
    if (filter) {
      const filterOutShipping = sale.items.filter((el) =>
        filter.every((ele) => el.key !== ele.key)
      );
      return itemsSet(filterOutShipping);
    } else {
      return itemsSet(sale.items);
    }
  }, [sale]);

  useEffect(() => {
    shippingArticle();
  }, [sale.items]);

  return (
    <>
      {items && (
        <div className={classes.wrapper}>
          <div id="drawer-body">
            <div role="region" aria-live="polite">
              {items.length > 0 &&
                items.map((el, i) => (
                  <motion.div
                    key={el.key}
                    layout
                    transition={{
                      layout: {
                        type: "spring",
                        damping: 50,
                        stiffness: 600,
                      },
                    }}
                  >
                    <DrawerItem item={el} sale={sale} />
                  </motion.div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DrawerList;
