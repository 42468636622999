import React, { useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getAllowedCurrencies } from "./util";
import { setCurrency, STATE_CURRENCY } from "../../common/state";
import { DataContext } from "../common/dataProvider";

const useStyles = makeStyles((theme) => ({
  container: {},
  button: {
    marginRight: "8px",
    minWidth: "100px",
  },
}));

const Currencies = (props) => {
  const { data, refetch } = useContext(DataContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSetCurrency = (currency) => {
    dispatch(setCurrency(currency));
    refetch();
  };
  const { currency, language, config } = useSelector((state) => ({
    currency: state.cookies[STATE_CURRENCY],
    language: state.language,
    config: state.config,
  }));
  const { allowedCurrencies } = config;
  const currencies = data?.currencies;
  const filteredCurrencies = useMemo(
    () => getAllowedCurrencies(currencies, allowedCurrencies),
    [currencies, allowedCurrencies]
  );

  if (!filteredCurrencies) return null;

  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" gutterBottom>
        {language.Currency}
      </Typography>
      {filteredCurrencies.map((c, i) => {
        const isActive = c.name === currency;
        return (
          <Button
            key={i}
            onClick={() => handleSetCurrency(c.name)}
            className={classes.button}
            disabled={isActive}
            data-testid={`button_${c.name}`}
          >
            {c.name}
          </Button>
        );
      })}
    </div>
  );
};

export default Currencies;
