import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  Container,
  MediaQuery,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { format, isSameDay } from "date-fns";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../common/state";
import ArticlePicker from "./ArticlePicker";

const query = gql`
  query detailQuery(
    $currency: String
    $culture: String
    $posId: Int
    $from: DateTime
    $until: DateTime
  ) {
    allotments(
      currency: $currency
      culture: $culture
      posId: $posId
      from: $from
      until: $until
    ) {
      description
      imageFileName
      translation {
        culture
        name
        description
      }
      occasions {
        time
        remaining
        allotment {
          id
          maxPerSale
        }
        articles {
          id
          name
          description
          imageFilename
          plu
          priceInclVat
          translation {
            culture
            name
            description
          }
          isPersonalTicket
        }
      }
    }
  }
`;

const TimePicker = ({ day, allotment, occasions, cultureLocale }) => {
  const [from, fromSet] = useState();
  const [until, untilSet] = useState();
  const [availableOccasions, availableOccasionsSet] = useState();
  const [chosenOccasion, chosenOccasionSet] = useState();
  const { config, currency, culture, language } = useSelector((state) => ({
    config: state.config,
    currency: state.cookies[STATE_CURRENCY],
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));

  const { posId } = config;

  const { data, loading } = useQuery(query, {
    variables: { posId, currency, culture, from, until },
  });

  let timeFormat = "HH:mm";

  useEffect(() => {
    chosenOccasionSet("");
  }, [day]);

  useEffect(() => {
    if (!loading && data && allotment && day) {
      const filterAllotments = data?.allotments?.filter(
        (el) => allotment.name === el.description
      );

      const filterOccasions = filterAllotments?.[0]?.occasions.filter((el) =>
        isSameDay(new Date(el.time), new Date(day))
      );
      availableOccasionsSet(filterOccasions);
    }
  }, [day, data, loading, allotment]);

  useEffect(() => {
    const timePicker = document.getElementById("event-time-picker");
    if (timePicker) {
      timePicker.focus();
    }
  }, [day]);

  return (
    <MediaQuery
      query="(max-width: 768px)"
      styles={{ marginTop: "1rem", marginBottom: "2rem" }}
    >
      <Container px={0} mx={0}>
        <SimpleGrid cols={1} spacing="xs">
          <Title
            order={2}
            style={{ fontSize: "1.25rem" }}
            id="event-time-picker"
            tabIndex={day && 0}
          >
            {language.ChooseTimeHeader}
          </Title>
          <Text
            size="s"
            component="small"
            color="gray.7"
            weight={400}
            sx={{ marginTop: "-0.5rem", marginBottom: "1rem" }}
          >
            {language.ChooseTimeDescription}
          </Text>
        </SimpleGrid>

        <SimpleGrid
          cols={4}
          breakpoints={[
            {
              maxWidth: 768,
              cols: 3,
              spacing: "sm",
            },
          ]}
        >
          {cultureLocale &&
            day &&
            availableOccasions &&
            availableOccasions.map((el, i) =>
              el.remaining > 0 ? (
                <Button
                  className={
                    chosenOccasion === el ? "timeOccasionSelected" : ""
                  }
                  aria-label={`${format(new Date(el?.time), "HH:mm", {
                    locale: cultureLocale,
                  })} ${chosenOccasion === el ? language.Chosen : ""}`}
                  size="lg"
                  variant={chosenOccasion === el ? "filled" : "default"}
                  color="indigo"
                  sx={{ padding: "0" }}
                  onClick={() => chosenOccasionSet(el)}
                  key={el + i}
                  style={{ border: "1px solid #8A8C93" }}
                >
                  <SimpleGrid cols={1} sx={{ gap: 0 }}>
                    <Text sx={{ textAlign: "center" }}>
                      {format(new Date(el.time), timeFormat)}
                    </Text>
                    <MediaQuery
                      query="(max-width: 768px)"
                      styles={{ fontSize: 10 }}
                    >
                      <Text
                        className="event-remainingTickets"
                        size="xs"
                        color={chosenOccasion === el ? "white" : "dimmed"}
                      >
                        {language.ApproximatelyShort} {el.remaining}{" "}
                        {language.Tickets.toLowerCase()}
                      </Text>
                    </MediaQuery>
                  </SimpleGrid>
                </Button>
              ) : (
                <Button color="indigo" size="lg" disabled key={el + i}>
                  <SimpleGrid cols={1} sx={{ gap: 0, textAlign: "center" }}>
                    <Text>{format(new Date(el.time), timeFormat)}</Text>
                    <MediaQuery
                      query="(max-width: 768px)"
                      styles={{ fontSize: 10 }}
                    >
                      <Text size="xs">{language.SoldOut}</Text>
                    </MediaQuery>
                  </SimpleGrid>
                </Button>
              )
            )}
        </SimpleGrid>
        <ArticlePicker
          chosenOccasion={chosenOccasion}
          availableOccasions={availableOccasions}
        />
      </Container>
    </MediaQuery>
  );
};

export default TimePicker;
