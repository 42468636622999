import React, { useState, useEffect } from "react";
import moment from "moment";

const CountdownContent = (props) => {
  let { sale, language, openModal } = props;
  const [timer, setTimer] = useState(undefined);
  const [isUpdated, setIsUpdated] = useState(false);
  const [timeState, setTimeState] = useState({
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (timer && moment().isBefore(timer)) {
      const interval = setInterval(() => updateCountdown(), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  });

  useEffect(() => {
    if (isUpdated === true) {
      openModal(true);
    }
  });

  useEffect(() => {
    setTimer(Date.parse(sale.firstArticleExpiresAt));
  }, [sale]);

  const updateCountdown = () => {
    const currentTime = new Date().getTime();
    const timeUntilCountdownFinishes = timer - currentTime;
    let minutes = String(
      Math.floor((timeUntilCountdownFinishes % (1000 * 60 * 120)) / (1000 * 60))
    ).padStart(2, "0");
    let seconds = String(
      Math.floor((timeUntilCountdownFinishes % (1000 * 60)) / 1000)
    ).padStart(2, "0");

    setTimeState({ minutes: minutes, seconds: seconds });
  };

  if (timer !== undefined) {
    let isWithinTwoHours = moment(timer).isBetween(
      Date.now(),
      moment().add(2, "hours")
    );

    if (!timer) {
      return null;
    } else if (moment().isAfter(timer)) {
      if (!isUpdated) {
        setIsUpdated(true);
      }
      return null;
    } else if (!isWithinTwoHours) {
      return null;
    } else {
      return (
        <div
          className={`countdownTimer ${
            props.changeBackground ? "darkColor" : "lightColor"
          }`} role="timer"
          style={{margin: 0}}
        >
          {language ? language.Countdown_Remaining : "Tid kvar:"}{" "}
          {timeState.minutes}:{timeState.seconds}
        </div>
      );
    }
  } else {
    return null;
  }
};

export default CountdownContent;
