/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import { forbidExtraProps } from "airbnb-prop-types";
import moment from "moment";
import omit from "lodash/omit";

import { DayPickerSingleDateController } from "react-dates";

import ScrollableOrientationShape from "react-dates/lib/shapes/ScrollableOrientationShape";

import { HORIZONTAL_ORIENTATION } from "react-dates/constants";
import { isInclusivelyAfterDay } from "react-dates";

const propTypes = forbidExtraProps({
  // example props for the demo
  autoFocus: PropTypes.bool,
  initialDate: momentPropTypes.momentObj,
  showInput: PropTypes.bool,

  keepOpenOnDateSelect: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: ScrollableOrientationShape,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool,
  onDateChange: PropTypes.func,
  blockedDates: PropTypes.array,
  allowedRange: PropTypes.object,
  highlightedDates: PropTypes.array,
  occasionDates: PropTypes.array,
  isOverrideStartdate: PropTypes.bool,
});

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  initialDate: null,
  showInput: false,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: "MMMM YYYY",
};

class DayPickerSingleDateControllerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: true,
      date: props.initialDate,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDateChange(date) {
    this.setState({ date });
    this.props.onDateChange(date);
  }

  onFocusChange() {
    // Force the focused states to always be truthy so that date is always selectable
    this.setState({ focused: true });
  }

  isHighlighted = (day, highligtedDays) => {
    if (highligtedDays.includes(day.format("YYYY-MM-DD"))) {
      return true;
    }
    return false;
  };

  isDayBlocked = (
    day,
    occasionDates,
    isOverrideStartdate,
    allowedRange,
    blockedDates
  ) => {
    if (isOverrideStartdate) {
      if (
        day.isBetween(
          moment(allowedRange.start).subtract("1", "days"),
          moment(allowedRange.end).add("1", "days")
        )
      ) {
        if (blockedDates.includes(day.format("YYYYMMDD"))) {
          return true;
        }
        return false;
      }
      return true;
    }
    if (occasionDates.includes(day.format("YYYY-MM-DD"))) {
      return false;
    }
    return true;
  };

  render() {
    const {
      showInput,
      highlightedDates,
      occasionDates,
      isOverrideStartdate,
      allowedRange,
      blockedDates,
    } = this.props;
    const { focused, date } = this.state;

    const props = omit(this.props, [
      "autoFocus",
      "initialDate",
      "showInput",
      "blockedDates",
      "occasionDates",
      "highlightedDates",
      "isOverrideStartdate",
      "allowedRange",
    ]);

    const dateString = date && date.format("YYYY-MM-DD");

    return (
      <div>
        {showInput && (
          <div style={{ marginBottom: 16 }}>
            <input
              type="text"
              name="start date"
              value={dateString || ""}
              readOnly
            />
          </div>
        )}

        <DayPickerSingleDateController
          {...props}
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          isDayHighlighted={(day) => this.isHighlighted(day, highlightedDates)}
          isDayBlocked={(day) =>
            this.isDayBlocked(
              day,
              occasionDates,
              isOverrideStartdate,
              allowedRange,
              blockedDates
            )
          }
          focused={focused}
          date={date}
        />
      </div>
    );
  }
}

DayPickerSingleDateControllerWrapper.propTypes = propTypes;
DayPickerSingleDateControllerWrapper.defaultProps = defaultProps;

export default DayPickerSingleDateControllerWrapper;