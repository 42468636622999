import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Context, TOAST, CART_ARTICLE_ADD } from "../../context";
import { getArticleConfig, formatDisplayPrice } from "./util";
import { addArticle } from "../../common/sale";
import { STATE_CURRENCY } from "../../common/state";
import { getCartArticle } from "../cart/util";

const useStyles = makeStyles({
  openPrice: {},
  list: {},
  listItem: {
    paddingRight: "150px",
    "@media(max-width: 667px)": {
      display: "grid",
      gridTemplateColumns: "1fr",
      padding: "0 0 24px",
      borderBottom: "none",
    },
  },
  listItemText: {},
  listItemAction: {
    display: "flex",
    "@media(max-width: 667px)": {
      position: "relative",
      right: 0,
      left: 0,
      display: "grid",
      gridTemplateColumns: "1fr auto",
    },
  },
  addButton: {
    alignSelf: "flex-end",
    marginLeft: "16px",
  },
  select: {
    width: "120px",
    textAlign: "center",
  },
  input: {
    maxWidth: "140px",
    textAlign: "right",
  },
});

const OpenPrice = (props) => {
  const { article, product } = props;
  const classes = useStyles();
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const { config, language, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const articleConfig = getArticleConfig(config.productConfig, article.plu);
  const hasOptions =
    articleConfig.openPriceValues && articleConfig.openPriceValues.length > 0;
  const options = articleConfig.openPriceValues;
  const [price, setPrice] = useState(hasOptions ? options[0] : "");
  const [busy, setBusy] = useState(false);

  const handleAdd = () => {
    setBusy(true);
    const cartArticle = getCartArticle(product, config, article.id);
    rdxDispatch(
      addArticle(
        { plu: article.plu, quantity: 1, priceInclVat: price * 100 },
        (status, msg) => {
          if (status >= 400) {
            setBusy(false);
            return dispatch({
              type: TOAST,
              payload: {
                text: msg,
                variant: "error",
              },
            });
          }
          setPrice(hasOptions ? options[0] : "");
          setBusy(false);
          dispatch({ type: CART_ARTICLE_ADD, payload: cartArticle });
        }
      )
    );
  };
  return (
    <div className={classes.openPrice}>
      <List className={classes.list}>
        <ListItem divider className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={
              article.name.length > 0
                ? article.name
                : `Product Name (${article.plu})`
            }
            secondary={formatDisplayPrice(price, currency)}
          />
          <ListItemSecondaryAction className={classes.listItemAction}>
            {hasOptions ? (
              <Select
                value={price}
                onChange={(e) => setPrice(Number(e.target.value))}
                className={classes.select}
              >
                {options.map((o, i) => (
                  <MenuItem key={i} value={o}>
                    {formatDisplayPrice(o, currency)}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                value={price}
                onChange={(e) => setPrice(Number(e.target.value))}
                type="number"
                label={language.Amount}
                placeholder={language.Add_Amount}
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: { input: classes.input },
                }}
              />
            )}
            <Button
              className={classes.addButton}
              disabled={!price || price < 1 || busy}
              onClick={handleAdd}
              color="primary"
            >
              {language.Article_Add}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
};

export default OpenPrice;
