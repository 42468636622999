import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Context, TOAST, CART_ARTICLE_ADD } from "../../context";
import { getCartArticle } from "../cart/util";
import { isPersonal, getSaleData } from "./util";
import { addArticle } from "../../common/sale";
import ProductContent from "../Styles/ProductContent";
import ProductHeader from "./header";
import ProductDescription from "./description";
import ProductImage from "./image";
import OverrideStartDate from "./overrideStartDate";
import OpenPrice from "./openPrice";
import ArticleList from "./articleList";
import ArticleSelect from "./articleSelect";
import Personal from "./personal";
import Modification from "../Modification";

const useStyles = makeStyles((theme) => ({
  product: {},
}));

const Product = ({ product }) => {
  const [overrideDate, setOverrideDate] = useState();
  const [photoOpen, setPhotoOpen] = useState(false);
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [quantity, setQuantity] = useState(1);
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = React.useState({ id: null, loading: false });
  const classes = useStyles();
  const isPersonalTicket = isPersonal(config, product);
  const productGroupConfig = config.productGroupConfig.find(
    (x) => x.name === product.name
  );

  const handleAdd = (product, article, values, actions, photo) => {
    const cartArticle = getCartArticle(product, config, article);
    setLoading({ id: product.plu, loading: true });
    const callback = (status, msg) => {
      setPhoto("");
      setOverrideDate();
      if (status >= 400) {
        setQuantity(1);
        return dispatch({
          type: TOAST,
          payload: {
            text: msg,
            variant: "error",
          },
        });
      }
      setQuantity(1);
      setLoading({ id: product.plu, loading: false });
      dispatch({ type: CART_ARTICLE_ADD, payload: cartArticle });
    };
    const saleData = getSaleData(
      product,
      article,
      values,
      photo,
      quantity,
      overrideDate
    );
    rdxDispatch(addArticle(saleData, callback));
    values && actions.resetForm();
  };
  if (product.isInfo) {
    return (
      <div className={classes.product}>
        <ProductHeader text={product.name} />
        <ProductDescription id={product.id} />
      </div>
    );
  }
  return (
    <div className={classes.product}>
      <ProductHeader text={product.name} />
      <ProductDescription id={product.id} description={product.description} />
      {product.isModification ? (
        <Modification />
      ) : (
        <ProductContent>
          <ProductImage images={product.images} />
          <div>
            {product.overrideStartDate && (
              <OverrideStartDate
                product={product}
                setOverrideDate={setOverrideDate}
              />
            )}
            {isPersonalTicket ? (
              <Personal
                product={product}
                photo={photo}
                photoOpen={photoOpen}
                setPhotoOpen={setPhotoOpen}
                savePhoto={(photo) => setPhoto(photo)}
                handleAdd={(article, values, actions) =>
                  handleAdd(product, article, values, actions, photo)
                }
              />
            ) : (
              <div className={classes.addContainer}>
                {product.isOpenPrice ? (
                  <OpenPrice
                    article={
                      productGroupConfig?.useGroupsAsItems
                        ? product.articles[0]
                        : product
                    }
                    product={product}
                    add={(article) => handleAdd(article)}
                  />
                ) : !productGroupConfig?.useGroupsAsItems ||
                  product.articles.length < 3 ? (
                  <ArticleList
                    add={(article) => handleAdd(article)}
                    articles={product.articles ? product.articles : [product]}
                    language={language}
                    isOverrideStartDate={product.overrideStartDate}
                    overrideStartDate={overrideDate}
                    setQuantity={setQuantity}
                    quantity={quantity}
                    isLoading={loading}
                  />
                ) : (
                  <ArticleSelect
                    add={(group, article) => handleAdd(group, article)}
                    articles={product.articles}
                    group={product}
                    language={language}
                    useGroupsAsItems={productGroupConfig?.useGroupsAsItems}
                  />
                )}
              </div>
            )}
          </div>
        </ProductContent>
      )}
    </div>
  );
};

export default Product;
