import React, { useState } from "react";
import SearchV2 from "../../common/Search/SearchV2";
import Cart from "../cart";
import CartV2 from "../CartV2";
import HamburgerMenu from "./HamburgerMenu";
import LogoV2 from "./LogoV2";
import MobileMenuOverlay from "./MobileMenuOverlay";

const MobileMenuV2 = ({ changeBackground, config, language, culture }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="mobileMenuV2Wrapper" tabIndex={-1}>
        <div className="hamburgerMenuWrapper" aria-live="polite">
          <HamburgerMenu
            open={open}
            setOpen={setOpen}
            changeBackground={changeBackground}
          />
        </div>
        {!open ? (
          <>
            <LogoV2 changeBackground={changeBackground} />
            <SearchV2 changeBackground={changeBackground} mobile={true} />
            {config?.useCartDrawer ? (
              <CartV2 changeBackground={changeBackground} />
            ) : (
              <Cart />
            )}
          </>
        ) : (
          ""
        )}
      </div>
      <div style={{ position: "relative" }}>
        <MobileMenuOverlay
          changeBackground={changeBackground}
          open={open}
          setOpen={setOpen}
          config={config}
          language={language}
          culture={culture}
        />
      </div>
    </>
  );
};

export default MobileMenuV2;
