import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import PrevIcon from "@material-ui/icons/ChevronLeft";
import NextIcon from "@material-ui/icons/ChevronRight";
import ImageGallery from "react-image-gallery";
import { ReactComponent as Placeholder } from "../../images/placeholder.svg";
import SocialShare from "../common/socialShare";

const useStyles = makeStyles((theme) => ({
  productImage: {},
  placeholder: {
    background: theme.app.background[2],
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "4px",
    marginTop: "8px",
  },
  carouselButton: {
    backgroundColor: "rgba(0,0,0,0.23)",
    color: "white",
  },
  left: {
    position: "absolute",
    zIndex: 100,
    left: 0,
    top: "50%",
    transform: "translate(0, -50%)",
  },
  right: {
    position: "absolute",
    zIndex: 100,
    right: 0,
    top: "50%",
    transform: "translate(0, -50%)",
  },
}));

const ProductImage = ({ images }) => {
  const classes = useStyles();
  const carouselImages = images?.map((img) => ({
    original: img,
  }));
  if (!images || images.length < 1)
    return <Placeholder className={classes.placeholder} />;
  return (
    <div className={classes.productImage}>
      <div className={classes.carousel}>
        <ImageGallery
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          items={carouselImages}
          renderLeftNav={(onClick, disabled) => (
            <IconButton
              className={clsx(
                classes.carouselButton,
                classes.left,
                "product__image-carousel__left-button"
              )}
              onClick={onClick}
              disabled={disabled}
            >
              <PrevIcon />
            </IconButton>
          )}
          renderRightNav={(onClick, disabled) => (
            <IconButton
              className={clsx(
                classes.carouselButton,
                classes.right,
                "product__image-carousel__left-button"
              )}
              onClick={onClick}
              disabled={disabled}
            >
              <NextIcon />
            </IconButton>
          )}
        />
      </div>
      <SocialShare />
    </div>
  );
};

export default ProductImage;
