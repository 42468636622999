import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  flagContainer: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Flag = ({ culture }) => {
  const classes = useStyles();
  switch (culture) {
    case "sv-SE":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="sv-SE"
          >
            <circle style={{ fill: "#FFDA44" }} cx="256" cy="256" r="256" />
            <g>
              <path
                style={{ fill: "#0052B4" }}
                d="M200.349,222.609h309.484C493.47,97.002,386.067,0,256,0c-19.114,0-37.733,2.113-55.652,6.085v216.524H200.349z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M133.565,222.608V31.127C63.271,69.481,12.95,139.832,2.167,222.609h131.398V222.608z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M133.564,289.391H2.167c10.783,82.777,61.104,153.128,131.398,191.481L133.564,289.391L133.564,289.391z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M200.348,289.392v216.523C218.267,509.887,236.886,512,256,512c130.067,0,237.47-97.002,253.833-222.609H200.348V289.392z"
              />
            </g>
          </svg>
        </div>
      );
    case "en-US":
    case "en-GB":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="en-US"
          >
            <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
            <g>
              <path
                style={{ fill: "#0052B4" }}
                d="M52.92,100.142c-20.109,26.163-35.272,56.318-44.101,89.077h133.178L52.92,100.142z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M503.181,189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075,89.076H503.181z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M8.819,322.784c8.83,32.758,23.993,62.913,44.101,89.075l89.074-89.075L8.819,322.784L8.819,322.784z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M411.858,52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177L411.858,52.921z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M100.142,459.079c26.163,20.109,56.318,35.272,89.076,44.102V370.005L100.142,459.079z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M189.217,8.819c-32.758,8.83-62.913,23.993-89.075,44.101l89.075,89.075V8.819z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M322.783,503.181c32.758-8.83,62.913-23.993,89.075-44.101l-89.075-89.075V503.181z"
              />
              <path
                style={{ fill: "#0052B4" }}
                d="M370.005,322.784l89.075,89.076c20.108-26.162,35.272-56.318,44.101-89.076H370.005z"
              />
            </g>
            <g>
              <path
                style={{ fill: "#D80027" }}
                d="M509.833,222.609h-220.44h-0.001V2.167C278.461,0.744,267.317,0,256,0c-11.319,0-22.461,0.744-33.391,2.167v220.44v0.001H2.167C0.744,233.539,0,244.683,0,256c0,11.319,0.744,22.461,2.167,33.391h220.44h0.001v220.442C233.539,511.256,244.681,512,256,512c11.317,0,22.461-0.743,33.391-2.167v-220.44v-0.001h220.442C511.256,278.461,512,267.319,512,256C512,244.683,511.256,233.539,509.833,222.609z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M322.783,322.784L322.783,322.784L437.019,437.02c5.254-5.252,10.266-10.743,15.048-16.435l-97.802-97.802h-31.482V322.784z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M189.217,322.784h-0.002L74.98,437.019c5.252,5.254,10.743,10.266,16.435,15.048l97.802-97.804V322.784z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M189.217,189.219v-0.002L74.981,74.98c-5.254,5.252-10.266,10.743-15.048,16.435l97.803,97.803H189.217z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M322.783,189.219L322.783,189.219L437.02,74.981c-5.252-5.254-10.743-10.266-16.435-15.047l-97.802,97.803V189.219z"
              />
            </g>
          </svg>
        </div>
      );
    case "de-DE":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="de-DE"
          >
            <path
              style={{ fill: "#FFDA44" }}
              d="M15.923,345.043C52.094,442.527,145.929,512,256,512s203.906-69.473,240.077-166.957L256,322.783L15.923,345.043z"
            />
            <path d="M256,0C145.929,0,52.094,69.472,15.923,166.957L256,189.217l240.077-22.261C459.906,69.472,366.071,0,256,0z" />
            <path
              style={{ fill: "#D80027" }}
              d="M15.923,166.957C5.633,194.69,0,224.686,0,256s5.633,61.31,15.923,89.043h480.155C506.368,317.31,512,287.314,512,256s-5.632-61.31-15.923-89.043H15.923z"
            />
          </svg>
        </div>
      );
    case "fr-FR":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="fr-FR"
          >
            <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
            <path
              style={{ fill: "#D80027" }}
              d="M512,256c0-110.071-69.472-203.906-166.957-240.077v480.155C442.528,459.906,512,366.071,512,256z"
            />
            <path
              style={{ fill: "#0052B4" }}
              d="M0,256c0,110.071,69.473,203.906,166.957,240.077V15.923C69.473,52.094,0,145.929,0,256z"
            />
          </svg>
        </div>
      );
    case "nn-NO":
    case "nb-NO":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="nn-NO"
          >
            <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
            <g>
              <path
                style={{ fill: "#D80027" }}
                d="M8.819,322.783c14.812,54.959,47.457,102.593,91.355,136.322V322.783H8.819z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M233.739,511.026C241.077,511.658,248.498,512,256,512c118.279,0,217.805-80.221,247.181-189.217H233.739V511.026z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M503.181,189.217C473.805,80.221,374.279,0,256,0c-7.502,0-14.923,0.342-22.261,0.974v188.243H503.181z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M100.174,52.895c-43.898,33.73-76.543,81.363-91.355,136.322h91.355V52.895z"
              />
            </g>
            <path
              style={{ fill: "#0052B4" }}
              d="M509.833,222.609H200.349h-0.001V6.085c-23.658,5.246-46.087,13.749-66.783,25.042v191.48v0.001H2.167C0.742,233.539,0,244.683,0,256s0.742,22.461,2.167,33.391h131.397h0.001v191.481c20.696,11.292,43.125,19.797,66.783,25.042V289.394v-0.002h309.485C511.256,278.461,512,267.317,512,256S511.256,233.539,509.833,222.609z"
            />
          </svg>
        </div>
      );
    case "fi-FI":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="fi-FI"
          >
            <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
            <path
              style={{ fill: "#0052B4" }}
              d="M509.833,222.609H200.349h-0.001V6.085c-23.658,5.246-46.087,13.749-66.783,25.042v191.48v0.001H2.167C0.742,233.539,0,244.683,0,256s0.742,22.461,2.167,33.391h131.397h0.001v191.481c20.696,11.292,43.125,19.797,66.783,25.042V289.394v-0.002h309.485C511.256,278.461,512,267.317,512,256S511.256,233.539,509.833,222.609z"
            />
          </svg>
        </div>
      );
    case "da-DK":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="da-DK"
          >
            <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
            <g>
              <path
                style={{ fill: "#D80027" }}
                d="M200.349,222.609h309.484C493.47,97.002,386.067,0,256,0c-19.115,0-37.732,2.113-55.652,6.085v216.524H200.349z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M133.565,222.608V31.127C63.272,69.481,12.95,139.832,2.167,222.609h131.398V222.608z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M133.564,289.391H2.167c10.783,82.777,61.105,153.128,131.398,191.481L133.564,289.391L133.564,289.391z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M200.348,289.392v216.523C218.268,509.887,236.885,512,256,512c130.067,0,237.47-97.002,253.833-222.609H200.348V289.392z"
              />
            </g>
          </svg>
        </div>
      );
    case "it-IT":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="it-IT"
          >
            <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
            <path
              style={{ fill: "#D80027" }}
              d="M512,256c0-110.071-69.472-203.906-166.957-240.077v480.155C442.528,459.906,512,366.071,512,256z"
            />
            <path
              style={{ fill: "#6DA544" }}
              d="M0,256c0,110.071,69.472,203.906,166.957,240.077V15.923C69.472,52.094,0,145.929,0,256z"
            />
          </svg>
        </div>
      );
    case "es-ES":
      return (
        <div className={classes.flagContainer}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width="1.25rem"
            height="1.25rem"
            data-testid="es-ES"
          >
            <path
              style={{ fill: "#FFDA44" }}
              d="M0,256c0,31.314,5.633,61.31,15.923,89.043L256,367.304l240.077-22.261C506.367,317.31,512,287.314,512,256s-5.633-61.31-15.923-89.043L256,144.696L15.923,166.957C5.633,194.69,0,224.686,0,256z"
            />
            <g>
              <path
                style={{ fill: "#D80027" }}
                d="M496.077,166.957C459.906,69.473,366.071,0,256,0S52.094,69.473,15.923,166.957H496.077z"
              />
              <path
                style={{ fill: "#D80027" }}
                d="M15.923,345.043C52.094,442.527,145.929,512,256,512s203.906-69.473,240.077-166.957H15.923z"
              />
            </g>
          </svg>
        </div>
      );

    default:
      throw new Error(`wrong culture for flag: ${culture}`);
  }
};

export default Flag;
