import isEmail from "sane-email-validation";

const validate = (values, config, language) => {
  let errors = {};

  //firstName
  if (
    !config ||
    (config.requiredFields.includes("firstName") && !values.firstName)
  ) {
    errors.firstName = `${language.Form_FirstName} ${language.Form_IsRequired}`;
  }

  //lastName
  if (
    !config ||
    (config.requiredFields.includes("lastName") && !values.lastName)
  ) {
    errors.lastName = `${language.Form_LastName} ${language.Form_IsRequired}`;
  }

  //email
  if (!config || (config.requiredFields.includes("email") && !values.email)) {
    errors.email = `${language.Form_Email} ${language.Form_IsRequired}`;
  } else if (
    values.email &&
    values.email.length > 0 &&
    !isEmail(values.email)
  ) {
    errors.email = `${language.Form_Invalid_Email}`;
  }

  //civicRegistrationNumber
  if (
    !config ||
    (config.requiredFields.includes("civicRegistrationNumber") &&
      !values.civicRegistrationNumber)
  ) {
    errors.civicRegistrationNumber = `${language.Form_CivicRegistrationNumber} ${language.Form_IsRequired}`;
  } else if (
    values.civicRegistrationNumber &&
    values.civicRegistrationNumber.length > 0 &&
    !/^(19|20)[0-9]{6}[- ]?[0-9]{4}$/.test(values.civicRegistrationNumber)
  ) {
    errors.civicRegistrationNumber =
      language.Form_Invalid_CivicRegistrationNumber;
  }

  //cellPhone
  if (
    !config ||
    (config.requiredFields.includes("cellPhone") && !values.cellPhone)
  ) {
    errors.cellPhone = `${language.Form_CellPhone} ${language.Form_IsRequired}`;
  }

  //street
  if (!config || (config.requiredFields.includes("street") && !values.street)) {
    errors.street = `${language.Form_Street} ${language.Form_IsRequired}`;
  }

  //streetNumber
  if (
    !config ||
    (config.requiredFields.includes("streetNumber") && !values.streetNumber)
  ) {
    errors.streetNumber = `${language.Form_StreetNumber} ${language.Form_IsRequired}`;
  }

  //zipCode
  if (
    !config ||
    (config.requiredFields.includes("zipCode") && !values.zipCode)
  ) {
    errors.zipCode = `${language.Form_ZipCode} ${language.Form_IsRequired}`;
  }

  //city
  if (!config || (config.requiredFields.includes("city") && !values.city)) {
    errors.city = `${language.Form_City} ${language.Form_IsRequired}`;
  }

  //countryCode
  if (
    !config ||
    (config.requiredFields.includes("countryCode") && !values.countryCode)
  ) {
    errors.countryCode = `${language.Form_Country} ${language.Form_IsRequired}`;
  }
  return errors;
};

export default validate;
